import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataService from "../services/data.service";
import AuthService from "../services/auth.service";
import Footer from "../common/Footer";
import Header from "../common/Header";
import BlogDetailSection from "./component/blog/blog-detail-section";

const Blogdetails = () => {
  const params = useParams();
  const auth = AuthService.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const [blog,setBlog] = useState([]);

   
  const getBlog = async ()=>{
    await DataService.getBlogDetails(params.id).then((data) => {
        setLoading(false);
        const blogData = data.data.data;
        setBlog(blogData);
        console.log(blog);
    }).catch((error)=>{
        const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        setLoading(false);
    })
}
useEffect(() => {
  getBlog();
}, [params.id]);
  return (
    <>
        <Header />
        <div className="container">
            <div className="row">
            <div className="col-md-12 px-5 mb-5">
            <BlogDetailSection data={blog}/>
            {/* <Comments/>
            <PostComments/> */}
            </div>
            {/* <div className="col-md-4">
                <Asidebar/>
            </div> */}
            </div>
        </div>
        
         <Footer />
    </>
  );
};

export default Blogdetails;