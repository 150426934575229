import React, { useEffect } from 'react'
import Footer from "../common/Footer";
import Header from "../common/Header";
import Banner from '../common/Banner';
import AbusePolicyDealit from './component/abusePolicy/AbusePolicyDealit';

const AbusePolicy = () => {
    useEffect(() => {
        document.title = "DEALITT Abuse and Threats Policy";
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Banner title={"DEALITT Abuse and Threats Policy"} />
            <AbusePolicyDealit />
            <Footer />
        </>
    )
}

export default AbusePolicy