import React, { useState, useEffect, useRef } from "react";
import { Link, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/sidebar'
import Profile from "../../images/profileimg.png"
import DataService from "../../services/data.service";
const AccountDetails = () => {
  const form = useRef();
  const navigate = useNavigate();
  const logout = (e) => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login-page");
  };
  const [profiledata, setProfileData] = useState({});
  const [loading, setLoading] = useState(true);
  const [editForm, seteditForm] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isDivVisible, setIsDivVisible] = useState(false);
  const [secondDivVisible, setSecondDivVisible] = useState(true);

  const toggleDiv = () => {
    setIsDivVisible(!isDivVisible);
  };
  useEffect(() => {
    document.title = "Profile";
    getData();
  }, []);

  const enableEdit = (e) => {
    seteditForm(true);
  };

  const cancelEdit = (e) => {
    seteditForm(false);
  };
  const getData = () => {
    DataService.getUserDetail()
      .then((data) => {
        setProfileData(data.data.data);
        setLoading(false);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = new FormData();
    // if(file && file.length>0){
    //     data.append('image', file[0])
    // }
    data.append('first_name', firstName ? firstName : profiledata.first_name);
    data.append('last_name', lastName ? lastName : profiledata.last_name);
    data.append('email', email ? email : profiledata.email)
    data.append('phone', phone ? phone : profiledata.phone);
    DataService.updateUser(data, profiledata.id).then(
      () => {
        toast.success('Profile updated successfully!', {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false);
        setIsDivVisible(false)
        setSecondDivVisible(true);
        getData();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    );
  };

  return (
    <>
      <div className="bg-grey h-100">
        <Header />

        <section className="content-area mt-4">
          <Sidebar />
          <div className="Right-content">
            <div className="main-content">
              <div className="row bg-white p-3 border border-bottom">
                <div className="row  align-items-center">
                  {/* <div className="col-sm-2">
            <div className="profile">
              <img src={Profile} alt="Profile-img" />
              <input type="file" className="custom-file-input" />
            </div>
          </div> */}
                  <div className="col-sm-6 new_edit">
                    <p><span>Name :</span>{profiledata.first_name ? profiledata.first_name : ""} {profiledata.last_name}</p>
                    <p><span>Email :</span>{profiledata.email ? profiledata.email : ""}</p>
                    <p><span>Phone :</span>{profiledata.phone ? profiledata.phone : ""}</p>
                  </div>
                  <div className="col-sm-6">
                    {editForm ? (
                      <div className="d-flex align-items-center justify-content-end mob_justify_center">
                        <button onClick={toggleDiv} className="main_button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#ffffff"
                            class="bi bi-pencil-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>
                          Edit
                        </button>
                      </div>
                    ) : ""}
                  </div>
                </div>
              </div>
              <div className="row bg-white p-3 justify-content-center">
                <div className="col-md-10 pt-md-0 pt-3">
                  <div className="row">
                    <div className="col-sm-12">
                      {/* <div className="d-flex">
                <p className="me-4">Your member ID:</p>
                <span>
                  <strong>in19036500485freo</strong>
                </span>
              </div> */}
                    </div>
                  </div>
                  {isDivVisible && <div>
                    <div className="d-flex align-items-center justify-content-end mob_justify_center">
                      <button
                        onClick={cancelEdit}
                        className="main_button red_button me-4"
                      >
                        Cancel
                      </button>
                      <button disabled={loading} onClick={handleSubmit} className="main_button green_button">
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Save</span>
                      </button>
                    </div>
                    <form onSubmit={handleSubmit} className="mt-5 profile-form" ref={form}>
                      <div className="row mt-lg-3">
                        <div className="col-sm-6">
                          <label for="first_name">
                            First Name<span className="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            id="first_name"
                            defaultValue={profiledata.first_name}
                            onChange={(e) => setFirstName(e.target.value)}
                            className="form-control myform_input"
                          />
                        </div>
                        <div className="col-sm-6">
                          <label for="last_name">
                            Last Name<span className="astrick">*</span>
                          </label>
                          <input
                            type="text"
                            id="last_name"
                            defaultValue={profiledata.last_name}
                            onChange={(e) => setLastName(e.target.value)}
                            className="form-control myform_input"
                          />
                        </div>
                      </div>
                      <div className="row mb-4 mt-lg-4">
                        <div className="col-sm-6">
                          <label for="email">
                            Email<span className="astrick">*</span>
                          </label>
                          <input
                            type="email"
                            id="email"
                            defaultValue={profiledata.email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control myform_input"
                          />
                        </div>
                        <div className="col-sm-6 input_phon">
                          <label for="phome">
                            Phone<span className="astrick">*</span>
                          </label>
                          <PhoneInput
                            required
                            value={profiledata.phone}
                            onChange={setPhone}
                            defaultCountry="US"
                            style={{ width: "100%" }}
                            className="phone_num"
                          />
                        </div>
                      </div>

                    </form></div>}


                  {/* <div className="row">
            <div className="col-sm-12 d-flex justify-content-end">
              <button className="main_button" onClick={logout}>
                Logout
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5 2C5 1.72386 5.22386 1.5 5.5 1.5L13.5 1.5C13.7761 1.5 14 1.72386 14 2V11C14 11.2761 13.7761 11.5 13.5 11.5H5.5C5.22386 11.5 5 11.2761 5 11V9C5 8.72386 4.77614 8.5 4.5 8.5C4.22386 8.5 4 8.72386 4 9V11C4 11.8284 4.67157 12.5 5.5 12.5H13.5C14.3284 12.5 15 11.8284 15 11V2C15 1.17157 14.3284 0.5 13.5 0.5L5.5 0.5C4.67157 0.5 4 1.17157 4 2V4C4 4.27614 4.22386 4.5 4.5 4.5C4.77614 4.5 5 4.27614 5 4V2Z"
                    fill="#F6F5FA"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.8536 6.85355C11.0488 6.65829 11.0488 6.34171 10.8536 6.14645L7.85355 3.14645C7.65829 2.95118 7.34171 2.95118 7.14645 3.14645C6.95118 3.34171 6.95118 3.65829 7.14645 3.85355L9.29289 6L0.5 6C0.223858 6 0 6.22386 0 6.5C0 6.77614 0.223858 7 0.5 7H9.29289L7.14645 9.14645C6.95118 9.34171 6.95118 9.65829 7.14645 9.85355C7.34171 10.0488 7.65829 10.0488 7.85355 9.85355L10.8536 6.85355Z"
                    fill="#F6F5FA"
                  />
                </svg>
              </button>
            </div>
          </div> */}
                </div>
              </div>

            </div>
            <Footer />
          </div>
        </section>


      </div>

    </>
  )
}

export default AccountDetails