import React, { useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import "../customCss/Home.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataService from "../services/data.service";
import AuthService from "../services/auth.service";
import img2 from "../images/part_2_product1.png";
import img3 from "../images/part_2_product2.png";
import img1 from "../images/product1.png";
import img5 from "../images/iphone.jpg";
import HomeCardFirst from "./component/homelayout/NewArrivals";
import NewArrivals from "./component/homelayout/NewArrivals";
import ServiceSection from "./component/homelayout/ServiceSection";
import HomeCardSecond from "./component/homelayout/HomeCardSecond";
// import HomeCardThird from "./component/homelayout/HomeCardThird";
import HomeBanner from "./component/homelayout/HomeBanner";
import RequestSec from "./component/homelayout/RequestSec";
import BestSellers from "./component/homelayout/BestSellers";
import LatestProducts from "./component/homelayout/LatestProducts";
import TopDeals from "./component/homelayout/TopDeals";
import Preloader from "../common/Preloader";
import { toast } from "react-toastify";
import HelperService from "../services/helper.service";
import AllProduct from "./component/homelayout/AllProduct";
import HomeCategory from "./component/homelayout/HomeCategory";
import Newsletter from "./component/homelayout/Newsletter";
import Popup from "./CartPopUp";

function Home() {
  const navigate = useNavigate();
  const [latest, setLatest] = useState([]);
  const [newArrival, setNewArrival] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [topDeal, setTopDeal] = useState([]);
  const [banners, setBanners] = useState([]);
  const [masterCatData, setMasterCatData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [electronics, setElectronics] = useState([]);
  const [brand, setBrand] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updateWishlist, setUpdateWishlist] = useState(false);
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [ipData, setIpData] = useState([]);

  const auth = AuthService.getCurrentUser();
  useEffect(() => {
    document.title = "DEALITT";
    getData();
    getProducts();
    getIpData();
  }, []);



  const getIpData = async () => {
    await DataService.getIPData().then((data) => {
      setIpData(data.data.data);
      setLoading(false);
   localStorage.setItem('ipData', JSON.stringify(data.data.data.id));

    });

  };
  // console.log(ipData)




  const getData = async () => {
    const userId = auth ? auth.id : "";
    await DataService.getHomePageData(userId).then((data) => {
      setMasterCatData(data.data.masterCategories);
      setCatData(data.data.categories);
      setBanners(data.data.banners);
      setLoading(false);
    });
    await DataService.getHomePageData(userId, 1).then((data) => {
      setBestSeller(data?.data?.best_sellers);
      setLatest(data?.data?.products);
    });
    await DataService.getHomePageData(userId, 2).then((data) => {
      setTopDeal(data?.data?.top_deals);
      setBrand(data.data.brands);
    });
    await DataService.getHomePageData(userId, 3).then((data) => {
      setNewArrival(data?.data?.new_arrivals);
      setElectronics(data.data.electronics);
    });
  };
  const addTowhislist = (event, item) => {
    if (auth) {
      var element = event.currentTarget;
      if (element.className == "remove-cart") {
        //get cat id..
        if (item?.Carts) {
          DataService.deleteWishlist(item.id).then(
            () => {
              toast.success("Product removed from wishlist!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              element.classList.remove("remove-cart");
              element.checked = false;
              setUpdateWishlist(true);
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.msg) ||
                error.message ||
                error.toString();

              toast.error(resMessage, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
        }
      } else {
        const data = {};
        data.product_title = item.name;
        data.price = item.offer_price;
        data.product_sku = item.sku;
        data.quantity = "1";
        data.variant = "";
        data.product_id = item.id;
        data.type = "whislist";
        DataService.addCart(data).then(
          () => {
            toast.success("Product added to whislist", {
              position: toast.POSITION.TOP_RIGHT,
            });
            element.checked = true;
            element.classList.add("remove-cart");
            setUpdateWishlist(true);
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            toast.error(resMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
      }
    } else {
      navigate("/login-page");
    }
  };

  const addTocart = (event, item) => {
    const auth = AuthService.getCurrentUser();
    const data1 = {};
    data1.product_title = item.name;
    data1.price = item.offer_price;
    data1.product_sku = item.sku;
    data1.quantity = item.cart_qty ? item.cart_qty : "1";
    data1.variant = item.variation ? item.variation : null;
    data1.product_id = item.id;
    data1.type = "cart";
    if (auth) {
      setLoading(true);
      DataService.addCart(data1).then(
        () => {
          setLoading(false);
          setShowPopup(true);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.msg) ||
            error.message ||
            error.toString();

          setLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    } else {
      setLoading(true);
      HelperService.setLocalCart(data1);
      setLoading(false);
      toast.success("Product added to cart!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      HelperService.updateCartCount();
    }
  };
  const getProducts = async () => {
    await searchProduct({
      mastCatId: data.mastCatId ? (data.catId ? [] : [data.mastCatId]) : [],
      catId: data.catId ? (data.subCatId ? [] : [data.catId]) : [],
      subCatId: data.subCatId ? [data.subCatId] : [],
      brandId: data.brandId ? [data.brandId] : [],
      dates: [],
      keyword: data.keyword ? data.keyword : "",
      filter: data.filter ? data.filter : "",
      limit: 6,
    });
    setLoading(false);
  };
  const searchProduct = async (data) => {
    const userId = auth ? auth.id : "";
    await DataService.searchProduct(data, userId).then((data) => {
      setData(data.data.products);
      setTotalRecords(data.data.products_count);
    });
  };



  return (
    <div>
      {showPopup && <Popup setShowPopup={setShowPopup} />}
      <Preloader />
        <Header />
        <HomeBanner   />
        {/* <RequestSec /> */}
        <ServiceSection data={masterCatData} banners={banners} />
        <div className="container main_card">
          <div className="card_part_1">
            <div className="row py-3">
              <div className="col-md-6 mb-3">
                <div className="part_1_main_body main_body pb-3 pt-2">
                  <h5 className="px-3 pt-2 mb-2">Clearance Deals</h5>
                  <div className="row px-4 g-2">
                    <NewArrivals
                      data={newArrival}
                      addTowhislist={addTowhislist}
                      addTocart={addTocart}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="part_1_main_body main_body pb-3 pt-2">
                  <h5 className="px-3 pt-2 mb-2">Best Sellers</h5>
                  <div className="row px-4 g-2">
                    <BestSellers
                      data={bestSeller}
                      addTowhislist={addTowhislist}
                      addTocart={addTocart}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="part_1_main_body main_body pb-3 pt-2">
                  <h5 className="px-3 pt-2 mb-2">Latest Products</h5>
                  <div className="row px-4 g-2">
                    <LatestProducts
                      data={latest}
                      addTowhislist={addTowhislist}
                      addTocart={addTocart}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="part_1_main_body main_body pb-3 pt-2">
                  <h5 className="px-3 pt-2 mb-2">Top Deals</h5>
                  <div className="row px-4 g-2">
                    <TopDeals
                      data={topDeal}
                      addTowhislist={addTowhislist}
                      addTocart={addTocart}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomeCategory data={catData} />
          <AllProduct
            addTowhislist={addTowhislist}
            addTocart={addTocart}
            data={data}
            totalRecords={totalRecords}
            searchProduct={searchProduct}
          />
          </div>
          <Newsletter/>
        
      <Footer />
    </div>
  );
}

export default Home;
