import React from 'react'
import '../../../customCss/PaymentMethods.css'

const PaymentMethodsDealit = () => {
    return (
        <>
            <section class="paymentMethodsSec">
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <p className='text-center mb-2'>At DEALITT, we prioritize the safety and convenience of your transactions. We offer a range of secure payment methods to meet your business needs. <br />Our platform supports the following payment options:</p>
                            <div className='row g-4 my-3'>
                                <div className='col-sm-4'>
                                    <div className='paymentMInner'>
                                        <h3>01.</h3>
                                        <h4>Credit & Debit Cards</h4>
                                        <ul>
                                            <li>Visa </li>
                                            <li>Mastercard </li>
                                            <li>American Express </li>
                                            <li>Discover </li>
                                        </ul>
                                        <p>You can easily pay using your Visa, Mastercard, American Express, or Discover cards, ensuring a smooth and secure transaction process.</p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='paymentMInner'>
                                        <h3>02.</h3>
                                        <h4>Digital Wallets</h4>
                                        <ul>
                                            <li>PayPal </li>
                                            <li>Google Pay </li>
                                            <li>Apple Pay </li>
                                            <li>Samsung Pay </li>
                                        </ul>
                                        <p>For a fast and secure checkout, use PayPal, Google Pay, Apple Pay, or Samsung Pay. These digital wallets are designed to protect your financial information and make payments hassle-free.</p>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='paymentMInner'>
                                        <h3>03.</h3>
                                        <h4>Secure Payment Option</h4>
                                        <ul>
                                            <li>Escrow Payment</li>
                                        </ul>
                                        <p>DEALITT offers an Escrow payment option to provide an extra layer of security for both buyers and sellers. With Escrow, the payment is held securely by a trusted third party until the buyer confirms receipt of the goods in satisfactory condition. This ensures that both parties are protected throughout the transaction process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <h3>Return and Refund Policy</h3>
                                    <p>We understand that sometimes things may not go as planned. DEALITT provides a straightforward return and refund process to protect your interests:</p>
                                    <ul>
                                        <li><strong>Return Option:</strong> If you receive a damaged or incorrect product, you can initiate a return request through our platform. Simply go to your order history, select the item, and choose the return option.</li>
                                        <li><strong>Claim Process:</strong> In the event of a damaged product, please file a claim by submitting photos of the damage through our platform. Our customer support team will review the claim and guide you through the process of returning the item and receiving a refund.</li>
                                        <li><strong>Refund:</strong> Once your return is approved, the refund will be processed back to your original payment method. The funds will be released as soon as the seller confirms the return or as per the terms of our Escrow payment protection.</li>
                                    </ul>
                                    <p>If you have any issues or questions, please don't hesitate to contact our customer service team at <a href="mailto: support@dealitt.com">support@dealitt.com.</a> We're here to help!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PaymentMethodsDealit