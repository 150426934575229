import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import DataService from "../services/data.service";
import Footer from "../common/Footer";
import Header from "../common/Header";
import PaymentMethod from "../pages/checkout/payment-method";
import { toast } from 'react-toastify';
import AuthService from "../services/auth.service";
import "../customCss/Checkout.css";

let orderSent = false;

const Checkout = () => {
    const buttonref = useRef();
    const buttonref1 = useRef();
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentMethod, setPaymentMethod] = useState('');
    const [totalAmount, setTotalAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const [buynow, setBuynow] = useState(false); // Add this line
    const [data, setData] = useState([]);
    const auth = AuthService.getCurrentUser();

    React.useEffect(() => {
        document.title = "Checkout";
        window.scrollTo(0, 0);

        if (!auth) {
            navigate("/login?url=checkout");
        } else {
            const url = window.location.href;
            const queryParam = url.split('=');
            if (queryParam.length > 1 && url.includes('buynow')) {
                setBuynow(true);
                getTempProduct();
            } else {
                getProduct();
            }
        }

        return;
    }, []);


    const getProduct = async () => {
        setLoading(true);
        try {
            const response = await DataService.getCart('cart');
            const cartItems = response.data.data;
            let total = 0;
            cartItems.forEach(item => {
                total += item.price * item.quantity;
            });
            setTotalAmount(total);
            setData(cartItems);
        } catch (error) {
            const resMessage = (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();
            toast.error(resMessage, { position: toast.POSITION.TOP_RIGHT });
        } finally {
            setLoading(false);
        }
    };

    const getTempProduct = async () => {
        setLoading(true);
        try {
            const response = await DataService.getTempCart();
            const tempCartItems = response.data.data;
            let total = 0;
            tempCartItems.forEach(item => {
                total += item.price * item.quantity;
            });
            setTotalAmount(total);
            setData(tempCartItems);
        } catch (error) {
            const resMessage = (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();
            toast.error(resMessage, { position: toast.POSITION.TOP_RIGHT });
        } finally {
            setLoading(false);
        }
    };

    const navigateto = () => {
        navigate('/myorders');
    };

    return (
        <div className="bg-white">
            <Header />
            {!loading ? (
                <div className="method_main">
                    <div className="container">
                        <div className="row">
                            <PaymentMethod
                                paymentMethodChange={setPaymentMethod}
                                paymentMethod={paymentMethod}
                                data={data}
                                buynow={buynow}
                                loading={orderLoading}
                                totalAmount={totalAmount}
                                auth={auth}
                            />
                        </div>
                        <Link data-bs-toggle="modal" data-bs-target='#staticBackdropOrder' style={{ display: "none" }} ref={buttonref}></Link>
                        <div className="modal fade" id="staticBackdropOrder" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        <img src="../assets/img/success.png" alt="" className="img-fluid" />
                                        <h3 className="mt-4 fw-bold">Thank you</h3>
                                        <p>Your Order Has Successfully Placed</p>
                                        <button onClick={navigateto} type="button" data-bs-dismiss="modal" className="btn btn-primary mb-4">Go to my orders</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Link data-bs-toggle="modal" data-bs-target='#verifyOtp' style={{ display: "none" }} ref={buttonref1}></Link>
                        <div className="modal fade" id="verifyOtp" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="verifyOtpLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body text-center">
                                        {/* <Otp otpVerifiedChange={otpVerifiedChange} /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : ""}
            <Footer />
        </div>
    );
};

export default Checkout;
