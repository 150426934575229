import React, {useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import AuthService from "../../services/auth.service";
import proImg from "../../images/pro-img.jpg"

const Sidebar = () => {
  const [user, setUser] = useState({});
  const url = window.location.href;

  const toggle = (event) => {
    if(event.currentTarget.classList.contains('open')){
      event.currentTarget.classList.remove('open');
      event.currentTarget.classList.add('close');
    }else{
      event.currentTarget.classList.add('open');
      event.currentTarget.classList.remove('close');
    }
  }

  useEffect(() => {
    const userData = AuthService.getCurrentUser();
    setUser(userData)
  }, []);
  return (
    <div className='sidebar'>
      <Link to={"/my-profile"} className="profile_new d-flex align-items-center">
        <figure className='mb-0'><img src={proImg} alt='profie image' /></figure>
        <div className="profile-detail ps-2">
          <h6 className='mb-1 text-dark-grey'>Hello</h6>
          <p className='text-dark-grey mb-0'>{user.first_name} {user.last_name}</p>
        </div>
      </Link>

      <nav className='mt-4'>
        <ul>
          <li className={url.includes('vendor-dashboard')?'active':''}>
            <Link to={"/vendor-dashboard"} >
              <i><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 2.75C0.5 1.50736 1.50736 0.5 2.75 0.5L7.25 0.5C8.49264 0.5 9.5 1.50736 9.5 2.75V7.25C9.5 8.49264 8.49264 9.5 7.25 9.5H2.75C1.50736 9.5 0.5 8.49264 0.5 7.25L0.5 2.75ZM12.5 2.75C12.5 1.50736 13.5074 0.5 14.75 0.5L19.25 0.5C20.4926 0.5 21.5 1.50736 21.5 2.75V7.25C21.5 8.49264 20.4926 9.5 19.25 9.5H14.75C13.5074 9.5 12.5 8.49264 12.5 7.25V2.75ZM0.5 14.75C0.5 13.5074 1.50736 12.5 2.75 12.5H7.25C8.49264 12.5 9.5 13.5074 9.5 14.75V19.25C9.5 20.4926 8.49264 21.5 7.25 21.5H2.75C1.50736 21.5 0.5 20.4926 0.5 19.25L0.5 14.75ZM12.5 14.75C12.5 13.5074 13.5074 12.5 14.75 12.5H19.25C20.4926 12.5 21.5 13.5074 21.5 14.75V19.25C21.5 20.4926 20.4926 21.5 19.25 21.5H14.75C13.5074 21.5 12.5 20.4926 12.5 19.25V14.75Z" fill="#F6F5FA" stroke="#161616" />
              </svg>
              </i><span>Dashboard</span>
            </Link>
          </li>

          <li className={url.includes('products')?'active':''}>
            <Link to={"/products"} >
              <i><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.2785 1.66918C12.0997 1.59766 11.9003 1.59766 11.7215 1.66918L2.76944 5.24999L12 8.94222L21.2306 5.24999L12.2785 1.66918ZM22.5 6.35777L12.75 10.2578V22.1422L22.5 18.2422V6.35777ZM11.25 22.1422V10.2578L1.5 6.35777V18.2422L11.25 22.1422ZM11.1644 0.276467C11.7008 0.0618971 12.2992 0.061897 12.8356 0.276467L23.5285 4.55363C23.8133 4.66753 24 4.94331 24 5.24999V18.2422C24 18.8556 23.6266 19.4071 23.0571 19.6349L12.2785 23.9463C12.0997 24.0179 11.9003 24.0179 11.7215 23.9463L0.942914 19.6349C0.373427 19.4071 0 18.8556 0 18.2422V5.24999C0 4.94331 0.186713 4.66753 0.471457 4.55363L11.1644 0.276467Z" fill="#161616" />
              </svg>
              </i><span>Products</span>
            
            </Link>

          </li>

          <li className={url.includes('orders')?'active':''}>
            <Link to={"/orders"}>
              <i><svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 2L4.5 8.87868L15 19.3787L21.8787 12.5L11.3787 2L4.5 2ZM3 2C3 1.17157 3.67157 0.5 4.5 0.5H11.3787C11.7765 0.5 12.158 0.658035 12.4393 0.93934L22.9393 11.4393C23.5251 12.0251 23.5251 12.9749 22.9393 13.5607L16.0607 20.4393C15.4749 21.0251 14.5251 21.0251 13.9393 20.4393L3.43934 9.93934C3.15803 9.65803 3 9.2765 3 8.87868V2Z" fill="#161616" />
                <path d="M8.25 6.5C7.83579 6.5 7.5 6.16421 7.5 5.75C7.5 5.33579 7.83579 5 8.25 5C8.66421 5 9 5.33579 9 5.75C9 6.16421 8.66421 6.5 8.25 6.5ZM8.25 8C9.49264 8 10.5 6.99264 10.5 5.75C10.5 4.50736 9.49264 3.5 8.25 3.5C7.00736 3.5 6 4.50736 6 5.75C6 6.99264 7.00736 8 8.25 8Z" fill="#161616" />
                <path d="M1.5 9.62868C1.5 10.0265 1.65803 10.408 1.93934 10.6893L13.125 21.875L13.0607 21.9393C12.4749 22.5251 11.5251 22.5251 10.9393 21.9393L0.439339 11.4393C0.158034 11.158 0 10.7765 0 10.3787V3.5C0 2.67157 0.671573 2 1.5 2V9.62868Z" fill="#161616" />
              </svg></i><span>Order</span>
            </Link>
          </li>
          <li className={url.includes('refund-request')?'active':''}>
            <Link to={"/refund-request"}>
              <i><svg width="24" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 2L4.5 8.87868L15 19.3787L21.8787 12.5L11.3787 2L4.5 2ZM3 2C3 1.17157 3.67157 0.5 4.5 0.5H11.3787C11.7765 0.5 12.158 0.658035 12.4393 0.93934L22.9393 11.4393C23.5251 12.0251 23.5251 12.9749 22.9393 13.5607L16.0607 20.4393C15.4749 21.0251 14.5251 21.0251 13.9393 20.4393L3.43934 9.93934C3.15803 9.65803 3 9.2765 3 8.87868V2Z" fill="#161616" />
                <path d="M8.25 6.5C7.83579 6.5 7.5 6.16421 7.5 5.75C7.5 5.33579 7.83579 5 8.25 5C8.66421 5 9 5.33579 9 5.75C9 6.16421 8.66421 6.5 8.25 6.5ZM8.25 8C9.49264 8 10.5 6.99264 10.5 5.75C10.5 4.50736 9.49264 3.5 8.25 3.5C7.00736 3.5 6 4.50736 6 5.75C6 6.99264 7.00736 8 8.25 8Z" fill="#161616" />
                <path d="M1.5 9.62868C1.5 10.0265 1.65803 10.408 1.93934 10.6893L13.125 21.875L13.0607 21.9393C12.4749 22.5251 11.5251 22.5251 10.9393 21.9393L0.439339 11.4393C0.158034 11.158 0 10.7765 0 10.3787V3.5C0 2.67157 0.671573 2 1.5 2V9.62868Z" fill="#161616" />
              </svg></i><span>Refund Requests</span>
            </Link>
          </li>


          <li className={url.includes('invoice')?'active':''}>
            <Link to={"/vendor-invoice"}>
              <i><svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V15C24 16.6569 22.6569 18 21 18H3C1.34315 18 0 16.6569 0 15V3ZM3 1.5C2.17157 1.5 1.5 2.17157 1.5 3V4.5H22.5V3C22.5 2.17157 21.8284 1.5 21 1.5H3ZM22.5 7.5H1.5V15C1.5 15.8284 2.17157 16.5 3 16.5H21C21.8284 16.5 22.5 15.8284 22.5 15V7.5Z" fill="#161616" />
                <path d="M3 12C3 11.1716 3.67157 10.5 4.5 10.5H6C6.82843 10.5 7.5 11.1716 7.5 12V13.5C7.5 14.3284 6.82843 15 6 15H4.5C3.67157 15 3 14.3284 3 13.5V12Z" fill="#161616" />
              </svg> </i><span>Invoice Manager</span>
            </Link>
          </li>
          <li className={url.includes('chats')?'active':''}>
            <Link to={"/company-account"} >
              <i><svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.125C0 1.08947 0.839466 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08947 20 2.125V4C20 4.34518 19.7202 4.625 19.375 4.625C18.3395 4.625 17.5 5.46447 17.5 6.5C17.5 7.53553 18.3395 8.375 19.375 8.375C19.7202 8.375 20 8.65482 20 9V10.875C20 11.9105 19.1605 12.75 18.125 12.75H1.875C0.839466 12.75 0 11.9105 0 10.875V9C0 8.65482 0.279822 8.375 0.625 8.375C1.66053 8.375 2.5 7.53553 2.5 6.5C2.5 5.46447 1.66053 4.625 0.625 4.625C0.279822 4.625 0 4.34518 0 4V2.125ZM1.875 1.5C1.52982 1.5 1.25 1.77982 1.25 2.125V3.43751C2.6764 3.72706 3.75 4.98815 3.75 6.5C3.75 8.01185 2.6764 9.27294 1.25 9.56249V10.875C1.25 11.2202 1.52982 11.5 1.875 11.5H18.125C18.4702 11.5 18.75 11.2202 18.75 10.875V9.56249C17.3236 9.27294 16.25 8.01185 16.25 6.5C16.25 4.98815 17.3236 3.72706 18.75 3.43751V2.125C18.75 1.77982 18.4702 1.5 18.125 1.5H1.875Z" fill="black" />
              </svg>
              </i><span>Bank Account</span>
            </Link>

          </li>

          <li className={url.includes('review')?'active':''}>
            <Link to={"/vendor-review"}>
              <i><svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.02897 7.08223L11.3052 0.448201C11.4523 0.150244 11.7248 0.000844778 11.9977 3.56861e-06C12.2721 -0.000841949 12.5469 0.14856 12.6948 0.448204L15.971 7.08223L23.3187 8.14924C23.7194 8.20743 23.9712 8.55309 23.9977 8.9235C24.0008 8.96646 24.0008 9.00975 23.9977 9.05297C23.9833 9.25226 23.902 9.44992 23.7421 9.60575L18.4586 14.7526L19.7031 22.0045C19.8198 22.6845 19.1635 23.2167 18.5838 22.9122L12 19.453L5.41616 22.9122C5.3437 22.9502 5.27005 22.9752 5.19685 22.9885C4.68449 23.0813 4.19474 22.5995 4.29685 22.0045L5.54144 14.7526L0.257945 9.60575C0.080497 9.43289 -0.000170449 9.20857 2.70384e-07 8.98765C0.000126456 8.82435 0.044417 8.66291 0.126434 8.52547C0.242222 8.33144 0.433201 8.18526 0.681258 8.14924L8.02897 7.08223ZM11.9977 17.674C12.117 17.6736 12.2363 17.7023 12.3462 17.76L17.8749 20.6647L16.8335 14.5969C16.7844 14.3108 16.8767 14.0179 17.0776 13.8222L21.4379 9.57475L15.3594 8.69206C15.1081 8.65557 14.8887 8.49152 14.7702 8.2515L12 2.64205L11.9977 2.64675V17.674Z" fill="black" />
              </svg></i><span>Reviews</span>
            </Link>

          </li>

          <li className={url.includes('brand')?'active':''}>
            <Link to={"/brand"} >
              <i><svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.125C0 1.08947 0.839466 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08947 20 2.125V4C20 4.34518 19.7202 4.625 19.375 4.625C18.3395 4.625 17.5 5.46447 17.5 6.5C17.5 7.53553 18.3395 8.375 19.375 8.375C19.7202 8.375 20 8.65482 20 9V10.875C20 11.9105 19.1605 12.75 18.125 12.75H1.875C0.839466 12.75 0 11.9105 0 10.875V9C0 8.65482 0.279822 8.375 0.625 8.375C1.66053 8.375 2.5 7.53553 2.5 6.5C2.5 5.46447 1.66053 4.625 0.625 4.625C0.279822 4.625 0 4.34518 0 4V2.125ZM1.875 1.5C1.52982 1.5 1.25 1.77982 1.25 2.125V3.43751C2.6764 3.72706 3.75 4.98815 3.75 6.5C3.75 8.01185 2.6764 9.27294 1.25 9.56249V10.875C1.25 11.2202 1.52982 11.5 1.875 11.5H18.125C18.4702 11.5 18.75 11.2202 18.75 10.875V9.56249C17.3236 9.27294 16.25 8.01185 16.25 6.5C16.25 4.98815 17.3236 3.72706 18.75 3.43751V2.125C18.75 1.77982 18.4702 1.5 18.125 1.5H1.875Z" fill="black" />
              </svg>
              </i><span>Brands</span>
            </Link>

          </li>
          <li className={url.includes('chats')?'active':''}>
            <Link to={"/chats"} >
              <i><svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.125C0 1.08947 0.839466 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08947 20 2.125V4C20 4.34518 19.7202 4.625 19.375 4.625C18.3395 4.625 17.5 5.46447 17.5 6.5C17.5 7.53553 18.3395 8.375 19.375 8.375C19.7202 8.375 20 8.65482 20 9V10.875C20 11.9105 19.1605 12.75 18.125 12.75H1.875C0.839466 12.75 0 11.9105 0 10.875V9C0 8.65482 0.279822 8.375 0.625 8.375C1.66053 8.375 2.5 7.53553 2.5 6.5C2.5 5.46447 1.66053 4.625 0.625 4.625C0.279822 4.625 0 4.34518 0 4V2.125ZM1.875 1.5C1.52982 1.5 1.25 1.77982 1.25 2.125V3.43751C2.6764 3.72706 3.75 4.98815 3.75 6.5C3.75 8.01185 2.6764 9.27294 1.25 9.56249V10.875C1.25 11.2202 1.52982 11.5 1.875 11.5H18.125C18.4702 11.5 18.75 11.2202 18.75 10.875V9.56249C17.3236 9.27294 16.25 8.01185 16.25 6.5C16.25 4.98815 17.3236 3.72706 18.75 3.43751V2.125C18.75 1.77982 18.4702 1.5 18.125 1.5H1.875Z" fill="black" />
              </svg>
              </i><span>Inbox</span>
            </Link>

          </li>
          <li className={url.includes('Upload-video')?'active':''}>
            <Link to={"/Upload-video"} >
              <i><svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.125C0 1.08947 0.839466 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08947 20 2.125V4C20 4.34518 19.7202 4.625 19.375 4.625C18.3395 4.625 17.5 5.46447 17.5 6.5C17.5 7.53553 18.3395 8.375 19.375 8.375C19.7202 8.375 20 8.65482 20 9V10.875C20 11.9105 19.1605 12.75 18.125 12.75H1.875C0.839466 12.75 0 11.9105 0 10.875V9C0 8.65482 0.279822 8.375 0.625 8.375C1.66053 8.375 2.5 7.53553 2.5 6.5C2.5 5.46447 1.66053 4.625 0.625 4.625C0.279822 4.625 0 4.34518 0 4V2.125ZM1.875 1.5C1.52982 1.5 1.25 1.77982 1.25 2.125V3.43751C2.6764 3.72706 3.75 4.98815 3.75 6.5C3.75 8.01185 2.6764 9.27294 1.25 9.56249V10.875C1.25 11.2202 1.52982 11.5 1.875 11.5H18.125C18.4702 11.5 18.75 11.2202 18.75 10.875V9.56249C17.3236 9.27294 16.25 8.01185 16.25 6.5C16.25 4.98815 17.3236 3.72706 18.75 3.43751V2.125C18.75 1.77982 18.4702 1.5 18.125 1.5H1.875Z" fill="black" />
              </svg>
              </i><span>Upload Video</span>
            </Link>
          </li>


          <li className={url.includes('edit-company')?'active':''}>
            <Link className={url.includes('edit-company')?'open':'close'} onClick={(e)=>toggle(e)} >
              <i><svg width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 2.125C0 1.08947 0.839466 0.25 1.875 0.25H18.125C19.1605 0.25 20 1.08947 20 2.125V4C20 4.34518 19.7202 4.625 19.375 4.625C18.3395 4.625 17.5 5.46447 17.5 6.5C17.5 7.53553 18.3395 8.375 19.375 8.375C19.7202 8.375 20 8.65482 20 9V10.875C20 11.9105 19.1605 12.75 18.125 12.75H1.875C0.839466 12.75 0 11.9105 0 10.875V9C0 8.65482 0.279822 8.375 0.625 8.375C1.66053 8.375 2.5 7.53553 2.5 6.5C2.5 5.46447 1.66053 4.625 0.625 4.625C0.279822 4.625 0 4.34518 0 4V2.125ZM1.875 1.5C1.52982 1.5 1.25 1.77982 1.25 2.125V3.43751C2.6764 3.72706 3.75 4.98815 3.75 6.5C3.75 8.01185 2.6764 9.27294 1.25 9.56249V10.875C1.25 11.2202 1.52982 11.5 1.875 11.5H18.125C18.4702 11.5 18.75 11.2202 18.75 10.875V9.56249C17.3236 9.27294 16.25 8.01185 16.25 6.5C16.25 4.98815 17.3236 3.72706 18.75 3.43751V2.125C18.75 1.77982 18.4702 1.5 18.125 1.5H1.875Z" fill="black" />
              </svg>
              </i>
              <span>Shop Decoration</span>
              <i className='ms-auto'><svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.146447 0.646447C0.341709 0.451184 0.658291 0.451184 0.853553 0.646447L6.85355 6.64645C7.04882 6.84171 7.04882 7.15829 6.85355 7.35355L0.853553 13.3536C0.658291 13.5488 0.341709 13.5488 0.146447 13.3536C-0.0488155 13.1583 -0.0488155 12.8417 0.146447 12.6464L5.79289 7L0.146447 1.35355C-0.0488155 1.15829 -0.0488155 0.841709 0.146447 0.646447Z" fill="#161616" />
                </svg>
              </i>
            </Link>
            <ul className='ps-4'>
              <li>
                <Link to={"/edit-company"} >Edit Company Profile</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>

  )
}

export default Sidebar;