import React from 'react'
import { Link } from 'react-router-dom';
import '../UserProfileCss/TaxInformation.css';
import Icon1 from '../../../../images/icon-text1.svg';
import Icon2 from '../../../../images/icon-text2.svg';
import Icon3 from '../../../../images/icon-text3.svg';


function TaxInformation() {
  return (
    <>
      <div className="header">
        <div className="row w-100 m-0">
          <div className="bg-image">
            <div className="container border rounded mt-5" style={{backdropFilter: `blur(20px)`}}>
              <h5 className="text-white fw-bold pt-3">DEALITTT.com tax exemption program</h5>
              <div className="row w-100 mx-0 my-3">
                <div className="col-lg-4 mb-lg-0 mb-2 p-3 bg-white border border-2 rounded">
                  <div className="row align-items-center">
                    <div className="col-md-2 text-center tax-image">
                      <img src={Icon1} alt="Icons" />
                    </div>
                    <div className="col-md-10 tax-info">
                      <h6 className="fw-bold">Membership benefits</h6>
                      <p className="m-0">Once you're verified, enjoy flexible paymentterms and more as an Enterprise
                        member of DEALITTT.com</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-lg-0 mb-2 p-3 bg-white border border-2 rounded">
                  <div className="row align-items-center">
                    <div className="col-md-2 text-center tax-image">
                      <img src={Icon2} alt="Icons" />
                    </div>
                    <div className="col-md-10 tax-info">
                      <h6 className="fw-bold">Tax-exempt purchases</h6>
                      <p className="m-0">In a few simple steps,submit your text information to be verified for
                        text-exemot purchases</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 mb-lg-0 mb-2 p-3 bg-white border border-2 rounded">
                  <div className="row align-items-center">
                    <div className="col-md-2 text-center tax-image">
                      <img src={Icon3} alt="Icons" />
                    </div>
                    <div className="col-md-10 tax-info">
                      <h6 className="fw-bold">Easy tax refunds</h6>
                      <p className="m-0">Claims refund for customs charges if you already pay sale tax on DEALITT.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header my-5 pt-5">
        <div className="container">
          <div className="row m-0">
            <h4 className="fw-bold">Tax Information</h4>
            <div className="col-12 bg-white p-4 rounded">
              <div className="fw-bold">US sales tax exemption</div>
              <div className="">If you purchase products for resale or production ,submit tax information to be
                verified and place tax exempt orders on DEALITT.com.
              </div>
              <div className="border border-2 rounded border border-warning mt-2 w-50">
                <div className="p-2 ">
                  {/* <Link to="#" className="link-warning">+Add or modify text exemption
                  </Link> */}
                  <button type="button" className="btn " data-bs-toggle="modal" data-bs-target="#exampleModal">
                  +Add tax information
                </button>
                </div>
              </div>
            </div>
            <div className="col-12 bg-white mt-3 p-4 rounded">
              <div className="fw-bold">EU/NO/UK or AU/NZ/SG tax exemption</div>
              <div className="">For business purchases in EU/NO/UK or AU/NZ/SG ,submit tax information to be
                verified and place tax-exempt orders on DEALITT.com.
              </div>
              <div className="tax-pop mt-3">
                {/* <!-- Button trigger modal --> */}
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  +Add tax information
                </button>

                {/* <!-- Modal --> */}
                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-6" id="exampleModalLabel">
                          Add tax information
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <form>
                          <label className="fs-6">Country/region</label>
                          <select className="form-select mb-3" aria-label="Default select example">
                            <option defaultValue>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                          <label className="fs-6">Country/region</label>
                          <input type="text" className="form-control mb-3" placeholder="Please enter" />
                          <label className="fs-6">Full registration name</label>
                          <input type="text" className="form-control" placeholder="Please enter" />
                          <div className="pop-btn text-center mt-4">
                            <button type="button" className="btn">Verify</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 bg-white mt-3 p-4 rounded">
              <div className="fw-bold">Canadian Provincial sales tax exemption</div>
              <div className="">If you purchase products for resale or production , submit tax information to be
                verified and place tax exempt orders on DEALITT.com.</div>
              <div className="tax-pop mt-3">
                {/* <!-- Button trigger modal --> */}
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                  +Add PST information
                </button>

                {/* <!-- Modal --> */}
                <div className="modal fade" id="exampleModal2" tabIndex="-1" aria-labelledby="exampleModal2Label" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-6" id="exampleModal2Label">
                          Add PST information
                        </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <div className="tax-tabs">
                          <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                              <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">
                                <div className="form-check mb-3">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked />
                                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Monitoba
                                  </label>
                                </div>
                              </button>
                            </li>
                            <li className="nav-item" role="presentation">
                              <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">
                                <div className="form-check mb-3">
                                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                                    Saskatchewan
                                  </label>
                                </div>
                              </button>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">

                            <form>
                              <label className="fs-6">Province</label>
                              <input type="text" className="form-control mb-3" placeholder="Please enter" />
                            </form>

                          </div>
                          <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">

                            <form>
                              <label className="fs-6">Monitoba</label>
                              <input type="text" className="form-control mb-3" placeholder="Please enter" />
                            </form>

                          </div>
                        </div>
                        <div className="pop-btn text-center mt-4">
                          <button type="button" className="btn">Verify</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container">
          <h4 className="fw-bold mb-3">Frequently asked questions</h4>
          <div className="row">
            <div className="col-lg-4 mb-lg-0 mb-3">
              <div className="card">
                <div className="card-body">
                  <h5 className="fw-bold">What if i don't have tax information ?</h5>
                  <p>Visit the website of your local tax authority to check your eligibility for tax
                    exemption. After you have obtained an exemption document from your local tax authority ,
                    you can submit it to us to get verified as text-exempt buyer.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-lg-0 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="fw-bold">Why is my tax information is invalid ?</h5>
                  <p>Check whether your tax information has expired or if you have provide incorrect information to us.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-lg-0 mb-3">
              <div className="card h-100">
                <div className="card-body">
                  <h5 className="fw-bold">How can i apply for a tax refund ?</h5>
                  <p>You can recieve a tax refund if your tax information has ben submitted and verified before your order has shipped.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer py-4">
        <div className="container">
          <div className="row">
            <div className="accordion accordion-flush" id="accordionFlushExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingOne">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <p className="fw-bold">What is US sales tax?</p>
                  </button>
                </h2>
                <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">Products sold on online e-commerce platform (“Marketplace”) and delivered to locations inside the US may be subject to sales tax. Under US marketplace facilitator laws, e-commerce platforms (known as "Marketplace Facilitators") are required to calculate, collect, and remit sales tax to state authorities for sales made by third-party sellers to buyers in the US. You may be exempt from sales tax on DEALITT.com if you are a retailer, wholesaler, or manufacturer purchasing goods for resale or production purposes. To get verified as a tax-exempt buyer, you can sign up for our tax exemption program, DEALITT US Sales Tax Exemption Program (ASTEP), by submitting information such as your tax ID number, sales tax permit.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingTwo">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    <p className="fw-bold">What is value-added tax?</p>
                  </button>
                </h2>
                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">Products sold on online e-commerce platform (“Marketplace”) and delivered to locations inside the US may be subject to sales tax. Under US marketplace facilitator laws, e-commerce platforms (known as "Marketplace Facilitators") are required to calculate, collect, and remit sales tax to state authorities for sales made by third-party sellers to buyers in the US. You may be exempt from sales tax on DEALITT.com if you are a retailer, wholesaler, or manufacturer purchasing goods for resale or production purposes. To get verified as a tax-exempt buyer, you can sign up for our tax exemption program, DEALITT US Sales Tax Exemption Program (ASTEP), by submitting information such as your tax ID number, sales tax permi.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="flush-headingThree">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    <p className="fw-bold">What is US sales tax?</p>
                  </button>
                </h2>
                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">Products sold on online e-commerce platform (“Marketplace”) and delivered to locations inside the US may be subject to sales tax. Under US marketplace facilitator laws, e-commerce platforms (known as "Marketplace Facilitators") are required to calculate, collect, and remit sales tax to state authorities for sales made by third-party sellers to buyers in the US. You may be exempt from sales tax on DEALITT.com if you are a retailer, wholesaler, or manufacturer purchasing goods for resale or production purposes. To get verified as a tax-exempt buyer, you can sign up for our tax exemption program, DEALITT US Sales Tax Exemption Program (ASTEP), by submitting information such as your tax ID number, sales tax permi.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TaxInformation 
