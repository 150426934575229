import React, { useState } from 'react'
import Logo from "../images/logo.png";
import { Link } from 'react-router-dom';
import "../customCss/ForgetPass.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import DataService from '../services/data.service';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Reset from '../images/reset-pas.jpg'


function ForgotPass() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('')
  const forgotPass = (e) => {
    e.preventDefault();
    const data = {};
    data.email = email;
    setLoading(true);
    DataService.forgotPassword(data).then(
      (res) => {
        setLoading(false);
        toast.success(res?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT
        });
        setTimeout(() => {
          navigate('/login-page')
        }, 1500);
      },
      (error) => {
        const resMessage = error?.response?.data?.msg
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }
  return (

    <>
      <div className="row align-items-center justify-content-between">
        <div className="col-sm-6">
          <img src={Reset} className="w-100 h-100" alt="reset-password" />
        </div>
        <div className="col-sm-6">
          <div class="pop">
            <Link to={"/"}>
              <img src={Logo} className="main_logo mb-4" alt="" srcset="" />
            </Link>
            <h4 class="mb-4"> Please Enter the your Email to get Reset Password Link</h4>
            <form className="">
              <div className="row">
                <div className="col-md-12">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-4 p-0 text-md-end">
                </div>
                <div className="col-md-8">
                  <div className="form-btn">
                    <button className='button_sign btn' disabled={loading} onClick={forgotPass}>
                      Submit
                    </button>

                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPass
