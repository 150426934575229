import './App.css';
import Routes from "./Routes";
import { ToastContainer, toast } from 'react-toastify';

function App() {
  return (
    <>
      <ToastContainer></ToastContainer>
      <Routes></Routes>
    </>
  );
}

export default App;
