import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AuthService from "../../../services/auth.service";
import DataService from "../../../services/data.service";
import { ImCross } from "react-icons/im";

import { Link } from "react-router-dom";
function HomeBanner(props) {
  const auth = AuthService.getCurrentUser();
  const form = useRef();
  const searchD = useRef();

  const submitbtn = useRef();
  const navigate = useNavigate();
  const params = useParams();
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [recent, setRecent] = useState([]);
  const [dbSearch, setDbSearch] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [showSearch, setShowSearch] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    setRecentSearches(searchKey);
    if (searchKey != "") {
      navigate("/product/search/" + searchKey);
    } else {
      document.getElementsByClassName("search-box")[0].focus();
      return false;
    }
  };
  const setRecentSearches = (value) => {
    if (value.length > 0) {
      var rs = JSON.parse(localStorage.getItem("recent_search"));
      if (!rs) {
        rs = [];
      }
      rs.push(value);
      var filteredArray = rs.filter(function (item, pos) {
        return rs.indexOf(item) == pos;
      });
      localStorage.setItem("recent_search", JSON.stringify(filteredArray));
    }
    getRecentSearches();
  };
  const getRecentSearches = () => {
    var rs = JSON.parse(localStorage.getItem("recent_search"));
    if (rs) {
      setRecent(JSON.parse(localStorage.getItem("recent_search")));
    }
  };
  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };
  const onSearchChange = (e) => {
    setSearchKey(e.target.value);
    if (e.target.value.length > 1) {
      searchProduct(e.target.value);
    } else {
      setIsSearchFocused(true);
      setSearchText(false);
    }
  };
  const searchProduct = async (keyVal) => {
    const userId = auth ? auth.id : "";
    await DataService.searchProduct(
      {
        mastCatId: [],
        catId: [],
        subCatId: [],
        brandId: [],
        keyword: keyVal,
      },
      userId
    ).then((data) => {
      var p = [];
      if (data.data.products.length > 0) {
        data.data.products.map((item, k) => {
          var urll =
            item.mastercategory.slug +
            "/" +
            item.category.slug +
            "/" +
            item.subcategory.slug +
            "/" +
            item.slug;
          p.push(item.name + "~~" + urll + "~~" + item.file_path + "~~" + item.offer_price);
        });
      }
      console.log(p);
      setDbSearch(p.slice(0, 10));
      setIsSearchFocused(false);
      setSearchText(true);
    });
  };
  const clearKey = (e) => {
    localStorage.setItem("recent_search", JSON.stringify([]));
    getRecentSearches();
  };
  const removeKey = (e, key) => {
    var rs = JSON.parse(localStorage.getItem("recent_search"));
    if (rs) {
      var result = rs.filter((k, g) => {
        return g != key;
      });
    } else {
      var result = [];
    }
    localStorage.setItem("recent_search", JSON.stringify(result));
    getRecentSearches();
  };
  const closeOpenMenus = (e) => {
    if (searchD.current && !searchD.current.contains(e.target)) {
      setIsSearchFocused(false);
      setSearchText(false);
    }
  };
  useEffect(() => {
    getRecentSearches();
    document.addEventListener("mousedown", closeOpenMenus);

    setSearchKey(params.keyword);
  }, []);
  const makeSearch = (e, val) => {
    setIsSearchFocused(true);
    var splits = val.split("~~");
    setSearchKey(splits[0]);
    if (splits.length > 1 && splits[1]) {
      setRecentSearches(val);
      navigate("/" + splits[1]);
    } else {
      setTimeout(function () {
        submitbtn.current.click();
      }, 500);
    }
    setIsSearchFocused(false);
    setSearchText(false);
  };
  return (
    <div>
      {/* <h1>{props.Refdata} times</h1> */}
      <section className="banner-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="banner-search_sec">
                <form
                  onSubmit={handleSubmit}
                  ref={form}
                  className="w-100 position-relative"
                  role="search"
                >
                  <input
                    type="text"
                    className="form-control search-box"
                    placeholder="Search Products Here..."
                    onFocus={handleSearchFocus}
                    value={searchKey}
                    onChange={onSearchChange}
                  />
                  {/* <div className="banner-btn">
                    <button type="submit" ref={submitbtn}></button>
                  </div> */}
                  {isSearchFocused && (
                    <div className="search_dropdown" ref={searchD}>
                      <div className="inner_search">
                        <h3>Recent Searches:</h3>
                        <h4
                          onClick={(e) => clearKey(e)}
                          style={{ cursor: "pointer" }}
                        >
                          Clear History
                        </h4>
                      </div>
                      <>
                        <ul>
                          {recent.length > 0 ? (
                            recent.slice(-10).map((val, key) => (
                              <li style={{ cursor: "pointer" }}>
                                <span onClick={(e) => makeSearch(e, val)}>
                                  {val.split("~~")[0]}
                                </span>
                                <span>
                                  <ImCross onClick={(e) => removeKey(e, key)} />
                                </span>
                              </li>
                            ))
                          ) : (
                            <li>No recent search</li>
                          )}
                        </ul>
                      </>
                    </div>
                  )}

                  {searchText && (
                    <div className="search_dropdown" ref={searchD}>
                      <div className="inner_search"></div>
                      <>
                        <ul>
                          {dbSearch.length > 0 ? (
                            dbSearch.map((val, key) => (
                              <li
                                style={{ cursor: "pointer" }}
                                onClick={(e) => makeSearch(e, val)}
                                className="searched_item"
                              >
                                <img
                                  src={
                                    val.split("~~")[2]
                                      ? "https://api.dealitt.com/" +
                                        val.split("~~")[2]
                                      : ""
                                  }
                                  alt={val.split("~~")[0]}
                                  srcset=""
                                />
                                <p className="search_name">{val.split("~~")[0]}</p>
                                <p className="search_price">${val.split("~~")[3]}</p>
                              </li>
                            ))
                          ) : (
                            <li>No matching product found</li>
                          )}
                        </ul>
                      </>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeBanner;
