import React, {useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import DataService from "../services/data.service";
import Baskit from "../pages/component/about/baskit";
import Counter from "../pages/component/about/counter";
import Pagetitle from "../pages/component/about/pagetitle";
import Reviews from "../pages/component/about/reviews";
import Banner from "../common/Banner";
import AboutDealit from "./component/about/AboutDealit"
// import Newsletter from "../common/Newsletter";
const About = () => {
  const [data, setData] = useState({});
  const [html, setHTML] = useState({__html: ""});
  const [html2, setHTML2] = useState({__html: ""});
  useEffect(() => {
      document.title = "About Us";
      getData();
      window.scrollTo(0, 0)
  }, []);
  const getData = () => {
      DataService.getPage('about-us').then((data) => {
          if(data.data.data.length>0){
              setData(data?.data?.data[0])
              setHTML({__html: data?.data?.data[0]?.description})
              setHTML2({__html: data?.data?.data[0]?.description2})
          }   
      });
  }


  return (
    <>
        <Header />
        <Banner title={"About Us"}/>
        <AboutDealit data={html} title={data?.name}/>
        <Counter />
        <Baskit data={html2}/>
        <Reviews />
        {/* <Newsletter /> */}
        <Footer />
    </>
  );
};

export default About;