import React, { useState } from "react";
import { Link } from "react-router-dom";
import NotFound from "../../../common/NotFound";
import { toast } from "react-toastify";


const AllProduct = (props) => {
  let page_no = 1;
  let limit = 6;
  let sortByPrice = "";
  let dates = "";
  let brandId = "";
  let subCatId = "";
  let mastCatId = "";
  let catId = "";
  let minPrice = "";
  let maxPrice = "";
  const itemsPerPageOptions = [2, 4, 6];
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(props.totalRecords / itemsPerPage);
  const [cartInput, setCartInput] = useState(1);
  const [gCart, setGCart] = useState(1)
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const goToPage = (page) => {
    page_no = page;
    setCurrentPage(page);
    filterData();
  };
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    limit = newItemsPerPage;
    page_no = 1;
    filterData();
  };
  const renderPageButtons = () => {
    const pageButtons = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (totalPages > 5) {
      if (currentPage <= 3) {
        endPage = 5;
      } else if (currentPage >= totalPages - 2) {
        startPage = totalPages - 4;
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      pageButtons.push(
        <button
          key={page}
          onClick={() => goToPage(page)}
          disabled={currentPage === page}
        >
          {page}
        </button>
      );
    }

    return pageButtons;
  };
  const filterData = (nv = false) => {
    props.searchProduct({
      dates,
      brandId,
      mastCatId,
      catId,
      subCatId,
      minPrice,
      maxPrice,
      priceSort: sortByPrice,
      keyword: props?.queryObj?.keyword,
      filter: props?.queryObj?.filter,
      page: page_no,
      limit: limit,
    });
  };
  const sortProdctByPrice = (e) => {
    sortByPrice = e.target.value;
    filterData();
  };
  const addTowhislist = (event, item) => {
    props.addTowhislist(event, item);
  };
  const [cartselect, setCartSelect] = useState("1-99");
  const addTocart = (event, item) => {
    item.cart_qty = gCart;
    props.addTocart(event, item)
  }
  function calculatePrice(cartInput) {
    for (const range of JSON.parse(props?.data?.price_groups)) {
      if (cartInput >= range.min && cartInput <= range.max) {
        return range.mrp;
      }
    }
    return 0;
  }
  const setMainInputValue = (e) => {
    if (e.target.value === "") {
      setCartInput(gCart);
    }
    else if (e.target.value < gCart) {
      toast.error(`Value should not less than ${gCart} !!`)
      setCartInput(parseInt(gCart))
    }
    else {
      setCartInput(Number.parseInt(e.target.value));
      const numericValue = parseFloat(e.target.value);
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * e.target.value;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setUnitPrice(calculatePrice(numericValue));
          setCalculatedPrice(price * e.target.value);
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };
  const increaseNumber = () => {
    setCartInput(cartInput + 1);
    let x = Number.parseInt(document.getElementById("cart_input").value) + 1;
    const numericValue = x;
    if (!props?.data?.price_groups) {
      let unitP = props?.data?.offer_price * x;
      setCalculatedPrice(unitP);
      setUnitPrice(props?.data?.offer_price);
    } else {
      if (!isNaN(numericValue)) {
        const price = calculatePrice(numericValue);
        setCalculatedPrice(price * x);
        setUnitPrice(calculatePrice(numericValue));
      } else {
        setCalculatedPrice(0);
      }
    }
  };
  const decreaseNumber = () => {
    if (cartInput === 1 || cartInput === gCart) {
      toast.error(`Value should not less than ${gCart} !!`);
    } else {
      setCartInput(cartInput - 1);
      let x = Number.parseInt(document.getElementById("cart_input").value) - 1;
      const numericValue = x;
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * x;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setCalculatedPrice(price * x);
          setUnitPrice(calculatePrice(numericValue));
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };
  return (
    <>
      <div
        className="container my-5 p-4"
        style={{ background: "#fff", borderRadius: "5px" }}
      >
        <div className="all_productHead">
          <div className="all_productHeadL">
            <h3>Best Products</h3>
          </div>

          <div className="all_productHeadR">
            <label>Sort By:</label>
            <select
              className="form-select sorting-select"
              onChange={sortProdctByPrice}
            >
              <option value="">Recommended</option>
              <option value="popularity">Popularity</option>
              <option value="asc">Price Low to High</option>
              <option value="desc">Price High to Low</option>
            </select>
          </div>
        </div>
        <div className="row g-2 my-3">
          {props.data && props.data.length > 0 ? (
            props.data.map((item, index) => {
              return (
                <>
                  <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6 card_inner">
                    <div className="card_inner_body">
                      <div className="img_sec img_cards">
                        <Link
                          to={
                            "/" +
                            item.mastercategory.slug +
                            "/" +
                            item.category.slug +
                            "/" +
                            item.subcategory.slug +
                            "/" +
                            item.slug
                          }
                        >
                          <img
                            src={
                              item.file_path
                                ? "https://api.dealitt.com/" + item.file_path
                                : ""
                            }
                            class="w-100"
                            alt=""
                            srcset=""
                          />
                        </Link>
                        {item?.Carts &&
                          item?.Carts.filter(function (value) {
                            return value.product_id === item.id;
                          }).length > 0 ? (
                          <div class="wishlist-icon">
                            <input
                              type="checkbox"
                              className="remove-cart"
                              key={item.id}
                              checked
                              onClick={(e) => addTowhislist(e, item)}
                            />
                            <span className="icon-wishlist"></span>
                          </div>
                        ) : (
                          <div class="wishlist-icon">
                            <input
                              type="checkbox"
                              key={item.id}
                              onClick={(e) => addTowhislist(e, item)}
                            />
                            <span className="icon-wishlist"></span>
                          </div>
                        )}
                      </div>
                      <div className="card_content_price text-center card_content">
                        <Link
                          to={
                            "/" +
                            item.mastercategory.slug +
                            "/" +
                            item.category.slug +
                            "/" +
                            item.subcategory.slug +
                            "/" +
                            item.slug
                          }
                        >
                          <h4>{item.name.length > 15 ? item.name.substring(0, 15) + "..." : item.name}</h4>
                        </Link>
                      </div>
                      <div className="card_content_price text-center card_content">
                        <p>US ${item.offer_price}</p>
                      </div>
                      {/* <div className="card_content_info text-center card_content">
                        <p>200 pieces</p>
                      </div> */}
                      <div className="add_to_cart">
                        <button
                          className="main_button"
                          data-bs-toggle="modal"
                          data-bs-target={`#staticBackdrop2_${item?.id}_4`}
                          onClick={() => {
                            if (item?.price_groups) {
                              let cartMinVal = JSON.parse(item?.price_groups);
                              setCartInput(Number(cartMinVal[0]?.min));
                              setGCart(Number(cartMinVal[0]?.min))
                            }
                          }
                          }
                        >
                          Add To Cart
                        </button>
                        <div className="">
                          <div
                            class="modal fade"
                            id={`staticBackdrop2_${item?.id}_4`}
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            aria-labelledby="staticBackdrop2Label"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-body py-3">
                                  <div className="d-flex flex-column justify-content-center adivgn-items-center cart_popup">
                                    <img
                                      src={
                                        item?.file_path
                                          ? "https://api.dealitt.com/" +
                                          item?.file_path
                                          : ""
                                      }
                                      class="d-block w-100"
                                      alt="..."
                                    />
                                    <h5 className="mt-4">{item?.name}</h5>
                                    <div className="int_sec">
                                      <button
                                        className="int_dec"
                                        onClick={decreaseNumber}
                                      >
                                        -
                                      </button>
                                      <input
                                        type="number"
                                        id="cart_input"
                                        className="form-control"
                                        value={cartInput}
                                        onChange={setMainInputValue}
                                      />

                                      <button
                                        className="int_inc"
                                        onClick={increaseNumber}
                                      >
                                        +
                                      </button>
                                    </div>
                                    {/* Calculated Price : ${calculatedPrice}
                              <br />
                              Unit Price : ${unitPrice} */}
                                    <div className="d-flex justify-content-center mt-3">
                                      <button
                                        type="button"
                                        class="main_button me-3"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => addTocart(e, item)}
                                      >
                                        Done
                                      </button>
                                      <button
                                        type="button"
                                        class="main_button red_button"
                                        data-bs-dismiss="modal"
                                        onClick={() => {
                                          setCartInput(1);
                                          setGCart(1)
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <NotFound />
          )}
        </div>
        <div className="pagination_filters">
          <div className="pagination_filtersL">
            {/* Items per page select dropdown */}
            <select
              class="form-select"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              disabled
            >
              {itemsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  Show {option}
                </option>
              ))}
            </select>
          </div>
          <div className="pagination_filtersR">
            <div>
              {currentPage > 1 && (
                <button onClick={() => goToPage(currentPage - 1)}>
                  <i class="fas fa-chevron-left"></i>
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => goToPage(currentPage + 1)}>
                  <i class="fas fa-chevron-right"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProduct;
