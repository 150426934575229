import React, { Fragment } from "react";
import { Link } from "react-router-dom";
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
const Grid = (props) => {
    const removeTags = (html) => {
        const regex = /(<([^>]+)>)/gi;
        return html.replace(regex, '');
    };
    return (
        <section class="blog-grid py-5">

            <div class="container h-100">
                <h1 className="blog_h1">Latest blogs</h1>
                <div class="row row-gap-3">
                    {props.data && props.data.length > 0
                        ? props.data.map((item, i) => (
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-12 ">
                                <div className="col-md-12">
                                    <Link to={"/blog-details/" + item.id}>
                                        <div className="blog-main">
                                            <div className="blog-img">
                                                <img src={item.file_path ? "https://api.dealitt.com/" + item.file_path : ""} className="w-100" alt="" />
                                            </div>
                                            <div className="blog-text-main">
                                                <div className="blog-tags">
                                                    <div className="d-flex">
                                                        <span className="admin">admin</span>
                                                    </div>
                                                </div>
                                                <div className="blog-title">
                                                    <h4>{item.name.length > 20 ? item.name.substring(0, 20) + "..." : item.name}</h4>
                                                </div>

                                                <div className="blog-text">
                                                    <h6 dangerouslySetInnerHTML={{ __html: item.description.length > 55 ? removeTags(item.description.substring(0, 55) + "...") : removeTags(item.description) }}></h6>
                                                </div>
                                                <div className="btn-blog">
                                                    <Link to={'/blog-details/' + item.id}>Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )) : ""}
                </div>
            </div>
        </section>
    );
};

export default Grid;