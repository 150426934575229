import React, { useEffect } from 'react'
import Footer from "../common/Footer";
import Header from "../common/Header";
import Banner from '../common/Banner';
import ReturnPolicyDealit from './component/returnPolicy/ReturnPolicyDealit';

const ReturnPolicy = () => {
    useEffect(() => {
        document.title = "DEALITT Return and Replacement Policy";
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Banner title={"DEALITT Return and Replacement Policy"} />
            <ReturnPolicyDealit />
            <Footer />
        </>
    )
}

export default ReturnPolicy