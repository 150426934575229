import React,{useEffect,useState} from "react";
import { NavLink } from "react-router-dom";
import { format } from 'date-fns'
import NotificationIcon from "../../images/bell.png"
//import { Link, NavLink } from "react-router-dom";
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
import DataService from "../../services/data.service";
import { toast } from "react-toastify";

const Notification = (props) => {
    const [loading, setLoading] = useState(false);

    const deleteNotifi= (id) => {
        DataService.deleteNotification(id).then(
          () => {
            toast.success("Notification deleted !", {
              position: toast.POSITION.TOP_RIGHT
            });
            props.getData();
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.msg) ||
              error.message ||
              error.toString();
    
            setLoading(false);
            toast.error(resMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
      }
    
    useEffect(() => {
      setLoading(false);
      
    }, []);

    const removenotification = (item) => {
        setLoading(true);
        deleteNotifi(item);
      };

  return (
    <div className="container-fluid">
    <div className="row">
        <div className="col-xl-12 col-md-12 pt-3">
            <h4 className="f-700">Notification</h4>
            <ul className="notifications mt-5">
            {props.data && props.data.length > 0
                ? props.data.map((item, i) => (
                    <>
                    <li>
                        <figure className="img-circle me-3 mb-0"><img src={NotificationIcon} alt='profie image' /></figure>
                        <div className="w-100 ">
                            <p className="mb-0 pb-2">{item.body}</p>
                            <p className="text-start mb-0"><small>{format ( new Date(item.createdAt), 'd MMMM Y')}</small></p>
                        </div>
                        <button onClick={()=>removenotification(item?.id)} className="delete_notification">X</button>
                    </li>
                  

                    </>
                ))
                :
                <>
                 <li>
                    <div className="no_product_main">
                        <p>You have no pending notifications</p>
                    </div>
                </li>
                </>
            }
            </ul>
        </div>
    </div>
</div>


 
  );
};

export default Notification;