import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NotFound from "../../../common/NotFound";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Autocomplete from "react-google-autocomplete";

const RelatedStores = (props) => {

  const containerStyle = {
    height: '350px'
  };
  
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState(9);
  const [center, setCenter] = React.useState({
    lat: 29.514233,
    lng: -95.237264
  });

  const redirectStore = (e, store) => {
    window.location.href = "/vendor-store/"+store.slug;
  }

  useEffect(() => {
    if(props.data && props.data.id){
      setTimeout(async function(){ 
        setIsLoaded(true); 
        //set markers..
        if(props.stores && props.stores.length > 0){
          var locations = await Promise.all(props.stores.map((store, index) => {
              if(store?.map_location){
                var loc = JSON.parse(store?.map_location);
                if(loc){
                  return {
                    lat: parseFloat(loc?.lat),
                    lng: parseFloat(loc?.lng),
                    name: store.name,
                    slug: store.slug,
                    description: store.description
                  }
                }
                
              }
          }));
          setMarkers(locations)
        }
      }, 3000);
      //set current store to center..
      if(props.data?.Store && props.data?.Store?.map_location){
        var location = JSON.parse(props.data?.Store?.map_location);
        if(location){
          setCenter({
            lat: parseFloat(location?.lat),
            lng: parseFloat(location?.lng)
          })
        }
      }
    }
  }, [props]);

  return (
    <div className="recommend my-5">
      <h3>Stores selling related products</h3>
      <div className="container">
        <div className="row">
          {props.stores && props.stores.length > 0 ? (
            <div className="col-md-12 col-12 card_inner">
                <Autocomplete
                    className="form-control map-location-input"
                    apiKey="AIzaSyBJx8whdYVc7PrySCGeSADJZAcz9m_b2Co"
                    libraries="maps,places"
                    placeholder="Enter a location to search stores"
                    onPlaceSelected={(place) => {
                      var location = place.geometry.location;
                      if(location){
                        setZoom(9);
                        setCenter(location);
                      }
                    }}
                  />
                  <div id="gmap">
                    {isLoaded ? (
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={center}
                          zoom={zoom}
                        >
                          { /* Child components, such as markers, info windows, etc. */ }
                            {markers.map((marker) => (
                                <Marker 
                                  title={marker.name}
                                  onClick={(e) => redirectStore(e, marker)}
                                  position={{ 
                                    lat: marker.lat,
                                    lng: marker.lng 
                                  }} />
                            ))}
                          <></>
                        </GoogleMap>
                    ) : <></>}
                  </div>
            </div>
          ) : (
            <NotFound />
          )}
        </div>
      </div>
    </div>
  );
};

export default RelatedStores;
