import React, {useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import DataService from "../services/data.service";
const Privacy = () => {
  const [data, setData] = useState({});
  const [html, setHTML] = useState({__html: ""});
  useEffect(() => {
      document.title = "Privacy";
      getData();
  }, []);
  const getData = () => {
      DataService.getPage('privacy-notice').then((data) => {
          if(data.data.data.length>0){
              setData(data?.data?.data[0])
              setHTML({__html: data?.data?.data[0]?.description})
          }   
      });
  }
  return (
    <>
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12 py-4">
            <h3 className="f-36">{data.name}</h3>
            <div dangerouslySetInnerHTML={html} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;