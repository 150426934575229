import React, {useEffect} from 'react'
import Footer from "../common/Footer";
import Header from "../common/Header";
import Banner from '../common/Banner';
import VisionDealit from './component/vision/VisionDealit';

const Vision = () => {
    useEffect(() => {
        document.title = "Our Vision, Aspiration, and Key Values";
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Banner title={"Our Vision, Aspiration, and Key Values"} />
            <VisionDealit />
            <Footer />
        </>
    )
}

export default Vision