import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider1 from "../../../images/slider-img1.png";
import Slider2 from "../../../images/slider-img2.jpg";
import Slider3 from "../../../images/slider-img3.png";
import Slider from "react-slick";

function ServiceBanner(data) {
  useEffect(() => {
    console.log(data.banners)
  }, [])
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    autoplaySpeed: 4000,
  };
  const bannerRedirect = (e, url) => {
    e.preventDefault();
    window.location.href = (url ? url : '#');
  };
  return (
    <div>
 <div className="HomepageSlider">

      <Slider {...settings}>
        {data.banners[1] && data.banners[1].length > 0 ?
          data.banners[1].map((banner, i) => (
            <Link onClick={(e) => { bannerRedirect(e, banner.url) }} >
              <div class="radiusSlider">
                <img src={banner.file_path ? "https://api.dealitt.com/" + banner.file_path : ""} alt="slide-1" class="w-100 h-100" />
                <div class="slide-2 position-absolute bottom-0">
                  <div>
                
                  </div>
                </div>
              </div>
            </Link>
          ))
          :
          ""
        }
      </Slider>
      </div>
     

    </div>
  );
}

export default ServiceBanner;
