import React from "react";
import { Link } from "react-router-dom";
import Ser1 from "../../../images/ser1.png";
import Ser2 from "../../../images/ser2.png";
import Ser3 from "../../../images/ser3.png";
import Ser4 from "../../../images/ser4.png";
import Ser5 from "../../../images/ser5.png";
import Ser6 from "../../../images/ser6.png";
import Ser7 from "../../../images/ser7.png";
import ser8 from "../../../images/All-catagory.png";

function ServiceMain(props) {
  return (
    <div>
      <div className="service-main">
        <div className="service-head border-bottom">
          <h2>My markets</h2>
        </div>
        {/* {props.data} */}
        <div className="service mt-3">
          <ul>
            {props.data && props.data.length > 0
              ? props.data.map((item, i) => (
                  <li>
                  <Link to={`${item.slug}`}>
                    <img
                            src={
                              item.file_path
                                ? "https://api.dealitt.com/" + item.file_path
                                : ""
                            }
                            class="w-100"
                            alt=""
                            srcset=""
                          />
                      <h3>
                        <Link to={`${item.slug}`}>{item.name}</Link>
                      </h3>
                    </Link>
                    <div className="service-side">
                      <div className="service-hover mb-4">
                        {item.Categories && item.Categories.length > 0
                          ? item.Categories.map((cat, j) => (
                              <>
                                <div className="category_inner">
                                  <ul>
                                    <Link to={`${item.slug}/${cat.slug}`}>
                                      <h4>{cat.name}</h4>
                                    </Link>
                                    {cat.Categories && cat.Categories.length > 0
                                      ? cat.Categories.map((subcat, k) => (
                                          <li>
                                            <Link
                                              to={`${item.slug}/${cat.slug}/${subcat.slug}`}
                                            >
                                              {subcat.name}
                                            </Link>
                                          </li>
                                        ))
                                      : ""}
                                  </ul>
                                </div>
                              </>
                            ))
                          : ""}
                      </div>
                    </div>
                  </li>
                ))
              : ""}
            {/* <li>
              <Link to="">
                <img src={Ser1} alt="Service-img" />
                <h3>Consumer Electronics</h3>
              </Link>
              <div className="service-side">
                <div className="service-hover mb-4">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">PCB & PCBS</Link>
                    </li>
                    <li>
                      <Link to="">Ceramic PCB</Link>
                    </li>
                    <li>
                      <Link to="">Aluminum PCB</Link>
                    </li>
                    <li>
                      <Link to="">Metal PCB</Link>
                    </li>
                    <li>
                      <Link to="">PCBA</Link>
                    </li>
                    <li>
                      <Link to="">Rigid PCS</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Home Decor</Link>
                    </li>
                    <li>
                      <Link to="">Plaques</Link>
                    </li>
                    <li>
                      <Link to="">Clocks</Link>
                    </li>
                    <li>
                      <Link to="">Vases</Link>
                    </li>
                    <li>
                      <Link to="">Blinds, Shades & Shutters</Link>
                    </li>
                    <li>
                      <Link to="">Poufs</Link>
                    </li>
                    <li>
                      <Link to="">Decorative Flowers</Link>
                    </li>
                  </ul>
                </div>
                <div className="service-hover">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li> */}
            {/* <li>
              <Link to="">
                <img src={Ser2} alt="Service-img" />
                <h3>Apparel & Accessories</h3>
              </Link>
              <div className="service-side">
                <div className="service-hover mb-4">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                </div>
                <div className="service-hover">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">PCB & PCBS</Link>
                    </li>
                    <li>
                      <Link to="">Ceramic PCB</Link>
                    </li>
                    <li>
                      <Link to="">Aluminum PCB</Link>
                    </li>
                    <li>
                      <Link to="">Metal PCB</Link>
                    </li>
                    <li>
                      <Link to="">PCBA</Link>
                    </li>
                    <li>
                      <Link to="">Rigid PCS</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Home Decor</Link>
                    </li>
                    <li>
                      <Link to="">Plaques</Link>
                    </li>
                    <li>
                      <Link to="">Clocks</Link>
                    </li>
                    <li>
                      <Link to="">Vases</Link>
                    </li>
                    <li>
                      <Link to="">Blinds, Shades & Shutters</Link>
                    </li>
                    <li>
                      <Link to="">Poufs</Link>
                    </li>
                    <li>
                      <Link to="">Decorative Flowers</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <Link to="">
                <img src={Ser3} alt="Service-img" />
                <h3>Vehicle Parts & Accessories</h3>
              </Link>
              <div className="service-side">
                <div className="service-hover mb-4">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">PCB & PCBS</Link>
                    </li>
                    <li>
                      <Link to="">Ceramic PCB</Link>
                    </li>
                    <li>
                      <Link to="">Aluminum PCB</Link>
                    </li>
                    <li>
                      <Link to="">Metal PCB</Link>
                    </li>
                    <li>
                      <Link to="">PCBA</Link>
                    </li>
                    <li>
                      <Link to="">Rigid PCS</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Home Decor</Link>
                    </li>
                    <li>
                      <Link to="">Plaques</Link>
                    </li>
                    <li>
                      <Link to="">Clocks</Link>
                    </li>
                    <li>
                      <Link to="">Vases</Link>
                    </li>
                    <li>
                      <Link to="">Blinds, Shades & Shutters</Link>
                    </li>
                    <li>
                      <Link to="">Poufs</Link>
                    </li>
                    <li>
                      <Link to="">Decorative Flowers</Link>
                    </li>
                  </ul>
                </div>
                <div className="service-hover">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <Link to="">
                <img src={Ser4} alt="Service-img" />
                <h3>Sports & Entertainment</h3>
              </Link>
              <div className="service-side">
                <div className="service-hover mb-4">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                </div>
                <div className="service-hover">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">PCB & PCBS</Link>
                    </li>
                    <li>
                      <Link to="">Ceramic PCB</Link>
                    </li>
                    <li>
                      <Link to="">Aluminum PCB</Link>
                    </li>
                    <li>
                      <Link to="">Metal PCB</Link>
                    </li>
                    <li>
                      <Link to="">PCBA</Link>
                    </li>
                    <li>
                      <Link to="">Rigid PCS</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Home Decor</Link>
                    </li>
                    <li>
                      <Link to="">Plaques</Link>
                    </li>
                    <li>
                      <Link to="">Clocks</Link>
                    </li>
                    <li>
                      <Link to="">Vases</Link>
                    </li>
                    <li>
                      <Link to="">Blinds, Shades & Shutters</Link>
                    </li>
                    <li>
                      <Link to="">Poufs</Link>
                    </li>
                    <li>
                      <Link to="">Decorative Flowers</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <Link to="">
                <img src={Ser5} alt="Service-img" />
                <h3>Industrial Machinery</h3>
              </Link>
              <div className="service-side">
                <div className="service-hover mb-4">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">PCB & PCBS</Link>
                    </li>
                    <li>
                      <Link to="">Ceramic PCB</Link>
                    </li>
                    <li>
                      <Link to="">Aluminum PCB</Link>
                    </li>
                    <li>
                      <Link to="">Metal PCB</Link>
                    </li>
                    <li>
                      <Link to="">PCBA</Link>
                    </li>
                    <li>
                      <Link to="">Rigid PCS</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Home Decor</Link>
                    </li>
                    <li>
                      <Link to="">Plaques</Link>
                    </li>
                    <li>
                      <Link to="">Clocks</Link>
                    </li>
                    <li>
                      <Link to="">Vases</Link>
                    </li>
                    <li>
                      <Link to="">Blinds, Shades & Shutters</Link>
                    </li>
                    <li>
                      <Link to="">Poufs</Link>
                    </li>
                    <li>
                      <Link to="">Decorative Flowers</Link>
                    </li>
                  </ul>
                </div>
                <div className="service-hover">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <Link to="">
                <img src={Ser6} alt="Service-img" />
                <h3>Home & Garden</h3>
              </Link>
              <div className="service-side">
                <div className="service-hover mb-4">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                </div>
                <div className="service-hover">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">PCB & PCBS</Link>
                    </li>
                    <li>
                      <Link to="">Ceramic PCB</Link>
                    </li>
                    <li>
                      <Link to="">Aluminum PCB</Link>
                    </li>
                    <li>
                      <Link to="">Metal PCB</Link>
                    </li>
                    <li>
                      <Link to="">PCBA</Link>
                    </li>
                    <li>
                      <Link to="">Rigid PCS</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Home Decor</Link>
                    </li>
                    <li>
                      <Link to="">Plaques</Link>
                    </li>
                    <li>
                      <Link to="">Clocks</Link>
                    </li>
                    <li>
                      <Link to="">Vases</Link>
                    </li>
                    <li>
                      <Link to="">Blinds, Shades & Shutters</Link>
                    </li>
                    <li>
                      <Link to="">Poufs</Link>
                    </li>
                    <li>
                      <Link to="">Decorative Flowers</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <Link to="">
                <img src={Ser7} alt="Service-img" />
                <h3>Beauty</h3>
              </Link>
              <div className="service-side">
                <div className="service-hover mb-4">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">PCB & PCBS</Link>
                    </li>
                    <li>
                      <Link to="">Ceramic PCB</Link>
                    </li>
                    <li>
                      <Link to="">Aluminum PCB</Link>
                    </li>
                    <li>
                      <Link to="">Metal PCB</Link>
                    </li>
                    <li>
                      <Link to="">PCBA</Link>
                    </li>
                    <li>
                      <Link to="">Rigid PCS</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Home Decor</Link>
                    </li>
                    <li>
                      <Link to="">Plaques</Link>
                    </li>
                    <li>
                      <Link to="">Clocks</Link>
                    </li>
                    <li>
                      <Link to="">Vases</Link>
                    </li>
                    <li>
                      <Link to="">Blinds, Shades & Shutters</Link>
                    </li>
                    <li>
                      <Link to="">Poufs</Link>
                    </li>
                    <li>
                      <Link to="">Decorative Flowers</Link>
                    </li>
                  </ul>
                </div>
                <div className="service-hover">
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <Link to="">Belts & Accessories</Link>
                    </li>
                    <li>
                      <Link to="">Fabric Belts</Link>
                    </li>
                    <li>
                      <Link to="">Genuine Leather Belts</Link>
                    </li>
                    <li>
                      <Link to="">Chain Belts</Link>
                    </li>
                    <li>
                      <Link to="">PU Belts</Link>
                    </li>
                    <li>
                      <Link to="">Plastic Belts</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li>
              <Link to="">
                <img src={ser8} alt="" />
                <h3>All Categories</h3>
              </Link>
              <div className="category-side">
                <Link to="">Belts & Accessories</Link>
                <span>/</span>
                <Link to="">Fabric Belts</Link>
                <span>/</span>
                <Link to="">Genuine Leather Belts</Link>
                <span>/</span>
                <Link to="">Chain Belts</Link>
                <span>/</span>
                <Link to="">PU Belts</Link>
                <span>/</span>
                <Link to="">Plastic Belts</Link>
                <span>/</span>
                <Link to="">Belts & Accessories</Link>
                <span>/</span>
                <Link to="">Fabric Belts</Link>
                <span>/</span>
                <Link to="">Genuine Leather Belts</Link>
                <span>/</span>
                <Link to="">Chain Belts</Link>
                <span>/</span>
                <Link to="">PU Belts</Link>
                <span>/</span>
                <Link to="">Plastic Belts</Link>
                <span>/</span>
                <Link to="">Belts & Accessories</Link>
                <span>/</span>
                <Link to="">Fabric Belts</Link>
                <span>/</span>
                <Link to="">Genuine Leather Belts</Link>
                <span>/</span>
                <Link to="">Chain Belts</Link>
                <span>/</span>
                <Link to="">PU Belts</Link>
                <span>/</span>
                <Link to="">Plastic Belts</Link>
                <span>/</span>
                <Link to="">Belts & Accessories</Link>
                <span>/</span>
                <Link to="">Fabric Belts</Link>
                <span>/</span>
                <Link to="">Genuine Leather Belts</Link>
                <span>/</span>
                <Link to="">Chain Belts</Link>
                <span>/</span>
                <Link to="">PU Belts</Link>
                <span>/</span>
                <Link to="">Plastic Belts</Link>
                <span>/</span>
                <Link to="">Belts & Accessories</Link>
                <span>/</span>
                <Link to="">Fabric Belts</Link>
                <span>/</span>
                <Link to="">Genuine Leather Belts</Link>
                <span>/</span>
                <Link to="">Chain Belts</Link>
                <span>/</span>
                <Link to="">PU Belts</Link>
                <span>/</span>
                <Link to="">Plastic Belts</Link>
                <span>/</span>
                <Link to="">Belts & Accessories</Link>
                <span>/</span>
                <Link to="">Fabric Belts</Link>
                <span>/</span>
                <Link to="">Genuine Leather Belts</Link>
                <span>/</span>
                <Link to="">Chain Belts</Link>
                <span>/</span>
                <Link to="">PU Belts</Link>
                <span>/</span>
                <Link to="">Plastic Belts</Link>
                <span>/</span>
                <Link to="">Belts & Accessories</Link>
                <span>/</span>
                <Link to="">Fabric Belts</Link>
                <span>/</span>
                <Link to="">Genuine Leather Belts</Link>
                <span>/</span>
                <Link to="">Chain Belts</Link>
                <span>/</span>
                <Link to="">PU Belts</Link>
                <span>/</span>
                <Link to="">Plastic Belts</Link>
                <span>/</span>
                <Link to="">Belts & Accessories</Link>
                <span>/</span>
                <Link to="">Fabric Belts</Link>
                <span>/</span>
                <Link to="">Genuine Leather Belts</Link>
                <span>/</span>
                <Link to="">Chain Belts</Link>
                <span>/</span>
                <Link to="">PU Belts</Link>
                <span>/</span>
                <Link to="">Plastic Belts</Link>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ServiceMain;
