import React, { useEffect, useState, Fragment } from "react";
import DataService from "../services/data.service"
import Footer from "../common/Footer";
import Header from "../common/Header";
import Grid from "../pages/component/blog/grid-section";
import Banner from "../common/Banner";
const About = () => {
    React.useEffect(() => {
        document.title = "Product Page";
  }, []);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  //console.log(props)

  useEffect(() => {
      document.title = "Blog";
      getData();
  }, []);

  const getData = () => {
      DataService.getBlog().then((data) => {
          setData(data.data.data);
          setLoading(false);
      });
  };
  return (
    <>
        <Header />

        <Banner title={"Blog"}/>
        
        <Grid data={data}/>
        <Footer />
    </>
  );
};

export default About;