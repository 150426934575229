import React, { useState } from 'react'
import '../../../customCss/SellBuy.css'

const SellBuyDealit = () => {
    const [tab, setTab] = useState('buyers');
    return (
        <>
            <section class="sellBuySec">
                <div className='container button_group py-2'>
                    <div className="row">
                        <div className='col-sm-12 button_group'>
                            <button
                                className={tab === 'buyers' ? 'activated' : ''}
                                onClick={() => setTab('buyers')}
                            >
                                For Buyers</button>
                            <button
                                className={tab === 'sellers' ? 'activated' : ''}
                                onClick={() => setTab('sellers')}
                            >For Sellers</button>
                        </div>
                    </div>
                </div>
                {tab === 'buyers' &&
                    <div class="container mt-3 mb-5 sell_flexSec">
                        <div className='text-center'>
                            <h2>For Buyers</h2>
                            <p>DEALITT offers an intuitive platform that simplifies the process of finding, comparing, and purchasing products. <br />Follow these steps to enhance your buying experience:
                            </p>
                        </div>
                        <div className='row g-4 justify-content-center align-items-stretch mt-3'>
                            <div className='col-sm-3'>
                                <div className='sell_boxInner'>
                                    <h4>
                                        Searching for Products:
                                    </h4>
                                    <ul>
                                        <li>Use the search bar at the top of the page to enter keywords related to the products you need.</li>
                                        <li>Apply filters such as price, brand, and specifications to narrow down your options.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='sell_boxInner'>
                                    <h4>
                                        Comparing Products:
                                    </h4>
                                    <ul>
                                        <li>Utilize the "Compare" feature to view side-by-side comparisons of product features, prices, and seller ratings, helping you make an informed choice.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='sell_boxInner'>
                                    <h4>
                                        Contacting Sellers:
                                    </h4>
                                    <ul>
                                        <li>If you have questions or need more details, use the messaging feature to contact sellers directly. Clear communication ensures you have all the information needed before purchasing.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='sell_boxInner'>
                                    <h4>
                                        Making a Purchase:
                                    </h4>
                                    <ul>
                                        <li>Add your chosen products to the cart and proceed to checkout. Review your order, select a payment method, and confirm the purchase.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='sell_boxInner'>
                                    <h4>
                                        Reporting Issues:
                                    </h4>
                                    <ul>
                                        <li>Should you encounter any issues with your order, such as receiving an incorrect or damaged item, report it through your order history. Provide detailed information to expedite resolution.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='sell_boxInner'>
                                    <h4>
                                        Building Your Profile:
                                    </h4>
                                    <ul>
                                        <li>Create a comprehensive buyer profile to receive personalized recommendations and track your purchase history. This helps to tailor your shopping experience on DEALITT.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='sell_boxInner'>
                                    <h4>
                                        Customer Service:
                                    </h4>
                                    <ul>
                                        <li>Our customer service team is here to help with any questions or issues. Contact us through the platform’s support center, where you can submit a ticket or engage in live chat.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {tab === 'sellers' &&
                    <div class="container mt-3 mb-5 sell_flexSec">
                        <div className='text-center'>
                            <h2>For Sellers</h2>
                            <p>As a seller on DEALITT, you can reach a global audience and grow your business.<br /> Follow these guidelines to maximize your success:
                            </p>
                        </div>
                        <div className='row g-4 justify-content-center align-items-stretch mt-3'>
                            <div className='col-sm-6'>
                                <div className='sell_boxInner forSeller'>
                                    <h4>
                                        Adding Your Products:
                                    </h4>
                                    <ul>
                                        <li><strong>Clear Titles:</strong> Use specific and descriptive product titles.</li>
                                        <li><strong>Detailed Descriptions:</strong> Include comprehensive details like features, dimensions, and uses.</li>
                                        <li><strong>Specifications:</strong> Provide relevant specifications to assist buyers in making informed decisions.</li>
                                        <li><strong>Manufacturing Process:</strong> Describe the manufacturing process, including materials and techniques.</li>
                                        <li><strong>Safety Information:</strong> Clearly state any hazardous materials and explain safety measures.</li>
                                        <li><strong>Certifications:</strong> Ensure all certifications (e.g., ISO, CE, ROHS) are valid and verifiable.</li>
                                        <li><strong>Customization Options:</strong> If available, specify customization options and timelines.</li>
                                        <li><strong>Lead Time:</strong> Provide realistic lead times based on order quantity.</li>
                                        <li><strong>High-Quality Photos:</strong> Upload 3-5 images showcasing your product from multiple angles.</li>
                                        <li><strong>Catalogs and Videos:</strong> Include catalogs and videos to highlight your manufacturing capabilities.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='sell_boxInner forSeller'>
                                    <h4>
                                        Shop Decoration:
                                    </h4>
                                    <ul>
                                        <li><strong>Business Information:</strong> Provide detailed information about your business, including name, address, products, and unique selling points.</li>
                                        <li><strong>Manufacturing Capability:</strong> Explain your company’s size and factory records.</li>
                                        <li><strong>Quality Control:</strong> Detail the processes in place to ensure product standards.</li>
                                        <li><strong>Research and Development:</strong> Share your latest R&D efforts to build trust.</li>
                                        <li><strong>Export Capability:</strong> List the countries to which you can export your products.</li>
                                        <li><strong>Company Introduction:</strong> Include your company’s vision, mission, and achievements.</li>
                                        <li><strong>Company Account Details:</strong> Ensure your banking details are current for smooth transactions.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div class="container mt-3 mb-5 sell_flexSec">
                    <div className='text-center'>
                        <h2>Seller Code of Conduct</h2>
                        <p>At DEALITT, we prioritize maintaining a trustworthy and reliable platform. <br />All sellers must adhere to the following Code of Conduct:
                        </p>
                    </div>
                    <div className='row g-4 justify-content-center align-items-stretch mt-3'>
                        <div className='col-sm-4'>
                            <div className='sell_boxInner forSeller'>
                                <h4>
                                    Honesty and Transparency
                                </h4>
                                <ul>
                                    <li><strong>Accurate Product Descriptions:</strong> Ensure all product details are truthful and comprehensive.</li>
                                    <li><strong>Genuine Certifications:</strong> Certifications must be valid and verifiable.</li>
                                    <li><strong>Transparent Pricing:</strong> Prices should be fair, with no hidden fees or price manipulation.</li>
                                    <li><strong>Accurate Lead Times:</strong> Provide realistic production and shipping times.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='sell_boxInner forSeller'>
                                <h4>
                                    Prohibited Practices
                                </h4>
                                <ul>
                                    <li><strong>Manipulation of Product Information:</strong> Deceptive practices, such as altering descriptions or images, will result in penalties.</li>
                                    <li><strong>Counterfeit and Substandard Goods:</strong> Selling counterfeit or substandard goods is strictly prohibited.</li>
                                    <li><strong>Fraudulent Activity:</strong> Any form of fraud, including false information or deceptive marketing, is not allowed.</li>
                                    <li><strong>Unauthorized Repackaging and Relabeling:</strong> Misleading buyers through repackaging or relabeling is forbidden.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='sell_boxInner forSeller'>
                                <h4>
                                    Ethical Business Practices
                                </h4>
                                <ul>
                                    <li><strong>Respect for Intellectual Property:</strong> Avoid unauthorized use of trademarks or copyrights.</li>
                                    <li><strong>Fair Competition:</strong> Engage in honest competition without resorting to tactics like fake reviews.</li>
                                    <li><strong>Sustainability:</strong> Adopt sustainable practices and clearly label products with potential environmental impacts.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='sell_boxInner forSeller'>
                                <h4>
                                    Commitment to Product Safety
                                </h4>
                                <ul>
                                    <li><strong>Safety Standards Compliance:</strong> Ensure products meet safety standards and provide clear instructions.</li>
                                    <li><strong>Disclosure of Hazardous Materials:</strong> Clearly disclose any hazardous materials and provide handling instructions.</li>
                                    <li><strong>Child Safety:</strong> Products intended for children must meet stringent safety standards.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='sell_boxInner forSeller'>
                                <h4>
                                    Customer Service and Responsiveness
                                </h4>
                                <ul>
                                    <li><strong>Timely Communication:</strong> Respond to buyer inquiries within 24-48 hours.</li>
                                    <li><strong>Problem Resolution:</strong> Cooperate with DEALITT’s dispute resolution process.</li>
                                    <li><strong>Returns and Refunds:</strong> Honor your return and refund.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='sell_boxInner forSeller'>
                                <h4>
                                    Compliance with DEALITT Policies
                                </h4>
                                <ul>
                                    <li><strong>Adherence to Platform Rules:</strong> Follow all DEALITT platform rules and guidelines.</li>
                                    <li><strong>Consequences for Violations:</strong> Violations may result in penalties, including suspension or banning.</li>
                                    <li><strong>Commitment to Improvement:</strong> Continuously seek to improve practices and update product listings.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='sell_boxInner forSeller'>
                                <h4>
                                    Legal and Ethical Obligations
                                </h4>
                                <ul>
                                    <li><strong>Compliance with Laws:</strong> Adhere to all applicable laws and regulations, including trade, safety, labor rights, and environmental laws.</li>
                                    <li><strong>Ethical Labor Practices:</strong> Ensure ethical labor conditions in your manufacturing process.</li>
                                    <li><strong>Anti-Corruption:</strong> Avoid any form of corruption or bribery.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='sell_boxInner forSeller'>
                                <h4>
                                    Continuous Monitoring and Enforcement
                                </h4>
                                <ul>
                                    <li><strong>Regular Audits:</strong> DEALITT will conduct audits to ensure compliance.</li>
                                    <li><strong>Reporting Violations:</strong> Violations can be reported confidentially for investigation.</li>
                                    <li><strong>Penalties for Non-Compliance:</strong> Penalties can range from warnings to permanent bans and legal action.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SellBuyDealit