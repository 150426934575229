import React from 'react'
import '../../../customCss/OurStory.css'
import Portrait from '../../../images/our-stoy.jpg'

const OurStoryDealit = () => {
    return (
        <>
            <section class="storySec">
                <div className='container my-5 our_storyFlex'>
                    <div className='row g-4 align-items-stretch'>
                        <div className='col-sm-3'>
                          <div className='stickImg'>
                            <img className='w-100' src={Portrait} alt='our-story'/>
                          </div>
                        </div>
                        <div className='col-sm-9 stickText'>
                            <p><strong>DEALITT</strong> was established in 2023, and we are committed to building the best technology to create better opportunities for people worldwide. We recognized a gap between social needs, which has left many unable to effectively communicate with factories. There is a need for better technology that facilitates communication between business owners and people globally. Additionally, many individuals struggle to identify the best products for their needs and to discern quality. We aim to assist both buyers and sellers by improving the quality of search and connection to the products that best meet buyers' needs. <strong>DEALITT</strong> is dedicated to establishing processes and policies that ensure a consistent and fair approach for both our clients and staff.</p><p>
                                We believe that building our business with the utmost responsibility is the key to our success. We strive for continuous improvement, with the goal of helping businesses worldwide secure the best deals. By supporting businesses of all sizes in achieving their targets, we can create opportunities in economically disadvantaged regions. This will assist governments in the Middle East, Asia, and Africa in improving quality of life and reducing poverty.</p><p>
                                <strong>DEALITT</strong> is a digital e-commerce company committed to helping businesses succeed by facilitating safe and successful deals. Our aim is to build trust between buyers and sellers across different countries. Millions of factories worldwide face challenges in communication and in presenting their products, which impacts business and economic growth in many countries, particularly in those with limited resources. Our mission is to bridge this gap by helping manufacturers globally secure deals and effectively showcase their products to diverse clients.</p><p>
                                We prioritize listening to everyone we work with to create opportunities and deliver the best outcomes. We are committed to fostering a culture of Equity, Diversity, and Inclusion within our company. We understand that without diversity, we cannot achieve happiness for our employees and clients, which is essential to our mission. We are dedicated to creating a safe and respectful workplace, welcoming individuals from diverse backgrounds and talents to achieve their best and build their skills.</p><p>
                                We never leave our people unsupported. Collaboration and teamwork are integral to our culture. If challenges arise, we come together to support each other, no matter the scale of the problem. We always encourage our staff to reach their full potential, build their futures, and support their families. We believe that the happiness of our employees and their families will lead to a happier community and, in turn, the success of our company. We continuously support our team’s career development through professional programs designed to enhance their skills.</p><p>
                                Anyone who joins <strong>DEALITT</strong> will have the opportunity to work and connect with our global network. Our diverse team, spread across different countries, helps employees build their skills and gain a deeper understanding of the challenges faced by other regions, which in turn motivates them to develop innovative solutions. We deploy the best cloud infrastructure and are always on the lookout for cutting-edge technology to enhance our systems. We strongly believe that building a resilient and robust IT system is crucial for our future. We encourage our staff to adopt the latest technology, understanding that sustainability involves navigating the complexities of environmental, economic, and social integration. We take pride in our mission to improve lives around the world by enhancing economies, which in turn contributes to a better environment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStoryDealit