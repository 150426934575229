import React, { useEffect } from 'react';

const GoogleTranslator = () => {
    useEffect(() => {
        // Check if the script is already added to prevent duplicates
        if (!document.getElementById('google-translate-script')) {
            const script = document.createElement('script');
            script.id = 'google-translate-script';
            script.type = 'text/javascript';
            script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            document.body.appendChild(script);
        }

        window.googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                { pageLanguage: 'en' },
                'google_translate_element'
            );
        };
    }, []);

    return <div id="google_translate_element"></div>;
};

export default GoogleTranslator;
