import React from 'react'
import '../../../customCss/PaymentGuide.css'

const PaymentTransactionDealit = () => {
    return (
        <>
            <section class="paymentGuideSec">
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <p className='text-center'>Welcome to DEALITT! To ensure a smooth and secure purchasing experience, we've prepared this guide to help you navigate our payment process. Whether you're a buyer or a seller, our platform offers multiple payment options to suit your needs.</p>
                            <h3>1. Overview of Payment Options</h3>
                            <p>DEALITT provides a variety of payment methods to accommodate your preferences and ensure transaction security:</p>
                            <ul>
                                <li><strong>Credit/Debit Cards</strong> Visa, Mastercard</li>
                                <li><strong>Digital Wallets:</strong> Google Pay, Apple Pay</li>
                                <li><strong>Secure Payment (Escrow):</strong> Protects both buyers and sellers during transactions</li>
                            </ul>
                            <h3>2. How to Make a Payment</h3>
                            <h4>Step 1: Selecting a Product</h4>
                            <ul>
                                <li><strong>Browse the Platform:</strong> Explore the wide range of products and services available on DEALITT.</li>
                                <li><strong>Add to Cart:</strong> Once you find a product you like, click on "Add to Cart" to save it for checkout.</li>
                            </ul>
                            <h4>Step 2: Proceeding to Checkout</h4>
                            <ul>
                                <li><strong>Review Your Cart:</strong> Before proceeding, review the items in your cart to ensure everything is correct.</li>
                                <li><strong>Click on Checkout:</strong> When you're ready to make a purchase, click the "Checkout" button.</li>
                            </ul>
                            <h4>Step 3: Choosing a Payment Method</h4>
                            <h6>Select Your Preferred Payment Option:</h6>
                            <ul>
                                <li><strong>Credit/Debit Cards:</strong> Choose Visa or Mastercard and securely enter your card details.</li>
                                <li><strong>Digital Wallets:</strong> Select Google Pay or Apple Pay, and follow the prompts to authorize payment.</li>
                                <li><strong>Escrow Payment:</strong> Choose the Escrow option for added security, where your payment is held until you confirm receipt of the product.</li>
                            </ul>
                            <h4>Step 4: Completing the Payment</h4>
                            <ul>
                                <li><strong>Confirm Payment Details:</strong> Double-check your payment details to ensure accuracy.</li>
                                <li><strong>Submit Payment:</strong> Click "Submit Payment" to finalize your purchase. You will receive a confirmation email once the transaction is complete.</li>
                            </ul>
                            <h3>3. Payment Security Features</h3>
                            <p>At DEALITT, your security is our top priority. Here are the measures we take to protect your payment information:</p>
                            <ul>
                                <li><strong>SSL Encryption:</strong> All transactions are secured with SSL encryption to protect your payment details.</li>
                                <li><strong>Tokenization:</strong> Sensitive card details are replaced with unique tokens to prevent unauthorized access.</li>
                                <li><strong>Two-Factor Authentication (2FA):</strong> For added security, we may require 2FA during payment processing.</li>
                            </ul>
                            <h3>4. Escrow Payment Process</h3>
                            <p>Escrow is a payment option that adds an extra layer of security for both buyers and sellers:</p>
                            <ul>
                                <li><strong>Payment Held Securely:</strong> Your payment is held by a trusted third-party escrow service until you receive and confirm the product.</li>
                                <li><strong>Release of Funds:</strong> Once you confirm receipt of the product in satisfactory condition, the funds are released to the seller.</li>
                                <li><strong>Dispute Resolution:</strong> If there are any issues with the product, the escrow service will mediate to resolve the dispute and protect your payment.</li>
                            </ul>
                            <h3>5. Handling Returns and Refunds</h3>
                            <p>If you encounter any issues with your order, such as receiving a damaged or incorrect item, DEALITT offers a straightforward return and refund process:</p>
                            <h6>Return Process</h6>
                            <ul>
                                <li><strong>Initiate Return:</strong> Go to your order history, select the item, and click "Return."</li>
                                <li><strong>Provide Details:</strong> Include photos of the product and a description of the issue.</li>
                                <li><strong>Submit Request:</strong> Once your return request is submitted, our support team will review it.</li></ul>
                            <h6>Refund Process</h6>
                            <ul>
                                <li><strong>Approval:</strong> If your return is approved, the refund will be processed.</li>
                                <li><strong>Refund Method:</strong> Funds will be returned to your original payment method or held in escrow until the seller confirms the return.</li>
                            </ul>
                            <h3> 6. Payment Confirmation and Notifications</h3>
                            <p>We ensure that you stay informed throughout the transaction process:</p>
                            <ul>
                                <li><strong>Order Confirmation:</strong> After completing your purchase, you will receive a confirmation email with details of your order and an invoice.</li>
                                <li><strong>Shipping Notifications:</strong> You will receive updates on the shipment and delivery status of your items, ensuring you're aware of your order’s progress.</li></ul>
                            <h3>7. Accepted Currencies and Exchange Rates</h3>
                            <p>DEALITT supports transactions in multiple currencies to make payments convenient for you:</p>
                            <ul>
                                <li><strong>Supported Currencies:</strong> We accept payments in various currencies, depending on your location and the seller’s preferences.</li>
                                <li><strong>Currency Conversion:</strong> If your payment is made in a currency different from your default currency, our system will handle the conversion. Please note that exchange rates and conversion fees may apply.</li></ul>
                            <h3>8. Tax and Fees Information</h3>
                            <p>Understanding the costs associated with your purchases is important:</p>
                            <ul>
                                <li><strong>Applicable Taxes:</strong> Taxes may be applied to your purchases based on your location and local regulations. These will be clearly displayed at checkout.</li>
                                <li><strong>Additional Fees:</strong> Some payment methods may incur additional fees, such as service fees or transaction fees. These will also be detailed during the checkout process.</li></ul>
                            <h3>9. Payment Receipts and Invoices</h3>
                            <p>Accessing and managing your payment documents is straightforward:</p>
                            <ul>
                                <li><strong>Accessing Receipts:</strong> After a successful transaction, you can download your payment receipts and invoices directly from your account under the "Order History" section.</li></ul>
                            <h3>10. Payment Method Management</h3>
                            <p>Managing your payment methods is easy and secure on DEALITT:</p>
                            <ul>
                                <li><strong>Adding/Removing Payment Methods:</strong> You can add new payment methods or remove existing ones from your account settings. This allows you to control which payment options are available during checkout.</li>
                                <li><strong>Updating Payment Information:</strong> If you need to update your card details or other payment information, simply navigate to your account settings and make the necessary changes.</li></ul>
                            <h3>11. Troubleshooting Payment Issues</h3>
                            <p>If you experience any issues during the payment process:</p>
                            <ul>
                                <li><strong>Check Payment Information:</strong> Ensure that all payment details are entered correctly.</li>
                                <li><strong>Contact Support:</strong> If the issue persists, contact our customer support team for assistance via email.</li>
                                <li><strong>Use Alternative Methods:</strong> Consider using a different payment method if the current one fails.</li>
                            </ul>
                            <h3> 12. Contact Support</h3>
                            <p>For any payment-related queries or issues:</p>
                            <ul>
                                <li>Email: <a href="mailto: support@dealitt.com">support@dealitt.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PaymentTransactionDealit