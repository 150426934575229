import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { calDiscount } from "../../../services/helper.service";
import "../../../customCss/subcategory.css"
import { toast } from "react-toastify";

let mastCatId = [];
let catId = [];
let subCatId = [];
let brandId = [];
let dates = [];
let breadC = { "master": "", "cat": "", "sub": "" };
let minPrice;
let maxPrice;
let sortByPrice = '';
let page_no = 1;
let limit = 12;
const Content = (props) => {

  const [isDivVisible, setIsDivVisible] = useState(false);
  const [cartInput, setCartInput] = useState(1);
  const [gCart, setGCart] = useState(1)
  const itemsPerPageOptions = [12, 24, 36];
  const [comingCat, setComingCat] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const totalPages = Math.ceil(props.totalRecords / itemsPerPage);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setComingCat(props.searchPay?.subCatId)
  }, [props.searchPay])
  const goToPage = (page) => {
    page_no = page;
    setCurrentPage(page);
    selectfilterData()
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    limit = newItemsPerPage
    page_no = 1;
    selectfilterData()
  };
  const renderPageButtons = () => {
    const pageButtons = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (totalPages > 5) {
      if (currentPage <= 3) {
        endPage = 5;
      } else if (currentPage >= totalPages - 2) {
        startPage = totalPages - 4;
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      pageButtons.push(
        <button
          key={page}
          onClick={() => goToPage(page)}
          disabled={currentPage === page}
        >
          {page}
        </button>
      );
    }

    return pageButtons;
  };


  //cmcvmmvmvmirmviemviemvimeivm


  const handleDivClick = () => {
    setIsDivVisible(true);
  };

  const handleArrowClick = () => {
    setIsDivVisible(false);
  };

  const addTowhislist = (event, item) => {
    props.addTowhislist(event, item)
  }
  const addTocart = (event, item) => {
    item.cart_qty = gCart;
    props.addTocart(event, item)
  }
  // const handleViewMore = () => {
  //   setDisplayedProducts(displayedProducts + 9);
  // };
  const clickmastcat = (e, id) => {
    const catcheckboxes = document.querySelectorAll(".category");
    const subcheckboxes = document.querySelectorAll(".subcategory");
    const catdiv = document.querySelectorAll(".cat-div-list");
    if (e.target.checked) {
      document.getElementById('divmastcat_' + id).classList.remove("close");
    } else {
      catcheckboxes.forEach(element => {
        element.checked = false
      })
      subcheckboxes.forEach(element => {
        element.checked = false
      })
      catdiv.forEach(element => {
        element.classList.add("close")
      })
      document.getElementById('divmastcat_' + id).classList.add("close");
    }
    unSelectAllExcept(".mastercat", e.target);
    selectfilterData(true);
  }
  const clickcat = (e, id) => {
    const subcatcheckboxes = document.querySelectorAll(".subcategory");
    if (e.target.checked) {
      document.getElementById('divcat_' + id).classList.remove("close");
    } else {
      subcatcheckboxes.forEach(c => {
        c.checked = false
      })
      document.getElementById('divcat_' + id).classList.add("close");
    }
    unSelectAllExcept(".category", e.target);
    selectfilterData();
  }
  const clicksubcat = (e, id) => {
    setComingCat(false)
    unSelectAllExcept(".subcategory", e.target);
    selectfilterData();
  }
  const unSelectAllExcept = (classs, current = null) => {
    const checkboxes = document.querySelectorAll(classs);
    checkboxes.forEach(element => {
      if (element != current) {
        element.checked = false;

        //find all category..
        var list = element.parentNode.parentNode.getElementsByClassName("category");
        if (list && list.length > 0) {
          for (var i = 0; i < list.length; i++) {
            list[i].checked = false;
          }
        }

        //find all category..
        var list = element.parentNode.parentNode.getElementsByClassName("subcategory");
        if (list && list.length > 0) {
          for (var i = 0; i < list.length; i++) {
            list[i].checked = false;
          }
        }

        element.parentNode.classList.add("close");
      }
    })
  }

  const selectfilterData = (nv = false) => {
    mastCatId = [];
    catId = [];
    subCatId = [];
    brandId = [];
    dates = [];
    breadC = { "master": "", "cat": "", "sub": "" };
    const masterCheckboxes = document.querySelectorAll(".mastercat:checked");
    const catcheckboxes = document.querySelectorAll(".category:checked");
    const subcheckboxes = document.querySelectorAll(".subcategory:checked");
    const brandcheckboxes = document.querySelectorAll(".brandsearch:checked");
    const datecheckboxes = document.querySelectorAll(".datecustome:checked");

    masterCheckboxes.forEach(element => {
      if (mastCatId.indexOf(element.value) <= -1) {
        var allowed = true;
        var list = element.parentNode.parentNode.getElementsByClassName("category");
        if (list && list.length > 0) {
          for (var i = 0; i < list.length; i++) {
            if (list[i].checked) {
              allowed = false;
            }
          }
        }
        if (allowed) {
          mastCatId.push(element.value)
        }
        breadC.master = element.value;
      }
    })

    catcheckboxes.forEach(element => {
      if (catId.indexOf(element.value) <= -1) {
        var allowed = true;
        var list = element.parentNode.parentNode.getElementsByClassName("subcategory");
        if (list && list.length > 0) {
          for (var i = 0; i < list.length; i++) {
            if (list[i].checked) {
              allowed = false;
            }
          }
        }
        if (allowed) {
          catId.push(element.value)
        }
        breadC.cat = element.value;
      }

    })
    subcheckboxes.forEach(element => {
      if (subCatId.indexOf(element.value) <= -1) {
        subCatId.push(element.value)
        breadC.sub = element.value;
      }
    })
    brandcheckboxes.forEach(element => {
      if (brandId.indexOf(element.value) <= -1) {
        brandId.push(element.value)
      }
    })
    datecheckboxes.forEach(element => {
      if (dates.indexOf(element.value) <= -1) {
        dates.push(element.value)
      }
    })
    filterData(nv);
  }
  const sortProdctByPrice = (e) => {
    sortByPrice = e.target.value;
    selectfilterData()
  }
  const clickBrand = (e, id) => {
    selectfilterData()
  }
  const clickCertification = (e, id) => {
    selectfilterData()

  }
  const clickDates = (e, id) => {
    selectfilterData()
  }
  const clickPrice = (e) => {
    e.preventDefault();
    selectfilterData();
  }
  const priceMinHandle = (e) => {
    minPrice = e.target.value;
  }
  const priceMaxHandle = (e) => {
    maxPrice = e.target.value;
  }
  const filterData = (nv = false) => {
    props.searchProduct({
      dates,
      brandId,
      mastCatId,
      catId,
      // subCatId : props.searchPay?.subCatId ? [props.searchPay?.subCatId] : [],
      subCatId: comingCat ? [comingCat] : subCatId,
      minPrice,
      maxPrice,
      priceSort: sortByPrice,
      keyword: props?.queryObj?.keyword,
      filter: props?.queryObj?.filter,
      page: page_no,
      limit: limit
    })

    if (nv) {
      props.makeBreadcrumb({
        mastCatId: breadC.master,
        catId: breadC.cat,
        subCatId: breadC.sub
      }, props.catData, nv)
    }
  }
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  function calculatePrice(cartInput) {
    for (const range of JSON.parse(props?.data?.price_groups)) {
      if (cartInput >= range.min && cartInput <= range.max) {
        return range.mrp;
      }
    }
    return 0;
  }
  const setMainInputValue = (e) => {
    if (e.target.value === "") {
      setCartInput(gCart);
    }
    else if (e.target.value < gCart) {
      toast.error(`Cart value should not be less than ${gCart}`)
      setCartInput(parseInt(gCart))
    }
    else {
      setCartInput(Number.parseInt(e.target.value));
      const numericValue = parseFloat(e.target.value);
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * e.target.value;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setUnitPrice(calculatePrice(numericValue));
          setCalculatedPrice(price * e.target.value);
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };
  const increaseNumber = () => {
    setCartInput(cartInput + 1);
    let x = Number.parseInt(document.getElementById("cart_input").value) + 1;
    const numericValue = x;
    if (!props?.data?.price_groups) {
      let unitP = props?.data?.offer_price * x;
      setCalculatedPrice(unitP);
      setUnitPrice(props?.data?.offer_price);
    } else {
      if (!isNaN(numericValue)) {
        const price = calculatePrice(numericValue);
        setCalculatedPrice(price * x);
        setUnitPrice(calculatePrice(numericValue));
      } else {
        setCalculatedPrice(0);
      }
    }
  };
  const decreaseNumber = () => {
    if (cartInput === 1 || cartInput === gCart) {
      toast.error(`Value should not less than ${gCart} !!`);
    } else {
      setCartInput(cartInput - 1);
      let x = Number.parseInt(document.getElementById("cart_input").value) - 1;
      const numericValue = x;
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * x;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setCalculatedPrice(price * x);
          setUnitPrice(calculatePrice(numericValue));
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };
  return (
    <section className="pb-5">
      <div className="container">
        <div className="row w-100 mob_row_reverse mb-3 mt-4 align-items-end">
          <div className="col-lg-6 col-md-6 col-4 d-flex justify-content-end">
          </div>
          <div className="col-lg-6 col-md-6 col-8">
            <div className="d-flex w-100 justify-content-end mob_flex" style={{ alignItems: 'center' }}>
              <label className="form-label m-0">Sort by</label>
              <select className="form-select sorting-select" onChange={sortProdctByPrice}>
                <option value="">Recommended</option>
                <option value="popularity">Popularity</option>
                <option value="asc">Price Low to High</option>
                <option value="desc">Price High to Low</option>
              </select>
            </div>
          </div>
        </div>
        <div className="d-flex mob-column gap-3">
          <aside className={isDivVisible ? 'mobile-div w-25 filter_sec mob_full flex-grow-1 d-flex-custom flex-column gap-3' : 'desktop-div w-25 filter_sec mob_full flex-grow-1 d-flex-custom flex-column gap-3'}>
            {/* <button onClick={handleArrowClick} className="filter_cross"><img src="../../../images/cross_icon.png" /></button> */}
            <div className="d-flex flex-column cat-part">
              <h4>Product Categories</h4>
              <div className="list-cat">
                {props.catData && props.catData.length > 0
                  ? props.catData.map((item, i) => (
                    <div className="master-cat">
                      <div className="d-flex flex-wrap align-items-center justify-content-between py-2" >
                        <div className={
                          props?.queryObj?.mastCatId && props?.queryObj?.mastCatId == item.id ?
                            "d-flex align-items-center gap-3 catList w-100" : "d-flex align-items-center gap-3 catList close w-100"} id={'divmastcat_' + item.id}  >
                          <input value={item.id} defaultChecked={
                            props?.queryObj?.mastCatId && props?.queryObj?.mastCatId == item.id ? true : false
                          } type="checkbox" onClick={(e) => clickmastcat(e, item.id)} id={'mascat_' + item.id} className="input-hide master-inner mastercat" hidden />
                          <label for={'mascat_' + item.id} class="custom-label"></label>
                          <label for={'mascat_' + item.id}>{item.name}</label>
                        </div>
                        {item.Categories && item.Categories.length > 0
                          ? item.Categories.map((cat, j) => (
                            <ul className="sub-cat" >
                              <li>
                                <div
                                  className={
                                    props?.queryObj?.mastCatId && props?.queryObj?.catId == cat.id ?
                                      "d-flex align-items-center gap-3 catList cat-div-list" : "d-flex align-items-center gap-3 catList cat-div-list close"
                                  }
                                  id={'divcat_' + cat.id}>
                                  <input
                                    value={cat.id}
                                    type="checkbox"
                                    onClick={(e) => clickcat(e, cat.id)}
                                    defaultChecked={
                                      props?.queryObj?.catId && props?.queryObj?.catId == cat.id ? true : false
                                    }
                                    id={'cat_' + cat.id}
                                    className="input-hide master-inner category" hidden />
                                  <label for={'cat_' + cat.id} class="custom-label"></label>
                                  <label for={'cat_' + cat.id}>{cat.name}</label>
                                </div>
                                {cat.Categories && cat.Categories.length > 0
                                  ? cat.Categories.map((subcat, k) => (
                                    <ul className="sub-cat">
                                      <li>
                                        <div class="d-flex align-items-center gap-3 sub-cat-div-list" id={'subcat' + subcat.id}>
                                          <input
                                            value={subcat.id}
                                            type="checkbox"
                                            onClick={(e) => clicksubcat(e, subcat.id)}
                                            defaultChecked={
                                              props?.queryObj?.subCatId && props?.queryObj?.subCatId == subcat.id ? true : false
                                            }
                                            id={'subcat_' + subcat.id}
                                            class="input-hide master-inner subcategory" hidden />
                                          <label for={'subcat_' + subcat.id} class="custom-label"></label>
                                          <label for={'subcat_' + subcat.id}>{subcat.name}</label>
                                        </div>
                                      </li>
                                    </ul>
                                  )) : ""}
                              </li>
                            </ul>
                          )) : ""}
                      </div>
                    </div>
                  )) : ""}
              </div>
            </div>
            <div class="d-flex2 flex-column cat-part">
              <h4>Brands</h4>
              <div class="list-cat">
                {props.brand && props.brand.length > 0
                  ? props.brand.map((item, i) => (
                    <div class="master-cat">
                      <div class="d-flex align-items-center justify-content-between py-2">
                        <div class="d-flex align-items-center gap-3">
                          <input
                            value={item.id}
                            type="checkbox"
                            onClick={(e) => clickBrand(e, item.id)}
                            name="brand" id={'brand' + item.id}
                            defaultChecked={
                              props?.queryObj?.brandId && props?.queryObj?.brandId == item.id ? true : false
                            }
                            class="input-hide master-inner brandsearch" hidden />
                          <label for={'brand' + item.id} class="custom-label"></label>
                          <label for={'brand' + item.id}>{item.name}</label>
                        </div>
                      </div>
                    </div>
                  )) : ""
                }
              </div>
            </div>
            <div class="d-flex2 flex-column cat-part no-scroll">
              <h4>Price</h4>
              <div class="list-cat">
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3 pric-form">
                      <input type="text" onChange={priceMinHandle} name="min" id="min" class="w-25" />
                      <span class="w-25 text-center">TO</span>
                      <input type="text" onChange={priceMaxHandle} name="max" id="max" class="w-25" />
                      <a href="#" onClick={(e) => clickPrice(e)} class="w-25 text-center go-btn">Ok</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="d-flex flex-column cat-part">
              <h4>Product Review</h4>
              <div class="list-cat">
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2 flex-column">
                    <div class="d-flex align-items-center gap-3 pric-form w-100">
                      <input type="range" class="custom-range w-100"  min="0" max="100" step="10" id="renge-slider" />
                      <div class="selector" id="selector">
                        <div class="select-btn"></div>
                      </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between w-100 mt-3">
                      <div class="flex-grow-1 text-start">
                        1 Star
                      </div>
                      <div class="flex-grow-1 text-end">
                        5 Star
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="d-flex2 flex-column cat-part no-scroll ">
              <h4>New arrival</h4>
              <div class="list-cat">
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='7' name="7days" id="7days" class="input-hide master-inner datecustome" hidden />
                      <label for="7days" class="custom-label"></label>
                      <label for="7days">Last 7 days</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='30' name="30days" id="30days" class="input-hide master-inner datecustome" hidden />
                      <label for="30days" class="custom-label"></label>
                      <label for="30days">Last 30 days</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='60' name="60days" id="60days" class="input-hide master-inner datecustome" hidden />
                      <label for="60days" class="custom-label"></label>
                      <label for="60days">Last 60 days</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex2 flex-column cat-part ">
              <h4>Supplier country/region</h4>
              <input type="search" placeholder="Search..." className="country_search" />
              <div class="list-cat">
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='7' name="7days" id="7days" class="input-hide master-inner datecustome" hidden />
                      <label for="7days" class="custom-label"></label>
                      <label for="7days">India</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='30' name="30days" id="30days" class="input-hide master-inner datecustome" hidden />
                      <label for="30days" class="custom-label"></label>
                      <label for="30days">Brazil</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='60' name="60days" id="60days" class="input-hide master-inner datecustome" hidden />
                      <label for="60days" class="custom-label"></label>
                      <label for="60days">France</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='60' name="60days" id="60days" class="input-hide master-inner datecustome" hidden />
                      <label for="60days" class="custom-label"></label>
                      <label for="60days">Japan</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="d-flex2 flex-column cat-part ">
              <h4>Management certifications</h4>
              <input type="search" placeholder="Search..." className="country_search" />
              <div class="list-cat">
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='7' name="7days" id="7days" class="input-hide master-inner datecustome" hidden />
                      <label for="7days" class="custom-label"></label>
                      <label for="7days">ISO</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='30' name="30days" id="30days" class="input-hide master-inner datecustome" hidden />
                      <label for="30days" class="custom-label"></label>
                      <label for="30days">BSCI</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='60' name="60days" id="60days" class="input-hide master-inner datecustome" hidden />
                      <label for="60days" class="custom-label"></label>
                      <label for="60days">IPMS</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='60' name="60days" id="60days" class="input-hide master-inner datecustome" hidden />
                      <label for="60days" class="custom-label"></label>
                      <label for="60days">SEDEX</label>
                    </div>
                  </div>
                </div>
                <div class="master-cat">
                  <div class="d-flex align-items-center justify-content-between py-2">
                    <div class="d-flex align-items-center gap-3">
                      <input type="checkbox" value='60' name="60days" id="60days" class="input-hide master-inner datecustome" hidden />
                      <label for="60days" class="custom-label"></label>
                      <label for="60days">ISCC</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
          <div class="w-75 mob_full flex-grow-1">
            <div class="cat-meni px-4">
              <div class="row">
                {props.data && props.data.length > 0
                  ? props.data.map((item, i) => (
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 card_inner2">
                      <div className="card_cat_inner">
                        <div className="img_sec img_cards">
                          <Link to={"/" + item.mastercategory.slug + "/" + item.category.slug + "/" + item.subcategory.slug + "/" + item.slug}><img src={item.file_path ? "https://api.dealitt.com/" + item.file_path : ""} alt="product" srcset="" class="w-100" /></Link>
                          {item?.Carts &&
                            item?.Carts.filter(function (value) {
                              return value.product_id === item.id;
                            }).length > 0 ? (
                            <div class="wishlist-icon">
                              <input
                                type="checkbox"
                                className="remove-cart"
                                key={item.id}
                                checked
                                onClick={(e) => addTowhislist(e, item)}
                              />
                              <span className="icon-wishlist"></span>
                            </div>
                          ) : (
                            <div class="wishlist-icon">
                              <input
                                type="checkbox"
                                key={item.id}
                                onClick={(e) => addTowhislist(e, item)}
                              />
                              <span className="icon-wishlist"></span>
                            </div>
                          )}
                        </div>
                        <div class="d-flex justify-content-flex-start flex-column w-100 title-side">
                          <div className="card_content_price text-center card_content">
                            {/* {
                              (item?.Carts && item?.Carts.filter(function (value) { return value.product_id === item.id; }).length > 0) ?
                                <div class="wishlist-icon">
                                  <input type="checkbox" className="remove-cart" key={item.id} checked onClick={(e) => addTowhislist(e, item)} />
                                  <span className="icon-wishlist"></span>
                                </div>
                                :
                                <div class="wishlist-icon">
                                  <input type="checkbox" key={item.id} onClick={(e) => addTowhislist(e, item)} />
                                  <span className="icon-wishlist"></span>
                                </div>
                            } */}

                            <Link to={"/" + item.mastercategory.slug + "/" + item.category.slug + "/" + item.subcategory.slug + "/" + item.slug} className="price_for_mobile"><h5 className="pro_title">{item.name.length > 35 ? item.name.substring(0, 35) + "..." : item.name}</h5></Link>

                          </div>
                          <div className="card_content_price text-center card_content">


                            {item.mrp > item.offer_price ? <span className="f-16 cross_green cutPrice"><s>${item.mrp.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</s> </span> : <div className="spacer_for_cut"></div>}
                            <h4 className="main_price ml-2"><span>$</span>{item.offer_price.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</h4>
                          </div>
                          <div className="card_content_info text-center card_content">
                            {/* <p>Min.{item?.price_group ? JSON.parse(item?.price_group[0]?.mrp) : 1}</p> */}
                          </div>
                          <div className="add_to_cart">
                            <button
                              data-bs-toggle="modal"
                              data-bs-target={`#staticBackdrop2_${item?.id}`}
                              onClick={() => {
                                if (item?.price_groups) {
                                  let cartMinVal = JSON.parse(item?.price_groups);
                                  setCartInput(Number(cartMinVal[0]?.min));
                                  setGCart(Number(cartMinVal[0]?.min))
                                }
                              }
                              } className="main_catbtn">Add to cart</button>
                            <div className="">
                              <div
                                class="modal fade"
                                id={`staticBackdrop2_${item?.id}`}
                                data-bs-backdrop="static"
                                data-bs-keyboard="false"
                                tabindex="-1"
                                aria-labelledby="staticBackdrop2Label"
                                aria-hidden="true"
                              >
                                <div class="modal-dialog modal-dialog-centered">
                                  <div class="modal-content">
                                    <div class="modal-body py-3">
                                      <div className="d-flex flex-column justify-content-center adivgn-items-center cart_popup">
                                        <img
                                          src={
                                            item?.file_path
                                              ? "https://api.dealitt.com/" +
                                              item?.file_path
                                              : ""
                                          }
                                          class="d-block w-100"
                                          alt="..."
                                        />
                                        <h5 className="mt-4">{item?.name}</h5>
                                        <div className="int_sec">
                                          <button
                                            className="int_dec"
                                            onClick={decreaseNumber}
                                          >
                                            -
                                          </button>
                                          <input
                                            type="number"
                                            id="cart_input"
                                            className="form-control"
                                            value={cartInput}
                                            onChange={setMainInputValue}
                                          />

                                          <button
                                            className="int_inc"
                                            onClick={increaseNumber}
                                          >
                                            +
                                          </button>
                                        </div>
                                        {/* Calculated Price : ${calculatedPrice}
                              <br />
                              Unit Price : ${unitPrice} */}
                                        <div className="d-flex justify-content-center mt-3">
                                          <button
                                            type="button"
                                            class="main_button me-3"
                                            data-bs-dismiss="modal"
                                            onClick={(e) => addTocart(e, item)}
                                          >
                                            Done
                                          </button>
                                          <button
                                            type="button"
                                            class="main_button red_button"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                              setCartInput(1);
                                              setGCart(1)
                                            }}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  )) :
                  <p className="nothing">No Products Found!!</p>
                }
                <div className="pagination_filters">
                  <div className="pagination_filtersL">
                    {/* Items per page select dropdown */}
                    <select class="form-select" value={itemsPerPage} onChange={handleItemsPerPageChange} disabled={props.data.length < 12}>
                      {itemsPerPageOptions.map((option) => (
                        <option key={option} value={option}>
                          Show {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pagination_filtersR">
                    {/* Pagination buttons */}
                    <div>
                      {/* Previous page button */}
                      {currentPage > 1 && (
                        <button onClick={() => goToPage(currentPage - 1)}><i class="fas fa-chevron-left"></i></button>
                      )}

                      {/* Page buttons */}
                      {renderPageButtons()}

                      {/* Next page button */}
                      {currentPage < totalPages && (
                        <button onClick={() => goToPage(currentPage + 1)}><i class="fas fa-chevron-right"></i></button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {/* {displayedProducts < props.data.length && (
        <button onClick={handleViewMore} className="view_moreProducts">View More</button>
      )} */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="d-flex d-md-none set-filter">
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.1" d="M19 3H5C3.89543 3 3 3.89543 3 5V6.17157C3 6.70201 3.21071 7.21071 3.58579 7.58579L9.41421 13.4142C9.78929 13.7893 10 14.298 10 14.8284V20V20.2857C10 20.9183 10.7649 21.2351 11.2122 20.7878L12 20L13.4142 18.5858C13.7893 18.2107 14 17.702 14 17.1716V14.8284C14 14.298 14.2107 13.7893 14.5858 13.4142L20.4142 7.58579C20.7893 7.21071 21 6.70201 21 6.17157V5C21 3.89543 20.1046 3 19 3Z" fill="#fff" />
          <path d="M19 3H5C3.89543 3 3 3.89543 3 5V6.17157C3 6.70201 3.21071 7.21071 3.58579 7.58579L9.41421 13.4142C9.78929 13.7893 10 14.298 10 14.8284V20V20.2857C10 20.9183 10.7649 21.2351 11.2122 20.7878L12 20L13.4142 18.5858C13.7893 18.2107 14 17.702 14 17.1716V14.8284C14 14.298 14.2107 13.7893 14.5858 13.4142L20.4142 7.58579C20.7893 7.21071 21 6.70201 21 6.17157V5C21 3.89543 20.1046 3 19 3Z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </section>
  );
};

export default Content;