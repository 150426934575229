import React, {useEffect} from 'react'
import Footer from "../common/Footer";
import Header from "../common/Header";
import Banner from '../common/Banner';
import PaymentMethodsDealit from './component/paymentsMethods/PaymentMethodsDealit';

const PaymentMethodsD = () => {
    useEffect(() => {
        document.title = "Secure Payment Options at DEALITT";
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Banner title={"Secure Payment Options at DEALITT"} />
            <PaymentMethodsDealit />
            <Footer />
        </>
    )
}

export default PaymentMethodsD