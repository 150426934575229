import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import DataService from "../services/data.service";
import HelperService from "../services/helper.service";
import Banner from "../common/Banner";
import Header from "../common/Header";
import Footer from "../common/Footer";
import "../customCss/Compare.css";
import { toast } from "react-toastify";

const Compare = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [producttwo, setProductTwo] = useState();
  const [productthree, setProductThree] = useState();
  const [review, setReview] = useState([]);
  const [plist, setPlist] = useState([]);
  const [plisttwo, setPlistTwo] = useState([]);
  const [bselect, setBselect] = useState("");
  const [bselecttwo, setBselectTwo] = useState([]);
  const [reviewCount, setReviewCount] = useState([]);
  const [Brand, setBrand] = useState([]);
  const [allproduct, setAllProduct] = useState({});
  const [relatedProduct, setRelatedProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState([]);
  const [html, setHTML] = useState({ __html: "" });
  const [htmltwo, setHTMLTwo] = useState({ __html: "" });
  const [htmlthree, setHTMLThree] = useState({ __html: "" });
  const [cartselect, setCartSelect] = useState(1);
  const auth = AuthService.getCurrentUser();

  let mastCatId = [];
  let catId = [];
  let subCatId = [];
  let brandId = [];
  let dates = [];
  let minPrice;
  let maxPrice;
  let sortByPrice = "";
  let page_no = 1;
  let limit = 15;
  const showBrand = (e) => {
    setBselect(e.target.value);
    filterData(e.target.value);
    setProductTwo();
    // searchProduct();
  };
  const showBrandTwo = (e) => {
    setBselectTwo(e.target.value);
    filterDataTwo(e.target.value);
    setProductThree();
    // searchProduct();
  };
  const filterData = (e) => {
    searchProduct({
      dates,
      brandId: [e],
      mastCatId,
      catId,
      subCatId,
      minPrice,
      maxPrice,
      priceSort: sortByPrice,
      keyword: props?.queryObj?.keyword,
      filter: props?.queryObj?.filter,
      page: page_no,
      limit: limit,
    });
  };
  const filterDataTwo = (e) => {
    searchProductTwo({
      dates,
      brandId: [e],
      mastCatId,
      catId,
      subCatId,
      minPrice,
      maxPrice,
      priceSort: sortByPrice,
      keyword: props?.queryObj?.keyword,
      filter: props?.queryObj?.filter,
      page: page_no,
      limit: limit,
    });
  };

  const searchProduct = async (data) => {
    const userId = auth ? auth.id : "";
    await DataService.searchProduct(data, userId).then((data) => {
      setPlist(data.data.products);
    });
  };
  const searchProductTwo = async (data) => {
    const userId = auth ? auth.id : "";
    await DataService.searchProduct(data, userId).then((data) => {
      setPlistTwo(data.data.products);
    });
  };

  const showProduct = (e) => {
    if (e.target.value !== "") {
      getProductTwo(e.target.value);
    } else {
      setProductTwo("");
    }
  };
  const showProductTwo = (e) => {
    if (e.target.value !== "") {
      getProductThree(e.target.value);
    } else {
      setProductTwo("");
    }
  };
  const getProductThree = async (e) => {
    const userId = auth ? auth.id : "";
    await DataService.getProductDetail(e, userId)
      .then((data) => {
        setLoading(false);
        const productData = data.data.category;
        productData.images = productData.images
          ? JSON.parse(productData?.images)
          : [];
        productData.variant = productData?.variant
          ? JSON.parse(productData?.variant)
          : [];
        setProductThree(productData);
        setHTMLThree({ __html: productData?.details });
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };
  const getProductTwo = async (e) => {
    const userId = auth ? auth.id : "";
    await DataService.getProductDetail(e, userId)
      .then((data) => {
        setLoading(false);
        const productData = data.data.category;
        productData.images = productData.images
          ? JSON.parse(productData?.images)
          : [];
        productData.variant = productData?.variant
          ? JSON.parse(productData?.variant)
          : [];
        setProductTwo(productData);
        setHTMLTwo({ __html: productData?.details });
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };
  const getProduct = async () => {
    const userId = auth ? auth.id : "";
    await DataService.getProductDetail(params.id, userId)
      .then((data) => {
        setLoading(false);
        const productData = data.data.category;
        productData.images = productData.images
          ? JSON.parse(productData?.images)
          : [];
        productData.variant = productData?.variant
          ? JSON.parse(productData?.variant)
          : [];
        setProduct(productData);
        setHTML({ __html: productData?.details });
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };

  const getReview = async () => {
    const userId = auth ? auth.id : "";
    //Get reviews..
    await DataService.getProductReviews(params.id, "asc")
      .then((dw) => {
        let totalRating = 0;
        const starts = [];
        if (dw?.data?.reviewsCount?.length) {
          dw?.data?.reviewsCount.map((value) => {
            totalRating += value["total_rating"];
          });
          const ratingValue = totalRating / dw?.data?.reviewsCount?.length;
          for (let i = 0; i < 5; i++) {
            starts.push(ratingValue - i);
          }
        }
        setRating(starts);
        setReview(dw.data.reviews);
        setReviewCount(dw.data.reviewsCount);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };
  const getBrands = () => {
    DataService.getAllBrand().then((data) => {
      setBrand(data.data.data);
      setLoading(false);
    });
  };
  const addTowhislist = (event, item) => {
    if (auth) {
      var element = event.currentTarget;
      if (element.className == "remove-cart") {
        //get cat id..
        if (item?.Carts) {
          DataService.deleteWishlist(item.id).then(
            () => {
              toast.success("Product removed from wishlist!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              element.classList.remove("remove-cart");
              element.checked = false;
              getProduct();
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.msg) ||
                error.message ||
                error.toString();

              toast.error(resMessage, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
        }
      } else {
        const data = {};
        data.product_title = item.name;
        data.price = item.offer_price;
        data.product_sku = item.sku;
        data.quantity = "1";
        data.variant = "";
        data.product_id = item.id;
        data.type = "whislist";
        DataService.addCart(data).then(
          () => {
            toast.success("Product added to whislist", {
              position: toast.POSITION.TOP_RIGHT,
            });
            element.checked = true;
            element.classList.add("remove-cart");
            getProduct();
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.msg) ||
              error.message ||
              error.toString();
            toast.error(resMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
      }
    } else {
      navigate("/login-page");
    }
  };
  const addTocart = (event, item) => {
    const auth = AuthService.getCurrentUser();
    const data1 = {};
    data1.product_title = item.name;
    data1.price = item.offer_price;
    data1.product_sku = item.sku;
    data1.quantity = item.cart_qty ? item.cart_qty : gCart;
    data1.variant = item.variation ? item.variation : null;
    data1.product_id = item.id;
    data1.type = "cart";
    if (auth) {
      setLoading(true);
      DataService.addCart(data1).then(
        () => {
          setLoading(false);
          toast.success("Product added to cart!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.scrollTo(0, 0);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.msg) ||
            error.message ||
            error.toString();

          setLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    } else {
      setLoading(true);
      HelperService.setLocalCart(data1);
      setLoading(false);
      toast.success("Product added to cart!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      HelperService.updateCartCount();
      window.scrollTo(0, 0);
    }
  };
  const [cartInput, setCartInput] = useState(1);
  const [gCart, setGCart] = useState(1)
  const [calculatedPrice, setCalculatedPrice] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  function calculatePrice(cartInput) {
    for (const range of JSON.parse(props?.data?.price_groups)) {
      if (cartInput >= range.min && cartInput <= range.max) {
        return range.mrp;
      }
    }
    return 0;
  }
  const setMainInputValue = (e) => {
    if (e.target.value === "") {
      setCartInput(gCart);
    }
    else if (e.target.value < gCart) {
      toast.error(`Cart value should not be less than ${gCart}`)
      setCartInput(parseInt(gCart))
    }
    else {
      setCartInput(Number.parseInt(e.target.value));
      const numericValue = parseFloat(e.target.value);
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * e.target.value;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setUnitPrice(calculatePrice(numericValue));
          setCalculatedPrice(price * e.target.value);
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };
  const increaseNumber = () => {
    setCartInput(cartInput + 1);
    let x = Number.parseInt(document.getElementById("cart_input").value) + 1;
    const numericValue = x;
    if (!props?.data?.price_groups) {
      let unitP = props?.data?.offer_price * x;
      setCalculatedPrice(unitP);
      setUnitPrice(props?.data?.offer_price);
    } else {
      if (!isNaN(numericValue)) {
        const price = calculatePrice(numericValue);
        setCalculatedPrice(price * x);
        setUnitPrice(calculatePrice(numericValue));
      } else {
        setCalculatedPrice(0);
      }
    }
  };
  const decreaseNumber = () => {
    if (cartInput === 1 || cartInput === gCart) {
      toast.error(`Value should not less than ${gCart} !!`);
    } else {
      setCartInput(cartInput - 1);
      let x = Number.parseInt(document.getElementById("cart_input").value) - 1;
      const numericValue = x;
      if (!props?.data?.price_groups) {
        let unitP = props?.data?.offer_price * x;
        setCalculatedPrice(unitP);
        setUnitPrice(props?.data?.offer_price);
      } else {
        if (!isNaN(numericValue)) {
          const price = calculatePrice(numericValue);
          setCalculatedPrice(price * x);
          setUnitPrice(calculatePrice(numericValue));
        } else {
          setCalculatedPrice(0);
        }
      }
    }
  };
  useEffect(() => {
    document.title = "Comparison";
    getProduct();
    getReview();
    getBrands();
    window.scrollTo(0, 0);
  }, [params.id]);

  return (
    <>
      <Header />
      <Banner title="Compare" />
      <div className="compare_sec">
        <p className="better_view">For Better Comparison View Please check in Desktop</p>
        <div className="row">
          <div className="col-sm-12">
            <div className="compare_outer">
              <div className="compare_flexSec">
                <div className="compare_innerSec">
                  <div className="compare_innerOne compare_innerDiv ">
                    <p>Product Details</p>
                  </div>
                  <div className="compare_innerTwo compare_innerDiv">
                    <p>Availability</p>
                  </div>
                  <div className="compare_innerThree compare_innerDiv">
                    <p>Price</p>
                  </div>
                  <div className="compare_innerFour compare_innerDiv">
                    <p>Main Category</p>
                  </div>
                  <div className="compare_innerFive compare_innerDiv">
                    <p>Company Details</p>
                  </div>
                  <div className="compare_innerSix compare_innerDiv">
                    <p>Brand</p>
                  </div>
                  <div className="compare_innerSeven compare_innerDiv">
                    <p>Ratings</p>
                  </div>
                  <div className="compare_innerEight compare_innerDiv">
                    <p>Specifications</p>
                  </div>
                  <div className="compare_innerNine compare_innerDiv">
                    <p>Contact</p>
                  </div>
                </div>
                <div className="compare_innerSec">
                  <div className="compare_innerOne compare_innerDiv">
                    <div className="compare_imageSec">
                      <img
                        src={
                          product?.file_path
                            ? "https://api.dealitt.com/" + product?.file_path
                            : ""
                        }
                        class="compare_img"
                        alt="..."
                      />
                      {product.Carts &&
                        product.Carts?.filter(function (value) {
                          return value.product_id === product.id;
                        }).length > 0 ? (
                        <div class="wishlist-icon for_single">
                          <input
                            type="checkbox"
                            className="remove-cart"
                            key={product.id}
                            checked
                            onClick={(e) => addTowhislist(e, product)}
                          />
                          <span className="icon-wishlist"></span>
                        </div>
                      ) : (
                        <div class="wishlist-icon for_single">
                          <input
                            type="checkbox"
                            key={product.id}
                            onClick={(e) => addTowhislist(e, product)}
                          />
                          <span className="icon-wishlist"></span>
                        </div>
                      )}
                      <h2 className="compare_main">{product?.name}</h2>
                    </div>
                  </div>
                  <div className="compare_innerTwo compare_innerDiv">
                    <p>
                      {product?.quantity > 0 ? (
                        <>
                          <div className="compare_addtocart">
                            <div>
                              <i class="fas fa-circle in_s"></i>In Stock
                            </div>
                            <div>
                              <button
                                className="main_button"
                                data-bs-toggle="modal"
                                data-bs-target={`#staticBackdrop2_${product.id}`}
                                onClick={() => {
                                  if (product?.price_groups) {
                                    let cartMinVal = JSON.parse(product?.price_groups);
                                    setCartInput(Number(cartMinVal[0]?.min));
                                    setGCart(Number(cartMinVal[0]?.min))
                                  }
                                }}
                              >
                                <i class="fas fa-shopping-cart"></i>Add to
                                Cart
                              </button>
                              <div className="">
                                <div
                                  class="modal fade"
                                  id={`staticBackdrop2_${product?.id}`}
                                  data-bs-backdrop="static"
                                  data-bs-keyboard="false"
                                  tabindex="-1"
                                  aria-labelledby="staticBackdrop2Label"
                                  aria-hidden="true"
                                >
                                  <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                      <div class="modal-body py-3">
                                        <div className="d-flex flex-column justify-content-center adivgn-items-center cart_popup">
                                          <img
                                            src={
                                              product?.file_path
                                                ? "https://api.dealitt.com/" +
                                                product?.file_path
                                                : ""
                                            }
                                            class="d-block w-100"
                                            alt="..."
                                          />
                                          <h5 className="mt-4">{product?.name}</h5>
                                          <div className="int_sec">
                                            <button
                                              className="int_dec"
                                              onClick={decreaseNumber}
                                            >
                                              -
                                            </button>
                                            <input
                                              type="number"
                                              id="cart_input"
                                              className="form-control"
                                              value={cartInput}
                                              onChange={setMainInputValue}
                                            />

                                            <button
                                              className="int_inc"
                                              onClick={increaseNumber}
                                            >
                                              +
                                            </button>
                                          </div>
                                          {/* Calculated Price : ${calculatedPrice}
                              <br />
                              Unit Price : ${unitPrice} */}
                                          <div className="d-flex justify-content-center mt-3">
                                            <button
                                              type="button"
                                              class="main_button me-3"
                                              data-bs-dismiss="modal"
                                              onClick={(e) => addTocart(e, product)}
                                            >
                                              Done
                                            </button>
                                            <button
                                              type="button"
                                              class="main_button red_button"
                                              data-bs-dismiss="modal"
                                              onClick={() => {
                                                setCartInput(1);
                                                setGCart(1)
                                              }}
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <i class="fas fa-circle not_in_s"></i>Not Available
                        </>
                      )}
                    </p>
                  </div>
                  <div className="compare_innerThree compare_innerDiv">
                    <p>${product?.offer_price}</p>
                  </div>
                  <div className="compare_innerFour compare_innerDiv">
                    <p>{product?.mastercategory?.name}</p>
                  </div>
                  <div className="compare_innerFive compare_innerDiv">
                    <p>
                      {product?.User?.first_name}, {product?.User?.email}
                      <br />
                      {product?.User?.website_link}
                    </p>
                  </div>
                  <div className="compare_innerSix compare_innerDiv">
                    <p>{product?.Brand?.name}</p>
                  </div>
                  <div className="compare_innerSeven compare_innerDiv">
                    <p className="rateing">
                      <span>{reviewCount.length}</span>
                      {rating && rating?.length > 0 ? (
                        rating.map((item, i) => (
                          <span>
                            {item > 1 ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-star-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                              </svg>
                            ) : item > 0 ? (
                              <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5.35411 5.11885L7.53823 0.792305C7.63633 0.597985 7.81801 0.500551 7.99992 0.500002C8.18286 0.499451 8.36604 0.596887 8.4647 0.792307L10.6488 5.11885L15.5473 5.81472C15.8144 5.85267 15.9823 6.0781 15.9999 6.31968C16.002 6.34769 16.002 6.37593 15.9999 6.40411C15.9904 6.53408 15.9362 6.66299 15.8295 6.76462L12.3072 10.1213L13.1369 14.8508C13.2147 15.2942 12.7771 15.6413 12.3907 15.4427L8.00146 13.1868L3.61224 15.4427C3.56393 15.4675 3.51483 15.4838 3.46603 15.4925C3.12446 15.5531 2.79796 15.2388 2.86603 14.8508L3.69576 10.1213L0.173428 6.76462C0.0551295 6.65189 0.00135121 6.50559 0.00146502 6.36151C0.00154915 6.25501 0.0310762 6.14972 0.0857544 6.06009C0.162946 5.93355 0.290266 5.83821 0.455637 5.81472L5.35411 5.11885ZM7.99992 12.0265C8.07945 12.0263 8.15902 12.045 8.23229 12.0826L11.918 13.977L11.2238 10.0197C11.1911 9.83316 11.2526 9.64214 11.3865 9.5145L14.2934 6.7444L10.2411 6.16874C10.0736 6.14494 9.92728 6.03795 9.84826 5.88141L8.00146 2.22308L7.99992 2.22614V12.0265Z"
                                  fill="#27454A"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                            )}
                          </span>
                        ))
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 15"
                          >
                            <path
                              id="Intersection_1"
                              data-name="Intersection 1"
                              d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                              fill="#27454A"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 15"
                          >
                            <path
                              id="Intersection_1"
                              data-name="Intersection 1"
                              d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                              fill="#27454A"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 15"
                          >
                            <path
                              id="Intersection_1"
                              data-name="Intersection 1"
                              d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                              fill="#27454A"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 15"
                          >
                            <path
                              id="Intersection_1"
                              data-name="Intersection 1"
                              d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                              fill="#27454A"
                            />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 15"
                          >
                            <path
                              id="Intersection_1"
                              data-name="Intersection 1"
                              d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                              fill="#27454A"
                            />
                          </svg>
                        </>
                      )}
                    </p>
                  </div>
                  <div className="compare_innerEight compare_innerDiv">
                    <p dangerouslySetInnerHTML={html}></p>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Proin scelerisque auctor lectus id aliquam. Maecenas
                      luctus luctus turpis, at semper diam laoreet quis. Nunc
                      pharetra luctus malesuada. Vivamus interdum orci eu est
                      interdum, a aliquam ligula fermentum. In tempus dui ut
                      justo porta, ac egestas leo consequat. Ut facilisis orci
                      et eros sodales, in ultrices velit imperdiet. Ut rutrum
                      mi vulputate felis pretium, quis vulputate lectus
                      eleifend.
                    </p>
                  </div>
                  <div className="compare_innerNine compare_innerDiv">
                    <button className="main_button">
                      <i class="fas fa-envelope"></i>Contact Supplier
                    </button>
                  </div>
                </div>
                <div className="compare_innerSec">
                  <div className="compare_innerOne compare_innerDiv">
                    <label for="brand_search">Add Product Here</label>
                    <select
                      name="brand_search"
                      id="brand_search"
                      className="form-select"
                      onChange={(e) => showBrand(e)}
                    >
                      <option value="">Select Brand</option>
                      {Brand && Brand.length > 0 ? (
                        Brand.map((option, i) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No Brands Found</option>
                      )}
                    </select>
                    <select
                      name="product_search"
                      id="product_search"
                      className="form-select"
                      onChange={(e) => showProduct(e)}
                    >
                      <option value="">Select Product</option>
                      {plist && plist.length > 0 ? (
                        plist.map((option, i) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No Products Found</option>
                      )}
                    </select>
                    {producttwo ? (
                      <div className="compare_imageSec">
                        <img
                          src={
                            producttwo?.file_path
                              ? "https://api.dealitt.com/" +
                              producttwo?.file_path
                              : ""
                          }
                          class="compare_img"
                          alt="..."
                        />
                        {producttwo.Carts &&
                          producttwo.Carts?.filter(function (value) {
                            return value.producttwo_id === producttwo.id;
                          }).length > 0 ? (
                          <div class="wishlist-icon for_single">
                            <input
                              type="checkbox"
                              className="remove-cart"
                              key={producttwo.id}
                              checked
                              onClick={(e) => addTowhislist(e, producttwo)}
                            />
                            <span className="icon-wishlist"></span>
                          </div>
                        ) : (
                          <div class="wishlist-icon for_single">
                            <input
                              type="checkbox"
                              key={producttwo.id}
                              onClick={(e) => addTowhislist(e, producttwo)}
                            />
                            <span className="icon-wishlist"></span>
                          </div>
                        )}
                        <h2 className="compare_main">{producttwo?.name}</h2>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {producttwo ? (
                    <>
                      <div className="compare_innerTwo compare_innerDiv">
                        <p>
                          {producttwo?.quantity > 0 ? (
                            <>
                              <div className="compare_addtocart">
                                <div>
                                  <i class="fas fa-circle in_s"></i>In Stock
                                </div>
                                <div>
                                  <button
                                    className="main_button"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#staticBackdrop2_${producttwo?.id}_1`}
                                    onClick={() => {
                                      if (producttwo?.price_groups) {
                                        let cartMinVal = JSON.parse(producttwo?.price_groups);
                                        setCartInput(Number(cartMinVal[0]?.min));
                                        setGCart(Number(cartMinVal[0]?.min))
                                      }
                                    }}
                                  >
                                    <i class="fas fa-shopping-cart"></i>Add to
                                    Cart
                                  </button>
                                  <div className="">
                                    <div
                                      class="modal fade"
                                      id={`staticBackdrop2_${producttwo?.id}_1`}
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdrop2Label"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                          <div class="modal-body py-3">
                                            <div className="d-flex flex-column justify-content-center adivgn-items-center cart_popup">
                                              <img
                                                src={
                                                  producttwo?.file_path
                                                    ? "https://api.dealitt.com/" +
                                                    producttwo?.file_path
                                                    : ""
                                                }
                                                class="d-block w-100"
                                                alt="..."
                                              />
                                              <h5 className="mt-4">{producttwo?.name}</h5>
                                              <div className="int_sec">
                                                <button
                                                  className="int_dec"
                                                  onClick={decreaseNumber}
                                                >
                                                  -
                                                </button>
                                                <input
                                                  type="number"
                                                  id="cart_input"
                                                  className="form-control"
                                                  value={cartInput}
                                                  onChange={setMainInputValue}
                                                />

                                                <button
                                                  className="int_inc"
                                                  onClick={increaseNumber}
                                                >
                                                  +
                                                </button>
                                              </div>
                                              {/* Calculated Price : ${calculatedPrice}
                              <br />
                              Unit Price : ${unitPrice} */}
                                              <div className="d-flex justify-content-center mt-3">
                                                <button
                                                  type="button"
                                                  class="main_button me-3"
                                                  data-bs-dismiss="modal"
                                                  onClick={(e) => addTocart(e, producttwo)}
                                                >
                                                  Done
                                                </button>
                                                <button
                                                  type="button"
                                                  class="main_button red_button"
                                                  data-bs-dismiss="modal"
                                                  onClick={() => {
                                                    setCartInput(1);
                                                    setGCart(1)
                                                  }}
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <i class="fas fa-circle not_in_s"></i>Not
                              Available
                            </>
                          )}
                        </p>
                      </div>
                      <div className="compare_innerThree compare_innerDiv">
                        <p>${producttwo?.offer_price}</p>
                      </div>
                      <div className="compare_innerFour compare_innerDiv">
                        <p>{producttwo?.mastercategory?.name}</p>
                      </div>
                      <div className="compare_innerFive compare_innerDiv">
                        <p>
                          {producttwo?.User?.first_name},{" "}
                          {producttwo?.User?.email}
                          <br />
                          {producttwo?.User?.website_link}
                        </p>
                      </div>
                      <div className="compare_innerSix compare_innerDiv">
                        <p>{producttwo?.Brand?.name}</p>
                      </div>
                      <div className="compare_innerSeven compare_innerDiv">
                        <p className="rateing">
                          <span>{reviewCount.length}</span>
                          {rating && rating?.length > 0 ? (
                            rating.map((item, i) => (
                              <span>
                                {item > 1 ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-star-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                  </svg>
                                ) : item > 0 ? (
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.35411 5.11885L7.53823 0.792305C7.63633 0.597985 7.81801 0.500551 7.99992 0.500002C8.18286 0.499451 8.36604 0.596887 8.4647 0.792307L10.6488 5.11885L15.5473 5.81472C15.8144 5.85267 15.9823 6.0781 15.9999 6.31968C16.002 6.34769 16.002 6.37593 15.9999 6.40411C15.9904 6.53408 15.9362 6.66299 15.8295 6.76462L12.3072 10.1213L13.1369 14.8508C13.2147 15.2942 12.7771 15.6413 12.3907 15.4427L8.00146 13.1868L3.61224 15.4427C3.56393 15.4675 3.51483 15.4838 3.46603 15.4925C3.12446 15.5531 2.79796 15.2388 2.86603 14.8508L3.69576 10.1213L0.173428 6.76462C0.0551295 6.65189 0.00135121 6.50559 0.00146502 6.36151C0.00154915 6.25501 0.0310762 6.14972 0.0857544 6.06009C0.162946 5.93355 0.290266 5.83821 0.455637 5.81472L5.35411 5.11885ZM7.99992 12.0265C8.07945 12.0263 8.15902 12.045 8.23229 12.0826L11.918 13.977L11.2238 10.0197C11.1911 9.83316 11.2526 9.64214 11.3865 9.5145L14.2934 6.7444L10.2411 6.16874C10.0736 6.14494 9.92728 6.03795 9.84826 5.88141L8.00146 2.22308L7.99992 2.22614V12.0265Z"
                                      fill="#27454A"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 15"
                                  >
                                    <path
                                      id="Intersection_1"
                                      data-name="Intersection 1"
                                      d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                      fill="#27454A"
                                    />
                                  </svg>
                                )}
                              </span>
                            ))
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                            </>
                          )}
                        </p>
                      </div>
                      <div className="compare_innerEight compare_innerDiv">
                        <p dangerouslySetInnerHTML={htmltwo}></p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin scelerisque auctor lectus id aliquam.
                          Maecenas luctus luctus turpis, at semper diam
                          laoreet quis. Nunc pharetra luctus malesuada.
                          Vivamus interdum orci eu est interdum, a aliquam
                          ligula fermentum. In tempus dui ut justo porta, ac
                          egestas leo consequat. Ut facilisis orci et eros
                          sodales, in ultrices velit imperdiet. Ut rutrum mi
                          vulputate felis pretium, quis vulputate lectus
                          eleifend.
                        </p>
                      </div>
                      <div className="compare_innerNine compare_innerDiv">
                        <button className="main_button">
                          <i class="fas fa-envelope"></i>Contact Supplier
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="compare_innerTwo compare_innerDiv"></div>
                      <div className="compare_innerThree compare_innerDiv"></div>
                      <div className="compare_innerFour compare_innerDiv"></div>
                      <div className="compare_innerFive compare_innerDiv"></div>
                      <div className="compare_innerSix compare_innerDiv"></div>
                      <div className="compare_innerSeven compare_innerDiv"></div>
                      <div className="compare_innerEight compare_innerDiv"></div>
                      <div className="compare_innerNine compare_innerDiv"></div>
                    </>
                  )}
                </div>
                <div className="compare_innerSec">
                  <div className="compare_innerOne compare_innerDiv">
                    <label for="brand_search">Add Product Here</label>
                    <select
                      name="brand_search"
                      id="brand_search"
                      className="form-select"
                      onChange={(e) => showBrandTwo(e)}
                    >
                      <option value="">Select Brand</option>
                      {Brand && Brand.length > 0 ? (
                        Brand.map((option, i) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No Brands Found</option>
                      )}
                    </select>
                    <select
                      name="product_search"
                      id="product_search"
                      className="form-select"
                      onChange={(e) => showProductTwo(e)}
                    >
                      <option value="">Select Product</option>
                      {plisttwo && plisttwo.length > 0 ? (
                        plisttwo.map((option, i) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))
                      ) : (
                        <option value="">No Products Found</option>
                      )}
                    </select>
                    {productthree ? (
                      <>
                        <div className="compare_imageSec">
                          <img
                            src={
                              productthree?.file_path
                                ? "https://api.dealitt.com/" +
                                productthree?.file_path
                                : ""
                            }
                            class="compare_img"
                            alt="..."
                          />
                          {productthree.Carts &&
                            productthree.Carts?.filter(function (value) {
                              return value.productthree_id === productthree.id;
                            }).length > 0 ? (
                            <div class="wishlist-icon for_single">
                              <input
                                type="checkbox"
                                className="remove-cart"
                                key={productthree.id}
                                checked
                                onClick={(e) =>
                                  addTowhislist(e, productthree)
                                }
                              />
                              <span className="icon-wishlist"></span>
                            </div>
                          ) : (
                            <div class="wishlist-icon for_single">
                              <input
                                type="checkbox"
                                key={productthree.id}
                                onClick={(e) =>
                                  addTowhislist(e, productthree)
                                }
                              />
                              <span className="icon-wishlist"></span>
                            </div>
                          )}
                          <h2 className="compare_main">
                            {productthree?.name}
                          </h2>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {productthree ? (
                    <>
                      <div className="compare_innerTwo compare_innerDiv">
                        <p>
                          {productthree?.quantity > 0 ? (
                            <>
                              <div className="compare_addtocart">
                                <div>
                                  <i class="fas fa-circle in_s"></i>In Stock
                                </div>
                                <div>
                                  <button
                                    className="main_button"
                                    data-bs-toggle="modal"
                                    data-bs-target={`#staticBackdrop2_${productthree?.id}_2`}
                                    onClick={() => {
                                      if (productthree?.price_groups) {
                                        let cartMinVal = JSON.parse(productthree?.price_groups);
                                        setCartInput(Number(cartMinVal[0]?.min));
                                        setGCart(Number(cartMinVal[0]?.min))
                                      }
                                    }}
                                  >
                                    <i class="fas fa-shopping-cart"></i>Add to
                                    Cart
                                  </button>
                                  <div className="">
                                    <div
                                      class="modal fade"
                                      id={`staticBackdrop2_${productthree?.id}_2`}
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdrop2Label"
                                      aria-hidden="true"
                                    >
                                      <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">
                                          <div class="modal-body py-3">
                                            <div className="d-flex flex-column justify-content-center adivgn-items-center cart_popup">
                                              <img
                                                src={
                                                  productthree?.file_path
                                                    ? "https://api.dealitt.com/" +
                                                    productthree?.file_path
                                                    : ""
                                                }
                                                class="d-block w-100"
                                                alt="..."
                                              />
                                              <h5 className="mt-4">{productthree?.name}</h5>
                                              <div className="int_sec">
                                                <button
                                                  className="int_dec"
                                                  onClick={decreaseNumber}
                                                >
                                                  -
                                                </button>
                                                <input
                                                  type="number"
                                                  id="cart_input"
                                                  className="form-control"
                                                  value={cartInput}
                                                  onChange={setMainInputValue}
                                                />

                                                <button
                                                  className="int_inc"
                                                  onClick={increaseNumber}
                                                >
                                                  +
                                                </button>
                                              </div>
                                              {/* Calculated Price : ${calculatedPrice}
                              <br />
                              Unit Price : ${unitPrice} */}
                                              <div className="d-flex justify-content-center mt-3">
                                                <button
                                                  type="button"
                                                  class="main_button me-3"
                                                  data-bs-dismiss="modal"
                                                  onClick={(e) => addTocart(e, productthree)}
                                                >
                                                  Done
                                                </button>
                                                <button
                                                  type="button"
                                                  class="main_button red_button"
                                                  data-bs-dismiss="modal"
                                                  onClick={() => {
                                                    setCartInput(1);
                                                    setGCart(1)
                                                  }}
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <i class="fas fa-circle not_in_s"></i>Not
                              Available
                            </>
                          )}
                        </p>
                      </div>
                      <div className="compare_innerThree compare_innerDiv">
                        <p>${productthree?.offer_price}</p>
                      </div>
                      <div className="compare_innerFour compare_innerDiv">
                        <p>{productthree?.mastercategory?.name}</p>
                      </div>
                      <div className="compare_innerFive compare_innerDiv">
                        <p>
                          {productthree?.User?.first_name},{" "}
                          {productthree?.User?.email}
                          <br />
                          {productthree?.User?.website_link}
                        </p>
                      </div>
                      <div className="compare_innerSix compare_innerDiv">
                        <p>{productthree?.Brand?.name}</p>
                      </div>
                      <div className="compare_innerSeven compare_innerDiv">
                        <p className="rateing">
                          <span>{reviewCount.length}</span>
                          {rating && rating?.length > 0 ? (
                            rating.map((item, i) => (
                              <span>
                                {item > 1 ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-star-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                  </svg>
                                ) : item > 0 ? (
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.35411 5.11885L7.53823 0.792305C7.63633 0.597985 7.81801 0.500551 7.99992 0.500002C8.18286 0.499451 8.36604 0.596887 8.4647 0.792307L10.6488 5.11885L15.5473 5.81472C15.8144 5.85267 15.9823 6.0781 15.9999 6.31968C16.002 6.34769 16.002 6.37593 15.9999 6.40411C15.9904 6.53408 15.9362 6.66299 15.8295 6.76462L12.3072 10.1213L13.1369 14.8508C13.2147 15.2942 12.7771 15.6413 12.3907 15.4427L8.00146 13.1868L3.61224 15.4427C3.56393 15.4675 3.51483 15.4838 3.46603 15.4925C3.12446 15.5531 2.79796 15.2388 2.86603 14.8508L3.69576 10.1213L0.173428 6.76462C0.0551295 6.65189 0.00135121 6.50559 0.00146502 6.36151C0.00154915 6.25501 0.0310762 6.14972 0.0857544 6.06009C0.162946 5.93355 0.290266 5.83821 0.455637 5.81472L5.35411 5.11885ZM7.99992 12.0265C8.07945 12.0263 8.15902 12.045 8.23229 12.0826L11.918 13.977L11.2238 10.0197C11.1911 9.83316 11.2526 9.64214 11.3865 9.5145L14.2934 6.7444L10.2411 6.16874C10.0736 6.14494 9.92728 6.03795 9.84826 5.88141L8.00146 2.22308L7.99992 2.22614V12.0265Z"
                                      fill="#27454A"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 15"
                                  >
                                    <path
                                      id="Intersection_1"
                                      data-name="Intersection 1"
                                      d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                      fill="#27454A"
                                    />
                                  </svg>
                                )}
                              </span>
                            ))
                          ) : (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 15"
                              >
                                <path
                                  id="Intersection_1"
                                  data-name="Intersection 1"
                                  d="M12.535,14.992l-.033-.007a.522.522,0,0,1-.113-.043L8,12.686,3.611,14.943,3.6,14.95a.521.521,0,0,1-.085.032h0l-.009,0-.034.007a.537.537,0,0,1-.6-.625l0-.017.83-4.729L.172,6.265a.553.553,0,0,1-.163-.3V5.953h0Q0,5.928,0,5.9v-.01c0-.011,0-.021,0-.032s0-.022,0-.033V5.819A.582.582,0,0,1,.023,5.7l0-.013v0A.565.565,0,0,1,.085,5.56a.534.534,0,0,1,.066-.088l0,0,.006-.006.005-.005,0,0a.506.506,0,0,1,.181-.115h0l.01,0h0l.009,0h0l.009,0h0l.014,0,.007,0H.413l.008,0H.427l.009,0h0l.014,0,4.9-.7L7.537.293A.548.548,0,0,1,7.675.117.51.51,0,0,1,8,0h.01a.512.512,0,0,1,.212.049.53.53,0,0,1,.246.244l2.183,4.326,4.9.7.014,0h0l.009,0h.006l.008,0h.006l.008,0,.009,0,.009,0,.007,0,.006,0,.007,0,.005,0,.007,0,0,0,.026.011,0,0,.007,0,0,0,.007,0,0,0,.007,0,.01.006,0,0,.006,0,0,0,.006,0,0,0,.007,0,0,0,.011.008h0l.01.008,0,0,.009.007h0a.536.536,0,0,1,.117.136l.009.015,0,.008v0A.585.585,0,0,1,16,5.819.664.664,0,0,1,16,5.9a.559.559,0,0,1-.06.213l0,0,0,.008,0,.008v0a.564.564,0,0,1-.1.127L12.306,9.621l.83,4.729a.554.554,0,0,1-.3.6.5.5,0,0,1-.219.051A.487.487,0,0,1,12.535,14.992Zm-4.3-3.409,3.686,1.894-.695-3.957a.565.565,0,0,1,.163-.5l2.907-2.77-4.053-.575a.527.527,0,0,1-.392-.288L8,1.727H8l0,0,0,0H8L6.153,5.381a.527.527,0,0,1-.393.288l-4.052.575,2.907,2.77a.565.565,0,0,1,.163.5l-.695,3.957,3.686-1.894A.509.509,0,0,1,8,11.526.5.5,0,0,1,8.231,11.583Z"
                                  fill="#27454A"
                                />
                              </svg>
                            </>
                          )}
                        </p>
                      </div>
                      <div className="compare_innerEight compare_innerDiv">
                        <p dangerouslySetInnerHTML={htmlthree}></p>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Proin scelerisque auctor lectus id aliquam.
                          Maecenas luctus luctus turpis, at semper diam
                          laoreet quis. Nunc pharetra luctus malesuada.
                          Vivamus interdum orci eu est interdum, a aliquam
                          ligula fermentum. In tempus dui ut justo porta, ac
                          egestas leo consequat. Ut facilisis orci et eros
                          sodales, in ultrices velit imperdiet. Ut rutrum mi
                          vulputate felis pretium, quis vulputate lectus
                          eleifend.
                        </p>
                      </div>
                      <div className="compare_innerNine compare_innerDiv">
                        <button className="main_button">
                          <i class="fas fa-envelope"></i>Contact Supplier
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="compare_innerTwo compare_innerDiv"></div>
                      <div className="compare_innerThree compare_innerDiv"></div>
                      <div className="compare_innerFour compare_innerDiv"></div>
                      <div className="compare_innerFive compare_innerDiv"></div>
                      <div className="compare_innerSix compare_innerDiv"></div>
                      <div className="compare_innerSeven compare_innerDiv"></div>
                      <div className="compare_innerEight compare_innerDiv"></div>
                      <div className="compare_innerNine compare_innerDiv"></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Compare;
