import React, { useEffect } from 'react'
import Footer from "../common/Footer";
import Header from "../common/Header";
import Banner from '../common/Banner';
import SellBuyDealit from './component/sellAndBuy/SellBuyDealit';

const SellBuy = () => {
    useEffect(() => {
        document.title = "Buy and Sell Guide on DEALITT B2B Platform";
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Banner title={"Buy and Sell Guide on DEALITT B2B Platform"} />
            <SellBuyDealit />
            <Footer />
        </>
    )
}

export default SellBuy