import React, { Fragment ,useState} from "react";
import { Link } from "react-router-dom";
import "../../../customCss/Blog.css"
import { useNavigate, useParams } from "react-router-dom";
import DataService from "../../../services/data.service";
import "../../../customCss/Blog.css"
import moment from "moment";
const BlogDetailSection = (props) => {


    console.log(props)
  return (
   <div className="container-fluid">
        <div className="row">
            <div className="col mt-5">
                <img src={props?.data?.file_path ? "https://api.dealitt.com/" + props.data.file_path : ""} class="blog_innerimg" alt="..."/>
                <div className="blog-meta mt-3">
                    <span><strong>Admin /</strong></span><span><strong> {moment(props?.data?.createdAt).format("MMM Do YY")}</strong></span>
                </div>
                <h1 className="mt-3">{props?.data?.name}</h1>
                <div className="d-flex flex-column mt-3">
                   <div dangerouslySetInnerHTML={{__html : props?.data?.description}}/>
                </div>
            </div>
        </div>
   </div>
  );
};

export default BlogDetailSection;