import React, { useEffect, useState } from 'react'
import DataService from '../services/data.service'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from '../common/Header'
import Footer from '../common/Footer'
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom'
import '../customCss/MainCategory.css'
import CatImg1 from '../images/jwlryacc.png'
import CatImg2 from '../images/watchacc.png'
import CatImg3 from '../images/eyeswear.png'
import CatImg4 from '../images/haieracc.png'
import CatImg5 from '../images/spex.png'
import CatImg6 from '../images/neckless.png'
import CatImg7 from '../images/watch.png'
import SubCatImg1 from '../images/single1.jpg'
import slide1 from "../images/VendorImages/cate-prdt1.png"
import slide2 from "../images/VendorImages/cate-prdt2.png"
import slide3 from "../images/VendorImages/cate-prdt3.png"
import slideImg1 from "../images/VendorImages/slideimg1.jpg"
import slideImg2 from "../images/VendorImages/slideimg2.jpg"
import slideImg3 from "../images/VendorImages/slideimg4.jpg"
import Slider from "react-slick";
import "../../view/customCss/slick.css"


function MainCategory() {
    const navigate = useNavigate();


    const params = useParams();
    const [masterData, setMasterData] = useState([]);



    const getData = async () => {
        await DataService.getHomePageData().then((data) => {
            const getSlug = data.data.masterCategories.filter((value) => {
                return value.slug.includes(params.master)
            });
            setMasterData(getSlug);
            // console.log(getSlug)
        });
    };
    useEffect(() => {
        getData();
    }, []);

    const navigatePage  = (e, slug)=>{
        navigate('/'+slug)
      }

    return (
        <>
            <Header />
            <section className="category-banner py-5">

                <div className="category-banner-info text-center">
                    <h2>Jewelry, Eyewear, Watches & Accessories</h2>
                    <h3>Discover new and trending products</h3>
                </div>

            </section>
            {/* <section className='main_categories'>
            <div className='main_cat'>
                <div className='container'>
                    <h1>Source by category</h1>
                    <div className='inner_slide'>
                        <Slider {...settings}>
                            {masterData[0] && masterData[0].Categories.length > 0 ? masterData[0].Categories.map((item, index) => (
                                <div className="shop_ByCategory">
                                        <img src={CatImg7} alt="Category-img" />
                                    <h4 class="text-center slide-title mt-2 fw-400">{item.name}</h4>
                                </div>
                            )) :
                                <p>No data found</p>
                            }
                        </Slider>
                    </div>
                </div>
            </div>
            </section> */}



            <section className="source-category">
                <div className="container">
                    <div className='source_inner'>
                    <h1>Source by category</h1>
                    
                    </div>
                   
                    <div className='item_maincat'>
                        <div className='item_maincatinner'>
                            {masterData[0] && masterData[0].Categories.length > 0 ? masterData[0].Categories.map((item, index) => (
                     
                                <div className="item_maincat_item" onClick={(e)=> navigatePage(e,item.slug)}>
                                    <img src={CatImg7} alt="Category-img" />
                                    <h4 class="text-center slide-title mt-2 fw-400">{item.name}</h4>
                                </div>
                               
                            )) :
                                <p>No data found</p>
                            }
                        </div>
                    </div>
      
                    

                </div>
            </section>


            {/* <!--################################# SOURCE-SECTION-END #################################--> */}

            {/* <!--################################# CHINA-SECTION-START #################################--> */}
            {/* <section className="china-cards-section py-4">
                <div className="container">

                    <div className="row w-100 m-0 mb-4">
                        <div className="col-md-6 mb-md-0 mb-3 ps-0 card-china-col">
                            <div className="row w-100 m-0 bg-white p-3 rounded">
                                <div className="category-china-head mb-3">
                                    <Link to="">
                                        <h2>Yiwu, China</h2>
                                    </Link>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={SubCatImg1} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slide3} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slide2} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg3} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pe-0 card-china-col">
                            <div className="row w-100 m-0 bg-white p-3 rounded">
                                <div className="category-china-head mb-3">
                                    <Link to="">
                                        <h2>Guangzhou, China</h2>
                                    </Link>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slide1} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg2} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg2} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg3} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row w-100 m-0 mb-2">
                        <div className="col-md-6 mb-md-0 mb-3 ps-0 card-china-col">
                            <div className="row w-100 m-0 bg-white p-3 rounded">
                                <div className="category-china-head mb-3">
                                    <Link to="">
                                        <h2>Yiwu, China</h2>
                                    </Link>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg3} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg1} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg2} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg3} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 pe-0 card-china-col">
                            <div className="row w-100 m-0 bg-white p-3 rounded">
                                <div className="category-china-head mb-3">
                                    <Link to="">
                                        <h2>Guangzhou, China</h2>
                                    </Link>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg3} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg1} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg2} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="category-china-cards">
                                        <div className="category-china-body text-center">
                                            <Link to="">
                                                <div className="category-china-image mb-lg-3 mb-2">
                                                    <img src={slideImg3} alt="Category-img" />
                                                </div>
                                                <div className="category-china-price">
                                                    <h4>US$52.00</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
            {/* <!--################################# CHINA-SECTION-END #################################--> */}

            {/* <!--################################# VERIFIED-SECTION-START #################################--> */}
            {/* <section className="category-verified-section">
                <div className="container">

                    <div className="category-verified-head mb-3">
                        <h2>Verified Pro Supplier</h2>
                    </div>

                    <div className="owl-carousel owl-theme main-category-slider">
                        <div className="item">
                            <div className="category-verified">
                                <div className="category-verified-image position-relative">
                                    <Link to="">
                                        <img src="assets/images/verified1.jpg" alt="Category-img" />
                                        <div className="category-verified-title">
                                            <h4>Jewelry supplier with end-to-end services</h4>
                                        </div>
                                    </Link>
                                    <div className="category-verified-nxt-images d-flex">
                                        <Link to="">
                                            <img src="assets/images/verified-sub1.jpg" alt="Category-img" />
                                        </Link>
                                        <Link to="">
                                            <img src="assets/images/verified-sub2.jpg" alt="Category-img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="category-verified">
                                <div className="category-verified-image position-relative">
                                    <Link to="">
                                        <img src="assets/images/verified2.jpg" alt="Category-img" />
                                        <div className="category-verified-title">
                                            <h4>Jewelry supplier with end-to-end services</h4>
                                        </div>
                                    </Link>
                                    <div className="category-verified-nxt-images d-flex">
                                        <Link to="">
                                            <img src="assets/images/verified-sub3.jpg" alt="Category-img" />
                                        </Link>
                                        <Link to="">
                                            <img src="assets/images/verified-sub4.jpg" alt="Category-img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="category-verified">
                                <div className="category-verified-image position-relative">
                                    <Link to="">
                                        <img src="assets/images/verified3.jpg" alt="Category-img" />
                                        <div className="category-verified-title">
                                            <h4>Jewelry supplier with end-to-end services</h4>
                                        </div>
                                    </Link>
                                    <div className="category-verified-nxt-images d-flex">
                                        <Link to="">
                                            <img src="assets/images/verified-sub5.jpg" alt="Category-img" />
                                        </Link>
                                        <Link to="">
                                            <img src="assets/images/verified-sub6.jpg" alt="Category-img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
            {/* <!--################################# VERIFIED-SECTION-END #################################--> */}

            {/* <!--################################# JUST-FOR-SECTION-START #################################--> */}
            {/* <section className="just-category-section py-5">
                <div className="container">

                    <div className="category-verified-head mb-3">
                        <h2>Just for you</h2>
                    </div>

                    <div id="carouselExampleIndicators" className="carousel slide">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item slider-items active">
                                <img src="assets/images/just-img1.jpg" alt="Slider-Img" />
                            </div>
                            <div className="carousel-item slider-items">
                                <img src="assets/images/just-img2.jpg" alt="Slider-Img" />
                            </div>
                            <div className="carousel-item slider-items">
                                <img src="assets/images/just-img3.jpg" alt="Slider-Img" />
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>
            </section> */}
            {/* <!--################################# JUST-FOR-SECTION-END #################################-->    */}

            <Footer />

        </>
    )
}

export default MainCategory
