import React from 'react'
import "../UserProfileCss/EmailPreferences.css"
import { Link } from 'react-router-dom'
function EmailPreferences() {
  return (
   
<div>
<div className="container">
        <div className="banner_main">
            <div>
                <h2>
                    Email preferences
                </h2>
                <p>select the type of emails you would like to receive.</p>
                <h6>Email preferences for navjotkaur@gmail.com </h6>


            </div>
            <div>
                <div className="bg-light email_1">
                    <div>
                        <h4>All notification emails</h4>
                        <p>Emails that inform you of important account updates and activities on DEALITT.com </p>
                    </div>
                    <div>
                        <label className="switch toggle_ email_left" >
                            <input type="checkbox" id="flexCheckDefault"/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>

                <div className="general_">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label email_left" htmlFor="flexCheckDefault">
                        </label>
                        <h6><strong>General notification emails</strong></h6>
                        <p>Emails triggered by your activities on the platform, including updates on your <br/>
                            accounts, communication, orders, shipments, etc.</p>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label email_left" htmlFor="flexCheckDefault">
                        </label>
                        <h6> <strong>Disputes updates</strong></h6>
                        <p>In the event where there is any dsputevwherein you are a party(whether initiated <br/> by you
                            or
                            not),we will notify and update you on its progress.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="bg-light email_1">
                    <div>
                        <h4>All marketing emails</h4>
                        <p>Emails that contain promotional messages about products and services on DEALITT.com </p>
                    </div>
                    <div>
                        <label className="switch toggle_ email_left">
                            <input type="checkbox" defaultChecked/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <div className="general_">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label email_left" htmlFor="flexCheckDefault">
                        </label>
                        <h6><strong>General marketing emails</strong></h6>
                        <p>Emails to inform you of deals,events and services updates, including product <br/> discounts,
                            special
                            offers events, and services offerings that may benefit your business.</p>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
                        <label className="form-check-label email_left" htmlFor="flexCheckDefault">
                        </label>
                        <h6> <strong>Surveys</strong></h6>
                        <p>Emails that invite you to provide feedback so that we can improve our offerings in <br/> order to
                            better
                            serve you.</p>
                    </div>
                </div>
            </div>
            <Link to="">Unsubscribe from all</Link>
        </div>
    </div>
</div>

  
  )
}

export default EmailPreferences
