import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom"
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
import "../../../../customCss/ProfileData.css"


const Sidebar = () => {
    const navigate = useNavigate();
    const logout = (e) => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
        window.location.reload();
      }
  return (
    <aside className="page-sidebar  desktop_profile_menu">
        <div className="menu-loop">
            <h2 className='Account_Security' >Settings</h2>
         <div className="main_inner">

            <div className="menu-loop-inner">
                <NavLink to='/favorites'>My Favorites</NavLink>
            </div>
            <div className="menu-loop-inner">
                <NavLink to='/notifications'>Notifications</NavLink>
            </div>
            <div className="menu-loop-inner">
                <NavLink to='/myorders'>My Orders</NavLink>
            </div>
            <div className="menu-loop-inner">
                <NavLink to='/address'>My Addresses</NavLink>
            </div>
            <div className="menu-loop-inner">
                <NavLink to='/help-center'>Support and Help</NavLink>
            </div>
         </div>
           
        </div>
    </aside>
  );
};

export default Sidebar;