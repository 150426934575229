import React, {useEffect} from 'react'
import Footer from "../common/Footer";
import Header from "../common/Header";
import Banner from '../common/Banner';
import PaymentTransactionDealit from './component/paymentTranscationGuide/PaymentTransactionDealit';

const PaymentTransactionGuide = () => {
    useEffect(() => {
        document.title = "DEALITT Payment Transaction Guide";
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Banner title={"DEALITT Payment Transaction Guide"} />
            <PaymentTransactionDealit />
            <Footer />
        </>
    )
}

export default PaymentTransactionGuide