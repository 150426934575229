import React from 'react'
import '../customCss/Compare_copy.css'
import { Link } from 'react-router-dom'
import img5 from "../images/img-5.png"
import img6 from "../images/img-6.png"
import Header from '../common/Header'
import Footer from '../common/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faPrint, faChevronRight,faChevronLeft,faEnvelope,faEye,faClock } from '@fortawesome/free-solid-svg-icons';
function CompareC() {
  return (
<>
    <Header/>
      <section className="compare-section py-5">
    <div className="container">

        <div className="compare-table">
            <table className="table mb-0">
                <thead className="com-table-header p-2">
                <tr>
                    <th>Compare results</th>
                    <th className="d-lg-flex align-items-center">
                        <label className="me-2">View:</label>
                        <select className="form-select" aria-label="Default select example">
                        <option defaultValue>All Features</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                    </th>
                    <th>
                        <div className="form-check table-head-checkbox">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault1" />
                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                            Hide the Same
                            </label>
                        </div>
                    </th>
                    <th>
                        <div className="form-check table-head-checkbox">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2" />
                            <label className="form-check-label" htmlFor="flexCheckDefault2">
                            Highlight the Difference
                            </label>
                        </div>
                    </th>
                    <th className="text-end"><FontAwesomeIcon icon={faPrint}  className="me-1" />Print Current</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><FontAwesomeIcon icon={faChevronLeft} /></td>
                        <td className="table-product">
                            <div className="table-prdt-image d-lg-flex">
                                <Link to="">
                                    <img src={img5} alt="Product-Img" />
                                </Link>
                                <div className="table-prdt-title ms-2">
                                    <Link to="">
                                        <h2>Hot 12V Starting Device Car Jumpstarter Auto</h2>
                                    </Link>
                                    <div className="table-prdt-btn">
                                        <Link to=""><FontAwesomeIcon icon={faEnvelope} className="me-1" />Contact supplier</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="table-prdt-icons-main pt-2 d-lg-flex">
                                <div className="table-prdt-icons me-4">
                                    <h6>
                                    <FontAwesomeIcon icon={faEye}  className="me-1" />300+
                                    </h6>
                                </div>
                                <div className="table-prdt-icons me-4">
                                    <h6>
                                    <FontAwesomeIcon icon={faMessage} className="me-1"/> 40+
                                    </h6>
                                </div>
                                <div className="table-prdt-icons">
                                    <h6>
                                    <FontAwesomeIcon icon={faClock}  className="me-1" /><FontAwesomeIcon icon={faChevronLeft} />24h
                                    </h6>
                                </div>
                            </div>
                        </td>
                        <td className="table-product">
                            <div className="table-prdt-image d-lg-flex">
                                <Link to="">
                                    <img src={img6} alt="Product-Img" />
                                </Link>
                                <div className="table-prdt-title ms-2">
                                    <Link to="">
                                        <h2>Hot 12V Starting Device Car Jumpstarter Auto</h2>
                                    </Link>
                                    <div className="table-prdt-btn">
                                        <Link to=""><FontAwesomeIcon icon={faEnvelope} className="me-1" />Contact supplier</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="table-prdt-icons-main pt-2 d-lg-flex">
                                <div className="table-prdt-icons me-4">
                                    <h6>
                                    <FontAwesomeIcon icon={faEye}  className="me-1" />300+
                                    </h6>
                                </div>
                                <div className="table-prdt-icons me-4">
                                    <h6>
                                    <FontAwesomeIcon icon={faMessage} className="me-1"/>40+
                                    </h6>
                                </div>
                                <div className="table-prdt-icons">
                                    <h6>
                                    <FontAwesomeIcon icon={faClock}  className="me-1" /><FontAwesomeIcon icon={faChevronLeft} />24h
                                    </h6>
                                </div>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td><FontAwesomeIcon icon={faChevronRight} /></td>
                    </tr>
                    <tr>
                        <td className="table-title">Product Features</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Min.order quantity</td>
                        <td className="table-data">100 pieces</td>
                        <td className="table-data">10 sets</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">FOB Price</td>
                        <td className="table-data">US $ 10.0-17.95/piece</td>
                        <td className="table-data">US $ 13.9-24.9/set</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Port</td>
                        <td className="table-data"></td>
                        <td className="table-data"></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-title">Supplier features</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Company name</td>
                        <td className="table-data"><Link to="">Guangdong Weishi Data Technology Co.,Ltd.</Link></td>
                        <td className="table-data"><Link to="">Guangdong Weishi Data Technology Co.,Ltd.</Link></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Payment Terms</td>
                        <td className="table-data">
                            <h6>L/C</h6>
                            <h6>Western Union</h6>
                            <h6>D/P</h6>
                            <h6>D/A</h6> 
                            <h6>T/T</h6> 
                        </td>
                        <td className="table-data">
                            <h6>L/C</h6>
                            <h6>Western Union</h6>
                            <h6>D/P</h6>
                            <h6>D/A</h6> 
                            <h6>T/T</h6> 
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Supplier location</td>
                        <td className="table-data">China</td>
                        <td className="table-data">China</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Main Products</td>
                        <td className="table-data">
                            <h6>Auto Parts</h6>
                            <h6>Auto Emergency Tools</h6>
                            <h6>Auto Sunshades</h6>
                            <h6>Auto Cleaning Tools</h6> 
                            <h6>Auto Steering Wheels</h6>
                            <h6>Jumper Starter</h6>
                            <h6>Emergency Tools</h6>
                            <h6>Car Films</h6>
                            <h6>Car Steering Wheel</h6>
                            <h6>Tire Inflators</h6> 
                        </td>
                        <td className="table-data">
                            <h6>Auto Parts</h6>
                            <h6>Auto Emergency Tools</h6>
                            <h6>Auto Sunshades</h6>
                            <h6>Auto Cleaning Tools</h6> 
                            <h6>Auto Steering Wheels</h6>
                            <h6>Jumper Starter</h6>
                            <h6>Emergency Tools</h6>
                            <h6>Car Films</h6>
                            <h6>Car Steering Wheel</h6>
                            <h6>Tire Inflators</h6> 
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Total Annual Sales Volume</td>
                        <td className="table-data">US$5 Million - US$10 Million</td>
                        <td className="table-data">US$5 Million - US$10 Million</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-title">Online Perfomance</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">No. of years with DEALITT.com</td>
                        <td className="table-data">2</td>
                        <td className="table-data">2</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Transaction History</td>
                        <td className="table-data"> - </td>
                        <td className="table-data"> - </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Supplier attributes</td>
                        <td className="table-data">
                            <h6>Gold Supplier</h6>
                            <h6>Onsite Checked</h6>
                            <h6>Assessed Supplier</h6>
                            <h6>Trade Assurance</h6> 
                        </td>
                        <td className="table-data">
                            <h6>Gold Supplier</h6>
                            <h6>Onsite Checked</h6>
                            <h6>Assessed Supplier</h6>
                            <h6>Trade Assurance</h6> 
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-title">Trade Capability</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Chat with supplier</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">Select to contact</td>
                        <td><input type="checkbox" /></td>
                        <td><input type="checkbox" /></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="table-other-title">
                            <div className="form-check last-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault3" />
                                <label className="form-check-label" htmlFor="flexCheckDefault3">
                                Select All
                                </label>
                            </div>
                        </td>
                        <td>
                            <div className="table-last-btn">
                                <Link to="">
                                <FontAwesomeIcon icon={faEnvelope} className="me-1" />Contact supplier
                                </Link>
                            </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                </tbody>
            </table>
        </div>

    </div>
</section>
<Footer/>
    </>
    
  )
}

export default CompareC
