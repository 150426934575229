import React, { useState } from 'react'
import { NavLink, Link } from "react-router-dom";
import Sidebar from './sidebar';
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import "../../../../customCss/ProfileData.css"
import DataService from '../../../../services/data.service';
const UserProfileBottom = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    React.useEffect(() => {
        document.title = "Profile";
        getData();
    }, []);


    const getData = () => {
        DataService.getUserDetail().then((data) => {
            setData(data.data.data);
            setLoading(false);
        }).catch((error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();


            setLoading(false);
            toast.error(resMessage, {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    }
    const navigate = useNavigate();
    const logout = (e) => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
        window.location.reload();
    }
    return (
        <>
            <section className="bg-dark-white">
                <div class="container h-100 py-3">
                    <div className="d-flex flex-column flex-md-row">
                    <div className="col-12 col-md-2">

</div>
                        <div className="col-12 col-md-3">
                            <Sidebar />
                        </div>
                        <div className="col-12 col-md-3">
                            <div className="menu-loop ">

                                <h2 className='Account_Security'>Account Security</h2>

                                <div className="main_inner">
                                    <div className="menu-loop-inner">
                                        <NavLink to='/'>My Profile</NavLink>
                                    </div>
                                    <div className="menu-loop-inner">
                                        <NavLink to='/'>Account Details</NavLink>
                                    </div>
                                    <div className="menu-loop-inner">
                                        <NavLink to='/'>My Favorites</NavLink>
                                    </div>
                                    <div className="menu-loop-inner">
                                        <NavLink to='/'>Notifications</NavLink>
                                    </div>
                                    <div className="menu-loop-inner">
                                        <NavLink to='/'>My Orders</NavLink>
                                    </div>
                                    <div className="menu-loop-inner">
                                        <NavLink to='/'>My Addresses</NavLink>
                                    </div>
                                    <div className="menu-loop-inner">
                                        <NavLink to='/'>Support and Help</NavLink>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-4">
                            {/* {!loading?<ProfileDetail data={data}/>:<div className="main_spinner">
                        <div class="spinner-border text-warning" role="status">
                    <span class="sr-only">Loading...</span>
                        </div>
                  </div>} */}
                            <aside className="page-sidebar mobile_profile_menu">
                                <div className="menu-loop ">
                                    <div className="main_inner">
                                        <div className="menu-loop-inner">
                                            <NavLink to='/profile'>My Profile</NavLink>
                                        </div>
                                        <div className="menu-loop-inner">
                                            <NavLink to='/account'>Account Details</NavLink>
                                        </div>
                                        <div className="menu-loop-inner">
                                            <NavLink to='/favorites'>My Favorites</NavLink>
                                        </div>
                                        <div className="menu-loop-inner">
                                            <NavLink to='/notifications'>Notifications</NavLink>
                                        </div>
                                        <div className="menu-loop-inner">
                                            <NavLink to='/myorders'>My Orders</NavLink>
                                        </div>
                                        <div className="menu-loop-inner">
                                            <NavLink to='/address'>My Addresses</NavLink>
                                        </div>
                                        <div className="menu-loop-inner">
                                            <NavLink to='/help-center'>Support and Help</NavLink>
                                        </div>
                                    </div>

                                </div>
                            </aside>

                        </div>
                    </div>
                </div>

            </section>

        </>
    )
}

export default UserProfileBottom