import React, { useState } from "react";
import { Link } from "react-router-dom";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "../../../../../src/view/customCss/SingleProduct.css";
import NotFound from "../../../common/NotFound";

function SingleProductMain(props) {
  const addTowhislist = (event, item) => {
    props.addTowhislist(event, item);
  };
  return (
    <>
      <div className="left_img_layout">
        {props?.data?.Carts &&
        props?.data?.Carts?.filter(function (value) {
          return value.product_id === props?.data.id;
        }).length > 0 ? (
          <div class="wishlist-icon for_single">
            <input
              type="checkbox"
              className="remove-cart"
              key={props?.data.id}
              checked
              onClick={(e) => addTowhislist(e, props?.data)}
            />
            <span className="icon-wishlist"></span>
          </div>
        ) : (
          <div class="wishlist-icon for_single">
            <input
              type="checkbox"
              key={props?.data.id}
              onClick={(e) => addTowhislist(e, props?.data)}
            />
            <span className="icon-wishlist"></span>
          </div>
        )}
        <div className="left_img">
          <div className="left_img_main img-fluid">
            <div
              id="carouselExampleCaptions"
              class="carousel slide pointer-event"
            >
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <Zoom>
                    <img
                      src={
                        props.data.file_path
                          ? "https://api.dealitt.com/" + props.data.file_path
                          : ""
                      }
                      class="d-block w-100"
                      alt="..."
                    />
                  </Zoom>
                </div>
                {props?.data?.images?.length > 0
                  ? props?.data?.images?.map((item, i) => (
                      <div class="carousel-item">
                        <Zoom>
                          <img
                            src={
                              props.data.file_path
                                ? "https://api.dealitt.com/" + item.file_path
                                : ""
                            }
                            class="d-block w-100"
                            alt="..."
                          />
                        </Zoom>
                      </div>
                    ))
                  : ""}
              </div>
              <div class="carousel-indicators position-relative m-0 d-flex justify-content-start mob-resp">
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                >
                  <img
                    src={
                      props.data.file_path
                        ? "https://api.dealitt.com/" + props.data.file_path
                        : ""
                    }
                    class="d-block w-100"
                    alt="..."
                  />
                </button>
                {props?.data?.images?.length > 0
                  ? props?.data?.images?.map((item, i) => (
                      <button
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to={i + 1}
                        class="active"
                        aria-current="true"
                        aria-label={"slide" + (i + 1)}
                      >
                        <img
                          src={
                            props.data.file_path
                              ? "https://api.dealitt.com/" + item.file_path
                              : ""
                          }
                          class="d-block w-100"
                          alt="..."
                        />
                      </button>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
      {props?.data?.video_path ? (
        <>
          <video width="100%"  className="mt-lg-5 mt-3" height="300" controls>
            <source
              src={
                props.data.video_path
                  ? "https://api.dealitt.com/" + props.data.video_path
                  : ""
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default SingleProductMain;
