import React from 'react'
import "../UserProfileCss/PrivacySettings.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
function PrivacySettings() {
    return (
        <div className="container">
            <div className="breadcrumb_body">
                <ul className="breadcrumb d-flex">
                    <li className="px-2"><Link to="#">Account Setting</Link></li>
                    <span className="px-2">&#x3e;</span>
                    <li className="px-2">
                        <Link to="#"><span className="currtent_page">Privacy </span> </Link>
                    </li>
                </ul>
            </div>
            <div className="privacy_main">
                <div className="main_head pb-3">
                    <h2>Privacy Settings</h2>
                </div>
                <div className="main_body">
                    <div className="form_body_1 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Basic information
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span
                                ><input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    /></span>
                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} />
                                    </span>
                                </span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option3"
                                >Share only with verified suppliers, their third-party
                                    services providers and/or their affiliates
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_4 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_4"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option4"
                                >Share with all users <span className="ms-1"> </span
                                ></span>
                            </div>
                        </div>
                    </div>
                    <div className="form_body_2 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Contact information
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>

                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1"
                                >Only suppliers you've exchanged business cards with can view
                                    this.</span
                                >
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Only (i) suppliers, (ii)their third-party services providers;
                                    and/or (iii)their affiliates you've exchanged business cards
                                    with can view this.
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                        </div>
                    </div>
                    <div className="form_body_3 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Your contact adds, blocks and spam status
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>

                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                    <div className="form_body_4 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Sourcing information
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                    <div className="form_body_5 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Sourcing information
                                <span className="ms-1"
                                ><FontAwesomeIcon icon={faCircleQuestion} />
                                </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                    <div className="form_body_6 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Your Activity on DEALITT.com<span
                                ><i className="fa-regular fa-circle-question"></i
                                ></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                    <div className="form_body_7 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Industries you are interested in
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                    <div className="form_body_8 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Most searched keywords
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                    <div className="form_body_9 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Recently searched keywords and viewed products
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                    <div className="form_body_10 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Transaction
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers, their third-party
                                    services providers and/or their affiliates
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                    <div className="form_body_11 my-3">
                        <div className="from_head py-1">
                            <h5>
                                Activity details
                                <span className="ms-1"> </span>
                                <span><FontAwesomeIcon icon={faCircleQuestion} /></span>
                            </h5>
                        </div>
                        <div className="form_options">
                            <div className="form_option_1 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_1"
                                        name="options"
                                        value="1"
                                    />
                                </span>

                                <span htmlFor="option1">Hide</span>
                            </div>
                            <div className="form_option_2 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_2"
                                        name="options"
                                        value="2"
                                    />
                                </span>

                                <span htmlFor="option2"
                                >Share only with verified suppliers
                                    <span className="ms-1"
                                    ><FontAwesomeIcon icon={faCircleQuestion} /> </span
                                    ></span>
                            </div>
                            <div className="form_option_3 d-flex">
                                <span>
                                    <input
                                        className="me-1 pe-1"

                                        type="radio"
                                        id="option_3"
                                        name="options"
                                        value="3"
                                    />
                                </span>

                                <span htmlFor="option3">Share with all users </span><br />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="save_btn">
                    <button className="btn btn_color">Save</button>
                </div>
            </div>
        </div>

    )
}

export default PrivacySettings
