import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Banner from '../common/Banner'

const ReportAbuse = () => {
  return (
    <>
      <Header />
      <Banner title="Complaints" />
      <div className="report_sec">
        <div className="container">
          <div className='reort_abuse'>
            <div className="report_area">
              <div className="report_main">
                <p>If you have any complaint about anything related to buying or selling, please feel free to contact with our team and will will work on it. </p>
                <input type="file" />
                <label>Reason</label>
                <textarea placeholder="Enter your text..."></textarea>
                <button className="submit_report">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ReportAbuse