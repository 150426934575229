import React, { useState, useRef } from "react";
import NewsImg from "../../../images/newsletter.png";
import DataService from "../../../services/data.service";
import { toast } from "react-toastify";

const Newsletter = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [gender, setGender] = useState("");
  const [mail, setMail] = useState("");
  const changeGender = (e) => {
    setGender(e.target.value);
  };
  const changeMail = (e) => {
    setMail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = { email: mail, gender: gender };
    DataService.addNewsletter(data).then(
      () => {
        toast.success("Form Submitted Succesfully!!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setGender("");
        setMail("");
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    );
  };
  return (
    <div className="newletter_sec">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="newsletter_inner">
              <div className="newsletter_innerL">
                <img src={NewsImg} alt="newletter" />
              </div>
              <div className="newsletter_innerR">
                <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
                <p>
                  Get all the latest information on Events, Sales and Offers.
                </p>
                <form onSubmit={handleSubmit} ref={form}>
                  <div className="newsletter_formFlex">
                    <div className="email_sec">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        value={mail}
                        onChange={changeMail}
                      />
                    </div>
                    <div className="gender_sec">
                      <select
                        className="form-select"
                        onChange={changeGender}
                        value={gender}
                      >
                        <option value="">Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                    <div className="submit_sec">
                      <button type="submit" className="main_button">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
