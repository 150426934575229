import React from 'react'
import "../customCss/Banner.css"
const Banner = (props) => {
  return (
    <>
      <div className='banner_main'>
        <div className='banner_innernew'>
          <div className='container'>
            <div className='inner_bannertext'>
              <h1>{props.title}</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Banner