import React from "react";
import Notfoundicon from "../images/search.png";

const NotFound = () => {
  return (
    <div className="data_not_found">
      <img src={Notfoundicon} alt="not_found_icon" />
      <p>No Data Found</p>
    </div>
  );
};

export default NotFound;
