import React from 'react'
import { Link } from 'react-router-dom';
import LoginHeader from '../../../../common/LoginHeader';
import "../UserProfileCss/EditMemberProfile.css";

function EditMemberProfile() {
  return (
    
    <div>
      <section className="edit-form py-5">
    <div className="container">

        <div className="row m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="edit-head border-bottom pb-2 mb-4">
                    <h4>Edit Member Profile</h4>
                </div>
                <form>
                    <div className="row pt-md-4 pt-3 mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Name:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="input-fields d-flex">
                                <input type="text" className="form-control" value="Navjot" />
                                <input type="text" className="form-control" value="Kaur" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Gender:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="input-radio d-flex">
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                    <label className="form-check-label" for="flexRadioDefault1">
                                      Male
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                    <label className="form-check-label" for="flexRadioDefault2">
                                      Female
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Email Address:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="input-fields d-flex">
                                <p>navjotkaurnvi@gmail.com <span>(Email address confirmed, <Link to="">change email address</Link> )
                                </span></p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Alternative Email Address:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="input-mail">
                               <input type="text" className="form-control" />
                               <div className="placeholder">
                                    <p>(Your alternative email address can be used to receive inquiries so please 
                                        make sure it is accurate and up-to-date.)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Contact Address:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row mb-2 align-items-baseline">
                                <div className="col-md-3">
                                    <div className="inner-label">
                                        <h6>Street Address:</h6>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="inner-fields">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-baseline">
                                <div className="col-md-3">
                                    <div className="inner-label">
                                        <h6>City:</h6>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="inner-fields">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-baseline">
                                <div className="col-md-3">
                                    <div className="inner-label">
                                        <h6>Province/State/Country:</h6>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="inner-fields">
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>-- Please select menu --</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2 align-items-baseline">
                                <div className="col-md-3">
                                    <div className="inner-label">
                                        <h6>Country/Region:</h6>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="inner-fields">
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>India</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-baseline">
                                <div className="col-md-3">
                                    <div className="inner-label">
                                        <h6>Zip/Postal Code:</h6>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="input-fields">
                                        <input type="text" className="form-control" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Tel:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="input-fields d-lg-flex">
                                <label className="pe-2">Country Code:</label>
                                <input type="text" className="form-control" value="91" />
                                <label className="pe-2">Area Code:</label>
                                <input type="text" className="form-control" />
                                <label className="pe-2">Number:</label>
                                <input type="text" className="form-control" value="8077818400" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Fax:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="input-fields d-lg-flex">
                                <label className="pe-2">Country Code:</label>
                                <input type="text" className="form-control" value="91" />
                                <label className="pe-2">Area Code:</label>
                                <input type="text" className="form-control" />
                                <label className="pe-2">Number:</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Mobile:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="input-fields">
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Department:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="inner-fields">
                                <select className="form-select" aria-label="Default select example">
                                    <option selected>-- Please select menu --</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-2 mb-3 align-items-baseline">
                        <div className="col-md-3 text-md-end">
                            <div className="label">
                                <h6>Job Title:</h6>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="inner-fields">
                                <input type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="last-btn text-center">
                        <Link to="/user-profile/member-profile">Submit</Link>
                    </div>
                </form>    

            </div>
        </div>

    </div>
</section>
    </div>
  )
}

export default EditMemberProfile
