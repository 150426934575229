import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import "../customCss/Faq.css";
import DataService from "../services/data.service";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import NotFound from "../common/NotFound";
import Banner from "../common/Banner";

const Faq = () => {
  useEffect(() => {
    document.title = "Faq - DEALITT";
    getData();
  }, []);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setfilteredData] = useState([]);

  const getData = () => {
    DataService.getFaqs().then((data) => {
      setData(data?.data?.data);
      setfilteredData(data?.data?.data);
      setLoading(false);
    });
  };
  const onChangeSearch = (e) => {
    if (e.target.value) {
      const result = data.filter((value) => {
        return (
          value.question.toLowerCase().includes(e.target.value.toLowerCase()) ||
          value.answer.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });
      setfilteredData(result);
    } else {
      setfilteredData(data);
    }
  };
  return (
    <>
      <Header />
      <Banner title="Faq's"/>
      <div className="faq_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="faq_search">
                <input
                  type="text"
                  placeholder="Search Here ..."
                  className="form-control"
                  onChange={onChangeSearch}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="faq_inner">
                <Accordion>
                  {filteredData && filteredData.length > 0 ? (
                    filteredData.map((item, index) => {
                      return (
                        <>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                {item.question}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                  }}
                                />
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </>
                      );
                    })
                  ) : (
                    <NotFound />
                  )}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
