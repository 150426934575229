import React from 'react'
import { Link } from 'react-router-dom';
import "../UserProfileCss/MyProfile.css";
import Profile from '../../../../images/profileimg.png';
import Flag from '../../../../images/flag.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import fb from "../../../../images/fb.png"
import ln from "../../../../images/link.png"

function MyProfile() {
  return (
    
<section className="profile-section py-5">
    <div className="container">

        <div className="row mt-5 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3 justify-content-center">
                    <div className="col-md-6">
                        <div className="profile d-md-flex">
                            <div className="prfle-image me-3">
                                <img src={Profile} alt="Profile-img" />
                            </div>
                            <div className="prfle-info-main">
                                <div className="prfle-name mb-3">
                                    <h2>Navjot Kaur</h2>
                                </div>
                                <div className="prfle-citiz mb-2 d-flex">
                                    <img src={Flag} alt="Image" />
                                    <h5>IN</h5>
                                </div>
                                <div className="prfle-at d-flex"> 
                                    <h3>at</h3> 
                                    <h4>gM</h4>
                                </div>
                                <div className="prfle-at d-flex">
                                    <h3>Email</h3>
                                    <h5>navjotkaurvni@gmail.com:</h5>
                                </div>
                            </div>    
                        </div>
                    </div>
                    <div className="col-md-6 pt-md-0 pt-2 position-relative">
                        <div className="prfle-edit text-md-end mb-md-0 mb-2">
                            <Link to="/user-profile/see-what-other"><FontAwesomeIcon icon={faEye} />See What Others See</Link>
                          
                            {/* <!-- edit-popup-start --> */}
                            <div className="profile-popup">
                                <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    <FontAwesomeIcon icon={faPenToSquare} />Edit
                                </button>
                                
                                {/* <!-- Modal --> */}
                                <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-xl">
                                        <div className="modal-content">
                                            <div className="modal-header profile-pop-head">
                                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Basic Information 
                                                    <span>Let suppliers know who you are</span></h1>
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div className="modal-body profile-pop-body p-0">
                                                <div className="profile-section position-relative">
                                                    <div className="profile-pop-icon d-md-block d-none">
                                                        <img src={Profile} alt="Profile-img"/>
                                                    </div>
                                                </div>
                                                <form className="profile-popup py-3">
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-6 col-md-8 px-md-0 px-3">
                                                            <div className="profile-popup-radio d-flex mb-3">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked/>
                                                                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                        Theme A
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-4">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                                                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                        Theme B
                                                                    </label>
                                                                </div>
                                                                <div className="form-check ms-4">
                                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
                                                                    <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                        Theme C
                                                                    </label>
                                                                </div>
                                                            </div> 
                                                            <div className="d-flex mb-3">
                                                                <div className="profile-popup-fields">
                                                                    <label>First Name:</label>
                                                                    <input type="text" className="form-control" defaultValue="Navjot"/>
                                                                </div>
                                                                <div className="profile-popup-fields ms-2">
                                                                    <label>Last Name:</label>
                                                                    <input type="text" className="form-control" defaultValue="Kaur"/>
                                                                </div>   
                                                            </div>   
                                                            
                                                            <div className="profile-popup-fields mb-3">
                                                                <label>
                                                                    Gender:
                                                                </label>
                                                                <div className="d-flex ps-3">
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                                            Male
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check ms-4">
                                                                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5"/>
                                                                        <label className="form-check-label" htmlFor="flexRadioDefault5">
                                                                            Female
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="profile-popup-fields mb-3">
                                                                <label>Job Title:</label>
                                                                <input type="text" className="form-control"/>
                                                            </div>
                                                            <div className="profile-popup-fields mb-3">
                                                                <label>Company Name:</label>
                                                                <input type="text" className="form-control" defaultValue="gM"/>
                                                            </div>
                                                            <div className="profile-popup-fields mb-3">
                                                                <label>Email:</label>
                                                                <input type="text" className="form-control" placeholder="navjotkaurnvi@gmail.com"/>
                                                                <Link to="">Change</Link>
                                                            </div>
                                                            <div className="profile-popup-fields mb-3">
                                                                <label>Main Products:</label>
                                                                <div className="profile-popup-input">
                                                                    <input type="text" className="form-control"/>
                                                                    <input type="text" className="form-control"/>
                                                                    <input type="text" className="form-control"/>
                                                                    <input type="text" className="form-control"/>
                                                                    <input type="text" className="form-control"/>
                                                                </div>    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="modal-footer profile-pop-footer justify-content-start">
                                                <button type="button" className="btn">Submit</button>
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                            {/* <!-- edit-popup-end --> */}
                        </div>
                        <div className="prfle-data">
                            <h3>Joined DEALITT.com in <span>2023</span></h3>
                            <h4>Main Products are</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-3 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3">
                    <div className="contact-head mb-2 d-flex justify-content-between">
                        <h2>Contact Information</h2>
                        {/* <!-- edit-popup-start --> */}
                        <div className="profile-popup">
                            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">
                                <FontAwesomeIcon icon={faPenToSquare} />Edit
                            </button>
                            
                            {/* <!-- Modal --> */}
                            <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header profile-pop-head">
                                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Contact Information 
                                                <span className="d-md-block d-none">Accurate and verified contacts will help to build 
                                                    trust with suppliers</span></h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body profile-pop-body p-0">
                                            <form className="profile-popup p-md-3 pt-3">
                                                <div className="row w-100 m-0">
                                                    <div className="col-lg-6 px-md-0 px-3">
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Email:</label>
                                                            <input type="text" className="form-control" placeholder="navjotkaurnvi@gmail.com"/>
                                                            <Link to="">Change</Link>
                                                        </div>
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Alternative Email:</label>
                                                            <input type="text" className="form-control"/>
                                                        </div>
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Mobile:</label>
                                                            <input type="text" className="form-control"/>
                                                        </div>
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Telephone:</label>
                                                            <div className="profile-popup-input">
                                                                <input type="text" className="form-control" defaultValue="91"/>
                                                                <span>-</span>
                                                                <input type="text" className="form-control"/>
                                                                <span>-</span>
                                                                <input type="text" className="form-control" defaultValue="8077818400"/>
                                                            </div>    
                                                        </div>
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Fax:</label>
                                                            <div className="profile-popup-input align-items-center">
                                                                <input type="text" className="form-control"/>
                                                                <span>-</span>
                                                                <input type="text" className="form-control"/>
                                                                <span>-</span>
                                                                <input type="text" className="form-control"/>
                                                            </div>    
                                                            <Link to="">Copy from telephone number</Link>
                                                        </div>
                                                        <div className="profile-popup-label mb-3">
                                                            <label>Social Links:</label>
                                                        </div>
                                                        <div className="profile-social-links p-md-3 pb-3">
                                                            <div className="profile-popup-input pb-3 border-bottom align-items-center">
                                                                <img src={fb} alt="FB"/>
                                                                <h5>Facebook</h5>
                                                                <input type="text" className="form-control"/>
                                                            </div> 
                                                            <div className="profile-popup-input pt-3 align-items-center">
                                                                <img src={ln} alt="FB"/>
                                                                <h4>Linkdin</h4>
                                                                <input type="text" className="form-control"/>
                                                            </div>   
                                                        </div>
                                                    </div>
                                                </div> 
                                            </form>
                                        </div>
                                        <div className="modal-footer profile-pop-footer justify-content-start">
                                            <button type="button" className="btn">Submit</button>
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        {/* <!-- edit-popup-end --> */}
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>navjotkaurvni@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Alternative Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Fax:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Telephone:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>91 - 9909843200</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Mobile:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>

        <div className="row mt-3 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3">
                    <div className="contact-head mb-2 d-flex justify-content-between">
                        <h2>Contact Information</h2>
                        {/* <!-- edit-popup-start --> */}
                       <div className="profile-popup">
                            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">
                                <FontAwesomeIcon icon={faPenToSquare} />Edit
                            </button>
                            
                            {/* <!-- Modal --> */}
                            <div className="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header profile-pop-head">
                                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Contact Information 
                                                <span className="d-md-block d-none">Let suppliers know more about your company's
                                                    background and strengths
                                                </span></h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body profile-pop-body p-0">
                                            <form className="profile-popup p-md-3 pt-3">
                                                <div className="row w-100 m-0">
                                                    <div className="col-lg-6 px-md-0 px-3">
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Company Name</label>
                                                            <input type="text" className="form-control" defaultValue="gM"/>
                                                        </div>
                                                        <div className="profile-popup-fields profile-checkbox mb-3">
                                                            <label className="mb-2">Business Type:</label>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault1"/>
                                                                <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                                online shop/store
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked2"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked2">
                                                                Manufacturer/Factory
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked3"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked3">
                                                                Trading Company
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked5"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked5">
                                                                Distributor/Wholesaler
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked6"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked6">
                                                                Retailer
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Official Website:</label>
                                                            <input type="text" className="form-control"/>
                                                        </div>
                                                        <div className="profile-popup-fields-main mb-3">
                                                            <label>Registered Address:</label>
                                                            <div className="d-flex">
                                                                <select className="form-select me-2" aria-label="Default select example">
                                                                    <option defaultValue>India</option>
                                                                    <option defaultValue="1">One</option>
                                                                    <option defaultValue="2">Two</option>
                                                                </select>
                                                                <select className="form-select me-2" aria-label="Default select example">
                                                                    <option defaultValue>India</option>
                                                                    <option defaultValue="1">One</option>
                                                                    <option defaultValue="2">Two</option>
                                                                </select>
                                                                <input type="text" className="form-control" placeholder="City"/>
                                                            </div>
                                                            <input type="text" className="form-control mt-2" placeholder="Street"/>
                                                            <div className="form-check py-3">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked7"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked7">
                                                                Same as registered address
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="profile-popup-fields-main dash-border mb-3">
                                                            <label>Tax information:</label>
                                                            <p>Please view and manage your tax information at <Link to="">Tax information</Link> page</p>
                                                        </div>
                                                        <div className="profile-popup-info">
                                                            <h2>More Information</h2>
                                                            <h5>Comprehensive and detailed information helps suppliers understand your company's
                                                                background and strengths faster.
                                                            </h5>
                                                        </div>
                                                        <div className="profile-popup-fields-main mb-3">
                                                            <label>Platforms for selling:</label>
                                                            <div className="form-check pb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked8"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked8">
                                                                Amazon
                                                                </label>
                                                            </div>
                                                            <div className="form-check pb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked9"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked9">
                                                                eBay
                                                                </label>
                                                            </div>
                                                            <div className="form-check pb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked10"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked10">
                                                                Etsy
                                                                </label>
                                                            </div>
                                                            <div className="form-check pb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked11"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked11">
                                                                Shopify
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked12"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked12">
                                                                Walmart
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="profile-popup-fields-main mb-3">
                                                            <label>Year Established:</label>
                                                            <input type="text" className="form-control"/>
                                                        </div>
                                                        <div className="profile-popup-fields-main mb-3">
                                                            <label>Total Number of Employees:</label>
                                                            <select className="form-select" aria-label="Default select example">
                                                                <option defaultValue>Please select</option>
                                                                <option defaultValue="1">One</option>
                                                                <option defaultValue="2">Two</option>
                                                            </select>
                                                        </div>
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Main Products:</label>
                                                            <div className="profile-popup-input">
                                                                <input type="text" className="form-control"/>
                                                                <input type="text" className="form-control"/>
                                                                <input type="text" className="form-control"/>
                                                                <input type="text" className="form-control"/>
                                                                <input type="text" className="form-control"/>
                                                            </div>    
                                                        </div>
                                                        <div className="profile-popup-fields-main mb-3">
                                                            <label>About Us:</label>
                                                            <div className="profile-popup-input-main">
                                                                <textarea rows="5"></textarea>
                                                            </div>    
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer profile-pop-footer justify-content-start">
                                            <button type="button" className="btn">Submit</button>
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        {/* <!-- edit-popup-end --> */}
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>navjotkaurvni@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Alternative Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Fax:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Telephone:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>91 - 9909843200</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Mobile:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        <div className="row mt-3 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3">
                    <div className="contact-head mb-2 d-flex justify-content-between">
                        <h2>Contact Information</h2>
                        {/* <!-- edit-popup-start --> */}
                        <div className="profile-popup">
                            <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop4">
                                <FontAwesomeIcon icon={faPenToSquare} />Edit
                            </button>
                            
                            {/* <!-- Modal --> */}
                            <div className="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header profile-pop-head">
                                            <h1 className="modal-title fs-5" id="staticBackdropLabel">Sourcing Information 
                                                <span className="d-md-block d-none">We will recommend more based on your preference.
                                                </span>
                                            </h1>
                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div className="modal-body profile-pop-body p-0">
                                            <form className="profile-popup p-md-3 pt-3">
                                                <div className="row w-100 m-0">
                                                    <div className="col-lg-6 px-md-0 px-3">
                                                        <div className="profile-popup-fields mb-3">
                                                            <label>Annual Purchasing Volumne:</label>
                                                            <select className="form-select me-2" aria-label="Default select example">
                                                                <option defaultValue>India</option>
                                                                <option defaultValue="1">One</option>
                                                                <option defaultValue="2">Two</option>
                                                            </select>
                                                        </div>
                                                        <div className="profile-popup-fields profile-checkbox mb-3">
                                                            <label className="mb-2">Primary Sourcing Purpose:</label>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault1"/>
                                                                <label className="form-check-label" htmlFor="flexCheckDefault1">
                                                                online shop/store
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked2"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked2">
                                                                Manufacturer/Factory
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked3"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked3">
                                                                Trading Company
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked5"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked5">
                                                                Distributor/Wholesaler
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked6"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked6">
                                                                other
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="profile-popup-fields profile-checkbox mb-3">
                                                            <label className="mb-2">Average Sourcing Frequency:</label>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                Never
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                                                <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                Daily
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3"/>
                                                                <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                Weekly
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4"/>
                                                                <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                                Monthly
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5"/>
                                                                <label className="form-check-label" htmlFor="flexRadioDefault5">
                                                                Quarterly
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="profile-popup-fields-main dash-border mb-3">
                                                            <label>Sourcing information:</label>
                                                            <p>Please view and manage your tax information at Tax information page</p>
                                                        </div>
                                                        <div className="profile-popup-fields-main mb-3">
                                                            <label>Preferred Industries:</label>
                                                            <div className="profile-popup-fields-main bg-white p-3 mb-3">
                                                                <label>Industry A:</label>
                                                                <select className="form-select me-2" aria-label="Default select example">
                                                                    <option defaultValue>Please select</option>
                                                                    <option defaultValue="1">One</option>
                                                                    <option defaultValue="2">Two</option>
                                                                </select>
                                                            </div>  
                                                            <div className="profile-popup-fields-main bg-white p-3 mb-3">
                                                                <label>Industry B:</label>
                                                                <select className="form-select me-2" aria-label="Default select example">
                                                                    <option defaultValue>Please select</option>
                                                                    <option defaultValue="1">One</option>
                                                                    <option defaultValue="2">Two</option>
                                                                </select>
                                                            </div>  
                                                            <div className="profile-popup-fields-main bg-white p-3">
                                                                <label>Industry C:</label>
                                                                <select className="form-select me-2" aria-label="Default select example">
                                                                    <option defaultValue>Please select</option>
                                                                    <option defaultValue="1">One</option>
                                                                    <option defaultValue="2">Two</option>
                                                                </select>
                                                            </div>    
                                                        </div>
                                                        <div className="profile-popup-fields profile-checkbox mb-3">
                                                            <label className="mb-2">Prefered Supplier Qualifications:</label>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault7"/>
                                                                <label className="form-check-label" htmlFor="flexCheckDefault7">
                                                                online shop/store
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked8"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked8">
                                                                Manufacturer/Factory
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked9"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked9">
                                                                Trading Company
                                                                </label>
                                                            </div>
                                                            <div className="form-check mb-2">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked10"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked10">
                                                                Distributor/Wholesaler
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked11"/>
                                                                <label className="form-check-label" htmlFor="flexCheckChecked11">
                                                                other
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer profile-pop-footer justify-content-start">
                                            <button type="button" className="btn">Submit</button>
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        {/* <!-- edit-popup-end --> */}
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>navjotkaurvni@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Alternative Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Fax:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Telephone:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>91 - 9909843200</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Mobile:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>

    </div>
</section>
  
  )
}

export default MyProfile
