import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import '../customCss/VendorStore.css'
import DataService from '../services/data.service'
import AuthService from '../services/auth.service'
import cate_prdt1 from "../images/VendorImages/cate-prdt1.png"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { faBars, faGem, faCircle, faSignal, faMedal, faSackDollar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import Proimg from "../../view/images/pro-img.jpg"
function VendorContact(props) {
    const form = React.useRef();
    const params = useParams();
    const [product_qty, setProduct_qty] = useState("");
    const [product_unit, setProduct_unit] = useState(props?.data?.unit);
    const [message, setMessage] = useState("");
    const [products, setProducts] = useState([])
    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])
    const [company, setCompany] = useState([])
    const [showFormChat, setShowFormChat] = useState(false);
    const [VendorChat, setVendorChat] = useState('');

    const getData = async () => {

        await DataService.getCompanyProfile(params.slug).then((data) => {
            setCompany(data.data.data);
        });
    };
    useEffect(() => {
        getData();
    }, [])



    const sendNewMessage = (e) => {
        e.preventDefault();
        const data = {
            to_user_id: company.id,
            message,
            product_qty,
            product_unit,
        };
        DataService.addChatWithVendor(data).then(
            () => {

                toast.success("message Sent!!");
                setShowFormChat(false)
                navigate("/chats")
                // getChat();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    };



    const getChat = async () => {
        await DataService.getChatWithVendor(company?.id)
            .then(async (data) => {
                setVendorChat(data?.data?.data)
                if (data?.data?.data === null) {
                    setShowFormChat(true)

                } else {
                    navigate("/chats");
                    setShowFormChat(false)
                }
            })
            .catch((error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            });
    };


    const navigate = useNavigate();
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 2,
        centerPadding: "40px",
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };
    const navigatePage = (e, slug) => {
        navigate("/" + slug);
    };
    return (
        <>
            <Header />
            <div className='main_vendor_name'>
                <div className='container'>
                    <div className='vendor_inner'>
                        <div className='company_image'>
                            <img
                                src={
                                    company.logo_path
                                        ? "https://api.dealitt.com/" + company.logo_path
                                        : ""
                                }
                                alt="Slider-Img"
                            />
                        </div>
                        <div className='company_discrip'>
                            <h1>{company.name}</h1>
                            <p>Main Products : {company.main_products}</p>
                            <p>Total Staff : {company.office_size}</p>
                            <p className='new_bg_vendor'>Register Year : {company.register_year}</p>
                        </div>



                        <div className="btn_vendor">
                            <button onClick={getChat}  >Chat Now</button>
                        </div>


                        {showFormChat && (

                            <div className='main_popvendor'>

                                <div className="capacity-form-newvendor">
                                    <div className='cut_vendor_chat'>
                                        <button onClick={() => setShowFormChat(false)}>X</button>
                                    </div>
                                    <div className="form-head mb-3">
                                        <h2>Send your message to this supplier</h2>
                                    </div>
                                    <form onSubmit={sendNewMessage} ref={form}>
                                        <div className="row align-items-baseline capacity-row">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>To:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-10 p-md-0">
                                                <div className="capacity-field">
                                                    <h3>{VendorChat?.legal_owner}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row capacity-row mt-2">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>Message:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-md-0">
                                                <div className="capacity-field">
                                                    <textarea
                                                        rows="6"
                                                        placeholder="Enter your inquiry details such as product name,color,size,MOQ,FOB,etc."
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    ></textarea>
                                                    <div className="capacity-info">
                                                        <p>
                                                            Your message must be between 20-8000 characters
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row capacity-row mt-2">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>Quantity:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-md-0">
                                                <div className="capacity-field-main d-md-flex">
                                                    <div className="capacity-field pe-2">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Please enter a number"
                                                            onChange={(e) => setProduct_qty(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="capacity-select">
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setProduct_unit(e.target.value)}
                                                            value={props?.data?.unit}
                                                        >
                                                            <option value={props?.data?.unit}>
                                                                {props?.data?.unit}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="capacity-btn">
                                                    <button type="submit" className="btn">
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        )}











                    </div>
                </div>
            </div>
            {/* navbar start */}
            <section className="vendor-header-section">
                <div className="container">

                    <nav className="navbar navbar-expand-lg p-0">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page"  to={`/vendor-store/${company.slug}`}>Home</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Products
                                    </Link>
                                    <ul className="dropdown-menu vendor-dropdown m-0">
                                        {
                                            products && products.length > 0 ? (products?.slice(0, 6).map((item) => {
                                                return (
                                                    <>
                                                        <li><Link className="dropdown-item" to="#">{item.name}</Link></li>

                                                    </>
                                                )
                                            })) : ("")


                                        }

                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Profile
                                    </Link>
                                    <ul className="dropdown-menu vendor-dropdown m-0">
                                    <li><Link className="dropdown-item" to={`/vendor-profile/${company.slug}`}>Company Overview</Link></li>
                                        <li><Link className="dropdown-item" to="#">Rating & Reviews</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link " aria-current="page"  to={`/vendor-contact/${company.slug}`}>Contact</Link>
                                </li>


                            </ul>
                            {/* <form role="search" className="pb-3 pb-lg-0">
                    <input className="form-control me-2" type="search" placeholder="Search in this store" aria-label="Search"/>
                </form> */}
                        </div>
                    </nav>

                </div>
            </section>
            {/* navbar end */}



            {/* <!--############################### BANNER-SECTION-START ###############################--> */}

            <section className="marquee-section pt-5">


            </section>
            {/* <!--############################### MARQUEE-SECTION-END ###############################-->

<!--############################### CATEGORY-SECTION-START ###############################--> */}
            <div className='container'>
                <div className='contact_main_vendor'>

                    <div className='contact_vendor'>
                        <div className='contact_vendor_first'>
                            <h2 className='company_contact'>Company contact information</h2>
                            <div className='row vendor_profile'>
                                <div className='col-lg-1 vendor_img'>
                                  <img src={Proimg}/>
                                </div>
                                <div className='col-lg-11 vendor_name'>
                                    <h2>{company?.contact_name}</h2>
                                    <p>{company?.contact_designation}</p>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <p>Company phone: {company?.contact_phone}</p>
                                    <p>Company mobile: {company?.contact_mobile}</p>
                                </div>
                                <div className='col-lg-6'>
                                    <p>Company fax: {company?.contact_fax}</p>
                                    <p>Company website: {company?.website}</p>
                                </div>
                            </div>
                        </div>

                        <div className='company_location'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <h2>Company Location</h2>
                                </div>
                                <div className='d-flex justify-content-end col-lg-6'>
                                    <p>by {company?.name}</p>
                                </div>
                            </div>
                            <div className='lcation_main'>
                                <p>{company.operation_city}, {company.operation_zip}, {company.opertaion_steet}, {company.operation_country}</p>
                            </div>
                        </div>

                    </div>


                    <div className='vendor_chat'>
                        <h2>Contact supplier</h2>
                        <div className='company_name'>
                            <p>{company?.name}</p>
                        </div>
                        <div className='btns_two'>
                            <button onClick={getChat}>Chat now</button>
                        </div>
                        <div className='btn_inquiry'>
                            <button>Send inquiry</button>
                        </div>
                        <div className='call_us'>
                            <button>Call us</button>
                        </div>
                    </div>
                </div>


            </div>



            <Footer />
        </>
    )
}

export default VendorContact
