import React, { useState, useEffect } from 'react'
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "../common/sidebar";
import Placeimg from "../../images/img-placeholder.svg"
import { ToastContainer, toast } from 'react-toastify';
import DataService from '../../services/data.service';
import VideoImg from "../../images/video.png"
const styles = {
    input: {
        opacity: '0%', // dont want to see it
        position: 'absolute' // does not mess with other elements 
    }
}
const UploadVideo = () => {

    const inputFileVideoRef = React.useRef();
    const [video, setVideo] = useState(null);
    const videoRef = React.useRef();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [store, setStore] = useState({})

    const triggerVideoFile = () => {
        /*Collecting node-element and performing click*/
        inputFileVideoRef.current.click();
    };
    const onFileVideoChangeCapture = (e) => {
        /*Selected files data can be collected here.*/
        setVideo(e.target.files)
        videoRef.current.src = VideoImg;
    };




    const getStore = async () => {
        await DataService.getVendor()
            .then((data) => {
                setStore(data?.data?.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setMessage(error?.response?.data?.msg);
            });
    };
    console.log(store.id)

    useEffect(() => {
        getStore();

    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        const data = new FormData();
        if (video && video.length > 0) {
            data.append('video', video[0])
        }
        DataService.uploadVideo(store.id, data).then(
            () => {
                setLoading(false);
                toast.success('Video Uploaded', {
                    position: toast.POSITION.TOP_RIGHT
                });
                window.location.reload();

                setLoading(false);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.msg) ||
                    error.message ||
                    error.toString();
                setLoading(false);
                toast.error(resMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        );
    }



    return (
        <>
            <div className="bg-grey h-100">
                <Header />

                <section className="content-area mt-4">
                    <Sidebar />
                    <div className="Right-content">
                        <div className="main-content">
                            <div className='main_upload_video'>
                                <h2>Company overview video</h2>
                                <form onSubmit={handleSubmit}>

                                    <div className="card mb-4">
                                        <div className="card-body text=center">
                                            <h4 className="f-700">Video</h4>
                                            <div className="Product-thumbnail" onClick={triggerVideoFile}>
                                                <img style={{ width: '100%' }} src={Placeimg} ref={videoRef} />
                                            </div>
                                            <p className="text-center">Set the company overview video. Only
                                                *.mp4, *.mov and *.avi video files are accepted</p>
                                        </div>
                                        <input
                                            type="file"
                                            ref={inputFileVideoRef}
                                            style={styles.input}
                                            onChangeCapture={onFileVideoChangeCapture}
                                        />
                                    </div>
                                    <div className='save_video'>
                                        <button className="btn btn-primary" type='submit'>Save video</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </section>


            </div>

        </>
    )
}

export default UploadVideo