import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import cat_img1 from "../../../images/cat-1.png"
import cat_img2 from "../../../images/cat-2.png"
import cat_img3 from "../../../images/cat-3.png"
import cat_img4 from "../../../images/cat-4.png"
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "../../../customCss/slick.css"
import CatImg7 from '../../../images/watch.png'
import "../../../customCss/slick.css"


function TrendSec(props) {

  return (
    <div>
      <section className="trend-section py-5">
        <div className="container">

          <div className="trend-head mb-3 d-flex justify-content-between">
            <Link to="">Sub category</Link>
            {/* <Link to="">View more</Link> */}
          </div>
          <section className="source-category">
                <div className="container">
                    <div className='source_inner'>
                    </div>
                   
                    <div className='item_maincat'>
                        <div className='item_maincatinner'>
                            {props.filterData[0] && props.filterData[0].Categories.length > 0 ? props.filterData[0].Categories.map((item, index) => (
                                <div className="item_maincat_item">
                                 
                                    <img src={CatImg7} alt="Category-img" />
                                    <h4 class="text-center slide-title mt-2 fw-400">{item.name}</h4>
                                </div>
                            )) :
                                <p>No data found</p>
                            }
                        </div>
                    </div>
                </div>
            </section>
      
        </div>
      </section>
    </div>
  )
}

export default TrendSec
