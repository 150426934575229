import React from 'react'
import { Link } from 'react-router-dom';
import "../UserProfileCss/MemberProfile.css";
function MemberProfile() {
  return (
    <div>
      <section className="edit-profile py-5">
    <div className="container">

        <div className="row m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="edit-head border-bottom pb-2 mb-4">
                    <h4>Edit Member Profile</h4>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Your Member ID:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p>in19036600679fruo</p>
                        </div>
                    </div>
                </div>
                <div className="row mb-md-0 mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Name:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p>Navjot Kaur</p>
                        </div>
                    </div>
                </div>
                <div className="de-activate-btn mb-md-0 mb-3 text-md-end">
                    <Link to="">Deactivate Account</Link>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Gender:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Email Address:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p>nav***@gmail.com <span>(Email address confirmed)</span></p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Alternative Email Address:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Contact Address:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p>India</p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Zip/Postal Code:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Tel:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p>91 91-******8400</p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Fax:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p>91</p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Mobile:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Department:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="row mb-3 align-items-baseline">
                    <div className="col-md-3 text-md-end">
                        <div className="label">
                            <h6>Job Title:</h6>
                        </div>
                    </div>
                    <div className="col-md-8 p-md-0">
                        <div className="label-info">
                            <p></p>
                        </div>
                    </div>
                </div>
                <div className="last-btn text-center">
                    <Link to="/user-profile/edit-member-profile">Edit</Link>
                </div>
            </div>    
        </div>

    </div>
</section>
    </div>
  )
}

export default MemberProfile
