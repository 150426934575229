import React, {useState, useEffect} from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import UserProfileMain from "./component/UserProfileLayout/UserProfilePage/UserProfileMain";
import "../customCss/UserProfile.css";
import DataService from "../services/data.service";
import UserProfileBottom from "./component/UserProfileLayout/UserProfilePage/UserProfileBottom";
import { toast } from 'react-toastify';


function UserProfile() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  React.useEffect(() => {
      document.title = "Profile";
      getData();
}, []);


const getData = () => {
  DataService.getUserDetail().then((data) => {
      setData(data.data.data);
      setLoading(false);
  }).catch((error)=>{
      const resMessage =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();


     setLoading(false);
      toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT
      });
  });
  console.log(data)
}
  return (
    <>
      <div>
        <Header />
      </div>
      <section className="profile-section">
        <div className="container">
          <div className="row justify-content-center py-4 m-0">
            <div className="col-md-8 bd_fo_white">
              <UserProfileMain />
              <UserProfileBottom />
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default UserProfile;
