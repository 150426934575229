import React from 'react';
import Logo1 from "../images/logo.jpg";
import Logo2 from "../images/logo2.jpg";
import Header from '../common/Header';
import Footer from "../common/Footer";
import { Link } from 'react-router-dom';
import "../customCss/Profile.css";
function ViewProfile() {
  return (
    <>
    <Header/>
  <div className='main_profile pt-4'>
<div className='cont_ainer'>
      <div className="container body_context part_1">
        <div className="container-fulid img-sec">
          <div className="img_head"></div>
        </div>
        <div className="img_profile"></div>
        <div className="profile_content d-flex mt-5 mx-3 justify-content-between">
          <div className="profile_inner">
            <h2>Raj Sharma <span className="profile_span">-3rd</span></h2>

            <p>Developer at Bepositive</p>
            <p>
              <span className="profile_span">Rajesthan , India</span>
              <span className="profile_span contact ps-3">Contact info</span>
            </p>
            <p><span className="profile_span">10 followrs . 6 connections</span></p>
          </div>
          <div className="button_part">
            <button className="btn btn-outline-secondary part_2_btn btn_radii">
              <i className="fa-solid fa-cart-shopping pe-2"></i> Store
            </button>
          </div>
        </div>
        <div className="buttons_part1 ps-4 mb-4">
          <button className="btn btn-primary ms-2 btn_radii" >
            <i className="fa-solid fa-plus pe-2"></i> Follow
          </button>
          <button className="btn btn-outline-primary ms-2 btn_radii">
            <i className="fa-solid fa-lock pe-2"></i>Message
          </button>
          <button className="btn btn-outline-secondary ms-2 btn_radii">More</button>
        </div>
      </div>

      <div className="container body_context part_2 mt-3">
        <div className="info-2 ps-4 mt-3">
          <h2>Activity</h2>
          <p><span className="profile_span">10 followers</span></p>
          <h6 className="mt-3">Raj hasn't posted yet</h6>
          <p>Recent posts Raj shares will be desplayed here</p>
        </div>
        <div className="showmore text-center py-2">
          <Link to="/"
          >Show all Activity <i className="fa-solid fa-arrow-right ps-3"></i
          ></Link>
        </div>
      </div>
      <div className="container part_3 mt-3 body_context">
        <h2 className="ms-3 mt-3">Skills</h2>
        <div className="skills_info">
          <p className="sk1 py-2 mx-3">MySQL</p>
          <p className="sk1 py-2 mx-3">PHP</p>
          <p className="py-2 sk2 mx-3">ReactNative</p>
        </div>
        <div className="showmore text-center py-2">
          <Link to="/"
          >Show all 8 Skills<i className="fa-solid fa-arrow-right ps-3"></i
          ></Link>
        </div>
      </div>
      <div className="container part_4  body_context mt-3">
        <h2 className="ms-3 mt-3">Interest</h2>
        <div className="info-4">
          <div className="info_inner text-center ms-4">
            <h6>Company</h6>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-5 mx-2">
            <div className="content d-flex">
              <div className="comp_logo">
                <img src={Logo1} alt="logo1" height="100px" />
              </div>
              <div className="comp_content">
                <h5>Company 1</h5>
                <p><span className="profile_span">986 followers</span></p>
                <div className="button_part2 my-2">
                  <button className="btn btn-outline-secondary part_2_btn">
                    <i className="fa-solid fa-plus pe-2"></i> Follow
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 mx-2">
            <div className="content d-flex">
              <div className="comp_logo">
                <img src={Logo2} height="100px" alt="logo2" />
              </div>
              <div className="comp_content">
                <h5>Company 1</h5>
                <p><span className="profile_span">1080 followers</span></p>
                <div className="button_part2 my-2">
                  <button className="btn btn-outline-secondary part_2_btn">
                    <i className="fa-solid fa-plus pe-2"></i> Follow
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="showmore text-center py-2">
          <Link to="/"
          >Show all Activity <i className="fa-solid fa-arrow-right ps-3"></i
          ></Link>
        </div>
      </div>
      </div>
      </div>  
      <Footer/>
    </>
  )
}

export default ViewProfile
