import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import userimg from "../../../images/user.png"
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
const Reviews = () => {
  return (
    <div className="container">
    <section class="choose py-5">
        <div class="container-fluid h-100">
            <div className="section-title mb-3">
                <p className="m-0">WHY CHOOSE US</p>
                {/* <h4>Most valuable brands<br /> are available</h4> */}
            </div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="review-inner p-4">
                        <h4>“Excellent Work”</h4>
                        <svg width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.61273 15.4427C3.22629 15.6413 2.78872 15.2942 2.86652 14.8508L3.69625 10.1213L0.173916 6.76462C-0.155264 6.45092 0.0151358 5.87737 0.456125 5.81472L5.3546 5.11885L7.53872 0.792305C7.73547 0.402565 8.26844 0.402565 8.46519 0.792305L10.6493 5.11885L15.5478 5.81472C15.9888 5.87737 16.1592 6.45092 15.83 6.76462L12.3077 10.1213L13.1374 14.8508C13.2152 15.2942 12.7776 15.6413 12.3912 15.4427L8.00195 13.1868L3.61273 15.4427Z" fill="#27454A"/>
                            <path d="M24.6127 15.4427C24.2263 15.6413 23.7887 15.2942 23.8665 14.8508L24.6962 10.1213L21.1739 6.76462C20.8447 6.45092 21.0151 5.87737 21.4561 5.81472L26.3546 5.11885L28.5387 0.792305C28.7355 0.402565 29.2684 0.402565 29.4652 0.792305L31.6493 5.11885L36.5478 5.81472C36.9888 5.87737 37.1592 6.45092 36.83 6.76462L33.3077 10.1213L34.1374 14.8508C34.2152 15.2942 33.7776 15.6413 33.3912 15.4427L29.002 13.1868L24.6127 15.4427Z" fill="#27454A"/>
                            <path d="M45.6127 15.4427C45.2263 15.6413 44.7887 15.2942 44.8665 14.8508L45.6962 10.1213L42.1739 6.76462C41.8447 6.45092 42.0151 5.87737 42.4561 5.81472L47.3546 5.11885L49.5387 0.792305C49.7355 0.402565 50.2684 0.402565 50.4652 0.792305L52.6493 5.11885L57.5478 5.81472C57.9888 5.87737 58.1592 6.45092 57.83 6.76462L54.3077 10.1213L55.1374 14.8508C55.2152 15.2942 54.7776 15.6413 54.3912 15.4427L50.002 13.1868L45.6127 15.4427Z" fill="#27454A"/>
                            <path d="M66.6127 15.4427C66.2263 15.6413 65.7887 15.2942 65.8665 14.8508L66.6962 10.1213L63.1739 6.76462C62.8447 6.45092 63.0151 5.87737 63.4561 5.81472L68.3546 5.11885L70.5387 0.792305C70.7355 0.402565 71.2684 0.402565 71.4652 0.792305L73.6493 5.11885L78.5478 5.81472C78.9888 5.87737 79.1592 6.45092 78.83 6.76462L75.3077 10.1213L76.1374 14.8508C76.2152 15.2942 75.7776 15.6413 75.3912 15.4427L71.002 13.1868L66.6127 15.4427Z" fill="#27454A"/>
                            <path d="M89.3546 5.11885L91.5387 0.792305C91.6368 0.597985 91.8185 0.500551 92.0004 0.500002C92.1834 0.499451 92.3665 0.596887 92.4652 0.792307L94.6493 5.11885L99.5478 5.81472C99.8149 5.85267 99.9828 6.0781 100 6.31968C100.002 6.34769 100.002 6.37593 100 6.40411C99.9908 6.53408 99.9366 6.66299 99.83 6.76462L96.3077 10.1213L97.1374 14.8508C97.2152 15.2942 96.7776 15.6413 96.3912 15.4427L92.002 13.1868L87.6127 15.4427C87.5644 15.4675 87.5153 15.4838 87.4665 15.4925C87.1249 15.5531 86.7984 15.2388 86.8665 14.8508L87.6962 10.1213L84.1739 6.76462C84.0556 6.65189 84.0018 6.50559 84.002 6.36151C84.002 6.25501 84.0316 6.14972 84.0862 6.06009C84.1634 5.93355 84.2908 5.83821 84.4561 5.81472L89.3546 5.11885ZM92.0004 12.0265C92.0799 12.0263 92.1595 12.045 92.2328 12.0826L95.9185 13.977L95.2243 10.0197C95.1916 9.83316 95.2531 9.64214 95.387 9.5145L98.2939 6.7444L94.2416 6.16874C94.074 6.14494 93.9278 6.03795 93.8487 5.88141L92.002 2.22308L92.0004 2.22614V12.0265Z" fill="#27454A"/>
                        </svg>
                        <p>
                        I am very impressed with Shopio. They provide my company with a product of
                        superb quality at a great cost. We look forward to a long relationship.
                        </p>
                        <div className="d-flex align-items-center gap-3 p-3">
                            <div className="user-img">
                                <img src={userimg} />
                            </div>
                            <p className="m-0">
                            BRUCE JONES<br /> Boston, LA
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="review-inner2 p-4">
                        <h4>“Excellent Work”</h4>
                        <svg width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.61273 15.4427C3.22629 15.6413 2.78872 15.2942 2.86652 14.8508L3.69625 10.1213L0.173916 6.76462C-0.155264 6.45092 0.0151358 5.87737 0.456125 5.81472L5.3546 5.11885L7.53872 0.792305C7.73547 0.402565 8.26844 0.402565 8.46519 0.792305L10.6493 5.11885L15.5478 5.81472C15.9888 5.87737 16.1592 6.45092 15.83 6.76462L12.3077 10.1213L13.1374 14.8508C13.2152 15.2942 12.7776 15.6413 12.3912 15.4427L8.00195 13.1868L3.61273 15.4427Z" fill="#27454A"/>
                            <path d="M24.6127 15.4427C24.2263 15.6413 23.7887 15.2942 23.8665 14.8508L24.6962 10.1213L21.1739 6.76462C20.8447 6.45092 21.0151 5.87737 21.4561 5.81472L26.3546 5.11885L28.5387 0.792305C28.7355 0.402565 29.2684 0.402565 29.4652 0.792305L31.6493 5.11885L36.5478 5.81472C36.9888 5.87737 37.1592 6.45092 36.83 6.76462L33.3077 10.1213L34.1374 14.8508C34.2152 15.2942 33.7776 15.6413 33.3912 15.4427L29.002 13.1868L24.6127 15.4427Z" fill="#27454A"/>
                            <path d="M45.6127 15.4427C45.2263 15.6413 44.7887 15.2942 44.8665 14.8508L45.6962 10.1213L42.1739 6.76462C41.8447 6.45092 42.0151 5.87737 42.4561 5.81472L47.3546 5.11885L49.5387 0.792305C49.7355 0.402565 50.2684 0.402565 50.4652 0.792305L52.6493 5.11885L57.5478 5.81472C57.9888 5.87737 58.1592 6.45092 57.83 6.76462L54.3077 10.1213L55.1374 14.8508C55.2152 15.2942 54.7776 15.6413 54.3912 15.4427L50.002 13.1868L45.6127 15.4427Z" fill="#27454A"/>
                            <path d="M66.6127 15.4427C66.2263 15.6413 65.7887 15.2942 65.8665 14.8508L66.6962 10.1213L63.1739 6.76462C62.8447 6.45092 63.0151 5.87737 63.4561 5.81472L68.3546 5.11885L70.5387 0.792305C70.7355 0.402565 71.2684 0.402565 71.4652 0.792305L73.6493 5.11885L78.5478 5.81472C78.9888 5.87737 79.1592 6.45092 78.83 6.76462L75.3077 10.1213L76.1374 14.8508C76.2152 15.2942 75.7776 15.6413 75.3912 15.4427L71.002 13.1868L66.6127 15.4427Z" fill="#27454A"/>
                            <path d="M89.3546 5.11885L91.5387 0.792305C91.6368 0.597985 91.8185 0.500551 92.0004 0.500002C92.1834 0.499451 92.3665 0.596887 92.4652 0.792307L94.6493 5.11885L99.5478 5.81472C99.8149 5.85267 99.9828 6.0781 100 6.31968C100.002 6.34769 100.002 6.37593 100 6.40411C99.9908 6.53408 99.9366 6.66299 99.83 6.76462L96.3077 10.1213L97.1374 14.8508C97.2152 15.2942 96.7776 15.6413 96.3912 15.4427L92.002 13.1868L87.6127 15.4427C87.5644 15.4675 87.5153 15.4838 87.4665 15.4925C87.1249 15.5531 86.7984 15.2388 86.8665 14.8508L87.6962 10.1213L84.1739 6.76462C84.0556 6.65189 84.0018 6.50559 84.002 6.36151C84.002 6.25501 84.0316 6.14972 84.0862 6.06009C84.1634 5.93355 84.2908 5.83821 84.4561 5.81472L89.3546 5.11885ZM92.0004 12.0265C92.0799 12.0263 92.1595 12.045 92.2328 12.0826L95.9185 13.977L95.2243 10.0197C95.1916 9.83316 95.2531 9.64214 95.387 9.5145L98.2939 6.7444L94.2416 6.16874C94.074 6.14494 93.9278 6.03795 93.8487 5.88141L92.002 2.22308L92.0004 2.22614V12.0265Z" fill="#27454A"/>
                        </svg>
                        <p>
                        I am very impressed with Shopio. They provide my company with a product of
                        superb quality at a great cost. We look forward to a long relationship.
                        </p>
                        <div className="d-flex align-items-center gap-3 p-3">
                            <div className="user-img">
                                <img src={userimg} />
                            </div>
                            <p className="m-0">
                            BRUCE JONES<br /> Boston, LA
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="review-inner3 p-4">
                        <h4>“Excellent Work”</h4>
                        <svg width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.61273 15.4427C3.22629 15.6413 2.78872 15.2942 2.86652 14.8508L3.69625 10.1213L0.173916 6.76462C-0.155264 6.45092 0.0151358 5.87737 0.456125 5.81472L5.3546 5.11885L7.53872 0.792305C7.73547 0.402565 8.26844 0.402565 8.46519 0.792305L10.6493 5.11885L15.5478 5.81472C15.9888 5.87737 16.1592 6.45092 15.83 6.76462L12.3077 10.1213L13.1374 14.8508C13.2152 15.2942 12.7776 15.6413 12.3912 15.4427L8.00195 13.1868L3.61273 15.4427Z" fill="#27454A"/>
                            <path d="M24.6127 15.4427C24.2263 15.6413 23.7887 15.2942 23.8665 14.8508L24.6962 10.1213L21.1739 6.76462C20.8447 6.45092 21.0151 5.87737 21.4561 5.81472L26.3546 5.11885L28.5387 0.792305C28.7355 0.402565 29.2684 0.402565 29.4652 0.792305L31.6493 5.11885L36.5478 5.81472C36.9888 5.87737 37.1592 6.45092 36.83 6.76462L33.3077 10.1213L34.1374 14.8508C34.2152 15.2942 33.7776 15.6413 33.3912 15.4427L29.002 13.1868L24.6127 15.4427Z" fill="#27454A"/>
                            <path d="M45.6127 15.4427C45.2263 15.6413 44.7887 15.2942 44.8665 14.8508L45.6962 10.1213L42.1739 6.76462C41.8447 6.45092 42.0151 5.87737 42.4561 5.81472L47.3546 5.11885L49.5387 0.792305C49.7355 0.402565 50.2684 0.402565 50.4652 0.792305L52.6493 5.11885L57.5478 5.81472C57.9888 5.87737 58.1592 6.45092 57.83 6.76462L54.3077 10.1213L55.1374 14.8508C55.2152 15.2942 54.7776 15.6413 54.3912 15.4427L50.002 13.1868L45.6127 15.4427Z" fill="#27454A"/>
                            <path d="M66.6127 15.4427C66.2263 15.6413 65.7887 15.2942 65.8665 14.8508L66.6962 10.1213L63.1739 6.76462C62.8447 6.45092 63.0151 5.87737 63.4561 5.81472L68.3546 5.11885L70.5387 0.792305C70.7355 0.402565 71.2684 0.402565 71.4652 0.792305L73.6493 5.11885L78.5478 5.81472C78.9888 5.87737 79.1592 6.45092 78.83 6.76462L75.3077 10.1213L76.1374 14.8508C76.2152 15.2942 75.7776 15.6413 75.3912 15.4427L71.002 13.1868L66.6127 15.4427Z" fill="#27454A"/>
                            <path d="M89.3546 5.11885L91.5387 0.792305C91.6368 0.597985 91.8185 0.500551 92.0004 0.500002C92.1834 0.499451 92.3665 0.596887 92.4652 0.792307L94.6493 5.11885L99.5478 5.81472C99.8149 5.85267 99.9828 6.0781 100 6.31968C100.002 6.34769 100.002 6.37593 100 6.40411C99.9908 6.53408 99.9366 6.66299 99.83 6.76462L96.3077 10.1213L97.1374 14.8508C97.2152 15.2942 96.7776 15.6413 96.3912 15.4427L92.002 13.1868L87.6127 15.4427C87.5644 15.4675 87.5153 15.4838 87.4665 15.4925C87.1249 15.5531 86.7984 15.2388 86.8665 14.8508L87.6962 10.1213L84.1739 6.76462C84.0556 6.65189 84.0018 6.50559 84.002 6.36151C84.002 6.25501 84.0316 6.14972 84.0862 6.06009C84.1634 5.93355 84.2908 5.83821 84.4561 5.81472L89.3546 5.11885ZM92.0004 12.0265C92.0799 12.0263 92.1595 12.045 92.2328 12.0826L95.9185 13.977L95.2243 10.0197C95.1916 9.83316 95.2531 9.64214 95.387 9.5145L98.2939 6.7444L94.2416 6.16874C94.074 6.14494 93.9278 6.03795 93.8487 5.88141L92.002 2.22308L92.0004 2.22614V12.0265Z" fill="#27454A"/>
                        </svg>
                        <p>
                        I am very impressed with Shopio. They provide my company with a product of
                        superb quality at a great cost. We look forward to a long relationship.
                        </p>
                        <div className="d-flex align-items-center gap-3 p-3">
                            <div className="user-img">
                                <img src={userimg} />
                            </div>
                            <p className="m-0">
                            BRUCE JONES<br /> Boston, LA
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
};

export default Reviews;