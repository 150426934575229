import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Cart_Product from "../images/Cart_product.jpg"
import Product_gif from "../images/paymentgf.gif"
import visa from "../images/visa.svg"
import master from "../images/master.svg"
import tt from "../images/tt.svg"
import paypal from "../images/paypal.svg"
import pay from "../images/pay.svg"
import gpay from "../images/gpay.svg"
import online from "../images/online.svg"
import "../customCss/PaymentPage.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollarToSlot, faLocationDot, faHouse, faCommentDots, faCircleExclamation, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons';
import Myaddresses from './address/myaddresses'
import DataService from '../services/data.service'
import "../customCss/ProfileData.css"
import Header from '../common/Header'
import Footer from '../common/Footer'
function PaymentPage() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [buynow, setBuynow] = useState(false);

  useEffect(() => {
    getAddress()
  }, [])

  const getAddress = async () => {
    setLoading(true)
    await DataService.getAddress().then((data) => {
      setLoading(false);
      setData(data?.data?.data)

    }).catch((error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.msg) ||
        error.message ||
        error.toString();
      setLoading(false);
    })
  }
  return (
    <>
      <Header />
      <div>
        <div className="container my-5">
          <div className="payment_body my-5">
            <div className="row">
              <div className="col-md-12 mb-md-0 mb-3">
                <div className="payment_left_top bg-white rounded-1 p-3 mb-3">
                  <div className="payment_left_top-popup">
                    {/* <!-- Button trigger modal --> */}
                    <div className="payment_left_top-popup-btn py-4 text-center">
                      {loading ?
                        <div className="col-lg-6 m-auto">
                          {loading && (
                            <div className="main_spinner">
                              <div class="spinner-border text-warning" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                          )}
                        </div>
                        : <Myaddresses data={data} buynow={buynow} />
                      }
                      {/* <button type="button" className="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  <FontAwesomeIcon icon={faLocationDot} />Add an address
                  </button> */}
                    </div>
                    {/* 
                <!-- Modal --> */}
                    {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                      <div className="modal-header payment-popup-head">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Edit address</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body payment-popup-body">
                        
                        <form className="p-lg-4 p-2">
                          <div className="row align-items-center mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>Country/Region</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="payment-form-input">
                                <select className="form-select" aria-label="Default select example">
                                  <option defaultValue>Open this select menu</option>
                                  <option defaultValue="1">One</option>
                                  <option defaultValue="2">Two</option>
                                  <option defaultValue="3">Three</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>Address Line 1</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="payment-form-input">
                                <input type="text" className="form-control" placeholder="Street Address, PO box,etc" />
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>Address Line 2</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="payment-form-input">
                                <input type="text" className="form-control" placeholder="Appartment,suite,unit,etc(optional)" />
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>State/Province</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="payment-form-input">
                                <input type="text" className="form-control" placeholder="Please select a State/Province/Region" />
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>City</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="payment-form-input">
                                <input type="text" className="form-control" placeholder="Please enter a City" />
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>Zip Code</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="payment-form-input">
                                <input type="text" className="form-control" placeholder="Please enter a ZIP/Postal Code" />
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>Contact Name</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="payment-form-input">
                                <input type="text" className="form-control" placeholder="Please enter a Contact Name" />
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>Phone Number</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="payment-form-input">
                                    <input type="text" className="form-control mb-2" placeholder="Country Code" />
                                  </div>
                                </div>
                                <div className="col-lg-8">
                                  <div className="payment-form-input">
                                    <input type="text" className="form-control" placeholder="Phone No." />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-baseline mb-3">
                            <div className="col-lg-3 px-lg-2 p-0 text-lg-end">
                              <div className="payment-form-label">
                                <label>Tag</label>
                              </div>
                            </div>
                            <div className="col-lg-9 p-0">
                              <div className="payment-form-input d-md-flex">
                                <h6>BUSINESS</h6>
                                <h6>FACTORY</h6>
                                <h6>WAREHOUSE</h6>
                                <h6>RESIDENTIAL</h6>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="modal-footer payment-popup-footer justify-content-start">
                        <button type="button" className="btn">Submit</button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div> */}
                  </div>

                </div>

                {/* <div className="payment_left_down bg-white rounded-1">
              <div className="payment_down_part1 border-bottom d-flex justify-content-between align-items-center
               py-4 mx-md-4">
                <div className="payment_down_1 d-flex align-items-center">
                  <div className="payment_select me-2 py-3">
                    <span></span>
                  </div>
                  <div className="payment_part1_content_body">
                    <div className="payment_part1_content">
                      <p className="m-0">
                        <span><FontAwesomeIcon icon={faHouse} /></span> 
                        Shenzhen Quanyaohui Technology Co., Ltd.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="payment_chat float-end">
                  <span></span>
                  <span><FontAwesomeIcon icon={faCommentDots} />Chat Now!</span>
                </div>
              </div>
              <div className="payment_down_2">
                <div className="row pt-4 px-md-4 px-3">
                  <div className="col-md-2 ps-2 d-md-flex">
                    <div className="payment_product_img">
                      <img
                        src={Cart_Product}
                        alt=""
                        width="100%"
                      />
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="payment_down_content">
                      <div className="payment_down_content1 mb-2">
                        <div className="payment_time_garunty">
                          <span> On-time Dispatch Guarantee</span>
                        </div>
                        <div
                          className="payment_product_name"
                        >
                          <p className="text-truncate">
                            2022 X8 smartwatch with magnetic charging rotate
                            button 1.75 inch largescreen waterproof IP67 Pro
                            plus Reloj smart watch X8 Max
                          </p>
                        </div>
                        <span className="payment_offer rounded-1">
                          <span className="offer_dis">38% OFF </span>
                          <span>Discount ends in 20d : 21h : 11m : 50s</span>
                        </span>
                      </div>
                      <div className="payment_down_content_2">
                        <div className="row">
                          <div className="col-md-4 drop_sec">
                            <div className="card_dropdown">
                              <div className="drodown_item d-flex align-items-baseline">
                                <h2>color:</h2>
                                <h3>black</h3>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 price_sec">
                            
                              <span className="piece_down_left">USD 4.89 </span><span className="piece_down_right">/Piece </span> 
                                <br/>
                            <span className=""> <del className="piece_down_right">USD 7.88 /Piece</del></span>
                          </div>
                          <div className="col-md-2  button_sec_payment">
                            <div className="button_all d-flex justify-content-center ">
                              <button>-</button>
                              <input
                                type="text"
                                defaultValue="1"
                               
                              />
                              <button>+</button>
                            </div>
                          </div>
                          <div className="col-md-2 total_payment_price">
                            <div className="payment_total_price">
                              <span className="piece_price_payment">USD 4.89</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="payment-price-footer-main">
                  <div className="payment-price-footer d-flex align-items-baseline justify-content-end">
                    <h5>Item(S) total:</h5>
                    <h6>USD 4.89</h6>
                  </div>
                  <div className="payment-price-footer text-end">
                    <h4>USD 2.99 saved</h4>
                  </div>
                </div>
              </div>
            </div> */}
                {/* <div className="payment_left_down bg-white rounded-1">
              <div className="row logistic-row mt-3 m-0 bg-white p-md-3 p-2">
                <div className="logistic-head border-bottom p-0">
                  <h2><FontAwesomeIcon icon={faCircleExclamation} />Logistics information to be confirmed chat with the supplier</h2>
                </div>
                <div className="row py-4 p-0">
                  <div className="col-md-4">
                    <div className="logistic-label">
                      <h2>Shipping methods</h2>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="logistic-data">
                      <h2><FontAwesomeIcon icon={faPlaneDeparture} />Express</h2>
                    </div>
                  </div>
                </div>
                <div className="row p-0">
                  <div className="col-md-4">
                    <div className="logistic-label">
                      <h2>Delivery timeline</h2>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="logistic-payment-main d-flex justify-content-between">
                      <div className="logistic-payment">
                        <h5>Payment</h5>
                      </div>
                      <div className="logistic-payment">
                        <h5>Dispatch</h5>
                      </div>
                      <div className="logistic-payment">
                        <h5>Received</h5>
                      </div>
                    </div>
                    <div className="logistic-payment-main d-flex justify-content-between align-items-center">
                      <div className="logistic-dot"></div>
                      <div className="logistic-line"></div>
                      <div className="logistic-dot"></div>
                      <div className="logistic-line"></div>
                      <div className="logistic-dot"></div>
                    </div>
                    <div className="logistic-payment-time mt-2 d-flex justify-content-evenly align-items-baseline">
                      <h5>Lead time 6 days</h5>
                      <h4>CN</h4>
                      <h6>Delivery date to be confirmed</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-price-footer-main">
                <div className="payment-price-footer d-flex align-items-baseline justify-content-end">
                  <h6>Shipping fee:</h6>
                  <h6>To Be Negotiated</h6>
                </div>
              </div>
            </div>    */}
                {/* <div className="payment_left_down bg-white rounded-1">
              <div className="row logistic-row mt-3 m-0 bg-white p-md-3 p-2">
                <div className="logistic-head border-bottom p-0">
                  <h2><FontAwesomeIcon icon={faCircleExclamation} />
                    The transaction fee varies depending on the payment method chosen.
                    <Link to="">Processing time and fees</Link>
                  </h2>
                </div>
                <div className="row py-4 p-0">
                  <div className="col-md-4">
                    <div className="logistic-label">
                      <h2>Payment methods</h2>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="logistic-data">
                      <img src={visa} alt="IMG" />
                      <img src={master} alt="IMG" />
                      <img src={tt} alt="IMG" />
                      <img src={paypal} alt="IMG" />
                      <img src={pay} alt="IMG" />
                      <img src={gpay} alt="IMG" />
                      <img src={online} alt="IMG" />
                    </div>
                  </div>
                </div>
              </div>
            </div>   */}
                {/* <div className="payment_left_down bg-white rounded-1">
              <div className="row logistic-row mt-3 m-0 bg-white p-md-3 p-2">
                <div className="col-md-4">
                  <div className="logistic-label">
                    <h2>Additional notes (optional)</h2>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="logistic-data">
                    <textarea className="w-100" rows="3" placeholder="Please fill in"></textarea>
                    <div className="logistic-placeholder">
                      <h6>0/2000</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
                {/* <div className="payment_left_down bg-white rounded-1">
              <div className="row logistic-row mt-3 m-0 bg-white p-md-3 p-2">
                <div className="logistic-head-last d-flex justify-content-between">
                  <p>Protections on this order <span>with</span> Trade Assurance</p>

                  <button type="button" className="btn" data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop2">
                    ViewDetails	&#62;
                  </button>

                  <div
                    className="modal fade"
                    id="staticBackdrop2"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdrop2Label"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdrop2Label">
                            Protections on this order
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="top_modal_body">
                            <h2>Refund policy</h2>
                            <p>
                              Our refund policy protects you in case purchases don't meet the
                              agreed terms. Apply for a partial or full refund within 30 days
                              from the delivery date. Platinum Buyers and above can claim
                              within 60 days.
                            </p>
                            <p className="mt-3">
                              Quick refunds: Claim within 2 hours after payment for full and
                              immediate refunds.
                            </p>
                          </div>
                          <div className="bottom_modal_body">
                            <h3>How to apply for a refund</h3>

                            <div className="modal_steps">
                              <div className="row">
                                <div className="col-2">
                                  <div className="indicator">
                                    <span className="point point_1"></span>
                                    <div className="line"></div>
                                  </div>
                                </div>
                                <div className="col-10">
                                  <h5>Pay on DealIt.com</h5>
                                  <p>
                                    Pay using your preferred payment method through the
                                    DealIt.com platform.
                                  </p>
                                </div>
                                <div className="col-2">
                                  <div className="indicator">
                                    <span className="point"></span>
                                  </div>
                                </div>
                                <div className="col-10 how_to mb-3">
                                  <h5>
                                    Apply for refund if purchases don't meet the agreed terms
                                  </h5>
                                  <p>Go to My orders 	&#62; Order details to apply.</p>
                                  <img src={Product_gif} alt="GIF" />
                                </div>
                                <div className="col-2">
                                  <div className="indicator">
                                    <span className="point"></span>
                                  </div>
                                </div>
                                <div className="col-10">
                                  <h5>Negotiate with supplier</h5>
                                  <p>
                                    If the supplier does not respond or you are unable to
                                    reach a consensus, escalate the case for DealIt.com to
                                    help resolve.
                                  </p>
                                </div>
                                <div className="col-2">
                                  <div className="indicator">
                                    <span className="point"></span>
                                  </div>
                                </div>
                                <div className="col-10">
                                  <h5>Get your money back</h5>
                                  <p>Receive your refund after the case is processed.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="logistic-refund">
                  <h4><FontAwesomeIcon icon={faCircleDollarToSlot} className='me-3'/>Refund policy</h4>
                  <p>Refunds for missing,defective,or incorrect products in 30 days</p>
                </div>
              </div>
            </div> */}
                {/* <div className="form-check py-2">
              <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault" />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                I agree to share my business card with supplier
              </label>
            </div> */}
              </div>
              {/* <div className="col-md-4">
            <div className="payment_right_body rounded-1 shadow px-3 py-4">
              <h3>payment subtotal</h3>
              <hr />
              <div className="payment_total_items d-flex justify-content-between">
                <div className="payment_total_head">
                  <span className="payment_right_head">Item(s) total </span>
                </div>
                <div className="payment_total_items">
                  <span>USD 0</span>
                </div>
              </div>
              <hr />
              <div className="payment_total_price d-flex justify-content-between">
                <div className="payment_total_head">
                  <span className="payment_right_head">Cart total</span>
                </div>
                <div className="payment_total_price">
                  <span className="payment_price_span">USD 0</span>
                </div>
              </div>
              <div className="payment_extra mb-3">
                <span className="payment_right_head"
                  >(Excluding shipping fee and tax)</span>
              </div>
              <div className="payment_checkout">
              <Link to="#" className='btn btn_checkout' > Check out(0)</Link>
                
              </div>
            </div>
          </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  )
}

export default PaymentPage;
