import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import "../customCss/SubCatagory.css"
import slideimg2 from "../images/VendorImages/slideimg2.jpg"
import slideimg3 from "../images/VendorImages/slideimg1.jpg"
import slideimg4 from "../images/VendorImages/slideimg4.jpg"
import SubCatagoryVedio from "../images/sub_cat_vedio.mp4"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faStar } from '@fortawesome/free-solid-svg-icons';
import Header from '../common/Header'
import Footer from '../common/Footer'
import Vsub_cat1 from "../images/v-sub-cat1.png"
import Vsub_cat2 from "../images/v-sub-cat2.png"
import Vsub_cat3 from "../images/olevs.png"
import { toast } from 'react-toastify'
import DataService from '../services/data.service';
import AuthService from '../services/auth.service';
import HelperService from '../services/helper.service';

import Subcategory from "../pages/component/subcategory/Content"
import Banner from '../common/Banner';
function SubCatagory() {
  const navigate = useNavigate();
  const params = useParams();
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [masterCatData, setMasterCatData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [brand, setBrand] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [customBreadcrumb, setCustomBreadcrumb] = useState("");
  const [searchPay, setSearchPay] = useState({})
  const [queryData, setqueryData] = useState({
    mastCatId: "",
    catId: "",
    subCatId: "",
    brandId: ""
  });


  const auth = AuthService.getCurrentUser();
  React.useEffect(() => {
    document.title = "Product Page";
    getData()
  }, [params]);


  const addTowhislist = (event, item) => {
    if (auth) {
      var element = event.currentTarget;
      if (element.className == 'remove-cart') {
        //get cat id..
        if (item?.Carts) {
          DataService.deleteWishlist(item.id).then(
            () => {
              toast.success('Product removed from wishlist!', {
                position: toast.POSITION.TOP_RIGHT
              });
              element.classList.remove("remove-cart");
              element.checked = false;
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.msg) ||
                error.message ||
                error.toString();

              toast.error(resMessage, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          );
        }
      } else {
        const data = {};
        data.product_title = item.name
        data.price = item.offer_price
        data.product_sku = item.sku
        data.quantity = '1'
        data.variant = ''
        data.product_id = item.id
        data.type = 'whislist'
        DataService.addCart(data).then(
          () => {
            toast.success('Product added to whislist', {
              position: toast.POSITION.TOP_RIGHT
            });
            element.checked = true;
            element.classList.add("remove-cart");
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.msg) ||
              error.message ||
              error.toString();
            toast.error(resMessage, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        );
      }
    } else {
      navigate("/login");
    }
  }


  const addTocart = (event, item) => {
    const auth = AuthService.getCurrentUser();
    const data1 = {};
    data1.product_title = item.name
    data1.price = item.offer_price
    data1.product_sku = item.sku
    data1.quantity = (item.cart_qty) ? item.cart_qty : "1"
    data1.variant = (item.variation ? item.variation : (item.variant[0]?.variant) ? item.variant[0]?.variant : "")
    data1.product_id = item.id
    data1.type = 'cart'
    if (auth) {
      setLoading(true);
      DataService.addCart(data1).then(
        () => {
          setLoading(false);
          toast.success("Product added to cart!", {
            position: toast.POSITION.TOP_RIGHT
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.msg) ||
            error.message ||
            error.toString();

          setLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      );
    } else {
      setLoading(true);
      HelperService.setLocalCart(data1)
      setLoading(false);
      toast.success("Product added to cart!", {
        position: toast.POSITION.TOP_RIGHT
      });
      HelperService.updateCartCount()
    }
  }


  const getData = async () => {
    var cats = [];
    var brands = [];
    await DataService.getAllCategory('0').then((data) => {
      setMasterCatData(data.data.categories);
      cats = data.data.categories
    });
    await DataService.getAllBrand().then((data) => {
      setBrand(data.data.data);
      brands = data.data.data
    });
    await setIdFromSlug(cats, brands).then(async (data) => {
      await makeBreadcrumb(data, cats);
      await searchProduct({
        mastCatId: (data.mastCatId) ? (data.catId) ? [] : [data.mastCatId] : [],
        catId: (data.catId) ? (data.subCatId) ? [] : [data.catId] : [],
        subCatId: (data.subCatId) ? [data.subCatId] : [],
        brandId: (data.brandId) ? [data.brandId] : [],
        dates: [],
        keyword: (data.keyword) ? data.keyword : "",
        filter: (data.filter) ? data.filter : "",
      })
      setSearchPay(data)
      setLoading(false)
    })
  };


  const setIdFromSlug = async (cats, brands) => {
    const obj = {
      mastCatId: "",
      catId: "",
      subCatId: "",
      brandId: ""
    };


    //check if category..
    if (params.master && params.master.length > 0) {
      var ss = await Promise.all(cats.filter((item, i) => {
        return (item.slug == params.master)
      }))


      if (ss.length > 0) {
        obj.mastCatId = ss[0].id;

        ///filter brands...
        var newBrands = await Promise.all(brands.filter((br) => {
          if (br.category_ids) {
            var cat_ids = br.category_ids.split(",");
            if (cat_ids.length > 0 && cat_ids.indexOf(String(ss[0].id)) !== -1) {
              return true;
            }
          }
        }))
        setBrand(newBrands);

        //check sub category..
        if (ss[0].Categories.length > 0 && (params.category && params.category.length > 0)) {
          var ss1 = await Promise.all(ss[0].Categories.filter((item, i) => {
            return (item.slug == params.category)
          }))
          if (ss1.length > 0) {
            obj.catId = ss1[0].id;


            //check sub sub category..
            if (ss1[0].Categories.length > 0 && (params.subcategory && params.subcategory.length > 0)) {
              var ss2 = await Promise.all(ss1[0].Categories.filter((item, i) => {
                return (item.slug == params.subcategory)
              }))
              if (ss2.length > 0) {
                obj.subCatId = ss2[0].id;
              }
            }
          }
        }
      }
    }


    //check if brand...
    if (params.brand && params.brand.length > 0) {
      var ss = await Promise.all(brands.filter((item, i) => {
        return (item.slug == params.brand)
      }))


      if (ss.length > 0) {
        obj.brandId = ss[0].id;
      }
    }


    //check if keyword..
    if (params.keyword && params.keyword.length > 0) {
      obj.keyword = params.keyword;
    }

    //check if keyword..
    if (params.filter && params.filter.length > 0) {
      obj.filter = params.filter;
    }

    setqueryData(obj)
    return obj;
  }


  const makeBreadcrumb = async (obj, mArray = [], nv = false) => {
    var nvUrl = null;
    var str = "<a href='/'>Home</a>";
    //check master category..
    if (masterCatData.length > 0) {
      mArray = masterCatData;
    }

    if (obj.mastCatId && obj.mastCatId > 0) {
      var ss = await Promise.all(mArray.filter((item, i) => {
        return (item.id == obj.mastCatId)
      }))


      if (ss.length > 0) {
        nvUrl = `${ss[0].slug}`;
        str += ` / <a href="/#/${nvUrl}" onclick="setTimeout(function () {window.location.reload(); }, 500);" >${ss[0].name}</a>`;

        //check sub category..
        if (ss[0].Categories.length > 0 && (obj.catId && obj.catId > 0)) {
          var ss1 = await Promise.all(ss[0].Categories.filter((item, i) => {
            return (item.id == obj.catId)
          }))
          if (ss1.length > 0) {
            nvUrl = `${ss[0].slug}/${ss1[0].slug}`;
            str += ` / <a href="/#/${nvUrl}"  onclick="setTimeout(function () {window.location.reload(); }, 500);">${ss1[0].name}</a>`;

            //check sub sub category..
            if (ss1[0].Categories.length > 0 && (obj.subCatId && obj.subCatId > 0)) {
              var ss2 = await Promise.all(ss1[0].Categories.filter((item, i) => {
                return (item.id == obj.subCatId)
              }))
              if (ss2.length > 0) {
                nvUrl = `${ss[0].slug}/${ss1[0].slug}/${ss2[0].slug}`;
                str += ` / <a href="/#/${nvUrl}"  onclick="setTimeout(function () {window.location.reload(); }, 500);">${ss2[0].name}</a>`;
              }
            }
          }
        }
        if (nv && nvUrl) {
          navigate("/" + nvUrl);
          window.location.reload();
        }
      }
    } else {
      nvUrl = `product`;
      str += " / " + "<a href='/#/product'>All Categories</a>";
    }
    if (nv && nvUrl) {
      navigate("/" + nvUrl);
      window.location.reload();
    }
    setCustomBreadcrumb(str);
  }


  const searchProduct = async (data) => {
    const userId = (auth) ? auth.id : '';
    await DataService.searchProduct(data, userId).then((data) => {
      setData(data.data.products);
      setTotalRecords(data.data.products_count)
    });
    window.scrollTo(0, 0)
  }



  return (
    <>
      <Header />

      {/* <!--################################# SUB-CATEGORY-SECTION-START #################################--> */}
      <Subcategory custombread={customBreadcrumb} makeBreadcrumb={makeBreadcrumb} addTowhislist={addTowhislist} addTocart={addTocart} catData={masterCatData} brand={brand} data={data} totalRecords={totalRecords} queryObj={queryData} searchProduct={searchProduct} searchPay={searchPay} />
      {/* <!--################################# SUB-CATEGORY-SECTION-END #################################--> */}

      <Footer />
    </>
  )
}

export default SubCatagory
