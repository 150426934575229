import React from 'react';
import { Link } from 'react-router-dom';
import '../UserProfileCss/SeeWhatOthers.css';
import Profile from '../../../../images/profileimg.png';
import Flag from '../../../../images/flag.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPenToSquare} from '@fortawesome/free-solid-svg-icons';


function SeeWhatOthers() {
  return (
    <>
      <section className="profile-section py-5">
    <div className="container">

        <div className="row mt-5 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3 justify-content-center pop-position">
                    <div className="col-md-6">
                        <div className="profile d-md-flex">
                            <div className="prfle-image me-3">
                                <img src={Profile} alt="Profile-img" />
                            </div>
                            <div className="prfle-info-main">
                                <div className="prfle-name mb-3">
                                    <h2>Navjot Kaur</h2>
                                </div>
                                <div className="prfle-citiz mb-2 d-flex">
                                    <img src={Flag} alt="Image"/>
                                    <h5>IN</h5>
                                </div>
                                <div className="prfle-at d-flex">
                                    <h3>at</h3>
                                    <h4>gM</h4>
                                </div>
                                <div className="prfle-at d-flex">
                                    <h3>Email</h3>
                                    <h5>navjotkaurvni@gmail.com:</h5>
                                </div>
                            </div>    
                        </div>
                    </div>
                    <div className="col-md-6 pt-md-0 pt-2 profile-position">
                        <div className="prfle-edit text-md-end mb-md-0 mb-2">
                            <Link to=""><FontAwesomeIcon icon={faEye} />See What Others See</Link>

                        </div>
                        <div className="prfle-data">
                            <h3>Joined DEALITT.com in <span>2023</span></h3>
                            <h4>Main Products are</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-3 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3">
                    <div className="contact-head mb-2 d-flex justify-content-between">
                        <h2>Contact Information</h2>
                        <Link to=""><FontAwesomeIcon icon={faPenToSquare} />Edit</Link>

                        {/* <!-- edit-popup-start --> */}
                            
                        {/* <!-- edit-popup-end --> */}
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>navjotkaurvni@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Alternative Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Fax:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Telephone:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>91 - 9909843200</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Mobile:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>

        <div className="row mt-3 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3">
                    <div className="contact-head mb-2 d-flex justify-content-between">
                        <h2>Contact Information</h2>
                        <Link to=""><FontAwesomeIcon icon={faPenToSquare} />Edit</Link>

                        {/* <!-- edit-popup-start --> */}
                            
                        {/* <!-- edit-popup-end --> */}
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>navjotkaurvni@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Alternative Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Fax:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Telephone:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>91 - 9909843200</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Mobile:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        <div className="row mt-3 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3">
                    <div className="contact-head mb-2 d-flex justify-content-between">
                        <h2>Contact Information</h2>
                        <Link to=""><FontAwesomeIcon icon={faPenToSquare} />Edit</Link>

                        {/* <!-- edit-popup-start --> */}
                            
                        {/* <!-- edit-popup-end --> */}
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>navjotkaurvni@gmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Alternative Email:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Social Links:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Fax:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Telephone:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>91 - 9909843200</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-md-1 mb-3 align-items-baseline">
                            <div className="col-md-4 text-md-end">
                                <div className="lable">
                                    <h6>Mobile:</h6>
                                </div>
                            </div>
                            <div className="col-md-8 p-md-0">
                                <div className="lable-info">
                                   <p>None</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
        {/* new sec */}
        <div className="row mt-3 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3">
                    <div className="contact-head mb-2 d-flex justify-content-between">
                        <h2>Activity in the past 90 days</h2>
                    </div>
                    <div className="col-md-12">
                        <div className="row mb-md-1 mb-3 p-3 align-items-baseline border activity-row">
                            <div className="col-md-2 activity-border">
                                <div className="lable pb-3">
                                    <h2>2023</h2>
                                    <h6>Email</h6>
                                </div>
                                <div className="lable">
                                    <h2>15</h2>
                                    <h6>Days Signed in</h6>
                                </div>
                            </div>
                            <div className="col-md-2 activity-border">
                                <div className="lable pb-3">
                                    <h2>31</h2>
                                    <h6>Product Views</h6>
                                </div>
                                <div className="lable">
                                    <h2>6</h2>
                                    <h6>Searhced</h6>
                                </div>
                            </div>
                            <div className="col-md-3 activity-border">
                                <div className="lable pb-3">
                                    <h2>0/ 0</h2>
                                    <h6>Valid Inquiries Sent/ Inquiries Replied to</h6>
                                </div>
                                <div className="lable">
                                    <h2>0</h2>
                                    <h6>Inquiries Marked as 'Spam'</h6>
                                </div>
                            </div>
                            <div className="col-md-3 activity-border">
                                <div className="lable pb-3">
                                    <h2>0</h2>
                                    <h6>Valid RFQs Submitted</h6>
                                </div>
                                <div className="lable">
                                    <h2>0/0</h2>
                                    <h6>Quotations Received/ Quotations Read</h6>
                                </div>
                            </div>
                            <div className="col-md-2 active-border">
                                <div className="lable pb-3">
                                    <h2>0</h2>
                                    <h6>Listed as a Contact</h6>
                                </div>
                                <div className="lable">
                                    <h2>0</h2>
                                    <h6>Add to Blacklist</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-head pt-4 mb-2 d-flex justify-content-between">
                        <h2>Recent Searches</h2>
                    </div>
                    <div className="recent-button d-flex">
                        <h2>food</h2>
                        <h2>jump starter</h2>
                        <h2>car battery jump starter</h2>
                    </div>
                    <div className="contact-head pt-4 mb-2 d-flex justify-content-between">
                        <h2>Most Sourcing Industries</h2>
                    </div>
                    <div className="recent-cate d-lg-flex">
                        <h2>food</h2>
                        <h2>jump starter</h2>
                        <h2>car battery jump starter</h2>
                        <h2>food</h2>
                        <h2>jump starter</h2>
                        <h2>car battery jump starter</h2>
                        <h2>food</h2>
                        <h2>jump starter</h2>
                        <h2>car battery jump starter</h2>
                    </div>
                    <div className="contact-head py-4 mb-2">
                        <h2>Latest Buying Requests</h2>
                    </div>
                    <div className="contact-head py-4 mb-2">
                        <h2>Latest Inquiries</h2>
                    </div>
                </div>    
            </div>
        </div>
        <div className="row mt-3 m-0 justify-content-center">
            <div className="col-lg-10 col-12">
                <div className="row bg-white p-3">
                    <div className="contact-head mb-2 d-flex justify-content-between">
                        <h2>Total Online Transactions</h2>
                    </div>
                    <div className="col-md-12">
                        <div className="row mb-md-1 mb-3 p-3 align-items-baseline border activity-row">
                            <div className="col-md-6 activity-border d-flex">
                                <div className="lable pb-3 pe-5">
                                    <h2>0</h2>
                                    <h6>Total Transactions</h6>
                                </div>
                                <div className="lable">
                                    <h2>0</h2>
                                    <h6>Total Transactions Volume (USD)</h6>
                                </div>
                            </div>
                            <div className="col-md-6 d-flex">
                                <div className="lable pb-3 pe-5">
                                    <h2>0</h2>
                                    <h6>Suppliers Traded with</h6>
                                </div>
                                <div className="lable">
                                    <h2>0</h2>
                                    <h6>Disputes Submitted</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </div>
    </div>
</section>


    </>
  )
}

export default SeeWhatOthers
