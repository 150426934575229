import React, { useState } from 'react'
import '../../../customCss/AbusePolicy.css'

const AbusePolicyDealit = () => {
    const [popup, setPopup] = useState(false)
    return (
        <>
            <section class="abuseSec">
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3>1. Overview</h3>
                            <p>At DEALITT, we are committed to creating a safe and respectful environment for everyone who interacts with our platform. This Abuse and Threats Policy outlines unacceptable behaviors, how to report them, and the actions we will take in response. Your adherence to these guidelines helps us ensure a positive experience for all.</p>
                            <h3>2. What Constitutes Abuse and Threats?</h3>
                            <h4>2.1 Abuse</h4>
                            <ul>
                                <li><strong>Verbal Abuse:</strong> This includes any insulting or offensive language directed at DEALITT staff or other users.</li>
                                <li><strong>Harassment:</strong> Repeated, unwanted behavior that intimidates, belittles, or distresses others.</li>
                                <li><strong>Discrimination:</strong> Any abusive behavior based on race, gender, sexual orientation, religion, disability, or other protected characteristics.</li>
                            </ul>
                            <h4>2.2 Threats</h4>
                            <ul>
                                <li><strong>Physical Threats:</strong> Statements or actions implying physical harm to individuals or property.</li>
                                <li><strong>Intimidation:</strong> Using threats to coerce or control others.</li>
                                <li><strong>Cyber Threats:</strong> Threats made through digital means, including email, chat, or social media.</li>
                            </ul>
                            <h3>3. How to Report Abuse or Threats</h3>
                            <p>If you encounter any behavior that you believe violates this policy, please report it as follows:</p>
                            <ul>
                                <li><strong>Via Online Form:</strong> Use our dedicated reporting form <span style={{ cursor: 'pointer' }} onClick={() => setPopup(true)}>[here]</span>.</li>
                                <li><strong>Email:</strong> Contact our customer support team at <a href="mailto: support@dealitt.com">support@dealitt.com</a> with details of the incident.</li>
                                <li><strong>Information to Include:</strong> When reporting, please provide a description of the incident, the date and time it occurred, the names or identifiers of those involved, and any supporting evidence like screenshots or recordings.</li>
                            </ul>
                            <h3>4. What Happens After You Report?</h3>
                            <h4>4.1 Investigation</h4>
                            <ul>
                                <li><strong>Review:</strong> We will review your report to determine if it falls under our policy.</li>
                                <li><strong>Investigation:</strong> If necessary, we will conduct a thorough investigation which may involve gathering information from all parties involved.</li>
                            </ul>
                            <h4>4.2 Actions We May Take</h4>
                            <p>Based on our investigation, we may:</p>
                            <ul>
                                <li><strong>Issue Warnings:</strong> Provide formal warnings to those involved.</li>
                                <li><strong>Restrict Access:</strong> Temporarily or permanently suspend access to our platform for those who violate the policy.</li>
                                <li><strong>Terminate Relationships:</strong> For severe or repeated violations, we may terminate user accounts or business relationships.</li>
                                <li><strong>Involve Authorities:</strong> In cases of severe threats or illegal activity, we may involve law enforcement.</li>
                            </ul>
                            <h3>5. Preventing Abuse and Threats</h3>
                            <p>We are committed to preventing abuse and threats by:</p>
                            <ul>
                                <li><strong>Training:</strong> Providing regular training for our staff to handle and report abuse.</li>
                                <li><strong>Policy Review:</strong> Regularly updating our policies and procedures to address new challenges and feedback.</li>
                            </ul>
                            <h3>6. Policy Updates</h3>
                            <ul>
                                <li><strong>Review:</strong> This policy will be reviewed and updated regularly to reflect changes in legal requirements and best practices.</li>
                                <li><strong>Amendments:</strong> We reserve the right to amend this policy at any time. Updates will be communicated through our platform.</li>
                            </ul>
                            <h3>7. Contact Us</h3>
                            <p>For questions about this policy or to report abuse or threats, please contact:</p>
                            <ul>
                                <li><strong>Customer Support:</strong> <a href="mailto: support@dealitt.com">support@dealitt.com</a></li>
                            </ul>
                            <p>Thank you for helping us maintain a respectful and safe environment for everyone. and Replacement </p>
                        </div>
                    </div>
                </div>
                {popup &&
                    <div className='abusePopup'>
                        <div className="report_area">
                            <span className='close' onClick={() => setPopup(false)} >x</span>
                            <div className="report_main">
                                <input type="file" />
                                <label>Reason</label>
                                <textarea placeholder="Enter your text..."></textarea>
                                <button className="submit_report">Submit</button>
                            </div>
                        </div>
                    </div>
                }
            </section>
        </>
    )
}

export default AbusePolicyDealit