import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "../../../customCss/about.css"
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
const Counter = () => {
  return (
    <div className="container">
    <section class="counter py-5">
        <div class="container-fluid h-100">
            <div className="row main_counter">
                <div className="col-12 col-md-3 Counter_column">
                    <div className="d-flex align-items-center justyfy-content-center flex-column">
                        <h4>415K</h4>
                        <p>PRODUCTS SOLD</p>
                    </div>
                </div>
                <div className="col-12 col-md-3 Counter_column">
                    <div className="d-flex align-items-center justyfy-content-center flex-column">
                        <h4>210K</h4>
                        <p>SALES ON FIRST MONTH</p>
                    </div>
                </div>
                <div className="col-12 col-md-3 Counter_column">
                    <div className="d-flex align-items-center justyfy-content-center flex-column">
                        <h4>150K</h4>
                        <p>LOCAL BRANDS</p>
                    </div>
                </div>
                <div className="col-12 col-md-3 Counter_column">
                    <div className="d-flex align-items-center justyfy-content-center flex-column">
                        <h4>365K</h4>
                        <p>CUSTOMER SERVED</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
};

export default Counter;