import React from "react";

import "../../../customCss/ServiceSection.css";

import ServiceMain from "./ServiceMain";
import ServiceBanner from "./ServiceBanner";
import ServiceBuyer from "./ServiceBuyer";
function ServiceSection(props) {
  return (
    <div>
      <section className="service-section mt-3">
        <div className="container">
          <div className="row service-row">
            <div className="col-md-3">
              <ServiceMain data={props.data} />
            </div>
            <div className="col-md-8 p-0">
              <ServiceBanner    banners={props.banners}/>
            </div>
            {/* <div className="col-md-2">
              <ServiceBuyer />
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ServiceSection;
