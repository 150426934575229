import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import DataService from "../../../../services/data.service";
import "../../../CustomCss/company.css";


const ManageCompanyInfo = () => {
    const [comapny, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredData, setfilteredData] = useState([]);
    //console.log(props)

    useEffect(() => {
        document.title = "Comapny";
        setLoading(false);

        // getCategory(); 
    }, []);

    const getCategory = () => {
        DataService.getAllBrand().then((data) => {
            setData(data.data.data);
            setfilteredData(data.data.data);
            setLoading(false);
        });
    };
    const onChangeSearch = (e) => {
        if (e.target.value) {
            const result = comapny.filter((value) => {
                return (
                    value.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                    value.description.toLowerCase().includes(e.target.value.toLowerCase())
                );
            });
            setfilteredData(result);
        } else {
            setfilteredData(comapny);
        }
    };
    const onChangeStatus = (e) => {
        console.log(e.target.value);
        if (e.target.value !== "All") {
            const result = comapny.filter((value) => {
                return value.status === e.target.value;
            });
            setfilteredData(result);
        } else {
            setfilteredData(comapny);
        }
    };
    return (


        <div class="container">

            <div class="dash-tabs">
                <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Basic Company Details</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Manufacturing Capability</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Quality Control</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="service-tab" data-bs-toggle="tab" data-bs-target="#service-tab-pane" type="button" role="tab" aria-controls="service-tab-pane" aria-selected="false">R&D Capability</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="about-tab" data-bs-toggle="tab" data-bs-target="#about-tab-pane" type="button" role="tab" aria-controls="about-tab-pane" aria-selected="false">Export Capability</button>
                    </li>
                    {/* <li>
                        <Link to="">Certificate Center</Link>
                    </li> */}
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="portfolio-tab" data-bs-toggle="tab" data-bs-target="#portfolio-tab-pane" type="button" role="tab" aria-controls="portfolio-tab-pane" aria-selected="false">Company Introduction</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment-tab-pane" type="button" role="tab" aria-controls="portfolio-tab-pane" aria-selected="false">Company Accounts</button>
                    </li>
                </ul>
            </div>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">

                    <form class="company">
                        <div class="company-form">
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Company Name:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <input type="text" class="form-control inner_height" />
                                    <div class="placeholder">
                                        <p>Please enter the full name of your company</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Location of Registration:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="label-main mb-3">
                                        <div class="label-two pe-2">
                                            <h2>Province/State/Country:</h2>
                                        </div>
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div class="label-main">
                                        <div class="label-two pe-2">
                                            <h2>Country/Region:</h2>
                                        </div>
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Company Operational Address:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="label-main mb-3">
                                        <div class="label-two pe-2">
                                            <h2>Street:</h2>
                                        </div>
                                        <input type="text" class="form-control inner_height" />
                                        <div class="placeholder">
                                            <p>Please enter your company address，e.g. street name & number, building number.</p>
                                        </div>
                                    </div>
                                    <div class="label-main mb-3">
                                        <div class="label-two pe-2">
                                            <h2>City:</h2>
                                        </div>
                                        <input type="text" class="form-control inner_height" />
                                    </div>
                                    <div class="label-main mb-3">
                                        <div class="label-two pe-2">
                                            <h2>Country/Region:</h2>
                                        </div>
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div class="label-main mb-3">
                                        <div class="label-two pe-2">
                                            <h2>Zip/Postal Code:</h2>
                                        </div>
                                        <input type="text" class="form-control inner_height" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Main category:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <input type="text" class="form-control inner_height" />
                                    <button type="button" class="btn">Main Category</button>
                                    <div class="placeholder">
                                        <p>Your decided main category will be the basis for your industry vertical for
                                            future events. Your Star Rating will be based on the secondary category
                                            with the highest product quantity under this primary category.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Main Products:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="input-list d-flex">
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                    </div>
                                    <div class="placeholder">
                                        <p>Please enter your main products. One product per box.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Other Products You Sell:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="input-list d-flex mb-2">
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                    </div>
                                    <div class="input-list d-flex">
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                    </div>
                                    <div class="placeholder">
                                        <p>One product per box.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Year Company Register:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="label-main">
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Year Company Register:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="label-main">
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Company Website url:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <input type="text" class="form-control inner_height" value="http://" />
                                    <div class="placeholder">
                                        <p>Each company website URL should begin with http://. If your company owns
                                            more than one website, click "+" to add a URL. Maximum 3 URLs allowed.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Legal Owner:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <input type="text" class="form-control inner_height" />
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Office Size:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="label-main">
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Company Advantages:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <textarea cols="30" rows="5"></textarea>
                                    <div class="placeholder">
                                        <div class="remaining d-flex">
                                            <h3>Remaining:</h3>
                                            <span>256;</span>
                                        </div>
                                        <p>Please briefly describe your company’s advantages. E.g. “We have twenty
                                            years experience of product design.”</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-btn">
                            <button type="button" class="btn">Save</button>
                            <button type="button" class="btn">Submit</button>
                            <div class="submit-info">
                                <p>By clicking 'Submit', all the information in the Manage Company Information
                                    section will be submitted. Once submitted, no further editing is allowed
                                    within one working day.
                                </p>
                            </div>
                        </div>
                    </form>

                </div>
                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                    <form class="capability">
                        <div class="capability-form">
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Whether to show production process:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process picture:</h2>
                                            </div>
                                            <input type="file" class="form-control inner_height" />
                                            <div class="placeholder">
                                                <p>Maximum 2M support JPG and PNG format</p>
                                            </div>
                                            <div class="remove my-2">
                                                <a href="">Remove</a>
                                            </div>
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process discribe:</h2>
                                            </div>
                                            <textarea cols="30" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Whether to show production equipment:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                        <label class="form-check-label" for="flexRadioDefault3">
                                            YES
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Equipment Name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Equipment Model:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Equipment quantity:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                        <label class="form-check-label" for="flexRadioDefault4">
                                            NO
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Whether to show Production line:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
                                        <label class="form-check-label" for="flexRadioDefault5">
                                            YES
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Production Line name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Supervisor Number:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Number of Operators:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>QC/QA Number:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                        <label class="form-check-label" for="flexRadioDefault6">
                                            NO
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Factory Location:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <input type="text" class="form-control inner_height" />
                                    <div class="placeholder">
                                        <p>Please enter the address of your factory.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Factory Size:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <select class="form-select select_innnerheight" aria-label="Default select example">
                                        <option selected disabled>-- Please select --</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Contract Manufacturing:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="check-main">
                                        <div class="form-check me-1">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1" />
                                            <label class="form-check-label" for="flexCheckDefault1">
                                                OEM Service Offered
                                            </label>
                                        </div>
                                        <div class="form-check me-1">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                                            <label class="form-check-label" for="flexCheckChecked2">
                                                Design Service Offered
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked3" />
                                            <label class="form-check-label" for="flexCheckChecked3">
                                                Buyer Label Offered
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>No.of QC Staff:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <select class="form-select select_innnerheight" aria-label="Default select example">
                                        <option selected disabled>-- Please select --</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>No.of R & D Staff:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <select class="form-select select_innnerheight" aria-label="Default select example">
                                        <option selected disabled>-- Please select --</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>No.of Production Lines:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <select class="form-select select_innnerheight" aria-label="Default select example">
                                        <option selected disabled>-- Please select --</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Annual Output Value:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <select class="form-select select_innnerheight" aria-label="Default select example">
                                        <option selected disabled>-- Please select --</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Add information about your annual production capacity:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" checked />
                                        <label class="form-check-label" for="flexRadioDefault7">
                                            YES
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <h6>Production Details:</h6>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Product Name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Units Produced (Previous Year):</h2>
                                            </div>
                                            <div class="d-flex">
                                                <input type="text" class="form-control inner_height me-2" />
                                                <select class="form-select select_innnerheight" aria-label="Default select example">
                                                    <option selected disabled>Select Unit Type</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="label-main">
                                            <div class="label-two">
                                                <h2>Highest Ever Annual Output:</h2>
                                            </div>
                                            <div class="d-flex">
                                                <input type="text" class="form-control inner_height me-2" />
                                                <select class="form-select select_innnerheight" aria-label="Default select example">
                                                    <option selected disabled>Select Unit Type</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" />
                                        <label class="form-check-label" for="flexRadioDefault8">
                                            NO
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-btn">
                            <button type="button" class="btn">Save</button>
                            <button type="button" class="btn">Submit</button>
                            <div class="submit-info">
                                <p>By clicking 'Submit', all the information in the Manage Company Information
                                    section will be submitted. Once submitted, no further editing is allowed
                                    within one working day.
                                </p>
                            </div>
                        </div>
                    </form>

                </div>
                <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">
                    <form class="quality">
                        <div class="quality-form">
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Whether to demonstrate he quality control process:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process picture:</h2>
                                            </div>
                                            <input type="file" class="form-control inner_height" />
                                            <div class="placeholder">
                                                <p>Maximum 2M support JPG and PNG format</p>
                                            </div>
                                            <div class="remove my-2">
                                                <a href="">Remove</a>
                                            </div>
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process discribe:</h2>
                                            </div>
                                            <textarea cols="30" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Whether to display testing equipment:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                        <label class="form-check-label" for="flexRadioDefault3">
                                            YES
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Equipment Name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Equipment Model:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Equipment quantity:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" />
                                        <label class="form-check-label" for="flexRadioDefault4">
                                            NO
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-btn">
                            <button type="button" class="btn">Save</button>
                            <button type="button" class="btn">Submit</button>
                            <div class="submit-info">
                                <p>By clicking 'Submit', all the information in the Manage Company Information
                                    section will be submitted. Once submitted, no further editing is allowed
                                    within one working day.
                                </p>
                            </div>
                        </div>
                    </form>

                </div>
                <div class="tab-pane fade" id="service-tab-pane" role="tabpanel" aria-labelledby="service" tabindex="0">
                    <form class="rd">
                        <div class="rd-form">
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Whether to show R & D process:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process picture:</h2>
                                            </div>
                                            <input type="file" class="form-control inner_height" />
                                            <div class="placeholder">
                                                <p>Maximum 2M support JPG and PNG format</p>
                                            </div>
                                            <div class="remove my-2">
                                                <a href="">Remove</a>
                                            </div>
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Process discribe:</h2>
                                            </div>
                                            <textarea cols="30" rows="5"></textarea>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="submit-btn">
                            <button type="button" class="btn">Save</button>
                            <button type="button" class="btn">Submit</button>
                            <div class="submit-info">
                                <p>By clicking 'Submit', all the information in the Manage Company Information
                                    section will be submitted. Once submitted, no further editing is allowed
                                    within one working day.
                                </p>
                            </div>
                        </div>
                    </form>

                </div>
                <div class="tab-pane fade" id="about-tab-pane" role="tabpanel" aria-labelledby="about" tabindex="0">
                    <form class="export">
                        <div class="export-form">
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Total Annual Revenue:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="label-main">
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option selected disabled> --- Please select ---</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Export Percentage:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="label-main">
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option selected disabled> --- Please select ---</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Main Marks and Distribution:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="progress">
                                        <div class="progress-bar" role="progressbar" aria-label="Basic example"
                                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div class="input_group">
                                        <ul>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>North America</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Southeast Asia</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Mid East</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Central America</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>South Asia</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>North America</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Southeast Asia</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Mid East</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Central America</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>South Asia</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>North America</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Southeast Asia</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Mid East</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>Central America</h4>
                                            </li>
                                            <li>
                                                <input type="text" class="form-control inner_height" value="0" />
                                                <span class="percentage">%</span>
                                                <h4>South Asia</h4>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Year when your company started exporting:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <select class="form-select select_innnerheight" aria-label="Default select example">
                                        <option selected disabled> --- Please select ---</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Whether add customer case:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                            checked />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Project/customer name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Customer's Country/Region:</h2>
                                            </div>
                                            <select class="form-select select_innnerheight" aria-label="Default select example">
                                                <option selected disabled> --- Please select ---</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Product You Supply To Customer</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Annual Turnover:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                            <div class="placeholder mb-3">
                                                <p>US$</p>
                                            </div>
                                            <input type="file" class="form-control inner_height" />
                                            <div class="placeholder">
                                                <p>Maximum 2M support JPG and PNG format</p>
                                            </div>
                                            <div class="remove">
                                                <a href="">Remove All</a>
                                            </div>
                                        </div>
                                        <div class="label-main">
                                            <div class="label-two">
                                                <h2>Transaction Documents:</h2>
                                            </div>
                                            <input type="file" class="form-control inner_height" />
                                            <div class="placeholder">
                                                <p>Include commercial Invoices, packing lists,etc, 2M Max, JPEG
                                                    or PNG format only.
                                                </p>
                                            </div>
                                            <div class="remove">
                                                <a href="">Remove All</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>No.of Employees in Trade Department</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="label-main">
                                        <select class="form-select select_innnerheight" aria-label="Default select example">
                                            <option selected disabled> --- Please select ---</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Nearest Port:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="input-list d-flex">
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                        <input type="text" class="form-control inner_height" />
                                    </div>
                                    <div class="placeholder">
                                        <p>One port name per box.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Average Lead Time:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="input-list d-flex">
                                        <input type="text" class="form-control inner_height" />
                                        <h5>Day(s)</h5>
                                    </div>
                                    <div class="placeholder">
                                        <p>Please enter the average production time. Numbers only.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Does your company have an overseas office?:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            YES
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            NO
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Accepted Delivery Terms:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="checkbox_main d-flex">
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault1" />
                                                <label class="form-check-label" for="flexCheckDefault1">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked2" />
                                                <label class="form-check-label" for="flexCheckChecked2">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked3" />
                                                <label class="form-check-label" for="flexCheckChecked3">
                                                    FCA
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked4" />
                                                <label class="form-check-label" for="flexCheckChecked4">
                                                    DDP
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked5" />
                                                <label class="form-check-label" for="flexCheckChecked5">
                                                    DAF
                                                </label>
                                            </div>
                                        </div>
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault6" />
                                                <label class="form-check-label" for="flexCheckDefault6">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked7" />
                                                <label class="form-check-label" for="flexCheckChecked7">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked8" />
                                                <label class="form-check-label" for="flexCheckChecked8">
                                                    FCA
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked9" />
                                                <label class="form-check-label" for="flexCheckChecked9">
                                                    DDP
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked10" />
                                                <label class="form-check-label" for="flexCheckChecked10">
                                                    DAF
                                                </label>
                                            </div>
                                        </div>
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault11" />
                                                <label class="form-check-label" for="flexCheckDefault11">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked12" />
                                                <label class="form-check-label" for="flexCheckChecked12">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked13" />
                                                <label class="form-check-label" for="flexCheckChecked13">
                                                    FCA
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked14" />
                                                <label class="form-check-label" for="flexCheckChecked14">
                                                    DDP
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Accepted Payment Currency:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="checkbox_main d-flex">
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault16" />
                                                <label class="form-check-label" for="flexCheckDefault16">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked17" />
                                                <label class="form-check-label" for="flexCheckChecked17">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked18" />
                                                <label class="form-check-label" for="flexCheckChecked18">
                                                    FCA
                                                </label>
                                            </div>
                                        </div>
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault19" />
                                                <label class="form-check-label" for="flexCheckDefault19">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked20" />
                                                <label class="form-check-label" for="flexCheckChecked20">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked21" />
                                                <label class="form-check-label" for="flexCheckChecked21">
                                                    FCA
                                                </label>
                                            </div>
                                        </div>
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault22" />
                                                <label class="form-check-label" for="flexCheckDefault22">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked23" />
                                                <label class="form-check-label" for="flexCheckChecked23">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked24" />
                                                <label class="form-check-label" for="flexCheckChecked24">
                                                    FCA
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Accepted Payment Type:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="checkbox_main d-flex">
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault25" />
                                                <label class="form-check-label" for="flexCheckDefault25">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked26" />
                                                <label class="form-check-label" for="flexCheckChecked26">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked27" />
                                                <label class="form-check-label" for="flexCheckChecked27">
                                                    FCA
                                                </label>
                                            </div>
                                        </div>
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault28" />
                                                <label class="form-check-label" for="flexCheckDefault28">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked29" />
                                                <label class="form-check-label" for="flexCheckChecked29">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked30" />
                                                <label class="form-check-label" for="flexCheckChecked30">
                                                    FCA
                                                </label>
                                            </div>
                                        </div>
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault31" />
                                                <label class="form-check-label" for="flexCheckDefault31">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked32" />
                                                <label class="form-check-label" for="flexCheckChecked32">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked33" />
                                                <label class="form-check-label" for="flexCheckChecked33">
                                                    FCA
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Language Spoken:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="checkbox_main d-flex">
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault34" />
                                                <label class="form-check-label" for="flexCheckDefault34">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked35" />
                                                <label class="form-check-label" for="flexCheckChecked35">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked36" />
                                                <label class="form-check-label" for="flexCheckChecked36">
                                                    FCA
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked37" />
                                                <label class="form-check-label" for="flexCheckChecked37">
                                                    DDP
                                                </label>
                                            </div>
                                        </div>
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault38" />
                                                <label class="form-check-label" for="flexCheckDefault38">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked39" />
                                                <label class="form-check-label" for="flexCheckChecked39">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked40" />
                                                <label class="form-check-label" for="flexCheckChecked40">
                                                    FCA
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked41" />
                                                <label class="form-check-label" for="flexCheckChecked41">
                                                    DDP
                                                </label>
                                            </div>
                                        </div>
                                        <div class="check">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault42" />
                                                <label class="form-check-label" for="flexCheckDefault42">
                                                    FOB
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked43" />
                                                <label class="form-check-label" for="flexCheckChecked43">
                                                    EXW
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked44" />
                                                <label class="form-check-label" for="flexCheckChecked44">
                                                    FCA
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked45" />
                                                <label class="form-check-label" for="flexCheckChecked45">
                                                    DDP
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-btn">
                            <button type="button" class="btn">Save</button>
                            <button type="button" class="btn">Submit</button>
                            <div class="submit-info">
                                <p>By clicking 'Submit', all the information in the Manage Company Information
                                    section will be submitted. Once submitted, no further editing is allowed
                                    within one working day.
                                </p>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="tab-pane fade" id="portfolio-tab-pane" role="tabpanel" aria-labelledby="about" tabindex="0">
                    <form class="intro">
                        <div class="intro-form">
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Company Logo:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <input type="file" class="form-control inner_height" />
                                    <div class="placeholder mb-2">
                                        <p>200KB max.JPEG, PNG format only. Suggested photo with and height
                                            100*100px.
                                        </p>
                                    </div>
                                    <div class="remove">
                                        <a href="">Remove</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Detailed Company Introduction:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <textarea cols="30" rows="5"></textarea>
                                    <div class="placeholder">
                                        <div class="remaining d-flex">
                                            <h3>Remaining:</h3>
                                            <span>4000;</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Company Photo:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="upload-main">
                                        <div class="label-main">
                                            <input type="file" class="form-control inner_height" />
                                            <div class="placeholder">
                                                <p>200KB max. JPEG or PNG format only. Suggested photo width and
                                                    height for the new version Minisite: 1200*675px.</p>
                                            </div>
                                            <div class="remove my-2">
                                                <a href="">Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-md-4 text-end">
                                    <label>Have you attender or planned to attend any trade shows?:</label>
                                </div>
                                <div class="col-md-8 p-0">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked />
                                        <label class="form-check-label" for="flexRadioDefault1">
                                            Yes
                                        </label>
                                    </div>
                                    <div class="upload-main">
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Trade Show Name:</h2>
                                            </div>
                                            <input type="text" class="form-control inner_height" />
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Date Attended:</h2>
                                            </div>
                                            <div class="d-flex">
                                                <select class="form-select me-2" aria-label="Default select example">
                                                    <option selected="" disabled=""> Year: </option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                                <select class="form-select select_innnerheight" aria-label="Default select example">
                                                    <option selected="" disabled=""> Month: </option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Host Country/Region:</h2>
                                            </div>
                                            <select class="form-select select_innnerheight" aria-label="Default select example">
                                                <option selected="" disabled=""> -- Please select -- </option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Trade Show Introduction:</h2>
                                            </div>
                                            <textarea cols="30" rows="5"></textarea>
                                            <div class="placeholder">
                                                <div class="remaining d-flex">
                                                    <h3>Remaining:</h3>
                                                    <span>200;</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="label-main mb-3">
                                            <div class="label-two">
                                                <h2>Trade Show Photo:</h2>
                                            </div>
                                            <input type="file" class="form-control inner_height" />
                                            <div class="placeholder">
                                                <p>Pictures recommendations upload size 16:9 maximum 2M support JPG and PNG format </p>
                                            </div>
                                            <div class="remove">
                                                <a href="">Remove All</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                        <label class="form-check-label" for="flexRadioDefault2">
                                            No
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </div>



    );
};

export default ManageCompanyInfo;
