import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { toast } from "react-toastify";
import DataService from "../services/data.service";
import AuthService from "../services/auth.service";
import HelperService from "../services/helper.service";
import SingleProductMain from "./component/SinglePagelayout/SingleProductMain";
import SingleProductSpecs from "./component/SinglePagelayout/SingleProductSpecs";
import MayLike from "./component/SinglePagelayout/MayLike";
import RelatedProducts from "./component/SinglePagelayout/RelatedProducts";
import RecentViewed from "./component/SinglePagelayout/RecentViewed";
import RelatedStores from "./component/SinglePagelayout/RelatedStores";
import Popup from "./CartPopUp";
import VendorStore from "./VendorStore";

const SinglePage = (props) => {
  const params = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Product detail";
    getProduct();
    window.scrollTo(0, 0);
  }, [params.id]);
  const [product, setProduct] = useState({});
  const [review, setReview] = useState([]);
  const [reviewCount, setReviewCount] = useState([]);
  const [allproduct, setAllProduct] = useState({});
  const [recentProduct, setRecentProduct] = useState({});
  const [relatedProduct, setRelatedProduct] = useState({});
  const [relatedStores, setRelatedStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState([]);
  const [html, setHTML] = useState({ __html: "" });
  const [showPopup, setShowPopup] = useState(false);
  const [VendorChat, setVendorChat] = useState([]);
  // const [shippingRates, setShippingRates] = useState([]);

  const auth = AuthService.getCurrentUser();
  const getProduct = async () => {
    const userId = auth ? auth.id : "";
    await DataService.getProductDetailWithSlug(params.id, userId)
      .then(async (data) => {
        setLoading(false);
        const productData = data.data.category;
        productData.images = productData.images
          ? JSON.parse(productData?.images)
          : [];
        productData.variant = productData?.variant
          ? JSON.parse(productData?.variant)
          : [];
     
          
        setProduct(productData);
        // setShippingRates(productData.ShippingRates);
        // console.log(shippingRates)
        setHTML({ __html: productData?.details });
        let bn = data?.data?.category?.User?.id
        getChat(bn)
        setRecentProduct(HelperService.getRecentProducts());
        HelperService.setRecentProducts(productData);

        //Get related...
        await DataService.getRelatedProducts(productData.id, userId).then(
          (de) => {
            setRelatedProduct(de.data.relatedProducts);
            setAllProduct(de.data.Products);
          }
        );

        //Get reviews..
        await DataService.getProductReviews(productData.id, "asc").then(
          (dw) => {
            let totalRating = 0;
            const starts = [];
            if (dw?.data?.reviewsCount?.length) {
              dw?.data?.reviewsCount.map((value) => {
                totalRating += value["total_rating"];
              });
              const ratingValue = totalRating / dw?.data?.reviewsCount?.length;
              for (let i = 0; i < 5; i++) {
                starts.push(ratingValue - i);
              }
            }
            setRating(starts);
            setReview(dw.data.reviews);
            setReviewCount(dw.data.reviewsCount);
          }
        );

        //Get related...
        await DataService.getRelatedStores(productData.id, userId).then(
          (dq) => {
            setRelatedStores(dq.data.data);
          }
        );
      })

      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
      });
  };
  const addTowhislist = (event, item) => {
    if (auth) {
      var element = event.currentTarget;
      if (element.className == "remove-cart") {
        //get cat id..
        if (item?.Carts) {
          DataService.deleteWishlist(item.id).then(
            () => {
              toast.success("Product removed from wishlist!", {
                position: toast.POSITION.TOP_RIGHT,
              });
              element.classList.remove("remove-cart");
              element.checked = false;
              getProduct();
            },
            (error) => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.msg) ||
                error.message ||
                error.toString();

              toast.error(resMessage, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          );
        }
      } else {
        const data = {};
        data.product_title = item.name;
        data.price = item.offer_price;
        data.product_sku = item.sku;
        data.quantity = "1";
        data.variant = "";
        data.product_id = item.id;
        data.type = "whislist";
        DataService.addCart(data).then(
          () => {
            toast.success("Product added to whislist", {
              position: toast.POSITION.TOP_RIGHT,
            });
            element.checked = true;
            element.classList.add("remove-cart");
            getProduct();
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.msg) ||
              error.message ||
              error.toString();
            toast.error(resMessage, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        );
      }
    } else {
      navigate("/login-page");
    }
  };


  const getChat = async (bn) => {
    await DataService.getChatWithVendor(bn)
      .then(async (data) => {
        setVendorChat(data?.data?.data)

      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      });
  };

  const addTocart = (event, item) => {
    const auth = AuthService.getCurrentUser();
    const data1 = {};
    data1.product_title = item.name;
    data1.price = item.offer_price;
    data1.product_sku = item.sku;
    data1.quantity = item.cart_qty ? item.cart_qty : "1";
    data1.variant = item.variation ? item.variation : null;
    data1.product_id = item.id;
    data1.type = "cart";
    if (auth) {
      setLoading(true);
      DataService.addCart(data1).then(
        () => {
          setLoading(false);
          setShowPopup(true);
          window.scrollTo(0, 0);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.msg) ||
            error.message ||
            error.toString();

          setLoading(false);
          toast.error(resMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      );
    } else {
      setLoading(true);
      HelperService.setLocalCart(data1);
      setLoading(false);
      setShowPopup(true);
      toast.success("Product added to cart!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      HelperService.updateCartCount();
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {showPopup && <Popup setShowPopup={setShowPopup} />}

      <Header />
      <div className="container mt-lg-5 mt-2 pt-lg-0 pt-3">
        {/* <!-- bread crumb --> */}
        <div className="main_body d-lg-flex">
          <div className="body_left">
            <div className="row">
              <div className="col-lg-4">
                <SingleProductMain
                  data={product}
                  review={review}
                  reviewCount={reviewCount}
                  addTowhislist={addTowhislist}
                  details={html}
                />
              </div>
              <div className="col-lg-8 mt-lg-0 mt-5 mt-md-0">
                <SingleProductSpecs
                  data={product}
                  review={review}
                  reviewCount={reviewCount}
                  addTocart={addTocart}
                  details={html}
                  VendorChat={VendorChat}
                />
              </div>
              
            </div>
          </div>
        </div>
        <MayLike
          data={allproduct}
          addTowhislist={addTowhislist}
          addTocart={addTocart}
        />
        <RelatedProducts
          data={relatedProduct}
          addTowhislist={addTowhislist}
          addTocart={addTocart}
        />
        <RecentViewed
          data={recentProduct}
          addTowhislist={addTowhislist}
          addTocart={addTocart}
        />
        <RelatedStores
          data={product}
          stores={relatedStores}
        />

       
      </div>
      <Footer />
    </>
  );
};

export default SinglePage;
