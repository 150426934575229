import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from "jwt-decode";
import FB from "../images/fb.svg";
import Google from "../images/google.svg";

const SocialLogin = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const responseFacebook = (response) => {
        if (response && response.accessToken) {

            var data = {
                social_type: 'facebook',
                social_id: response.id,
                email: response.email,
                first_name: response.name.split(' ').slice(0, -1).join(' '),
                last_name: response.name.split(' ').slice(-1).join(' '),
                role: "user"
            }
            //make api req...
            makeSocialLogin(data);

        } else {
            toast.error("Login Failed!", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const responseMessage = async (response) => {
        if (response.credential) {
            let decoded = jwt_decode(response.credential);

            var data = {
                social_type: 'google',
                social_id: decoded.sub,
                email: decoded.email,
                first_name: decoded.name.split(' ').slice(0, -1).join(' '),
                last_name: decoded.name.split(' ').slice(-1).join(' '),
                role: "user"
            }

            //make api req...
            makeSocialLogin(data);

        } else {
            toast.error("Login Failed!", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const errorMessage = (error) => {
        toast.error("Login Failed!", {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const makeSocialLogin = async (data) => {
        setLoading(true);
        await AuthService.socialLogin(data).then(
            async (data) => {
                const url = window.location.href;
                const queryParam = url.split('=')
                if (queryParam.length > 1) {
                    navigate('/' + queryParam[1])
                } else {
                    navigate('/')
                }
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.msg) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                toast.error(resMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        );
    }

    return (
        <div className="tags-input">
            <FacebookLogin
                appId="<your_app_id>"
                autoLoad={false}
                size="metro"
                fields="name,email,picture"
                scope="public_profile,email"
                callback={responseFacebook}
                cssClass="btnFacebook"
            />
            <GoogleOAuthProvider clientId="<your_client_id>">
                <GoogleLogin className="btnGoogle" onSuccess={responseMessage} onError={errorMessage} />
            </GoogleOAuthProvider>
        </div>
    );
};

export default SocialLogin;


