import React, { Fragment } from "react";
import { Link } from "react-router-dom";
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
import aboutleft from "../../../images/about_left.jpg"
const Baskit = (props) => {
  return (
    <div className="container">
    <section class="about py-5">
        <div class="container-fluid h-100">
            <div class="d-flex align-items-center justify-content-center flex-column flex-md-row h-100">
                <div className="qualityone">
                    <img src={aboutleft} className="w-100" />
                </div>
                <div className="qualitytwo p-5">
                <div dangerouslySetInnerHTML={props.data}></div>
                    <div className="slide-2 p-0"><Link to={"/"} className="vidit-button">Visit Our Store</Link></div>
                </div>
            </div>
        </div>
    </section>
    </div>
  );
};

export default Baskit;