import React, { useState, useEffect, useRef } from 'react'
import "../customCss/LoginPage.css"
import Logo from "../images/logo.svg";
import Login_fb from "../images/fb.png"
import Login_tw from "../images/twitter.png"
import Login_ln from "../images/link.png"
import Login_google from "../images/google.png"
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import LoginFooter from '../common/LoginFooter';
import LoginHeader from '../common/LoginHeader';
import SocialLogin from "../common/SocialLogin";
import HelperService from '../services/helper.service';
import DataService from '../services/data.service';
function LoginPage() {
    const form = useRef();
    const checkBtn = useRef();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [passwordType, setPasswordType] = useState("password");


    const required = (value) => {
        if (!value) {
            return (
                <div className="invalid-feedback d-block">
                    This field is required!
                </div>
            );
        }
    };


    const onChangeUsername = (e) => {
        const username = e.target.value;
        setUsername(username);

    };


    const onChangePassword = (e) => {
        const password = e.target.value;
        setPassword(password);
    };

    const navigate = useNavigate();
    useEffect(() => {
        const auth = AuthService.getCurrentUser();
        if (auth) {
            navigate("/");
        }
    }, [navigate]);




    const handleLogin = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);

        form.current.validateAll();
        if (checkBtn.current.context._errors.length === 0) {
            AuthService.login(username, password).then(
                async (data) => {
                    const carts = HelperService.getLocalCart();
                    await Promise.all(carts.map(async (value, i) => {
                        await DataService.addCart(value).then((data) => { }).catch((error) => { })
                    }));
                    HelperService.emptyLocalCart();
                    const url = window.location.href;
                    const queryParam = url.split('=')
                    if (queryParam.length > 1) {
                        navigate('/' + queryParam[1])
                    } else {
                        navigate('/')
                    }
                },
                (error) => {
                    const resMessage =
                        error?.response?.data?.msg

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    };


    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }
    return (
        <>

            {/* <!-- ################################# NAVBAR-SECTION-START ################################### --> */}
            <LoginHeader />
            {/* <!-- ################################# NAVBAR-SECTION-END ################################### --> */}

            {/* <!-- ################################# BANNER-SECTION-START ################################### -->     */}

            <section className="Login_banner-section py-5">
                <div className="container">

                    <div className="row">
                        <div className="col-md-8">
                            <div className="banner-info text-center">
                                <h2>Better deals in just time</h2>
                                <h3>sep. 4-30</h3>
                                <Link to="">source now</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {message && (
                                <div className="form-group">
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                            <Form onSubmit={handleLogin} ref={form}>
                                <div className="form-main">
                                    <label>Account:</label>
                                    <input type="email"
                                        className="form-control Login"
                                        placeholder="Email address or member ID"
                                        name="username"
                                        value={username}
                                        required
                                        autoFocus
                                        validations={[required]}
                                        onChange={onChangeUsername}
                                    />

                                    <div className="forget-pass">
                                        <label>Password:</label>
                                        <div className="passord">
                                            <Link to="/forgot-password">Forgot password?</Link>
                                        </div>
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <input type={passwordType}
                                            className="form-control Login"
                                            placeholder="Password"
                                            required
                                            name="password"
                                            value={password}
                                            validations={[required]}
                                            onChange={onChangePassword} />
                                        {passwordType === "password"
                                            ?
                                            <i class="fas fa-eye logIco" onClick={togglePassword}></i>
                                            :
                                            <i class="fas fa-eye-slash logIco" onClick={togglePassword}></i>
                                        }
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault" >

                                            Stay signed in
                                        </label>
                                    </div>
                                    <div className="form-btn d-grid">
                                        <button className='button_sign btn' disabled={loading}>
                                            {loading && (
                                                <span className="spinner-border spinner-border-sm"></span>
                                            )}
                                            <span>Sign In</span></button>

                                    </div>
                                    <div className="form-links">
                                        <Link to=""></Link>
                                        <Link to="/register">Create account</Link>
                                    </div>
                                </div>
                                <div className="follow-links">

                                    <div className="follow-icons" style={{ margin: "0 auto" }}>
                                        <SocialLogin></SocialLogin>

                                    </div>
                                </div>
                                <CheckButton style={{ display: "none" }} ref={checkBtn} />

                            </Form>
                        </div>
                    </div>

                </div>
            </section>
            {/* <!-- ################################# BANNER-SECTION-END ################################### -->     */}

            {/* <!-- ################################# FOOTER-SECTION-START ################################### -->   */}

            <LoginFooter />

        </>
    )
}

export default LoginPage
