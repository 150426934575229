import React from 'react'
import { Link } from 'react-router-dom';
import Header from "../common/Header";
import Footer from "../common/Footer";
import "../customCss/Favorite.css";

import Whimg1 from '../images/whishlist1.jpg';
import Whimg2 from '../images/whislist2.jpg';
import Whimg3 from '../images/whislist3.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPen, faTrash, faEnvelope, faCommentDots} from '@fortawesome/free-solid-svg-icons';

function Favorite() {
  return (
    <>
     <div>
        <Header> </Header>
      </div>
      <section className="favourite-section">
    <div className="container">

        <div className="favourite bg-white">
            {/* <div className="fav-btn d-md-flex">
                <input type="checkbox" className="me-2" />
                <button type="button" className="btn">Contact Supplier</button>
                <button type="button" className="btn">Compare</button>
                <select className="form-select" aria-label="Default select example">
                    <option defaultValue>Move To</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
                <button type="button" className="btn">Delete</button>
            </div> */}
            <div className="row py-3 align-items-center border-bottom hover">
                <div className="col-lg-2 col-md-2">
                    <div className="prdt-image">
                        <Link to="">
                            <img src={Whimg1} alt="prdt-img" />
                        </Link>
                    </div>
                </div>
                <div className="col-lg-8 col-md-7">
                    <div className="prdt-info">
                        <Link to="">
                            <h2>2022 X8 smartwatch with magnetic charging rotate button 1.75 inch largescreen
                            waterproof IP67 Pro plus Reloj smart watch X8 Max</h2>
                        </Link>
                        <p>Shenzhen Quanyaohui Technology Co.Ltd.[China]</p>
                        <div className="prdt-price d-flex">
                            <h3>US $4.8 - 7.88</h3>
                            <span>/</span>
                            <h4>piece</h4>
                        </div>
                        <div className="prdt-order d-flex">
                            <h3>1 piece</h3>
                            <h5>(Min.Order)</h5>
                        </div>
                        <div className="prdt-mark">
                            <Link to="">
                                <h3><FontAwesomeIcon icon={faPen} />Remarks</h3>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 p-0">
                    <div className="prdt-hover-btn mb-md-3">
                        <Link to=""><FontAwesomeIcon icon={faChevronDown} />Move to</Link>
                        <Link to=""><FontAwesomeIcon icon={faTrash} />Delete</Link>
                    </div>
                    <div className="prdt-btn text-center">
                        <Link to=""><FontAwesomeIcon icon={faEnvelope} />Contact Supplier</Link>
                        <Link to=""><FontAwesomeIcon icon={faCommentDots} />Chat Now</Link>
                    </div>
                </div>
            </div>
            <div className="row py-3 align-items-center border-bottom hover">
                <div className="col-lg-2 col-md-2">
                    <div className="prdt-image">
                        <Link to="">
                            <img src={Whimg2} alt="prdt-img" />
                        </Link>
                    </div>
                </div>
                <div className="col-lg-8 col-md-7">
                    <div className="prdt-info">
                        <Link to="">
                            <h2>2022 X8 smartwatch with magnetic charging rotate button 1.75 inch largescreen
                            waterproof IP67 Pro plus Reloj smart watch X8 Max</h2>
                        </Link>
                        <p>Shenzhen Quanyaohui Technology Co.Ltd.[China]</p>
                        <div className="prdt-price d-flex">
                            <h3>US $4.8 - 7.88</h3>
                            <span>/</span>
                            <h4>piece</h4>
                        </div>
                        <div className="prdt-order d-flex">
                            <h3>1 piece</h3>
                            <h5>(Min.Order)</h5>
                        </div>
                        <div className="prdt-mark">
                            <Link to="">
                                <h3><FontAwesomeIcon icon={faPen} />Remarks</h3>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 p-0">
                    <div className="prdt-hover-btn mb-md-3">
                        <Link to=""><FontAwesomeIcon icon={faChevronDown} />Move to</Link>
                        <Link to=""><FontAwesomeIcon icon={faTrash} />Delete</Link>
                    </div>
                    <div className="prdt-btn text-center">
                        <Link to=""><FontAwesomeIcon icon={faEnvelope} />Contact Supplier</Link>
                        <Link to=""><FontAwesomeIcon icon={faCommentDots} />Chat Now</Link>
                    </div>
                </div>
            </div>
            <div className="row py-3 align-items-center hover">
                <div className="col-lg-2 col-md-2">
                    <div className="prdt-image">
                        <Link to="">
                            <img src={Whimg3} alt="prdt-img" />
                        </Link>
                    </div>
                </div>
                <div className="col-lg-8 col-md-7">
                    <div className="prdt-info">
                        <Link to="">
                            <h2>2022 X8 smartwatch with magnetic charging rotate button 1.75 inch largescreen
                            waterproof IP67 Pro plus Reloj smart watch X8 Max</h2>
                        </Link>
                        <p>Shenzhen Quanyaohui Technology Co.Ltd.[China]</p>
                        <div className="prdt-price d-flex">
                            <h3>US $4.8 - 7.88</h3>
                            <span>/</span>
                            <h4>piece</h4>
                        </div>
                        <div className="prdt-order d-flex">
                            <h3>1 piece</h3>
                            <h5>(Min.Order)</h5>
                        </div>
                        <div className="prdt-mark">
                            <Link to="">
                                <h3><FontAwesomeIcon icon={faPen} />Remarks</h3>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-2 col-md-3 p-0">
                    <div className="prdt-hover-btn mb-md-3">
                        <Link to=""><FontAwesomeIcon icon={faChevronDown} />Move to</Link>
                        <Link to=""><FontAwesomeIcon icon={faTrash} />Delete</Link>
                    </div>
                    <div className="prdt-btn text-center">
                        <Link to=""><FontAwesomeIcon icon={faEnvelope} />Contact Supplier</Link>
                        <Link to="">Chat Now</Link>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<Footer> </Footer>
    </>
  )
}

export default Favorite
