import React, { useState, Fragment, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import DataService from "../services/data.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import AuthService from "../services/auth.service";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import "../customCss/Favorite.css";
import { faChevronDown, faPen, faTrash, faEnvelope, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import "../customCss/Whislist.css";
const AddWhislist = (props) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    document.title = "Wishlist";
    window.scrollTo(0, 0)
    if (!auth) {
      navigate("/login-page?url=wishlist");
    } else {
      getProduct();
    }
  }, []);
  const [loading, setLoading] = useState(true);
  const [newTotal, setnewTotal] = useState(0);
  const [cartData, setCartData] = useState({});
  const auth = AuthService.getCurrentUser();
  const getProduct = async () => {
    await DataService.getCart('whislist').then((data) => {
      setLoading(false);
      setCartData(data?.data?.data)

    }).catch((error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.msg) ||
        error.message ||
        error.toString();
      setLoading(false);
    })
  }

  useEffect(() => {
    // Simulating an API call or data fetching
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulating a loading delay of 2 seconds
  }, []);

  const removeWhislist = (item) => {
    setLoading(true);
    deleteCart(item)
  }
  const moveToCart = (item) => {
    const data = {};
    data.type = 'cart'
    updateCart(data, item)
  }

  const updateCart = (data, item) => {
    DataService.updateCart(data, item.id).then(
      () => {
        let newTotal = cartData.total - (item.price * item.quantity);
        setnewTotal(newTotal)
        cartData.splice(cartData.findIndex(value => value.id === item.id), 1)
        toast.success('Product moved to cart!', {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    );
  }

  const deleteCart = (item) => {
    DataService.deleteCart(item.id).then(
      () => {
        let newTotal = cartData.total - (item.price * item.quantity);
        setnewTotal(newTotal)
        cartData.splice(cartData.findIndex(value => value.id === item.id), 1)
        toast.success('Product deleted from wishlist!', {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    );
  }
  return (
    <>
      <Header />
      <div className="py-3 container">
        <div className="cart_body mt-5">


          <ToastContainer></ToastContainer>
          {loading ? (
            <p>Your wishlist is empty</p>
          ) : (
            <>
              {cartData && cartData.length > 0
                ? cartData.map((item, i) => (

                  <>
                    <section className="favourite-section">
                      <div className="container">

                        <div className="favourite bg-white">
                          {/* <div className="fav-btn d-md-flex">
                <input type="checkbox" className="me-2" />
                <button type="button" className="btn">Contact Supplier</button>
                <button type="button" className="btn">Compare</button>
                <select className="form-select" aria-label="Default select example">
                    <option defaultValue>Move To</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
                <button type="button" className="btn">Delete</button>
            </div> */}
                          <div className="row py-3 align-items-center border-bottom hover">
                            <div className="col-lg-2 col-md-2">
                              <div className="prdt-image">
                                {item?.Product?.file_path ?
                                  <img class="w-100" alt={item?.Product?.name} src={item.Product.file_path ? "https://api.dealitt.com/" + item.Product.file_path : ""} ></img> : <img src="../assets/img/product1.jpg" />
                                }
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-7">
                              <div className="prdt-info">
                                <Link to="">
                                  <h2>{item?.Product?.name}</h2>
                                </Link>
                                <p>{item?.variant} </p>
                                <div className="prdt-price d-flex">
                                  <h3> ${item?.Product?.offer_price}</h3>
                                  <span>/</span>
                                  <h4>piece</h4>
                                </div>
                                <div className="prdt-order d-flex">
                                  <h3>1 piece</h3>
                                  <h5>(Min.Order)</h5>
                                </div>
                                <div className="prdt-mark">
                                  
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2 col-md-3 p-0">
                              <div className=" mb-md-3">
                                <Link
                                  data-bs-toggle="modal" data-bs-target={`#staticBackdrop2_${item.id}`}
                                  className="text-yellow text-decoration-none">
                                  Move to cart
                                </Link>


                                <div class="modal fade" id={`staticBackdrop2_${item.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                  <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">

                                      <div class="modal-body py-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                          {/* <i className="text-center"><svg width="100" viewBox="0 0 268 268" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g opacity="0.01">
                                                                <path d="M134 0.939941C60.5108 0.939941 0.939941 60.5108 0.939941 134C0.939941 207.489 60.5108 267.06 134 267.06C207.489 267.06 267.06 207.489 267.06 134C267.06 60.5108 207.489 0.939941 134 0.939941Z" fill="#C5D9F1" />
                                                                <path d="M134 0.939941C60.5108 0.939941 0.939941 60.5108 0.939941 134C0.939941 207.489 60.5108 267.06 134 267.06C207.489 267.06 267.06 207.489 267.06 134C267.06 60.5108 207.489 0.939941 134 0.939941Z" stroke="#F2F2F2" stroke-width="1.25" />
                                                            </g>
                                                            <path d="M90.5 102.991H177.5V192.378C177.5 197.714 173.169 202.045 167.833 202.045H100.167C94.8307 202.045 90.5 197.714 90.5 192.378V102.991ZM97.75 110.241V192.378C97.75 193.712 98.8327 194.795 100.167 194.795H167.833C169.167 194.795 170.25 193.712 170.25 192.378V110.241H97.75Z" fill="#2C5F2D" />
                                                            <path d="M88.5944 79.0569L175.594 79.0573C180.93 79.0573 185.261 83.388 185.261 88.724L185.261 108.057L78.9276 108.057L78.9277 88.7236C78.9277 83.3876 83.2584 79.0569 88.5944 79.0569ZM178.011 100.807L178.011 88.724C178.011 87.39 176.928 86.3073 175.594 86.3073L88.5944 86.3069C87.2604 86.3069 86.1777 87.3896 86.1777 88.7236L86.1776 100.807L178.011 100.807Z" fill="#2C5F2D" />
                                                            <path d="M124.361 62.3733L139.828 62.3734C144.097 62.3734 147.561 65.838 147.561 70.1068L147.561 83.5318L116.628 83.5317L116.628 70.1066C116.628 65.8378 120.092 62.3733 124.361 62.3733ZM141.761 70.1067C141.761 69.0395 140.895 68.1734 139.828 68.1734L124.361 68.1733C123.294 68.1733 122.428 69.0395 122.428 70.1067L122.428 77.7317L141.761 77.7318L141.761 70.1067Z" fill="#2C5F2D" />
                                                            <path d="M129.921 126C129.921 123.999 131.545 122.375 133.546 122.375C135.547 122.375 137.171 123.999 137.171 126V183.816C137.171 185.817 135.547 187.441 133.546 187.441C131.545 187.441 129.921 185.817 129.921 183.816V126ZM130.375 126C130.375 123.999 131.999 122.375 134 122.375C136.001 122.375 137.625 123.999 137.625 126V183.816C137.625 185.817 136.001 187.441 134 187.441C131.999 187.441 130.375 185.817 130.375 183.816V126ZM129.771 125.908C129.771 123.907 131.395 122.283 133.396 122.283C135.397 122.283 137.021 123.907 137.021 125.908V183.724C137.021 185.725 135.397 187.349 133.396 187.349C131.395 187.349 129.771 185.725 129.771 183.724V125.908Z" fill="#2C5F2D" />
                                                        </svg>
                                                        </i> */}

                                          <h5 className="mt-4">Are you sure you want to  move to cart ?</h5>
                                          <div className="d-flex justify-content-center mt-3">
                                             <div className="d-flex justify-content-center mt-3">
                                                    <button
                                                      type="button"
                                                      class="main_button me-3"
                                                      data-bs-dismiss="modal"
                                                      onClick={() => {
                                                        moveToCart(item);
                                                      }}
                                                    >
                                                      Yes
                                                    </button>
                                                    <button
                                                      type="button"
                                                      class="main_button red_button"
                                                      data-bs-dismiss="modal"
                                                    >
                                                      No
                                                    </button>
                                                  </div>
                                          </div>
                              
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <Link data-bs-toggle="modal" data-bs-target={`#staticBackdrop_${item.id}`} className="mx-2 remove_link">
                                  <i className="me-2">
                                    <FontAwesomeIcon icon={faTrash} />
                                  </i>
                               Delete </Link>

                                <div class="modal fade" id={`staticBackdrop_${item.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                  <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">

                                      <div class="modal-body py-3">
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                        <i className="text-center">
                                                    <svg
                                                      width="100"
                                                      viewBox="0 0 268 268"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g opacity="0.01">
                                                        <path
                                                          d="M134 0.939941C60.5108 0.939941 0.939941 60.5108 0.939941 134C0.939941 207.489 60.5108 267.06 134 267.06C207.489 267.06 267.06 207.489 267.06 134C267.06 60.5108 207.489 0.939941 134 0.939941Z"
                                                          fill="#02224e"
                                                        />
                                                        <path
                                                          d="M134 0.939941C60.5108 0.939941 0.939941 60.5108 0.939941 134C0.939941 207.489 60.5108 267.06 134 267.06C207.489 267.06 267.06 207.489 267.06 134C267.06 60.5108 207.489 0.939941 134 0.939941Z"
                                                          stroke="#F2F2F2"
                                                          stroke-width="1.25"
                                                        />
                                                      </g>
                                                      <path
                                                        d="M90.5 102.991H177.5V192.378C177.5 197.714 173.169 202.045 167.833 202.045H100.167C94.8307 202.045 90.5 197.714 90.5 192.378V102.991ZM97.75 110.241V192.378C97.75 193.712 98.8327 194.795 100.167 194.795H167.833C169.167 194.795 170.25 193.712 170.25 192.378V110.241H97.75Z"
                                                        fill="#02224e"
                                                      />
                                                      <path
                                                        d="M88.5944 79.0569L175.594 79.0573C180.93 79.0573 185.261 83.388 185.261 88.724L185.261 108.057L78.9276 108.057L78.9277 88.7236C78.9277 83.3876 83.2584 79.0569 88.5944 79.0569ZM178.011 100.807L178.011 88.724C178.011 87.39 176.928 86.3073 175.594 86.3073L88.5944 86.3069C87.2604 86.3069 86.1777 87.3896 86.1777 88.7236L86.1776 100.807L178.011 100.807Z"
                                                        fill="#02224e"
                                                      />
                                                      <path
                                                        d="M124.361 62.3733L139.828 62.3734C144.097 62.3734 147.561 65.838 147.561 70.1068L147.561 83.5318L116.628 83.5317L116.628 70.1066C116.628 65.8378 120.092 62.3733 124.361 62.3733ZM141.761 70.1067C141.761 69.0395 140.895 68.1734 139.828 68.1734L124.361 68.1733C123.294 68.1733 122.428 69.0395 122.428 70.1067L122.428 77.7317L141.761 77.7318L141.761 70.1067Z"
                                                        fill="#02224e"

                                                      />
                                                      <path
                                                        d="M129.921 126C129.921 123.999 131.545 122.375 133.546 122.375C135.547 122.375 137.171 123.999 137.171 126V183.816C137.171 185.817 135.547 187.441 133.546 187.441C131.545 187.441 129.921 185.817 129.921 183.816V126ZM130.375 126C130.375 123.999 131.999 122.375 134 122.375C136.001 122.375 137.625 123.999 137.625 126V183.816C137.625 185.817 136.001 187.441 134 187.441C131.999 187.441 130.375 185.817 130.375 183.816V126ZM129.771 125.908C129.771 123.907 131.395 122.283 133.396 122.283C135.397 122.283 137.021 123.907 137.021 125.908V183.724C137.021 185.725 135.397 187.349 133.396 187.349C131.395 187.349 129.771 185.725 129.771 183.724V125.908Z"
                                                        fill="#02224e"
                                                      />
                                                    </svg>
                                                  </i>

                                          <h5 className="mt-4">Are you sure you want to delete this from wishlist ?</h5>
                                          <div className="d-flex justify-content-center mt-3">
                                             <div className="d-flex justify-content-center mt-3">
                                                    <button
                                                      type="button"
                                                      class="main_button me-3"
                                                      data-bs-dismiss="modal"
                                                      onClick={() => {
                                                        removeWhislist(item);
                                                      }}
                                                    >
                                                      Yes
                                                    </button>
                                                    <button
                                                      type="button"
                                                      class="main_button red_button"
                                                      data-bs-dismiss="modal"
                                                    >
                                                      No
                                                    </button>
                                                  </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                              </div>
                              {/* <div className="prdt-btn text-center">
                                <Link to=""><FontAwesomeIcon icon={faEnvelope} />Contact Supplier</Link>
                                <Link to=""><FontAwesomeIcon icon={faCommentDots} />Chat Now</Link>
                              </div> */}
                            </div>
                          </div>

                        </div>

                      </div>
                    </section>
                  </>
                )) : <div className="no_product_main">
                  <p>You whislist is empty</p>
                </div>
              }
            </>
          )}
        </div>
      </div>


























      <Footer />
    </>

  );
};

export default AddWhislist;