import React, { useEffect, useState } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import '../customCss/VendorStore.css'
import DataService from '../services/data.service'
import AuthService from '../services/auth.service'
// import  slideimg1 from "../images/VendorImages/slideimg1.jpg"
import slideimg2 from "../images/VendorImages/slideimg2.jpg"
import slideimg3 from "../images/VendorImages/slideimg1.jpg"
import slideimg4 from "../images/VendorImages/slideimg4.jpg"
import cate_prdt1 from "../images/VendorImages/cate-prdt1.png"
import cate_prdt2 from "../images/VendorImages/cate-prdt2.png"
import cate_prdt3 from "../images/VendorImages/cate-prdt3.png"
import v_service1 from "../images/VendorImages/v-service1.png"
import v_service2 from "../images/VendorImages/v-service2.png"
import v_service3 from "../images/VendorImages/v-service3.png"
import v_service4 from "../images/VendorImages/v-service4.png"
import v_service5 from "../images/VendorImages/v-service5.png"
import recommend_pro1 from "../images/VendorImages/recommend-pro1.png"
// import  recommend_pro2 from "../images/VendorImages/recommend-pro2.png"
import choose1 from "../images/VendorImages/choose1.png"
import choose2 from "../images/VendorImages/choose2.png"
import choose3 from "../images/VendorImages/choose3.png"
import choose4 from "../images/VendorImages/choose4.png"
import choose5 from "../images/VendorImages/choose5.png"
import VendorVideo from "../images/VendorImages/video_vendor.mp4"
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import { faBars, faGem, faCircle, faSignal, faMedal, faSackDollar } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";

function VendorStore(props) {
    const form = React.useRef();
    const params = useParams();
    const [product_qty, setProduct_qty] = useState("");
    const [product_unit, setProduct_unit] = useState(props?.data?.unit);
    const [message, setMessage] = useState("");
    const [products, setProducts] = useState([])
    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])
    const [company, setCompany] = useState([])
    const [showFormChat, setShowFormChat] = useState(false);
    const [VendorChat, setVendorChat] = useState('');
    const getData = async () => {
        await DataService.getVendorProducts(params.slug).then((data) => {
            setProducts(data.data.data);
        });
        await DataService.getVendorBrands(params.slug).then((data) => {
            setBrands(data.data.data);
        });
        await DataService.getVendorCategories(params.slug).then((data) => {
            setCategories(data.data.data);

        });
        await DataService.getCompanyProfile(params.slug).then((data) => {
            setCompany(data.data.data);
            
        });
    };
    useEffect(() => {
        getData();
    }, [])




    const sendNewMessage = (e) => {
        e.preventDefault();
        const data = {
            to_user_id: company.id,
            message,
            product_qty,
            product_unit,
        };
        DataService.addChatWithVendor(data).then(
            () => {
                
                toast.success("message Sent!!");
                setShowFormChat(false)
                navigate("/chats")
                // getChat();
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    };



    const getChat = async () => {
        await DataService.getChatWithVendor(company?.id)
            .then(async (data) => {
                setVendorChat(data?.data?.data)
                if (data?.data?.data === null) {
                    setShowFormChat(true)

                } else {
                    navigate("/chats");
                    setShowFormChat(false)
                }
            })
            .catch((error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            });
    };


    const navigate = useNavigate();
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 2,
        centerPadding: "40px",
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };
    const navigatePage = (e, slug) => {
        navigate("/" + slug);
    };
    return (
        <>
            <Header />
            <div className='main_vendor_name'>
                <div className='container'>
                    <div className='vendor_inner'>
                        <div className='company_image'>
                            <img
                                src={
                                    company.logo_path
                                        ? "https://api.dealitt.com/" + company.logo_path
                                        : ""
                                }
                                alt="Slider-Img"
                            />
                        </div>
                        <div className='company_discrip'>
                            <h1>{company.name}</h1>
                            <p>Main Products : {company.main_products}</p>
                            <p>Total Staff : {company.office_size}</p>
                            <p className='new_bg_vendor'>Register Year : {company.register_year}</p>
                        </div>



                        <div className="btn_vendor">
                            <button onClick={getChat}  >Chat Now</button>
                        </div>


                        {showFormChat && (

                            <div className='main_popvendor'>
                                
                                <div className="capacity-form-newvendor">
                                <div className='cut_vendor_chat'>
                                    <button onClick={()=>setShowFormChat(false)}>X</button>
                                    </div>
                                    <div className="form-head mb-3">
                                        <h2>Send your message to this supplier</h2>
                                    </div>
                                    <form onSubmit={sendNewMessage} ref={form}>
                                        <div className="row align-items-baseline capacity-row">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>To:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-10 p-md-0">
                                                <div className="capacity-field">
                                                    <h3>{VendorChat?.legal_owner}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row capacity-row mt-2">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>Message:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-md-0">
                                                <div className="capacity-field">
                                                    <textarea
                                                        rows="6"
                                                        placeholder="Enter your inquiry details such as product name,color,size,MOQ,FOB,etc."
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    ></textarea>
                                                    <div className="capacity-info">
                                                        <p>
                                                            Your message must be between 20-8000 characters
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row capacity-row mt-2">
                                            <div className="col-md-2 text-md-end">
                                                <div className="capacity-label">
                                                    <label>Quantity:</label>
                                                </div>
                                            </div>
                                            <div className="col-md-8 p-md-0">
                                                <div className="capacity-field-main d-md-flex">
                                                    <div className="capacity-field pe-2">
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            placeholder="Please enter a number"
                                                            onChange={(e) => setProduct_qty(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="capacity-select">
                                                        <select
                                                            className="form-select"
                                                            aria-label="Default select example"
                                                            onChange={(e) => setProduct_unit(e.target.value)}
                                                            value={props?.data?.unit}
                                                        >
                                                            <option value={props?.data?.unit}>
                                                                {props?.data?.unit}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="capacity-btn">
                                                    <button type="submit" className="btn">
                                                        Send
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>

                        )}











                    </div>
                </div>
            </div>
            {/* navbar start */}
            <section className="vendor-header-section">
                <div className="container">

                    <nav className="navbar navbar-expand-lg p-0">
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <FontAwesomeIcon icon={faBars} />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to={`/vendor-store/${company.slug}`}>Home</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Products
                                    </Link>
                                    <ul className="dropdown-menu vendor-dropdown m-0">
                                        {
                                            products && products.length > 0 ? (products?.slice(0, 6).map((item) => {
                                                return (
                                                    <>
                                                        <li><Link className="dropdown-item" to="#">{item.name}</Link></li>

                                                    </>
                                                )
                                            })) : ("")


                                        }

                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Profile
                                    </Link>
                                    <ul className="dropdown-menu vendor-dropdown m-0">
                                        <li><Link className="dropdown-item" to={`/vendor-profile/${company.slug}`}>Company Overview</Link></li>
                                        <li><Link className="dropdown-item" to="#">Rating & Reviews</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" aria-current="page" to={`/vendor-contact/${company.slug}`}>Contact</Link>
                                </li>


                            </ul>
                            {/* <form role="search" className="pb-3 pb-lg-0">
                    <input className="form-control me-2" type="search" placeholder="Search in this store" aria-label="Search"/>
                </form> */}
                        </div>
                    </nav>

                </div>
            </section>
            {/* navbar end */}



            {/* <!--############################### BANNER-SECTION-START ###############################--> */}

            <section className="marquee-section pt-5">

                <div className="marquee-image">
                    <marquee direction="right" behavior="alternate">


                        {
                            products && products.length > 0 ? (products?.map((pro) => {
                                return (
                                    <>

                                        <Link to="">
                                            <img
                                                src={
                                                    pro.file_path
                                                        ? "https://api.dealitt.com/" + pro.file_path
                                                        : ""
                                                }
                                                alt="Slider-Img"
                                            /></Link>
                                    </>
                                )
                            })) : ("")


                        }

                    </marquee>
                </div>

            </section>
            {/* <!--############################### MARQUEE-SECTION-END ###############################-->

<!--############################### CATEGORY-SECTION-START ###############################--> */}
            <section className="category-section py-5">
                <div className="container">

                    <div className="category-head-one text-center mb-5 ">
                        <h2>Products</h2>
                        <div className="category-head-icon-one d-flex align-items-center justify-content-center">

                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                            <div className="category-head-icon mx-1">
                                <FontAwesomeIcon icon={faGem} />
                            </div>
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row">



                        {
                            products && products.length > 0 ? (products?.slice(0, 8).map((pro) => {
                                return (
                                    <>
                                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 card_inner">
                                            <div className="card_inner_body">
                                                <div className="img_sec_new img_cards">
                                                    <Link
                                                        to={
                                                            "/" +
                                                            pro.mastercategory.slug +
                                                            "/" +
                                                            pro.category.slug +
                                                            "/" +
                                                            pro.subcategory.slug +
                                                            "/" +
                                                            pro.slug
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                pro.file_path
                                                                    ? "https://api.dealitt.com/" + pro.file_path
                                                                    : ""
                                                            }
                                                            class="d-block w-100"
                                                            alt="..."
                                                        />
                                                    </Link>
                                                </div>
                                                <div className="card_content_price text-center card_content">
                                                    <Link
                                                        to={
                                                            "/" +
                                                            pro.mastercategory.slug +
                                                            "/" +
                                                            pro.category.slug +
                                                            "/" +
                                                            pro.subcategory.slug +
                                                            "/" +
                                                            pro.slug
                                                        }
                                                    >
                                                        <h4>{pro.name}</h4>
                                                    </Link>
                                                </div>
                                                <div className="card_content_price text-center card_content">
                                                    <p>US ${pro.offer_price}</p>

                                                </div>

                                            </div>
                                        </div>
                                    </>
                                )
                            })) : ("")


                        }





                        {/* {
                        products && products.length > 0 ? (products?.slice(0, 6).map((pro) => {
                            return (
                                <>
                                    <div className="col-md-4">
                                        <Link to={
                                            "/" +
                                            pro.mastercategory.slug +
                                            "/" +
                                            pro.category.slug +
                                            "/" +
                                            pro.subcategory.slug +
                                            "/" +
                                            pro.slug
                                        }>
                                            <div className="category-product text-center border bg-white p-3 mb-4">
                                                <div className="category-product-image mb-2">
                                                    <img
                                                        src={
                                                            pro.file_path
                                                                ? "https://api.dealitt.com/" + pro.file_path
                                                                : ""
                                                        }
                                                        class="d-block w-100"
                                                        alt="..."
                                                    />
                                                </div>
                                                <div className="category-product-info">
                                                    <div className="category-product-title">
                                                        <h3>{pro.name}</h3>
                                                        <p>{pro.description}</p>
                                                    </div>
                                                    <div className="category-product-btn py-2">
                                                        <h3>View</h3>
                                                    </div>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>
                                </>
                            )
                        })) : ("")


                    } */}

                    </div>

                </div>


                <div className="container">

                    <div className="category-head-one text-center mb-5">
                        <h2>Brands</h2>
                        <div className="category-head-icon-one d-flex align-items-center justify-content-center">
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                            <div className="category-head-icon mx-1">
                                <FontAwesomeIcon icon={faGem} />
                            </div>
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="row my-4 shopByCategory_Slider">

                            <div className="col-sm-12">
                                <Slider {...settings}>
                                    {brands && brands.length > 0 ? brands?.map((brand) => (
                                        <div
                                            class="slide-inner byCategory"
                                            onClick={(e) => navigatePage(e, brand.slug)}
                                        >
                                            <div className="shop_ByCategory">
                                                <img
                                                    src={
                                                        brand.file_path
                                                            ? "https://api.dealitt.com/" + brand.file_path
                                                            : ""
                                                    }
                                                    class="w-100"
                                                    alt=""
                                                    srcset=""
                                                />
                                                <h4 class="text-center slide-title">
                                                    {brand.name}
                                                </h4>
                                            </div>
                                        </div>
                                    ))
                                        : ""}
                                </Slider>
                            </div>
                        </div>
                        {/* 
                        {
                            brands && brands.length > 0 ? (brands?.map((brand) => {
                                return (
                                    <>
                                        <div className="col-md-4">
                                            <Link to="">

                                                <div className="category-product text-center border bg-white p-3 mb-4">
                                                    <div className="category-product-image mb-2">
                                                        <img
                                                            src={
                                                                brand.file_path
                                                                    ? "https://api.dealitt.com/" + brand.file_path
                                                                    : ""
                                                            }
                                                            class="d-block w-100"
                                                            alt="..."
                                                        />
                                                    </div>
                                                    <div className="category-product-info">
                                                        <div className="category-product-title">
                                                            <h3>{brand.name}</h3>
                                                        </div>
                                                        <div className="category-product-btn py-2">
                                                        </div>
                                                    </div>


                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            })) : ("")
                        } */}


                    </div>

                </div>


                <div className="container">

                    <div className="category-head-one text-center mb-5 mt-5">
                        <h2>Categories</h2>
                        <div className="category-head-icon-one d-flex align-items-center justify-content-center">
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                            <div className="category-head-icon mx-1">
                                <FontAwesomeIcon icon={faGem} />
                            </div>
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {
                            categories && categories.length > 0 ? (categories?.map((category) => {
                                return (
                                    <>
                                        <div className="col-md-4">
                                            <Link to="">
                                                <div className="category-product text-center border bg-white p-3 mb-4">
                                                    <div className="category-product-image mb-2">
                                                        <img
                                                            src={
                                                                category.file_path
                                                                    ? "https://api.dealitt.com/" + category.file_path
                                                                    : ""
                                                            }
                                                           
                                                            alt="Product-IMG"
                                                        />
                                                    </div>
                                                    <div className="category-product-info">
                                                        <div className="category-product-title">
                                                            <h3>{category.name}</h3>
                                                        </div>
                                                        <div className="category-product-btn py-2">
                                                            <h3>READ MORE</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                )
                            })) : ("")
                        }

                    </div>

                </div>
            </section >
            {/* <!--############################### CATEGORY-SECTION-END ###############################-->

<!--############################### SERVICE-SECTION-START ###############################--> */}
            {/* <section className="service_section py-5">
                <div className="container">

                    <div className="category-head-one-two text-center mb-5">
                        <h2>One-Stop Service</h2>
                        <div className="category-head-icon-one-two d-flex align-items-center justify-content-center">
                            <div className="category-head-line-main-two">
                                <div className="category-head-line-two"></div>
                                <div className="category-head-line-two"></div>
                            </div>
                            <div className="category-head-icon-two mx-1">
                                <FontAwesomeIcon icon={faGem} />
                            </div>
                            <div className="category-head-line-main-two">
                                <div className="category-head-line-two"></div>
                                <div className="category-head-line-two"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="vendor-service mb-md-5 mb-4">
                                <div className="vendor-service-image">
                                    <img src={v_service5} alt="Service-Img" />
                                </div>
                                <div className="vendor-service-info my-md-3 my-1">
                                    <h2>1. Communicate Design Details</h2>
                                    <p>Show your favorite design, determine the material, size and style with us</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="vendor-service mb-md-5 mb-4">
                                <div className="vendor-service-image">
                                    <img src={v_service4} alt="Service-Img" />
                                </div>
                                <div className="vendor-service-info my-md-3 my-1">
                                    <h2>1. Communicate Design Details</h2>
                                    <p>Show your favorite design, determine the material, size and style with us</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="vendor-service mb-md-5 mb-4">
                                <div className="vendor-service-image">
                                    <img src={v_service3} alt="Service-Img" />
                                </div>
                                <div className="vendor-service-info my-md-3 my-1">
                                    <h2>1. Communicate Design Details</h2>
                                    <p>Show your favorite design, determine the material, size and style with us</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="vendor-service mb-md-5 mb-4">
                                <div className="vendor-service-image">
                                    <img src={v_service2} alt="Service-Img" />
                                </div>
                                <div className="vendor-service-info my-md-3 my-1">
                                    <h2>1. Communicate Design Details</h2>
                                    <p>Show your favorite design, determine the material, size and style with us</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="vendor-service mb-md-5 mb-4">
                                <div className="vendor-service-image">
                                    <img src={v_service1} alt="Service-Img" />
                                </div>
                                <div className="vendor-service-info my-md-3 my-1">
                                    <h2>1. Communicate Design Details</h2>
                                    <p>Show your favorite design, determine the material, size and style with us</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
            {/* <!--############################### SERVICE-SECTION-END ###############################-->

<!--############################### ONLINE-SECTION-START ###############################--> */}
            {/* <section className="online-section py-5">
                <div className="container">

                    <div className="category-head-one text-center mb-5">
                        <h2>Online Services</h2>
                        <div className="category-head-icon-one d-flex align-items-center justify-content-center">
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                            <div className="category-head-icon mx-1">
                                <FontAwesomeIcon icon={faGem} />
                            </div>
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-between">
                        <div className="col-md-2 colume">
                            <Link to="">
                                <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                    <div className="online-service-img mb-2">
                                        <img src={slideimg3} alt="Service-Img" />
                                    </div>
                                    <div className="online-service-info">
                                        <div className="online-service-title">
                                            <h2>Sunny</h2>
                                            <h3>Professional Service</h3>
                                        </div>
                                        <div className="online-service-btn mt-3">
                                            <h3>Contact me</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 colume">
                            <Link to="">
                                <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                    <div className="online-service-img mb-2">
                                        <img src={slideimg3} alt="Service-Img" />
                                    </div>
                                    <div className="online-service-info">
                                        <div className="online-service-title">
                                            <h2>Sunny</h2>
                                            <h3>Professional Service</h3>
                                        </div>
                                        <div className="online-service-btn mt-3">
                                            <h3>Contact me</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 colume">
                            <Link to="">
                                <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                    <div className="online-service-img mb-2">
                                        <img src={slideimg3} alt="Service-Img" />
                                    </div>
                                    <div className="online-service-info">
                                        <div className="online-service-title">
                                            <h2>Sunny</h2>
                                            <h3>Professional Service</h3>
                                        </div>
                                        <div className="online-service-btn mt-3">
                                            <h3>Contact me</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 colume">
                            <Link to="">
                                <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                    <div className="online-service-img mb-2">
                                        <img src={slideimg3} alt="Service-Img" />
                                    </div>
                                    <div className="online-service-info">
                                        <div className="online-service-title">
                                            <h2>Sunny</h2>
                                            <h3>Professional Service</h3>
                                        </div>
                                        <div className="online-service-btn mt-3">
                                            <h3>Contact me</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-2 colume">
                            <Link to="">
                                <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                    <div className="online-service-img mb-2">
                                        <img src={slideimg3} alt="Service-Img" />
                                    </div>
                                    <div className="online-service-info">
                                        <div className="online-service-title">
                                            <h2>Sunny</h2>
                                            <h3>Professional Service</h3>
                                        </div>
                                        <div className="online-service-btn mt-3">
                                            <h3>Contact me</h3>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>

                </div>
            </section> */}
            {/* <!--############################### ONLINE-SECTION-END ###############################-->

<!--############################### RECOMMEND-SECTION-START ###############################--> */}
            {/* <section className="recommend-section py-5">


                <div className="category-head-one text-center mb-5">
                    <h2>Recommended Products</h2>
                    <div className="category-head-icon-one d-flex align-items-center justify-content-center">
                        <div className="category-head-line-main">
                            <div className="category-head-line"></div>
                            <div className="category-head-line mt-1"></div>
                        </div>
                        <div className="category-head-icon mx-1">
                            <FontAwesomeIcon icon={faGem} />
                        </div>
                        <div className="category-head-line-main">
                            <div className="category-head-line"></div>
                            <div className="category-head-line mt-1"></div>
                        </div>
                    </div>
                </div>

                <div className="row w-100 m-0 mb-md-4 mb-0">
                    <div className="col-md-6 p-0">
                        <div className="recommend-products">
                            <div className="recommend-products-image">
                                <img src={recommend_pro1} alt="Recommend-img" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 colume">
                        <Link to="">
                            <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                <div className="online-service-img mb-2">
                                    <img src={slideimg3} alt="Service-Img" />
                                </div>
                                <div className="online-service-info">
                                    <div className="online-service-title">
                                        <h2>Sunny</h2>
                                        <h3>Professional Service</h3>
                                    </div>
                                    <div className="online-service-btn mt-3">
                                        <h3>Contact me</h3>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-2 colume">
                        <Link to="">
                            <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                <div className="online-service-img mb-2">
                                    <img src={slideimg3} alt="Service-Img" />
                                </div>
                                <div className="online-service-info">
                                    <div className="online-service-title">
                                        <h2>Sunny</h2>
                                        <h3>Professional Service</h3>
                                    </div>
                                    <div className="online-service-btn mt-3">
                                        <h3>Contact me</h3>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="row w-100 m-0 justify-content-end">
                    <div className="col-md-2 colume">
                        <Link to="">
                            <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                <div className="online-service-img mb-2">
                                    <img src={slideimg3} alt="Service-Img" />
                                </div>
                                <div className="online-service-info">
                                    <div className="online-service-title">
                                        <h2>Sunny</h2>
                                        <h3>Professional Service</h3>
                                    </div>
                                    <div className="online-service-btn mt-3">
                                        <h3>Contact me</h3>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-2 colume">
                        <Link to="">
                            <div className="online-service text-center border rounded bg-white p-lg-4 p-3">
                                <div className="online-service-img mb-2">
                                    <img src={slideimg3} alt="Service-Img" />
                                </div>
                                <div className="online-service-info">
                                    <div className="online-service-title">
                                        <h2>Sunny</h2>
                                        <h3>Professional Service</h3>
                                    </div>
                                    <div className="online-service-btn mt-3">
                                        <h3>Contact me</h3>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="recommend-products">
                            <div className="recommend-products-image">
                                <img src={recommend_pro1} alt="Recommend-img" />
                            </div>
                        </div>
                    </div>
                </div>


            </section> */}
            {/* <!--############################### RECOMMEND-SECTION-END ###############################-->

<!--############################### COMPANY-SECTION-START ###############################--> */}
            {/* <section className="company_section py-5">
                <div className="container">

                    <div className="row">
                        <div className="col-md-8">
                            <div className="company-video">
                                <img src={slideimg2}/>
                                <video src={VendorVideo} loop muted autoPlay controls></video>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="company-info mt-lg-4 mt-md-0 mt-2">
                                <h2>Company Profile</h2>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas perferendis minus
                                    incidunt expedita natus harum voluptate, asperiores repudiandae accusamus tempora
                                    reprehenderit fugiat. Eaque sapiente amet soluta officiis, dolorum inventore
                                    nulla?Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas perferendis minus
                                    incidunt expedita natus harum voluptate, asperiores repudiandae accusamus tempora
                                    reprehenderit fugiat. Eaque sapiente amet soluta officiis, dolorum inventore
                                    nulla?</p>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-5 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>01</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-5 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>02</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-5 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>03</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-5 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>04</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-5 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>05</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-4 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>06</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-4 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>07</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-4 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>08</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-4 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>09</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-6 colume mt-md-4 mt-3 mb-0">
                            <div className="company-cards">
                                <div className="company-card-image position-relative mb-4">
                                    <img src={slideimg3} alt="Card-Img" />
                                    <div className="company-card-number">
                                        <h4>10</h4>
                                    </div>
                                </div>
                                <div className="company-card-title">
                                    <h2><FontAwesomeIcon icon={faCircle} />Professional service team</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
            {/* <!--############################### COMPANY-SECTION-END ###############################-->

<!--############################### CHOOSE-SECTION-START ###############################--> */}
            {/* <section className="choose-section py-5">
                <div className="contiaenr">

                    <div className="category-head-one text-center mb-5">
                        <h2>Why Choose Us</h2>
                        <div className="category-head-icon-one d-flex align-items-center justify-content-center">
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                            <div className="category-head-icon mx-1">
                                <FontAwesomeIcon icon={faGem} />
                            </div>
                            <div className="category-head-line-main">
                                <div className="category-head-line"></div>
                                <div className="category-head-line mt-1"></div>
                            </div>
                        </div>
                    </div>

                    <div className="marquee-image">
                        <marquee direction="right" behavior="alternate">
                            <Link to=""><img src={choose1} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose2} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose3} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose4} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose5} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose1} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose2} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose3} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose4} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose5} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose1} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose2} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose3} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose4} alt="Slider-Img" /></Link>
                            <Link to=""><img src={choose5} alt="Slider-Img" /></Link>
                        </marquee>
                    </div>

                    <div className="container">
                        <div className="row mt-5">
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-3">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faSignal} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>50PCS Custom MOQ</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-3">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faMedal} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>15 years experience</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-3">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faSackDollar} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>Competitive price</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-3">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faSignal} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>50PCS Custom MOQ</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-3">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faMedal} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>15 years experience</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-3">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faSackDollar} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>Competitive price</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-md-0 mb-3">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faSignal} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>50PCS Custom MOQ</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-md-0 mb-3">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faMedal} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>15 years experience</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="choose-icons-part d-flex align-items-baseline rounded bg-white p-lg-4 p-3 mb-md-0">
                                    <div className="choose-icon me-lg-4 me-3">
                                        <FontAwesomeIcon icon={faSackDollar} />
                                    </div>
                                    <div className="choose-icon-title">
                                        <h2>Competitive price</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
            {/* <!--############################### CHOOSE-SECTION-END ###############################-->

<!--############################### REVIEW-SECTION-START ###############################--> */}
            {/* <section className="review-section"></section> */}
            {/* <!--############################### REVIEW-SECTION-END ###############################--> 

<!--############################### OWL-SECTION-START ###############################-->  */}
            {/* <section className="owl-section pt-5">
                <div className="container">


                    <OwlCarousel items={1}
                        className="owl-theme"
                        loop
                        nav
                    >


                        <div className="item">
                            <div className="row w-100 m-0 vendor-prdt-row border p-md-5 px-2 py-4 bg-white position-relative">
                                <div className="col-md-4">
                                    <div className="vendor-prdt">
                                        <div className="vendor-prdt-image">
                                            <Link to="">
                                                <img src={slideimg4} alt="Product-img" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="vendor-prdt-info">
                                        <div className="vendor-prdt-title mb-lg-4 mb-2">
                                            <Link to="">
                                                <h2>Fashion Luxury Crystal Green Dial Quartz Waterproof Ladies Wristwatches
                                                    Montre Femme Luxe Watches For Women</h2>
                                            </Link>
                                        </div>
                                        <div className="vendor-prdt-price d-flex align-items-baseline">
                                            <h3>₹1,934.82 - ₹2,443.98</h3>
                                            <span>/</span>
                                            <h4>piece</h4>
                                        </div>
                                        <div className="vendor-prdt-piece d-flex align-items-baseline">
                                            <h5>50 pieces</h5>
                                            <span>(Min.Order)</span>
                                        </div>
                                        <div className="vendor-prdt-btn">
                                            <Link to="">View details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="row w-100 m-0 vendor-prdt-row border p-md-5 px-2 py-4 bg-white position-relative">
                                <div className="col-md-4">
                                    <div className="vendor-prdt">
                                        <div className="vendor-prdt-image">
                                            <Link to="">
                                                <img src={slideimg3} alt="Product-img" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="vendor-prdt-info">
                                        <div className="vendor-prdt-title mb-lg-4 mb-2">
                                            <Link to="">
                                                <h2>Fashion Luxury Crystal Green Dial Quartz Waterproof Ladies Wristwatches
                                                    Montre Femme Luxe Watches For Women</h2>
                                            </Link>
                                        </div>
                                        <div className="vendor-prdt-price d-flex align-items-baseline">
                                            <h3>₹1,934.82 - ₹2,443.98</h3>
                                            <span>/</span>
                                            <h4>piece</h4>
                                        </div>
                                        <div className="vendor-prdt-piece d-flex align-items-baseline">
                                            <h5>50 pieces</h5>
                                            <span>(Min.Order)</span>
                                        </div>
                                        <div className="vendor-prdt-btn">
                                            <Link to="">View details</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>


                </div>
            </section>  */}

            {/* <!--############################### OWL-SECTION-END ###############################-->  */}




            {/* <section className="trending-section py-3">
                <div className="container">

                    <div className="row w-100 m-0 bg-white border rounded v-trending-row">
                        <div className="col-md-3 p-0">
                            <div className="trending-products">
                                <div className="trending-products-image position-relative">
                                    <img className="rounded" src={slideimg2} alt="Products-Img" />
                                    <div className="trending-products-info">
                                        <h2>New Trending Products</h2>
                                        <div className="trending-products-btn">
                                            <Link to="">View more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="vendor-last-prdts py-2 px-5">
                                <div className="vendor-last-prdt-image mb-2">
                                    <Link to="">
                                        <img src={slideimg2} alt="Product-img" />
                                    </Link>
                                </div>
                                <div className="vendor-last-prdt-info">
                                    <div className="vendor-last-prdt-title">
                                        <Link to="">
                                            <h2>Men Calendar stainless steel 5atm Waterproof</h2>
                                        </Link>
                                    </div>
                                    <div className="vendor-last-prdt-price">
                                        <h3>US$23.80 - US$28.00</h3>
                                    </div>
                                    <div className="vendor-last-prdt-piece d-flex align-items-baseline">
                                        <h5>50 pieces</h5>
                                        <h6>(Min.Order)</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="vendor-last-prdts py-2 px-5">
                                <div className="vendor-last-prdt-image mb-2">
                                    <Link to="">
                                        <img src={slideimg2} alt="Product-img" />
                                    </Link>
                                </div>
                                <div className="vendor-last-prdt-info">
                                    <div className="vendor-last-prdt-title">
                                        <Link to="">
                                            <h2>Men Calendar stainless steel 5atm Waterproof</h2>
                                        </Link>
                                    </div>
                                    <div className="vendor-last-prdt-price">
                                        <h3>US$23.80 - US$28.00</h3>
                                    </div>
                                    <div className="vendor-last-prdt-piece d-flex align-items-baseline">
                                        <h5>50 pieces</h5>
                                        <h6>(Min.Order)</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="vendor-last-prdts py-2 px-5">
                                <div className="vendor-last-prdt-image mb-2">
                                    <Link to="">
                                        <img src={slideimg2} alt="Product-img" />
                                    </Link>
                                </div>
                                <div className="vendor-last-prdt-info">
                                    <div className="vendor-last-prdt-title">
                                        <Link to="">
                                            <h2>Men Calendar stainless steel 5atm Waterproof</h2>
                                        </Link>
                                    </div>
                                    <div className="vendor-last-prdt-price">
                                        <h3>US$23.80 - US$28.00</h3>
                                    </div>
                                    <div className="vendor-last-prdt-piece d-flex align-items-baseline">
                                        <h5>50 pieces</h5>
                                        <h6>(Min.Order)</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section> */}
            <Footer />
        </>
    )
}

export default VendorStore
