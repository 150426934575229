import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "../common/sidebar";
import ManageCompanyInfo from "../section/home/Company/manage-company-info";

const ManageProfile = () => {
  React.useEffect(() => { 
    document.title = "Manage";
  }, []);

  return (
    <div className="bg-grey h-100">
      <Header />
      
<section className="content-area mt-4">
    <Sidebar/>
    <div className="Right-content">
      <div className="main-content">
      <ManageCompanyInfo/>   
      
      </div> 
      <Footer />
    </div>
</section>
      
     
    </div>
  );
};

export default ManageProfile;