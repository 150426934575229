import React, { useState, useEffect } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "../common/sidebar";
import Graphics from "../section/home/dashboard/graphics";
import TopProduct from "../section/home/dashboard/top-product";
import TopVendor from "../section/home/dashboard/top-vendor";
import StockReport from "../section/home/dashboard/stock-report";
import RecentOrder from "../section/home/dashboard/recent-order";
import DataService from "../../services/data.service";
import "../../Dashboard/CustomCss/Dashboard.css"
const Dashboard = () => {
  const [dashdata, setDashData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [topProducts, setTopProducts] = useState([]);
  const [recentOrders, setRecentOrders] = useState([]);
  const [stockreport, setStockReport] = useState([]);
  React.useEffect(() => {
    document.title = "Dashboard";
    getData();
  }, []);
  const getData = () => {
    setLoading(true);
    DataService.getDashboard().then((data) => {
      setDashData(data.data);
      setTopProducts(data.data.top_products);
      setRecentOrders(data.data.recent_orders);
      setStockReport(data.data.stock_report);
      setLoading(false);
    });
  }



  return (
    <div className="bg-grey h-100">
      <Header />

      <section className="content-area mt-4">
        <Sidebar />
        <div className="Right-content">
          <div className="main-content">
            <Graphics data={dashdata} />
            <TopProduct data={topProducts} />
            <RecentOrder data={recentOrders} />
            <StockReport data={stockreport} />

          </div>
          <Footer />
        </div>
      </section>


    </div>
  );
};

export default Dashboard;