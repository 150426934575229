import React, { Fragment } from "react";
import { Link } from "react-router-dom";
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
const AboutDealit = (props) => {
  return (
    <section class="about py-5">
        <div class="container h-100">
            <div class="d-flex align-items-center justify-content-center flex-column flex-md-row h-100">
                <div className="flex-grow-1 quality">
                    <h6>{props.title}</h6>
                    <div dangerouslySetInnerHTML={props.data} className="about_para"></div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default AboutDealit;