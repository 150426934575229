import React from 'react'
import '../../../customCss/ReturnPolicy.css'

const ReturnPolicyDealit = () => {
    return (
        <>
            <section class="returnSec">
                <div className='container my-5'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h3>1. Overview</h3>
                            <p>At DEALITT, we are committed to providing high-quality products and ensuring customer satisfaction. This Return and Replacement Policy outlines the conditions under which products can be returned or replaced, and the responsibilities of both DEALITT and the customer. Please read this policy carefully to understand your rights and obligations.</p>
                            <h3>2. Eligibility for Returns and Replacements</h3>
                            <h4>2.1 Defective or Damaged Products</h4>
                            <ul>
                                <li><strong>Criteria:</strong> Products received in a defective or damaged condition are eligible for return or replacement.</li>
                                <li><strong>Customer Responsibility:</strong> Notify DEALITT within 15 days of delivery, including detailed information such as the order number and photos of the defect or damage.</li>
                                <li><strong>DEALITT Responsibility:</strong> Upon verification, we will arrange for the return and either replace the product or issue a refund.</li>
                            </ul>
                            <h4> 2.2 Incorrect Products</h4>
                            <ul>
                                <li><strong>Criteria:</strong> Products that do not match your order (e.g., incorrect model, size, or specification) are eligible for return or replacement.</li>
                                <li><strong>Customer Responsibility:</strong> Contact DEALITT within 15 days of delivery, providing the order number and details of the discrepancy.</li>
                                <li><strong>DEALITT Responsibility:</strong> We will review your request and, if verified, arrange for the correct product to be sent or provide a refund.</li>
                            </ul>
                            <h4>2.3 Product Condition</h4>
                            <ul>
                                <li><strong>Criteria:</strong> Products must be returned in their original, unused condition. DEALITT will not accept returns if the product shows signs of use or damage caused by the customer.</li>
                                <li><strong>Return Approval:</strong> Acceptance of a return is contingent upon DEALITT's inspection and approval, confirming that the goods have not been used or damaged.</li>
                                <li><strong>Customer Responsibility:</strong> Ensure that the product is returned in the same condition as received, with all original packaging, manuals, and accessories included.</li></ul>
                            <h4> 2.4 Return Window</h4>
                            <ul>
                                <li><strong>Time Frame:</strong> Returns and replacements must be requested within 15 days of product delivery. After this period, requests may not be accepted.</li></ul>
                            <h4>2.5 Exclusions</h4>
                            <ul>
                                <li><strong>Non-Eligible Products:</strong> Customized products, perishable goods, and any items explicitly marked as non-returnable on the product page are not eligible for return or replacement.</li></ul>
                            <h4>2.6 Communication Outside the DEALITT Platform</h4>
                            <ul>
                                <li><strong>Criteria:</strong> DEALITT will not process refunds or assist in resolving issues for transactions or communications that occur outside of the DEALITT platform.</li>
                                <li><strong>Customer Responsibility:</strong> Ensure all communications and transactions are conducted within the DEALITT platform to be eligible for support, returns, and replacements.</li></ul>

                            <h3> 3. Return and Replacement Process</h3>
                            <h4>3.1 Initiating a Request</h4>
                            <ul>
                                <li><strong>Customer Responsibility:</strong> Initiate a return or replacement request via your DEALITT account or by contacting our customer service team. Provide all required information, including the order number, reason for the request, and any supporting documentation (e.g., photos).</li>
                                <li><strong>DEALITT Responsibility:</strong> We will review the request within 48 hours and notify you of approval or denial. If approved, we will provide instructions for the return or dispatch the replacement.</li></ul>
                            <h4>3.2 Shipping and Handling</h4>
                            <h6>Return Shipping Costs:</h6>
                            <ul>
                                <li><strong>DEALITT Responsibility:</strong> We will cover return shipping costs for defective, damaged, or incorrect products by providing a pre-paid return label.</li>
                                <li><strong>Customer Responsibility:</strong> For returns due to other reasons (e.g., change of mind), the customer is responsible for return shipping costs.</li></ul>
                            <h6>Replacement Shipping:</h6>
                            <ul>
                                <li><strong>DEALITT Responsibility:</strong> If a replacement is warranted, we will ship the replacement product within 5 business days of receiving the returned item.</li></ul>
                            <h3>4. Refunds and Credits</h3>
                            <h4>4.1 Refund Method</h4>
                            <ul>
                                <li><strong>Customer Choice:</strong> Refunds can be issued as a credit to your DEALITT account or to the original payment method. You may select your preferred option when initiating the return.</li>
                                <li><strong>DEALITT Responsibility:</strong> We will process refunds within 7-10 business days after receiving and inspecting the returned product.</li></ul>
                            <h3>5. Special Considerations</h3>
                            <h4>5.1 Bulk Orders</h4>
                            <ul>
                                <li><strong>Customer Responsibility:</strong> For bulk orders, returns, and replacements may be subject to additional terms. Please contact our account management team for assistance.</li>
                                <li><strong>DEALITT Responsibility:</strong> We will work with you to find a satisfactory resolution based on the specifics of your order.</li></ul>
                            <h4>5.2 International Returns</h4>
                            <ul>
                                <li><strong>Customer Responsibility:</strong> International customers may be responsible for additional shipping costs and customs fees associated with returns.
                                    DEALITT Responsibility: We will assist in coordinating the return, but additional time and costs may be involved.</li></ul>
                            <h3>6. Documentation and Record Keeping</h3>
                            <h4>6.1 Tracking</h4>
                            <ul>
                                <li><strong>Customer Responsibility:</strong> Keep a record of all communications, receipts, and documentation related to your return or replacement request.</li>
                                <li><strong>DEALITT Responsibility:</strong> We will maintain detailed records of all requests and communications to ensure transparency and accountability.</li></ul>
                            <h3>7. Communication</h3>
                            <h4>7.1 Clear Policy Access</h4>
                            <ul>
                                <li><strong>DEALITT Responsibility:</strong> This policy is accessible on our website and will be included in your order confirmation emails. We will ensure it is easy to find and understand.</li>
                                <li><strong>Customer Responsibility:</strong> Review the policy before making a purchase to understand your rights and responsibilities.</li></ul>
                            <h4>7.2 Customer Support</h4>
                            <ul>
                                <li><strong>DEALITT Responsibility:</strong> Our customer support team is available to assist with any questions or concerns regarding returns and replacements. Contact us at <a href="mailto:support@dealitt.com">support@dealitt.com</a>.</li></ul>
                            <h3>8. Continuous Improvement</h3>
                            <h4>8.1 Feedback</h4>
                            <ul>
                                <li><strong>Customer Responsibility:</strong> Provide feedback on your return and replacement experience to help us improve our processes.</li>
                                <li><strong>DEALITT Responsibility:</strong> We will regularly review and update this policy based on customer feedback and evolving business needs.</li></ul>
                            <h3>9. Legal Compliance</h3>
                            <h4>9.1 Compliance</h4>
                            <ul>
                                <li><strong>DEALITT Responsibility:</strong> Our policy complies with all relevant local and international laws, including consumer protection regulations.</li></ul>
                                <p>This policy is effective as of January 1, 2025. DEALITT reserves the right to amend this policy at any time, and any changes will be communicated to our customers accordingly.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReturnPolicyDealit