import React from 'react'
import "../UserProfileCss/UploadMyPhoto.css"
import change_photo from "../../../../images/profile_change_photo.webp"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
function UploadMyPhoto() {
  return (
    <div>
         <div className="container">
        <div className="banner_">
            <p className="photo_">
                Upload My Photo
            </p>
            <hr/>
            <div className="banner_inner">
                <FontAwesomeIcon icon={faCircleInfo} style={{color:`#84e4f7`}} />
                <p>Your Photo will be displayed within 24 hours.</p>
            </div>
            <div className="row">
                <div className="col-lg-8 col-sm-12">
                    <div className="upload_">
                        <div className="upload_1">
                            <img src={change_photo}
                                alt=""/>
                            <label htmlFor="input-file">Upload photo</label>
                            <input type="file" accept="image/jpeg, imsge/png, image/jpg" id="input-file"/>
                        </div>
                        <div className="upload_2">
                            <p>Click the button or drag the image onto the dotted box to upload</p>
                            <p>Upload JPG format , sized no larger than 3MB</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <fieldset>
                        <div className="upload_main">
                            <legend>Uploading rules</legend>
                                <ul>
                                    <li>Please upload a photo that matches the gender,age and status details in your personal profile.</li>
                                    <li>Use a photo that is appropriate for a business setting.Group photos are not allowed.</li>
                                    <li>Photos voilating the rules stated in the <span><Link to="">Terms of User</Link></span>  will be removed without notice.</li>
                                </ul>
                        </div>
                </fieldset>

                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default UploadMyPhoto
