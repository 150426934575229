import React from 'react'
import "../customCss/Header.css";
import Logo from "../images/logo.png";
import Flag from "../images/flag.png";
import FB from "../images/fb.svg";
import Google from "../images/google.svg";
import Linkdin from "../images/link.svg";
import Twiter from "../images/twitter.svg";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faBars, faAngleDown, faUser, faClipboard, faCartShopping, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import GoogleTranslator from './GoogleTranslator';
function LoginHeader(props) {



    return (
        <>

            <header>
                <section className="navbar-section bg-white">
                    <div className="container">

                        <nav className="navbar navbar-expand-md">

                            {/* <!----- Nav Logo -----> */}
                            <div className="navbar-logo">

                                <Link to="/" className="navbar-brand">
                                    <img src={Logo} alt="Logo" />
                                </Link>

                            </div>

                            {/* <!----- Category Dropdown Start-----> */}
                            {/* <div className="category-dropdown">
                    <button className="dropbtn"><FontAwesomeIcon icon={faBars} />Categories<FontAwesomeIcon icon={faAngleDown} /></button>
                    <div className="dropdown-content">
                        <ul>
                        {props.data && props.data.length > 0
                            ? props.data.map((item, i) => (
                              <li>
               <Link to={`${item.slug}`}>{item.name}</Link>
                <div className="right-arrow">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                                <div className="side-content">
                                {item.Categories && item.Categories.length > 0
                                                        ? item.Categories.map((cat, j) => (
                                                          <>
                                                          <ul>
                                                                    <Link to={`${item.slug}/${cat.slug}`}><h2>{cat.name}</h2></Link>
                                                                        {cat.Categories && cat.Categories.length > 0
                                                                            ? cat.Categories.map((subcat, k) => (
                                                                                <li>
                                                                                    <Link to={`${item.slug}/${cat.slug}/${subcat.slug}`}>{subcat.name}</Link>
                                                                                </li>
                                                                            )) : ""
                                                                        }
                                                                        </ul>
                                                                        </>
                                                        )) : ""
                                                        
                                                    }
                                </div>
            </li>
                            ))
                            : ""
                        }
                        </ul>
                    </div>
                </div> */}
                            {/* <!----- Category Dropdown End-----> */}


                            {/* <!----- Nav Icons Start-----> */}
                            <div className="navbar-icons ms-auto">
                                <div className="user-dropdown">
                                    {/* <Link to="/view-profile" className="dropbtn"><FontAwesomeIcon icon={faUser} /><FontAwesomeIcon icon={faAngleDown} /></Link> */}
                                    {/* <div className="dropdown-content">
                            <div className="user-head">
                                <h2>Welcome back!</h2>
                            </div>
                            <div className="user-btn text-center">
                                <Link to="/login-page">Sign in</Link>
                                <Link to="">join for free</Link>
                                <span>Or</span>
                            </div>
                            <div className="user-social my-3">
                            <Link to=""><img src={FB} alt="Fb" /></Link>
                               <Link to=""><img src={Google} alt="Google" /></Link>
                               <Link to=""><img src={Linkdin} alt="Linkdin" /></Link>
                               <Link to=""><img src={Twiter} alt="Twitter" /></Link>
                            </div>
                            <div className="social-info">
                                <p>By sliding to Continue with or Create My Account , I agree to 
                                    <Link to="">DealIt.com Free Membership Agreement</Link> and 
                                    <Link to="">Receive Marketing Materials</Link></p>
                            </div>
                            <div className="user-links">
                                <Link to="">My DealIt</Link>
                                <Link to="">Manage RFQ</Link>
                                <Link to="/vendor-store">Vendor Store</Link>
                                <Link to="">Orders</Link>
                                <Link to="/favorite">Favorites</Link>
                                <Link to="/user-profile">Account</Link>
                            </div>
                            <div className="user-last">
                                <Link to="">Submit RFQ</Link>
                            </div>
                        </div> */}
                                </div>
                                <div className="msg-dropdown">
                                    {/* <button className="dropbtn"><FontAwesomeIcon icon={faMessage} /><FontAwesomeIcon icon={faAngleDown} /></button> */}
                                    {/* <div className="dropdown-content">
                            <div className="msg-head text-center">
                                <h3>Unread message reminder</h3>
                                <p>We will remind you here when there is new message. Please log in to 
                                    view.</p>
                            </div>
                            <div className="user-btn">
                                <Link to="">Sign in</Link>
                            </div>
                            <div className="msg-info">
                                <p>New user? Please <Link to="">register</Link> and start your business!</p>
                            </div>
                        </div> */}
                                </div>
                                <Link to="/add-to-cart">
                                    {/* <div className="shopping-dropdown">
                        <button className="dropbtn"><FontAwesomeIcon icon={faCartShopping} /><FontAwesomeIcon icon={faAngleDown} /></button>
                        <div className="dropdown-content">
                            <div className="shopping-drop text-center">
                                <p>You haven't signed in yet. Sign in</p>
                            </div>
                        </div>
                    </div>    */}
                                </Link>
                            </div>
                            {/* <!----- Nav Icons Start -----> */}

                            {/* <!----- Help Link Start -----> */}
                            <div className="help-link ps-2 d-lg-block d-none">
                                <div className="help-dropdown">
                                    <button className="dropbtn">Help<FontAwesomeIcon icon={faAngleDown} /></button>
                                    <div className="dropdown-content">
                                        <h5>Let Us Help You</h5>
                                        <Link to="#">Track Packages View Orders</Link>
                                        <Link to="#">Post Products</Link>
                                        <Link to="/user-profile">My Account</Link>
                                        <Link to="/return-policy">Return and Replacement</Link>
                                    </div>
                                </div>
                            </div>
                            {/* <!----- Help Link Start -----> */}

                            {/* <!----- Language Link Start -----> */}
                            <div className="lang-link px-2 d-lg-block d-none">
                                <div className="lang-dropdown pe-2">
                                    <button className="dropbtn">English-USD<FontAwesomeIcon icon={faAngleDown} /></button>
                                    <div className="dropdown-content">
                                        <div className="lang-head mb-3">
                                            <h2>Region settings</h2>
                                        </div>
                                        <div className="lang-select">
                                            <label>Language</label>
                                            <GoogleTranslator />
                                            <label>Currency</label>
                                            <select className="form-select mb-4" aria-label="Default select example">
                                                <option value="1">USD - US Dollar</option>
                                                <option value="2">HKD - Hong Kong Dollar</option>
                                                <option value="3">TOP - Tongan Pa'anga</option>
                                                <option value="4">PYG - Paraguay Guarani</option>
                                            </select>
                                        </div>
                                        <div className="lang-btn text-end">
                                            <button type="button" className="btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="ship-dropdown">
                                    <button className="dropbtn">Ship to: <img src={Flag} alt="Flag" />
                                        <FontAwesomeIcon icon={faAngleDown} />
                                    </button>
                                    <div className="dropdown-content">
                                        <div className="ship-head">
                                            <h2>Choose your location</h2>
                                            <p>Delivery options and fees may vary based on your location</p>
                                            <div className="ship-btn d-grid text-center">
                                                <button type="button" className="btn">Sign in to see your address</button>
                                            </div>
                                            <span>OR</span>
                                        </div>
                                        <div className="ship-select">
                                            <select className="form-select mb-3" aria-label="Default select example">
                                                <option value="1">English</option>
                                                <option value="2">Hindi</option>
                                                <option value="3">Punjabi</option>
                                                <option value="4">Marathi</option>
                                            </select>
                                            <input type="text" className="form-control mb-3" placeholder="Enter city or ZIP code" />
                                            <div className="ship-btn">
                                                <button type="button" className="btn">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!----- Language Link End -----> */}

                            {/* <!----- More Link Start -----> */}
                            <div className="more-link d-lg-block d-none">
                                <div className="more-dropdown">
                                    <button className="dropbtn">More<FontAwesomeIcon icon={faAngleDown} /></button>
                                    <div className="dropdown-content">
                                        <div className="more-links mb-3">
                                            <h5>DEALITT Payment Method</h5>
                                            <Link to="/payment-transaction-guide">Payment Method Help</Link>
                                            <Link to="/payment-methods">Payment Options</Link>
                                            <Link to="#">Payment Transactions</Link>

                                        </div>
                                        <div className="more-links">
                                            <h5>Sell on DEALITT.com</h5>
                                            <Link to="/blog">Blog</Link>
                                            <Link to="#">Letter of Credit</Link>
                                        </div>
                                        <div className="more-links">
                                            <h5>Customer Services</h5>
                                            {/* <Link to="/faq">Help Center</Link> */}
                                            <Link to="/privacy">Policies and Rules</Link>
                                            <Link to="/buy-and-sell-guide">How to sell and buy</Link>
                                            <Link to="/abuse-policy">Report abuse</Link>
                                        </div>
                                        {/* <div className="more-links">
                                <h5>Get to Know Us</h5>
                                <Link to="#">Careers</Link>
                            </div> */}
                                    </div>
                                </div>
                            </div>

                            {/* <!----- More Link End -----> */}
                        </nav>

                    </div>
                </section>
            </header>
        </>
    )
}

export default LoginHeader
