import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeCategory = (props) => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 2,
    centerPadding: "40px",
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  const navigatePage = (e, slug) => {
    navigate("/" + slug);
  };
  return (
    <div
      className="container mt-3 mb-3 p-4 pb-5"
      style={{ background: "#fff", borderRadius: "10px" }}
    >
      <h3>Shop By Category</h3>
      <div className="row my-4 shopByCategory_Slider">
        <div className="col-sm-12">
          <Slider {...settings}>
            {props.data && props.data.length > 0
              ? props.data.map((item, i) => (
                  <div
                    class="slide-inner byCategory"
                    onClick={(e) => navigatePage(e, item.slug)}
                  >
                    <div className="shop_ByCategory">
                      <img
                        src={
                          item.file_path
                            ? "https://api.dealitt.com/" + item.file_path
                            : ""
                        }
                        class="w-100"
                        alt=""
                        srcset=""
                      />
                      <h4 class="text-center slide-title">
                        {item.name}
                      </h4>
                    </div>
                  </div>
                ))
              : ""}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HomeCategory;
