import React, { useEffect, useState, useRef } from "react";
import { Link } from 'react-router-dom';
import AuthService from "../../services/auth.service";
import proImg from "../../images/pro-img.jpg"
const Sidebar = () => {
  const [user, setUser] = useState({});
  const url = window.location.href;
  useEffect(() => {
    const userData = AuthService.getCurrentUser();
    setUser(userData)
  }, []);
  return (
    <div className='sidebar'>
      <Link to={"/my-profile"} className="profile_new d-flex align-items-center">
        <figure className='mb-0'><img src={proImg} /></figure>
        <div className="profile-detail ps-2">
          <h6 className='mb-1 text-dark-grey'>Hello</h6>
          <p className='text-dark-grey mb-0'>{user.first_name} {user.last_name}</p>
        </div>
      </Link>

      <nav className='mt-4'>
        <ul>
          {/* <li className={url.includes('dashboard')?'active':''}>
            <Link to={"/dashboard"} >
              <i><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.5 2.75C0.5 1.50736 1.50736 0.5 2.75 0.5L7.25 0.5C8.49264 0.5 9.5 1.50736 9.5 2.75V7.25C9.5 8.49264 8.49264 9.5 7.25 9.5H2.75C1.50736 9.5 0.5 8.49264 0.5 7.25L0.5 2.75ZM12.5 2.75C12.5 1.50736 13.5074 0.5 14.75 0.5L19.25 0.5C20.4926 0.5 21.5 1.50736 21.5 2.75V7.25C21.5 8.49264 20.4926 9.5 19.25 9.5H14.75C13.5074 9.5 12.5 8.49264 12.5 7.25V2.75ZM0.5 14.75C0.5 13.5074 1.50736 12.5 2.75 12.5H7.25C8.49264 12.5 9.5 13.5074 9.5 14.75V19.25C9.5 20.4926 8.49264 21.5 7.25 21.5H2.75C1.50736 21.5 0.5 20.4926 0.5 19.25L0.5 14.75ZM12.5 14.75C12.5 13.5074 13.5074 12.5 14.75 12.5H19.25C20.4926 12.5 21.5 13.5074 21.5 14.75V19.25C21.5 20.4926 20.4926 21.5 19.25 21.5H14.75C13.5074 21.5 12.5 20.4926 12.5 19.25V14.75Z" fill="#F6F5FA" stroke="#161616" />
              </svg>
              </i><span>Dashboard</span>
            </Link>
          </li> */}



          <li className={url.includes('orders') ? 'active' : ''}>
            <Link to={"/dashboard-order"}>
              <i><svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.5 2L4.5 8.87868L15 19.3787L21.8787 12.5L11.3787 2L4.5 2ZM3 2C3 1.17157 3.67157 0.5 4.5 0.5H11.3787C11.7765 0.5 12.158 0.658035 12.4393 0.93934L22.9393 11.4393C23.5251 12.0251 23.5251 12.9749 22.9393 13.5607L16.0607 20.4393C15.4749 21.0251 14.5251 21.0251 13.9393 20.4393L3.43934 9.93934C3.15803 9.65803 3 9.2765 3 8.87868V2Z" fill="#161616" />
                <path d="M8.25 6.5C7.83579 6.5 7.5 6.16421 7.5 5.75C7.5 5.33579 7.83579 5 8.25 5C8.66421 5 9 5.33579 9 5.75C9 6.16421 8.66421 6.5 8.25 6.5ZM8.25 8C9.49264 8 10.5 6.99264 10.5 5.75C10.5 4.50736 9.49264 3.5 8.25 3.5C7.00736 3.5 6 4.50736 6 5.75C6 6.99264 7.00736 8 8.25 8Z" fill="#161616" />
                <path d="M1.5 9.62868C1.5 10.0265 1.65803 10.408 1.93934 10.6893L13.125 21.875L13.0607 21.9393C12.4749 22.5251 11.5251 22.5251 10.9393 21.9393L0.439339 11.4393C0.158034 11.158 0 10.7765 0 10.3787V3.5C0 2.67157 0.671573 2 1.5 2V9.62868Z" fill="#161616" />
              </svg></i><span>Order</span>
            </Link>
          </li>
          <li className={url.includes('orders') ? 'active' : ''}>
            <Link to={"/refund-request"}>
              <i><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 640 512"><path d="M629.657 343.598L528.971 444.284c-9.373 9.372-24.568 9.372-33.941 0L394.343 343.598c-9.373-9.373-9.373-24.569 0-33.941l10.823-10.823c9.562-9.562 25.133-9.34 34.419.492L480 342.118V160H292.451a24.005 24.005 0 0 1-16.971-7.029l-16-16C244.361 121.851 255.069 96 276.451 96H520c13.255 0 24 10.745 24 24v222.118l40.416-42.792c9.285-9.831 24.856-10.054 34.419-.492l10.823 10.823c9.372 9.372 9.372 24.569-.001 33.941zm-265.138 15.431A23.999 23.999 0 0 0 347.548 352H160V169.881l40.416 42.792c9.286 9.831 24.856 10.054 34.419.491l10.822-10.822c9.373-9.373 9.373-24.569 0-33.941L144.971 67.716c-9.373-9.373-24.569-9.373-33.941 0L10.343 168.402c-9.373 9.373-9.373 24.569 0 33.941l10.822 10.822c9.562 9.562 25.133 9.34 34.419-.491L96 169.881V392c0 13.255 10.745 24 24 24h243.549c21.382 0 32.09-25.851 16.971-40.971l-16.001-16z" /></svg></i><span>Refund Requests</span>
            </Link>
          </li>


          <li className={url.includes('invoice') ? 'active' : ''}>
            <Link to={"/invoice"}>
              <i><svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V15C24 16.6569 22.6569 18 21 18H3C1.34315 18 0 16.6569 0 15V3ZM3 1.5C2.17157 1.5 1.5 2.17157 1.5 3V4.5H22.5V3C22.5 2.17157 21.8284 1.5 21 1.5H3ZM22.5 7.5H1.5V15C1.5 15.8284 2.17157 16.5 3 16.5H21C21.8284 16.5 22.5 15.8284 22.5 15V7.5Z" fill="#161616" />
                <path d="M3 12C3 11.1716 3.67157 10.5 4.5 10.5H6C6.82843 10.5 7.5 11.1716 7.5 12V13.5C7.5 14.3284 6.82843 15 6 15H4.5C3.67157 15 3 14.3284 3 13.5V12Z" fill="#161616" />
              </svg> </i><span>Invoice Manager</span>
            </Link>
          </li>

          <li className={url.includes('review') ? 'active' : ''}>
            <Link to={"/review"}>
              <i><svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.02897 7.08223L11.3052 0.448201C11.4523 0.150244 11.7248 0.000844778 11.9977 3.56861e-06C12.2721 -0.000841949 12.5469 0.14856 12.6948 0.448204L15.971 7.08223L23.3187 8.14924C23.7194 8.20743 23.9712 8.55309 23.9977 8.9235C24.0008 8.96646 24.0008 9.00975 23.9977 9.05297C23.9833 9.25226 23.902 9.44992 23.7421 9.60575L18.4586 14.7526L19.7031 22.0045C19.8198 22.6845 19.1635 23.2167 18.5838 22.9122L12 19.453L5.41616 22.9122C5.3437 22.9502 5.27005 22.9752 5.19685 22.9885C4.68449 23.0813 4.19474 22.5995 4.29685 22.0045L5.54144 14.7526L0.257945 9.60575C0.080497 9.43289 -0.000170449 9.20857 2.70384e-07 8.98765C0.000126456 8.82435 0.044417 8.66291 0.126434 8.52547C0.242222 8.33144 0.433201 8.18526 0.681258 8.14924L8.02897 7.08223ZM11.9977 17.674C12.117 17.6736 12.2363 17.7023 12.3462 17.76L17.8749 20.6647L16.8335 14.5969C16.7844 14.3108 16.8767 14.0179 17.0776 13.8222L21.4379 9.57475L15.3594 8.69206C15.1081 8.65557 14.8887 8.49152 14.7702 8.2515L12 2.64205L11.9977 2.64675V17.674Z" fill="black" />
              </svg></i><span>Reviews</span>
            </Link>

          </li>


          <li className={url.includes('chats') ? 'active' : ''}>
            <Link to={"/chats"} >
              <i><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512"><path d="M256 32C114.6 32 0 125.1 0 240c0 49.6 21.4 95 57 130.7C44.5 421.1 2.7 466 2.2 466.5c-2.2 2.3-2.8 5.7-1.5 8.7S4.8 480 8 480c66.3 0 116-31.8 140.6-51.4 32.7 12.3 69 19.4 107.4 19.4 141.4 0 256-93.1 256-208S397.4 32 256 32zM128 272c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 0c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z" /></svg>
              </i><span>Chats</span>
            </Link>

          </li>
          <li className={url.includes('chats') ? 'active' : ''}>
            <Link to={"/Account-details"} >
              <i><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 496 512"><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z" /></svg>
              </i><span>Account Details</span>
            </Link>

          </li>





        </ul>
      </nav>
    </div>

  )
}

export default Sidebar;