import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import DataService from "../../services/data.service";
import AuthService from "../../services/auth.service";

const ActivateAccount = (props) => {
   const params = useParams();
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const auth = AuthService.getCurrentUser();

   React.useEffect(() => {
      document.title = "Activate Account";
   }, []);


   const navigateToNextPage = async () => {
      setLoading(true);
      const data = {};
      data.token = params.token;
      await DataService.activateAccount(data).then(
         () => {
            setLoading(false);
            toast.success('Account Activated Successfully', {
               position: toast.POSITION.TOP_RIGHT
            });
            setTimeout(() => {
               if (auth) {
                  navigate('/');
               } else {
                  navigate('/login-page')
               }
            }, 1000);
         },
         (error) => {
            const resMessage = error?.response?.data?.msg;
            setTimeout(() => {
               if (auth) {
                  navigate('/');
               } else {
                  navigate('/login-page')
               }
            }, 1000);
            setLoading(false);
            toast.error(resMessage, {
               position: toast.POSITION.TOP_RIGHT
            });
         });
   }
   useEffect(() => {
      if (params?.token) {
         navigateToNextPage();
      }
   }, [params])

   return (
      <></>
   );
};
export default ActivateAccount;
