import React, {useEffect} from 'react'
import Footer from "../common/Footer";
import Header from "../common/Header";
import Banner from '../common/Banner';
import OurStoryDealit from './component/ourstory/OurStoryDealit';

const OurStory = () => {
    useEffect(() => {
        document.title = "Our Story";
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <Header />
            <Banner title={"Our Story"} />
            <OurStoryDealit />
            <Footer />
        </>
    )
}

export default OurStory