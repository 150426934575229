import React, { useEffect, useState, useRef } from "react";
import DataService from "../../../../services/data.service";
import HelperService from "../../../../services/helper.service";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Editor } from '@tinymce/tinymce-react';
import TagsInput from '../../../common/TagsInput'

const styles = {
    input: {
        opacity: '0%', // dont want to see it
        position: 'absolute' // does not mess with other elements 
    }
}
const MAX_COUNT = 5;
const serverUrl = 'https://api.dealitt.com/'

let oldImages = [];
let oldDocuments = [];
const EditProductSection = () => {
    const params = useParams();
    const form = React.useRef();
    const editorRef = useRef(null);
    const [name, setName] = useState("");
    const [tag, setTag] = useState("");
    const [description, setDescription] = useState("");
    const [detail, setDetail] = useState("");
    const [brand, setBrand] = useState(null);
    const [group, setGroup] = useState("");
    const [deliveryTime, setdeliveryTime] = useState("");
    const [sku, setSku] = useState("");
    const [quantity, setquantity] = useState("");
    const [costPrice, setcostPrice] = useState("");
    const [mrp, setMrp] = useState("");
    const [priceGroup, setPriceGroup] = useState([]);
    const [unit, setUnit] = useState("Piece/Pieces");
    const [offerPrice, setOfferPrice] = useState("");
    const [masterCategoryId, setMasterCatId] = useState(null);
    const [categoryId, setCatId] = useState(null);
    const [subCategoryId, setbSuCatId] = useState(null);
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [masterCategory, setmastercategory] = useState([]);
    const [category, setcategory] = useState([]);
    const [subCategory, setsubCategory] = useState([]);
    const [allCategory, setAllCategory] = useState([]);
    const [allBrand, setAllBrand] = useState([]);
    const [product, setProduct] = useState({});
    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [video, setVideo] = useState(null);
    const inputFileVideoRef = React.useRef();
    const videoRef = React.useRef();
    const [dataLoaded, setdataLoaded] = useState(false);
    const [fileLimit, setFileLimit] = useState(false);
    const inputFileRef = React.useRef();
    const imgRef = React.useRef();
    const navigate = useNavigate();
    const [variantCount, setVariantCount] = useState(0);
    const [inputFields, setInputFields] = useState([]);
    const [inputVariations, setInputVariations] = useState([]);
    const [allActive, setAllActive] = useState(false);
    const [allMrp, setAllMrp] = useState(false);
    const [allOffer, setAllOffer] = useState(false);
    const [certifications, setCertifications] = useState([]);
    const [leadTimes, setLeadTimes] = useState([]);

    useEffect(() => {
        getData();
    }, [images, documents]);

    const getData = async () => {
        await DataService.getAllBrand().then((data) => {
            const catData = data.data.data;
            setAllBrand(catData)
            //setLoading(false);
        });
        await DataService.getAllCategory().then((data) => {
            const catData = data.data.categories;
            setAllCategory(catData);
            const masterCatData = catData.filter(value => value.type === '0')
            setmastercategory(masterCatData)
            getProduct(catData);
        });
    }

    const getProduct = async (categoryData) => {
        await DataService.getVendorProductDetail(params.id).then((data) => {
            setDataLoading(false);
            const productData = data.data.category;
            const productImages = (productData?.images) ? JSON.parse(productData?.images) : [];
            const productDocuments = (productData?.documents) ? JSON.parse(productData?.documents) : [];
            if (!oldImages.length) {
                productImages.forEach(value => {
                    let imagePath = serverUrl + value.file_path;
                    oldImages.push(imagePath)
                })
            }
            if (!oldDocuments.length) {
                productDocuments.forEach(value => {
                    oldDocuments.push("../../assets/img/pdf.webp")
                })
            }

            const catData = categoryData.filter(value => value.parent_id == productData?.master_category_id && value.type === '1');
            setcategory(catData);
            const subCatData = categoryData.filter(value => value.parent_id == productData?.category_id && value.type === '2');
            setsubCategory(subCatData);
            setName(productData?.name);
            if (productData.tag) {
                setTag(productData?.tag);
            }
            setdeliveryTime(productData?.delivery_time);
            setBrand(productData?.brand_id);
            setGroup(productData?.group);
            setDescription(productData?.description);
            setSku(productData?.sku);
            setquantity(productData?.quantity);
            setcostPrice(productData?.cost_price)
            setMrp(productData?.mrp);
            setOfferPrice(productData?.offer_price);
            setMasterCatId(productData?.master_category_id);
            setCatId(productData?.category_id);
            setbSuCatId(productData?.sub_category_id);
            setProduct(productData);
            setUnit((productData?.unit) ? productData?.unit : "Piece/Pieces");
            if (productData?.price_groups) {
                setPriceGroup(JSON.parse(productData?.price_groups));
            }
            if (productData?.certifications.length > 0) {
                var certs = [];
                if (productData?.certifications) {
                    certs = JSON.parse(productData?.certifications);
                    if (!Array.isArray(certs)) {
                        certs = [certs];
                    }
                }
                setCertifications(certs);
            }
            if (productData?.lead_time_days.length > 0) {
                setLeadTimes((productData?.lead_time_days ? JSON.parse(productData?.lead_time_days) : []));
            }
            if (productData?.variant && productData?.Variations && productData?.Variations.length > 0) {
                var variants = JSON.parse(productData?.variant);
                if (variants.length > 0) {
                    setInputFields(variants);
                    setVariantCount(variants.length)
                    setInputVariations(productData?.Variations);
                }
            }
            setdataLoaded(true)

        }).catch((error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.msg) ||
                error.message ||
                error.toString();
            toast.error(resMessage, {
                position: toast.POSITION.TOP_RIGHT
            });
            setDataLoading(false);
        })
    }
    const onFileChangeCapture = (e) => {
        /*Selected files data can be collected here.*/
        const file = e.target.files[0]
        setFile(e.target.files)
        const reader = new FileReader();
        const url = reader.readAsDataURL(file);
        reader.onloadend = function (theFile) {
            var image = new Image();
            image.src = theFile.target.result;
            imgRef.current.src = image.src

        }
    };
    const triggerVideoFile = () => {
        /*Collecting node-element and performing click*/
        inputFileVideoRef.current.click();
    };
    const onFileVideoChangeCapture = (e) => {
        /*Selected files data can be collected here.*/
        setVideo(e.target.files)
        videoRef.current.src = "../assets/img/video.png";
    };
    const handleUploadedFiles = files => {
        const uploaded = (uploadedFiles ? uploadedFiles : []);
        let limitExceeded = false;
        let imageSrc = [];
        if (images.length) {
            images.map((img, i) => {
                imageSrc.push(img)
            });
        }
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                const reader = new FileReader();
                const url = reader.readAsDataURL(file);
                reader.onloadend = function (theFile) {
                    var image = new Image();
                    image.src = theFile.target.result;
                    imageSrc.push(image.src)
                }
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    toast.error(`You can only uploaded a maximun of ${MAX_COUNT} files`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLimit(true);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) {
            setUploadedFiles(uploaded);
            setImages(imageSrc);

        }
    }
    const onFileChangeCaptureMultiple = (e) => {
        const choosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadedFiles(choosenFiles)
    }
    const handleUploadedDocuments = files => {
        const uploaded = (uploadedDocuments ? uploadedDocuments : []);
        let limitExceeded = false;
        let imageSrc = [];
        if (documents.length) {
            documents.map((img, i) => {
                imageSrc.push(img)
            });
        }
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
                const reader = new FileReader();
                const url = reader.readAsDataURL(file);
                reader.onloadend = function (theFile) {
                    var extenstion = file.name.split('.').pop();
                    if (extenstion == "pdf") {
                        imageSrc.push("../../assets/img/pdf.webp");
                    } else {
                        var image = new Image();
                        image.src = theFile.target.result;
                        imageSrc.push(image.src)
                    }
                }
                if (uploaded.length === MAX_COUNT) setFileLimit(true);
                if (uploaded.length > MAX_COUNT) {
                    toast.error(`You can only uploaded a maximun of ${MAX_COUNT} files`, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFileLimit(true);
                    limitExceeded = true;
                    return true;
                }
            }
        })
        if (!limitExceeded) {
            setUploadedDocuments(uploaded);
            setDocuments(imageSrc);
        }
    }
    const onDocumentChangeCaptureMultiple = (e) => {
        const choosenFiles = Array.prototype.slice.call(e.target.files);
        handleUploadedDocuments(choosenFiles)
    }
    const onChangeDetail = (e) => {
        const data = e.target.value;
        setDetail(data);
    }
    const onChangeName = (e) => {
        const name = e.target.value;
        setName(name);
    };
    const onChangeTag = (e) => {
        const value = e.target.value;
        setTag(value);
    }
    const onChangeDeliveryTime = (e) => {
        const deliverTime = e.target.value;
        setdeliveryTime(deliverTime);
    };
    const onChangeBrand = (e) => {
        const brnadId = (e.target.value) ? e.target.value : "";
        setBrand(brnadId);
    };
    const onChangeGroup = (e) => {
        const group = e.target.value;
        setGroup(group);
    };
    const onChangeDescription = (e) => {
        const description = e.target.value;
        setDescription(description);
    };
    const onChangeSku = (e) => {
        const sku = e.target.value;
        setSku(sku);
    };
    const onChangeUnit = (e) => {
        setUnit(e.target.value);
    };
    const onChangeQuantity = (e) => {
        const quantity = e.target.value;
        setquantity(quantity);
    };
    const onChangeCostPrice = (e) => {
        const price = e.target.value;
        setcostPrice(price);
    };
    const onChangeMrp = (e) => {
        var groupArr = [];
        var all = document.getElementsByClassName('price-row');
        if (all && all.length > 0) {
            for (var i = 0; i < all.length; i++) {
                var mrp = all[i].querySelector(".mrp").value;
                var min = all[i].querySelector(".min").value;
                var max = all[i].querySelector(".max").value;
                if (mrp && min) {
                    if (i == 0) {
                        setMrp(mrp)
                        setcostPrice(mrp);
                        setOfferPrice(mrp);
                    }
                    groupArr.push({ min: min, max: max, mrp: mrp })
                }
            }
        }
        setPriceGroup(groupArr);
    };
    const onChangeOfferPrice = (e) => {
        const offerPrice = e.target.value;
        setOfferPrice(offerPrice);
    };
    const handleChange = (e) => {
        const parentId = (e.target.value) ? e.target.value : "";
        setMasterCatId(parentId);
        const catData = allCategory.filter(value => value.parent_id == parentId && value.type === '1');
        setcategory(catData);
    };
    const handleChangeCat = (e) => {
        const parentId = (e.target.value) ? e.target.value : "";
        setCatId(parentId);
        const catData = allCategory.filter(value => value.parent_id === parentId && value.type === '2');
        setsubCategory(catData);
    };
    const handleChangeSubCat = (e) => {
        const parentId = (e.target.value) ? e.target.value : "";
        setbSuCatId(parentId);
    };
    const triggerFile = () => {
        /*Collecting node-element and performing click*/
        inputFileRef.current.click();
    };

    const handleLeadsChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...leadTimes];
        list[index][name] = value;
        setLeadTimes(list);
    }

    const addLeadTimeField = () => {
        setLeadTimes([...leadTimes, {
            qty: "",
            days: ""
        }])
    }

    const removeLeadTimeFields = (index, evnt) => {
        const rows = [...leadTimes];
        rows.splice(index, 1);
        setLeadTimes(rows);
    }

    const addInputField = () => {
        setInputFields([...inputFields, {
            variant: '',
            values: []
        }])
        setVariantCount(variantCount + 1);
    }

    const removeInputFields = (index, evnt) => {
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
        setVariantCount(variantCount - 1);
        setCombinations(rows);
    }
    const handleVariantChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

    const selectedTags = (index, tags) => {
        const list = [...inputFields];
        list[index]['values'] = tags;
        setInputFields(list);
        setCombinations(list);
    };

    const setCombinations = async (list) => {
        const cs = [];
        const combinations = await makeCombinations(list.filter((it) => { return (it.values && it.values.length > 0) }));
        if (combinations && combinations.length > 0) {
            await Promise.all(combinations.map(ele => {
                cs.push({
                    name: ele,
                    mrp: "",
                    offer_price: "",
                    status: "active"
                })
            }))
        }
        setInputVariations(cs);
    }

    const makeCombinations = (arrays, i = 0) => {

        if (arrays[i]) {
        } else {
            return []
        }
        if (i == arrays.length - 1) {
            return arrays[i].values;
        }

        //get combinations from subsequent arrays
        var tmparray = makeCombinations(arrays, i + 1);

        var result = [];

        // concat each array from tmp with each element from $arrays[$i]
        arrays[i].values.forEach(e1 => {
            tmparray.forEach(e2 => {
                result.push(e1 + ' + ' + e2)
            })
        })

        return result;
    }

    const changeAllChecked = (e) => {
        if (e.target.checked) {
            setAllActive(true)
        } else {
            setAllActive(false)
        }
        var all = document.getElementsByClassName('variationStatus');
        if (all && all.length > 0) {
            for (var i = 0; i < all.length; i++) {
                handleVariantionChange(i, 'status', (e.target.checked ? "active" : "inactive"))
            }
        }
    }

    const changeAllMrp = (e) => {
        if (e.target.checked) {
            setAllMrp(true)
            setAllValues('variantMrp')
        } else {
            setAllMrp(false)
        }
    }

    const changeAllOffer = (e) => {
        if (e.target.checked) {
            setAllOffer(true)
            setAllValues('variantOffer')
        } else {
            setAllOffer(false)
        }
    }

    const setAllValues = (classname) => {
        var all = document.getElementsByClassName(classname);
        if (all && all.length > 0) {
            var main = all[0];
            for (var i = 0; i < all.length; i++) {
                handleVariantionChange(i, all[i].name, main.value)
            }
        }
    }

    const setVariantOffer = (index, e) => {
        handleVariantionChange(index, 'offer_price', e.target.value)
        if (allOffer) {
            setAllValues('variantOffer')
        }
    }

    const setVariantMrp = (index, e) => {
        handleVariantionChange(index, 'mrp', e.target.value)
        if (allMrp) {
            setAllValues('variantMrp')
        }
    }

    const setVariationStatus = (index, e) => {
        handleVariantionChange(index, 'status', (e.target.checked ? "active" : "inactive"))
    }

    const handleVariantionChange = (index, key, value) => {
        const list = [...inputVariations];
        list[index][key] = value;
        setInputVariations(list);
        setHiddenPrices(key)
    }

    const setHiddenPrices = (classname) => {
        if (classname == "mrp" || classname == "offer_price") {
            var all = document.getElementsByClassName((classname == "mrp" ? "variantMrp" : "variantOffer"));
            if (all && all.length > 0) {
                var main = all[0];
                if (classname == "mrp") {
                    setcostPrice(main.value)
                    setMrp(main.value)
                } else if (classname == "offer_price") {
                    setOfferPrice(main.value)
                }
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage("");
        setLoading(true);
        const data = new FormData();
        if (file && file.length > 0) {
            data.append('image', file[0])
        }
        if (uploadedFiles && uploadedFiles.length > 0) {
            uploadedFiles.some((file) => {
                data.append('images', file)
            })
        }
        if (video && video.length > 0) {
            data.append('video', video[0])
        }
        if (uploadedDocuments && uploadedDocuments.length > 0) {
            uploadedDocuments.some((file) => {
                data.append('documents', file)
            })
        }
        data.append('name', name);
        data.append('description', description)
        if (brand) {
            data.append('brand_id', brand)
        }
        data.append('group', group)
        data.append('delivery_time', deliveryTime)
        data.append('sku', sku)
        data.append('tag', tag)
        data.append('quantity', quantity)
        data.append('cost_price', costPrice)
        data.append('mrp', mrp)
        data.append('unit', unit);
        data.append('offer_price', offerPrice > 0 ? offerPrice : mrp)
        data.append('price_groups', (priceGroup.length > 0 ? JSON.stringify(priceGroup) : ''))
        data.append('details', editorRef.current.getContent())
        data.append('variant', (inputFields.length > 0 ? JSON.stringify(inputFields) : ''))
        data.append('variations', (inputVariations.length > 0 ? JSON.stringify(inputVariations) : ''))
        data.append('lead_time_days', (leadTimes.length > 0 ? JSON.stringify(leadTimes) : ''))
        if (masterCategoryId) {
            data.append('master_category_id', masterCategoryId)
        }
        if (categoryId) {
            data.append('category_id', categoryId)
        }
        if (subCategoryId) {
            data.append('sub_category_id', subCategoryId)
        }
        const certifications = document.querySelectorAll(".product_certifications:checked");
        if (certifications.length > 0) {
            for (let r = 0; r < certifications.length; r++) {
                data.append("certifications", certifications[r].value);
            }
        } else {
            data.append("certifications", "");
        }

        DataService.updateProduct(data, product?.id).then(
            () => {
                toast.success('Product updated successfully', {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoading(false);
                // navigate("/products");
                setTimeout(function () {
                    window.location.reload();
                }, 1500)
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.msg) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                toast.error(resMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        );
    };

    const deleteImage = (e, index, api = true) => {
        if (api == true) {
            DataService.deleteProductImage(product?.id, index).then(
                () => {
                    toast.success('Image deleted!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    document.getElementById('api-image-' + index).remove();
                },
                (error) => { }
            );
        } else {
            if (uploadedFiles && uploadedFiles.length > 0) {
                var uploaded = uploadedFiles.filter((file, i) => {
                    return index != i
                })
                var imageSrc = [];
                var ss = uploaded.some((file) => {
                    const reader = new FileReader();
                    const url = reader.readAsDataURL(file);
                    reader.onloadend = function (theFile) {
                        var image = new Image();
                        image.src = theFile.target.result;
                        imageSrc.push(image.src)
                    }
                })
                setUploadedFiles(uploaded);
                setImages(imageSrc);
            }
        }
    }

    const deleteDocument = (e, index, api = true) => {
        if (api == true) {
            DataService.deleteProductDocument(product?.id, index).then(
                () => {
                    toast.success('Document deleted!', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    document.getElementById('api-document-' + index).remove();
                },
                (error) => { }
            );
        } else {
            if (uploadedDocuments && uploadedDocuments.length > 0) {
                var uploaded = uploadedDocuments.filter((file, i) => {
                    return index != i
                })
                var imageSrc = [];
                var ss = uploaded.some((file) => {
                    const reader = new FileReader();
                    const url = reader.readAsDataURL(file);
                    reader.onloadend = function (theFile) {
                        var extenstion = file.name.split('.').pop();
                        if (extenstion == "pdf") {
                            imageSrc.push("../../assets/img/pdf.webp");
                        } else {
                            var image = new Image();
                            image.src = theFile.target.result;
                            imageSrc.push(image.src)
                        }
                    }
                })
                setUploadedDocuments(uploaded);
                setDocuments(imageSrc);
            }
        }
    }

    return (
        <div className="container-fluid">
            <ToastContainer></ToastContainer>
            <div className="row">
                <div className="d-flex w-100 justify-content-between align-items-center mb-4">
                    <h4 className="mb-0">Edit Product</h4>
                    {/* <button className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#ImportProuct">Import Product</button>
                    <div class="modal fade" id="ImportProuct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg">
                            <div class="modal-content">

                                <div class="modal-body bg-yellow"> 
                                    <button type="button" class="btn-close float-end" data-bs-dismiss="modal" aria-label="Close"></button>
                                    <div class="card-body p-4 importSectionModal bg-white rounded-5 m-5">
                                        
                                        <div class="mb-4">
                                        <label class="form-label">Upload File</label>
                                            <div class="upload-box">
                                                <i><svg width="47" height="39" viewBox="0 0 47 39" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M32 27.5L24 19.5L16 27.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M24 19.5V37.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M40.7799 32.28C42.7306 31.2165 44.2716 29.5337 45.1597 27.4972C46.0477 25.4607 46.2323 23.1864 45.6843 21.0334C45.1363 18.8803 43.8869 16.971 42.1333 15.6069C40.3796 14.2427 38.2216 13.5014 35.9999 13.5H33.4799C32.8745 11.1585 31.7462 8.98464 30.1798 7.14195C28.6134 5.29927 26.6496 3.83567 24.4361 2.86118C22.2226 1.8867 19.817 1.42669 17.4002 1.51573C14.9833 1.60478 12.6181 2.24057 10.4823 3.3753C8.34649 4.51003 6.49574 6.11417 5.06916 8.06713C3.64259 10.0201 2.6773 12.271 2.24588 14.6508C1.81446 17.0305 1.92813 19.477 2.57835 21.8065C3.22856 24.136 4.3984 26.2877 5.99992 28.1" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M32 27.5L24 19.5L16 27.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                </i>
                                                <div class="ms-3">
                                                    <h5>Select a file or drag and drop here</h5>
                                                    <p class="mb-0 text-secondary">JPG, PNG or PDF, file size no more than 10MB</p>
                                                </div>
                                                <div class="upload-btn-wrapper ms-auto ms-3">
                                                    <button class="btn-file">Select file</button>
                                                    <input type="file" name="myfile" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-start btn-min-width">
                                            <button class="btn btn-primary">
                                                <span>Save</span>
                                            </button></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {Object.keys(product).length > 0 ?
                <form onSubmit={handleSubmit} className="mt-4 login" ref={form}>
                    {message && (
                        <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-xxl-3 col-lg-4">
                            <div className="card mb-4">
                                <div className="card-body text=center">
                                    <h4 className="f-700">Thumbnail</h4>
                                    <div className="Product-thumbnail" onClick={triggerFile}>
                                        {(product.file_path ?
                                            <img style={{ width: '100%' }} src={"https://api.dealitt.com/" + product.file_path} ref={imgRef} alt="product" />
                                            : <img style={{ width: '100%' }} src="../assets/img/img-placeholder.svg" ref={imgRef} alt="product" />
                                        )}
                                    </div>
                                    <p className="text-center">Set the product thumbnail image. Only
                                        *.png, *.jpg and *.jpeg image files
                                        are accepted</p>
                                </div>
                                <input
                                    type="file"
                                    ref={inputFileRef}
                                    accept="image/*"
                                    style={styles.input}
                                    onChangeCapture={onFileChangeCapture}
                                />
                            </div>

                            <div className="card mb-5">
                                <div className="card-body p-4">
                                    <label className="form-label"> <h4 className="f-700">Media</h4></label>
                                    <div className="upload-box">
                                        <i><svg width="47" height="39" viewBox="0 0 47 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M32 27.5L24 19.5L16 27.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M24 19.5V37.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M40.7799 32.28C42.7306 31.2165 44.2716 29.5337 45.1597 27.4972C46.0477 25.4607 46.2323 23.1864 45.6843 21.0334C45.1363 18.8803 43.8869 16.971 42.1333 15.6069C40.3796 14.2427 38.2216 13.5014 35.9999 13.5H33.4799C32.8745 11.1585 31.7462 8.98464 30.1798 7.14195C28.6134 5.29927 26.6496 3.83567 24.4361 2.86118C22.2226 1.8867 19.817 1.42669 17.4002 1.51573C14.9833 1.60478 12.6181 2.24057 10.4823 3.3753C8.34649 4.51003 6.49574 6.11417 5.06916 8.06713C3.64259 10.0201 2.6773 12.271 2.24588 14.6508C1.81446 17.0305 1.92813 19.477 2.57835 21.8065C3.22856 24.136 4.3984 26.2877 5.99992 28.1" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M32 27.5L24 19.5L16 27.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        </i>
                                        <div class="upload-btn-wrapper ms-3">
                                            <button class="btn-file">Select a file or drag and drop here</button>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="myfile"
                                                style={{ width: "100%", height: "100%" }}
                                                multiple
                                                onChangeCapture={onFileChangeCaptureMultiple} />
                                        </div>
                                    </div>

                                    <ul className="thumbs-img mt-3">
                                        {oldImages && oldImages.length > 0
                                            ? oldImages.map((item, i) => (
                                                <li id={`api-image-${(i + 1)}`} style={{ position: 'relative' }}>
                                                    <i><img width="27" src={item} alt='product' /></i>
                                                    <div class="Delete-image" onClick={(e) => { if (window.confirm('Are you sure you want to delete this image?')) deleteImage(e, (i + 1)) }}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.410582 0.410749C0.736019 0.0853125 1.26366 0.0853125 1.58909 0.410749L5.99984 4.82149L10.4106 0.410749C10.736 0.0853125 11.2637 0.0853125 11.5891 0.410749C11.9145 0.736186 11.9145 1.26382 11.5891 1.58926L7.17835 6.00001L11.5891 10.4108C11.9145 10.7362 11.9145 11.2638 11.5891 11.5893C11.2637 11.9147 10.736 11.9147 10.4106 11.5893L5.99984 7.17852L1.58909 11.5893C1.26366 11.9147 0.736019 11.9147 0.410582 11.5893C0.0851447 11.2638 0.0851447 10.7362 0.410582 10.4108L4.82133 6.00001L0.410582 1.58926C0.0851447 1.26382 0.0851447 0.736186 0.410582 0.410749Z" fill="black"></path></svg></div>
                                                </li>
                                            ))
                                            : ""}
                                        {images && images.length > 0 || oldImages
                                            ? images.map((item, i) => (
                                                <li id={`local-image-${i}`} style={{ position: 'relative' }}>
                                                    <i><img width="27" src={item} alt='product' /></i>
                                                    <div class="Delete-image" onClick={(e) => { if (window.confirm('Are you sure you want to delete this image?')) deleteImage(e, i, false) }}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.410582 0.410749C0.736019 0.0853125 1.26366 0.0853125 1.58909 0.410749L5.99984 4.82149L10.4106 0.410749C10.736 0.0853125 11.2637 0.0853125 11.5891 0.410749C11.9145 0.736186 11.9145 1.26382 11.5891 1.58926L7.17835 6.00001L11.5891 10.4108C11.9145 10.7362 11.9145 11.2638 11.5891 11.5893C11.2637 11.9147 10.736 11.9147 10.4106 11.5893L5.99984 7.17852L1.58909 11.5893C1.26366 11.9147 0.736019 11.9147 0.410582 11.5893C0.0851447 11.2638 0.0851447 10.7362 0.410582 10.4108L4.82133 6.00001L0.410582 1.58926C0.0851447 1.26382 0.0851447 0.736186 0.410582 0.410749Z" fill="black"></path></svg></div>
                                                </li>
                                            )) : <><li><i><svg width="27" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.4759 4.75448C4.98561 4.75448 3.65994 5.72109 3.17856 7.16013L3.14604 7.26855C3.03249 7.64961 2.98492 7.97009 2.98492 8.29075V14.7203L0.72654 7.08378C0.436099 5.96056 1.098 4.79606 2.20852 4.48577L16.6034 0.580665C16.7831 0.533541 16.9628 0.510909 17.1397 0.510909C18.0668 0.510909 18.914 1.13423 19.1514 2.05284L19.9901 4.75448H6.4759Z" fill="#DCE5F1" />
                                                <path d="M9.03623 14.6561C10.0631 14.6561 10.898 15.5019 10.898 16.5421C10.898 17.5823 10.0631 18.4282 9.03623 18.4282C8.00939 18.4282 7.17432 17.5823 7.17432 16.5421C7.17432 15.5019 8.00939 14.6561 9.03623 14.6561Z" fill="#DCE5F1" />
                                                <path d="M20.6725 21.2572H6.70859C5.42589 21.2572 4.38135 20.1991 4.38135 18.8996V8.52655C4.38135 7.22701 5.42589 6.1689 6.70859 6.1689H20.6725C21.9553 6.1689 22.9999 7.22701 22.9999 8.52655V18.8996C22.9999 20.1991 21.9553 21.2572 20.6725 21.2572ZM6.70859 19.3711H20.6725C20.9295 19.3711 21.138 19.16 21.138 18.8996V12.2051L18.1973 15.6812C17.8853 16.0517 17.4337 16.2498 16.9488 16.261C16.4666 16.2582 16.0142 16.0414 15.7051 15.666L12.2476 11.4621L11.1212 12.6004C10.4845 13.2453 9.44835 13.2453 8.81255 12.6004L6.24322 9.99854V18.8996C6.24322 19.16 6.45173 19.3711 6.70859 19.3711Z" fill="#DCE5F1" />
                                            </svg>
                                            </i></li><li><i><svg width="27" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.4759 4.75448C4.98561 4.75448 3.65994 5.72109 3.17856 7.16013L3.14604 7.26855C3.03249 7.64961 2.98492 7.97009 2.98492 8.29075V14.7203L0.72654 7.08378C0.436099 5.96056 1.098 4.79606 2.20852 4.48577L16.6034 0.580665C16.7831 0.533541 16.9628 0.510909 17.1397 0.510909C18.0668 0.510909 18.914 1.13423 19.1514 2.05284L19.9901 4.75448H6.4759Z" fill="#DCE5F1" />
                                                <path d="M9.03623 14.6561C10.0631 14.6561 10.898 15.5019 10.898 16.5421C10.898 17.5823 10.0631 18.4282 9.03623 18.4282C8.00939 18.4282 7.17432 17.5823 7.17432 16.5421C7.17432 15.5019 8.00939 14.6561 9.03623 14.6561Z" fill="#DCE5F1" />
                                                <path d="M20.6725 21.2572H6.70859C5.42589 21.2572 4.38135 20.1991 4.38135 18.8996V8.52655C4.38135 7.22701 5.42589 6.1689 6.70859 6.1689H20.6725C21.9553 6.1689 22.9999 7.22701 22.9999 8.52655V18.8996C22.9999 20.1991 21.9553 21.2572 20.6725 21.2572ZM6.70859 19.3711H20.6725C20.9295 19.3711 21.138 19.16 21.138 18.8996V12.2051L18.1973 15.6812C17.8853 16.0517 17.4337 16.2498 16.9488 16.261C16.4666 16.2582 16.0142 16.0414 15.7051 15.666L12.2476 11.4621L11.1212 12.6004C10.4845 13.2453 9.44835 13.2453 8.81255 12.6004L6.24322 9.99854V18.8996C6.24322 19.16 6.45173 19.3711 6.70859 19.3711Z" fill="#DCE5F1" />
                                            </svg>
                                            </i></li><li><i><svg width="27" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.4759 4.75448C4.98561 4.75448 3.65994 5.72109 3.17856 7.16013L3.14604 7.26855C3.03249 7.64961 2.98492 7.97009 2.98492 8.29075V14.7203L0.72654 7.08378C0.436099 5.96056 1.098 4.79606 2.20852 4.48577L16.6034 0.580665C16.7831 0.533541 16.9628 0.510909 17.1397 0.510909C18.0668 0.510909 18.914 1.13423 19.1514 2.05284L19.9901 4.75448H6.4759Z" fill="#DCE5F1" />
                                                <path d="M9.03623 14.6561C10.0631 14.6561 10.898 15.5019 10.898 16.5421C10.898 17.5823 10.0631 18.4282 9.03623 18.4282C8.00939 18.4282 7.17432 17.5823 7.17432 16.5421C7.17432 15.5019 8.00939 14.6561 9.03623 14.6561Z" fill="#DCE5F1" />
                                                <path d="M20.6725 21.2572H6.70859C5.42589 21.2572 4.38135 20.1991 4.38135 18.8996V8.52655C4.38135 7.22701 5.42589 6.1689 6.70859 6.1689H20.6725C21.9553 6.1689 22.9999 7.22701 22.9999 8.52655V18.8996C22.9999 20.1991 21.9553 21.2572 20.6725 21.2572ZM6.70859 19.3711H20.6725C20.9295 19.3711 21.138 19.16 21.138 18.8996V12.2051L18.1973 15.6812C17.8853 16.0517 17.4337 16.2498 16.9488 16.261C16.4666 16.2582 16.0142 16.0414 15.7051 15.666L12.2476 11.4621L11.1212 12.6004C10.4845 13.2453 9.44835 13.2453 8.81255 12.6004L6.24322 9.99854V18.8996C6.24322 19.16 6.45173 19.3711 6.70859 19.3711Z" fill="#DCE5F1" />
                                            </svg>
                                            </i></li></>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-body text=center">
                                    <h4 className="f-700">Video</h4>
                                    <div className="Product-thumbnail" onClick={triggerVideoFile}>
                                        {(product.video_path ?
                                            <img style={{ width: '100%' }} src="../assets/img/video.png" ref={videoRef} alt="product" />
                                            : <img style={{ width: '100%' }} src="../assets/img/img-placeholder.svg" ref={videoRef} alt="product" />
                                        )}
                                    </div>
                                    <p className="text-center">Set the product video. Only
                                        *.mp4, *.mov and *.avi video files are accepted</p>
                                </div>
                                <input
                                    type="file"
                                    ref={inputFileVideoRef}
                                    style={styles.input}
                                    onChangeCapture={onFileVideoChangeCapture}
                                />
                            </div>

                            <div className="card">
                                <div className="card-body text=center">
                                    <h4 className="f-700">Product Details</h4>
                                    <div className="mb-3">
                                        <label className="form-label">Master Categories</label>
                                        <select required defaultValue={product?.master_category_id} className="form-select" onChange={handleChange}>
                                            <option>Select an option</option>
                                            {masterCategory && masterCategory.length > 0
                                                ? masterCategory.map((item, i) => (
                                                    <><option value={item.id}>{item.name}</option></>
                                                ))
                                                : ""}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Categories</label>
                                        <select required defaultValue={product?.category_id} className="form-select" onChange={handleChangeCat}>
                                            <option>Select an option</option>
                                            {category && category.length > 0
                                                ? category.map((item, i) => (
                                                    <><option value={item.id}>{item.name}</option></>
                                                ))
                                                : ""}
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Sub Categories</label>
                                        <select required defaultValue={product?.sub_category_id} className="form-select" onChange={handleChangeSubCat}>
                                            <option>Select an option</option>
                                            {subCategory && subCategory.length > 0
                                                ? subCategory.map((item, i) => (
                                                    <><option value={item.id}>{item.name}</option></>
                                                ))
                                                : ""}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Tags</label>
                                        <input
                                            type="text"
                                            defaultValue={product?.tag}
                                            onChange={onChangeTag}
                                            className="form-control" />
                                        <div className="form-text">Add tag to a product</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-9 col-lg-8 ps-xxl-5 ps-md-3 ps-0">
                            <div className="card mb-5">
                                <div className="card-body p-4">

                                    <div className="mb-3">
                                        <label className="form-label">Product Name *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required
                                            defaultValue={product.name}
                                            onChange={onChangeName}
                                            placeholder="Product Name" />
                                        <div className="form-text">A product name is required and recommended to be unique.</div>
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Brand *</label>

                                        <select defaultValue={product?.brand_id} className="form-select" onChange={onChangeBrand}>
                                            <option >Select product brand</option>
                                            {allBrand && allBrand.length > 0
                                                ? allBrand.map((item, i) => (
                                                    <><option value={item.id}>{item.name}</option></>
                                                ))
                                                : ""}
                                        </select>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Description</label>
                                        <textarea
                                            placeholder="Description"
                                            className="form-control"
                                            defaultValue={product.description}
                                            onChange={onChangeDescription}
                                            rows="5"></textarea>

                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Details & Spec</label>
                                        <Editor
                                            apiKey='1nolfd56snnawdzchbfmu06ihvzd2nkhvgvdj5i85do1bws6'
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            initialValue={product.details}
                                            init={{
                                                height: 500,
                                                menubar: true,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                    'bold italic forecolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-5">
                                <div className="card-body p-4">
                                    <label className="form-label"><h4 className="f-700">Catalogs & Certificates</h4></label>

                                    <div className="upload-box">
                                        <i><svg width="47" height="39" viewBox="0 0 47 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M32 27.5L24 19.5L16 27.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M24 19.5V37.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M40.7799 32.28C42.7306 31.2165 44.2716 29.5337 45.1597 27.4972C46.0477 25.4607 46.2323 23.1864 45.6843 21.0334C45.1363 18.8803 43.8869 16.971 42.1333 15.6069C40.3796 14.2427 38.2216 13.5014 35.9999 13.5H33.4799C32.8745 11.1585 31.7462 8.98464 30.1798 7.14195C28.6134 5.29927 26.6496 3.83567 24.4361 2.86118C22.2226 1.8867 19.817 1.42669 17.4002 1.51573C14.9833 1.60478 12.6181 2.24057 10.4823 3.3753C8.34649 4.51003 6.49574 6.11417 5.06916 8.06713C3.64259 10.0201 2.6773 12.271 2.24588 14.6508C1.81446 17.0305 1.92813 19.477 2.57835 21.8065C3.22856 24.136 4.3984 26.2877 5.99992 28.1" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M32 27.5L24 19.5L16 27.5" stroke="#27454A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        </i>

                                        <div className="ms-3">
                                            <h5>Select a file or drag and drop here</h5>
                                            <p className="mb-0 text-secondary">JPG, PNG or PDF files are accepted, file size max upto 4MB</p>
                                        </div>
                                        <div class="upload-btn-wrapper ms-auto ms-3">
                                            <button class="btn-file">Select file</button>
                                            <input
                                                type="file"
                                                accept="image/*,.pdf"
                                                name="documents"
                                                multiple
                                                style={{ width: "100%", height: "100%" }}
                                                onChangeCapture={onDocumentChangeCaptureMultiple} />
                                        </div>
                                    </div>

                                    <ul className="thumbs-img mt-3">
                                        {oldDocuments && oldDocuments.length > 0
                                            ? oldDocuments.map((item, i) => (
                                                <li id={`api-document-${(i + 1)}`} style={{ position: 'relative' }}>
                                                    <i><img width="27" src={item} alt='product' /></i>
                                                    <div class="Delete-image" onClick={(e) => { if (window.confirm('Are you sure you want to delete this document?')) deleteDocument(e, (i + 1)) }}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.410582 0.410749C0.736019 0.0853125 1.26366 0.0853125 1.58909 0.410749L5.99984 4.82149L10.4106 0.410749C10.736 0.0853125 11.2637 0.0853125 11.5891 0.410749C11.9145 0.736186 11.9145 1.26382 11.5891 1.58926L7.17835 6.00001L11.5891 10.4108C11.9145 10.7362 11.9145 11.2638 11.5891 11.5893C11.2637 11.9147 10.736 11.9147 10.4106 11.5893L5.99984 7.17852L1.58909 11.5893C1.26366 11.9147 0.736019 11.9147 0.410582 11.5893C0.0851447 11.2638 0.0851447 10.7362 0.410582 10.4108L4.82133 6.00001L0.410582 1.58926C0.0851447 1.26382 0.0851447 0.736186 0.410582 0.410749Z" fill="black"></path></svg></div>
                                                </li>
                                            ))
                                            : ""}
                                        {documents && documents.length > 0 || oldDocuments
                                            ? documents.map((item, i) => (
                                                <li id={`local-document-${i}`} style={{ position: 'relative' }}>
                                                    <i><img width="27" src={item} alt='product' /></i>
                                                    <div class="Delete-image" onClick={(e) => { if (window.confirm('Are you sure you want to delete this document?')) deleteDocument(e, i, false) }}><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.410582 0.410749C0.736019 0.0853125 1.26366 0.0853125 1.58909 0.410749L5.99984 4.82149L10.4106 0.410749C10.736 0.0853125 11.2637 0.0853125 11.5891 0.410749C11.9145 0.736186 11.9145 1.26382 11.5891 1.58926L7.17835 6.00001L11.5891 10.4108C11.9145 10.7362 11.9145 11.2638 11.5891 11.5893C11.2637 11.9147 10.736 11.9147 10.4106 11.5893L5.99984 7.17852L1.58909 11.5893C1.26366 11.9147 0.736019 11.9147 0.410582 11.5893C0.0851447 11.2638 0.0851447 10.7362 0.410582 10.4108L4.82133 6.00001L0.410582 1.58926C0.0851447 1.26382 0.0851447 0.736186 0.410582 0.410749Z" fill="black"></path></svg></div>
                                                </li>
                                            )) : <><li><i><svg width="27" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.4759 4.75448C4.98561 4.75448 3.65994 5.72109 3.17856 7.16013L3.14604 7.26855C3.03249 7.64961 2.98492 7.97009 2.98492 8.29075V14.7203L0.72654 7.08378C0.436099 5.96056 1.098 4.79606 2.20852 4.48577L16.6034 0.580665C16.7831 0.533541 16.9628 0.510909 17.1397 0.510909C18.0668 0.510909 18.914 1.13423 19.1514 2.05284L19.9901 4.75448H6.4759Z" fill="#DCE5F1" />
                                                <path d="M9.03623 14.6561C10.0631 14.6561 10.898 15.5019 10.898 16.5421C10.898 17.5823 10.0631 18.4282 9.03623 18.4282C8.00939 18.4282 7.17432 17.5823 7.17432 16.5421C7.17432 15.5019 8.00939 14.6561 9.03623 14.6561Z" fill="#DCE5F1" />
                                                <path d="M20.6725 21.2572H6.70859C5.42589 21.2572 4.38135 20.1991 4.38135 18.8996V8.52655C4.38135 7.22701 5.42589 6.1689 6.70859 6.1689H20.6725C21.9553 6.1689 22.9999 7.22701 22.9999 8.52655V18.8996C22.9999 20.1991 21.9553 21.2572 20.6725 21.2572ZM6.70859 19.3711H20.6725C20.9295 19.3711 21.138 19.16 21.138 18.8996V12.2051L18.1973 15.6812C17.8853 16.0517 17.4337 16.2498 16.9488 16.261C16.4666 16.2582 16.0142 16.0414 15.7051 15.666L12.2476 11.4621L11.1212 12.6004C10.4845 13.2453 9.44835 13.2453 8.81255 12.6004L6.24322 9.99854V18.8996C6.24322 19.16 6.45173 19.3711 6.70859 19.3711Z" fill="#DCE5F1" />
                                            </svg>
                                            </i></li><li><i><svg width="27" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.4759 4.75448C4.98561 4.75448 3.65994 5.72109 3.17856 7.16013L3.14604 7.26855C3.03249 7.64961 2.98492 7.97009 2.98492 8.29075V14.7203L0.72654 7.08378C0.436099 5.96056 1.098 4.79606 2.20852 4.48577L16.6034 0.580665C16.7831 0.533541 16.9628 0.510909 17.1397 0.510909C18.0668 0.510909 18.914 1.13423 19.1514 2.05284L19.9901 4.75448H6.4759Z" fill="#DCE5F1" />
                                                <path d="M9.03623 14.6561C10.0631 14.6561 10.898 15.5019 10.898 16.5421C10.898 17.5823 10.0631 18.4282 9.03623 18.4282C8.00939 18.4282 7.17432 17.5823 7.17432 16.5421C7.17432 15.5019 8.00939 14.6561 9.03623 14.6561Z" fill="#DCE5F1" />
                                                <path d="M20.6725 21.2572H6.70859C5.42589 21.2572 4.38135 20.1991 4.38135 18.8996V8.52655C4.38135 7.22701 5.42589 6.1689 6.70859 6.1689H20.6725C21.9553 6.1689 22.9999 7.22701 22.9999 8.52655V18.8996C22.9999 20.1991 21.9553 21.2572 20.6725 21.2572ZM6.70859 19.3711H20.6725C20.9295 19.3711 21.138 19.16 21.138 18.8996V12.2051L18.1973 15.6812C17.8853 16.0517 17.4337 16.2498 16.9488 16.261C16.4666 16.2582 16.0142 16.0414 15.7051 15.666L12.2476 11.4621L11.1212 12.6004C10.4845 13.2453 9.44835 13.2453 8.81255 12.6004L6.24322 9.99854V18.8996C6.24322 19.16 6.45173 19.3711 6.70859 19.3711Z" fill="#DCE5F1" />
                                            </svg>
                                            </i></li><li><i><svg width="27" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.4759 4.75448C4.98561 4.75448 3.65994 5.72109 3.17856 7.16013L3.14604 7.26855C3.03249 7.64961 2.98492 7.97009 2.98492 8.29075V14.7203L0.72654 7.08378C0.436099 5.96056 1.098 4.79606 2.20852 4.48577L16.6034 0.580665C16.7831 0.533541 16.9628 0.510909 17.1397 0.510909C18.0668 0.510909 18.914 1.13423 19.1514 2.05284L19.9901 4.75448H6.4759Z" fill="#DCE5F1" />
                                                <path d="M9.03623 14.6561C10.0631 14.6561 10.898 15.5019 10.898 16.5421C10.898 17.5823 10.0631 18.4282 9.03623 18.4282C8.00939 18.4282 7.17432 17.5823 7.17432 16.5421C7.17432 15.5019 8.00939 14.6561 9.03623 14.6561Z" fill="#DCE5F1" />
                                                <path d="M20.6725 21.2572H6.70859C5.42589 21.2572 4.38135 20.1991 4.38135 18.8996V8.52655C4.38135 7.22701 5.42589 6.1689 6.70859 6.1689H20.6725C21.9553 6.1689 22.9999 7.22701 22.9999 8.52655V18.8996C22.9999 20.1991 21.9553 21.2572 20.6725 21.2572ZM6.70859 19.3711H20.6725C20.9295 19.3711 21.138 19.16 21.138 18.8996V12.2051L18.1973 15.6812C17.8853 16.0517 17.4337 16.2498 16.9488 16.261C16.4666 16.2582 16.0142 16.0414 15.7051 15.666L12.2476 11.4621L11.1212 12.6004C10.4845 13.2453 9.44835 13.2453 8.81255 12.6004L6.24322 9.99854V18.8996C6.24322 19.16 6.45173 19.3711 6.70859 19.3711Z" fill="#DCE5F1" />
                                            </svg>
                                            </i></li></>
                                        }
                                    </ul>
                                </div>
                            </div>
                            {variantCount == 0 && <div class="col-12 mt-2 mb-4"><button type="button" onClick={addInputField} className="btn-add"><i><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 3c.5523 0 1 .44772 1 1v7h7c.5523 0 1 .4477 1 1s-.4477 1-1 1h-7v7c0 .5523-.4477 1-1 1s-1-.4477-1-1v-7h-7c-.55228 0-1-.4477-1-1s.44772-1 1-1h7v-7c0-.55228.4477-1 1-1z" fill="#ffffff" fill-rule="evenodd" /></svg></i></button> Add Variants</div>}
                            {variantCount > 0 && <div className="card mb-5">
                                <div className="card-body p-4">
                                    <h4 className="f-700">Variant</h4>
                                    {
                                        inputFields.map((data, index) => {
                                            return (<div className="row">
                                                <div className="col-md-10">
                                                    <div className="row">
                                                        <div className="mb-3 col-md-6">
                                                            <label className="form-label"></label>
                                                            <input
                                                                type="text"
                                                                required
                                                                name="variant"
                                                                value={data?.variant}
                                                                onChange={(evnt) => handleVariantChange(index, evnt)}
                                                                className="form-control"
                                                                placeholder="Title" />
                                                        </div>
                                                        <div className="mb-3 col-md-6">
                                                            <label className="form-label"></label>
                                                            <TagsInput tags={data?.values} index={index} selectedTags={selectedTags} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <button type="button" onClick={(evnt) => removeInputFields(index, evnt)} className="btn-delete"><i><svg viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><path d="m3 7h2v20.48a3.53 3.53 0 0 0 3.52 3.52h15a3.53 3.53 0 0 0 3.48-3.52v-20.48h2a1 1 0 0 0 0-2h-7v-2a2 2 0 0 0 -1.95-2h-8.05a2 2 0 0 0 -2 2v2h-7a1 1 0 0 0 0 2zm9-4h8v2h-8zm-2 4h15v20.48a1.52 1.52 0 0 1 -1.52 1.52h-15a1.52 1.52 0 0 1 -1.48-1.52v-20.48z" /><path d="m12.68 25a1 1 0 0 0 1-1v-12a1 1 0 0 0 -2 0v12a1 1 0 0 0 1 1z" /><path d="m19.32 25a1 1 0 0 0 1-1v-12a1 1 0 0 0 -2 0v12a1 1 0 0 0 1 1z" /></g></svg></i></button>
                                                </div>
                                            </div>
                                            )
                                        })
                                    }
                                    {variantCount < 3 && <div class="col-12 mt-2 mb-2"><button type="button" onClick={addInputField} className="btn-add"><i><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 3c.5523 0 1 .44772 1 1v7h7c.5523 0 1 .4477 1 1s-.4477 1-1 1h-7v7c0 .5523-.4477 1-1 1s-1-.4477-1-1v-7h-7c-.55228 0-1-.4477-1-1s.44772-1 1-1h7v-7c0-.55228.4477-1 1-1z" fill="#ffffff" fill-rule="evenodd" /></svg></i></button> Add New Variant</div>}
                                </div>
                            </div>
                            }
                            {(inputVariations && inputVariations.length > 0) && <div className="card mb-5">
                                <div className="card-body p-4">
                                    <div className="col-md-12">
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <h4 class="mb-0 text-success">Select Variants</h4>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <h4 class="mb-0 text-success"> MRP</h4>
                                            </div>
                                            <div className="col-md-4">
                                                <h4 class="mb-0 text-success">Offer Price</h4>
                                            </div> */}
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <input class="form-check-input" style={{ margin: 0 }} type="checkbox" onChange={changeAllChecked} value="1" id="status_check" />
                                                    </div>
                                                    <div className="col-md-11">
                                                        &nbsp;&nbsp; <label for="status_check">Select all</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-4">
                                                <input class="form-check-input" style={{margin:0}} type="checkbox" value="1"  onChange={changeAllMrp} id="mrp_check"/>
                                                &nbsp;&nbsp; <label for="mrp_check">Same for all</label>
                                            </div>
                                            <div className="col-md-4">
                                                <input class="form-check-input" style={{margin:0}} type="checkbox" value="1"  onChange={changeAllOffer} id="offer_check"/>
                                                &nbsp;&nbsp; <label for="offer_check">Same for all</label>
                                            </div> */}
                                        </div>
                                        {inputVariations.map((data, index) => {
                                            return (
                                                <div className="row mb-3">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-1">
                                                                <input class="form-check-input variationStatus" type="checkbox" value="1" checked={(data?.status == 'active' ? true : false)} onChange={(evnt) => setVariationStatus(index, evnt)} id="flexCheckDefault" />
                                                            </div>
                                                            <div className="col-md-11">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    readOnly={true}
                                                                    value={data?.name}
                                                                    className="form-control"
                                                                    placeholder="Red + M" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <input
                                                            type="number" 
                                                            name="mrp"
                                                            required
                                                            onChange={(evnt)=>setVariantMrp(index, evnt)}
                                                            defaultValue={data?.mrp}
                                                            value={data?.mrp}
                                                            readOnly={(index > 0 && allMrp) ? true : false}
                                                            className="form-control variantMrp" 
                                                            placeholder="KES 200" />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input
                                                            type="number" 
                                                            name="offer_price"
                                                            onChange={(evnt)=>setVariantOffer(index, evnt)}
                                                            defaultValue={data?.offer_price}
                                                            value={data?.offer_price}
                                                            readOnly={(index > 0 && allOffer) ? true : false}
                                                            className="form-control variantOffer" 
                                                            placeholder="KES 200" />
                                                    </div> */}
                                                </div>
                                            )
                                        })

                                        }
                                    </div>
                                </div>
                            </div>
                            }
                            {/* <div className="card mb-5">
                                <div className="card-body p-4">

                                    <div className="mb-3">
                                        <label className="form-label">Serviceability Group</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            onChange={onChangeGroup}
                                            defaultValue={product?.group}
                                            placeholder="Serviceability Group" />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Expected Delivery time (in days)</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={product?.delivery_time}
                                            onChange={onChangeDeliveryTime}
                                            placeholder="Expected Delivery time (in days)" />

                                    </div>
                                </div>
                            </div> */}

                            <div className="card mb-5">
                                <div className="card-body p-4">
                                    <h4 className="f-700">Price</h4>
                                    <div class="row price-row">
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">Minimum Qty*</label>
                                            <input
                                                type="number"
                                                required
                                                min="0"
                                                onChange={onChangeMrp}
                                                className="form-control min"
                                                defaultValue={(priceGroup?.[0]?.min)}
                                                placeholder="50" />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">Maximum Qty</label>
                                            <input
                                                type="number"
                                                className="form-control max"
                                                defaultValue={(priceGroup?.[0]?.max)}
                                                min="0"
                                                onChange={onChangeMrp}
                                                placeholder="100" />

                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">MRP*</label>
                                            <input
                                                type="number"
                                                min="0"
                                                required
                                                onChange={onChangeMrp}
                                                defaultValue={(priceGroup?.[0]?.mrp)}
                                                className="form-control mrp"
                                                placeholder="200" />

                                        </div>
                                    </div>
                                    <div class="row price-row">
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">Minimum Qty</label>
                                            <input
                                                type="number"
                                                onChange={onChangeMrp}
                                                defaultValue={(priceGroup?.[1]?.min)}
                                                min="0"
                                                className="form-control min"
                                                placeholder="101" />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">Maximum Qty</label>
                                            <input
                                                type="number"
                                                className="form-control max"
                                                defaultValue={(priceGroup?.[1]?.max)}
                                                min="0"
                                                onChange={onChangeMrp}
                                                placeholder="500" />

                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">MRP</label>
                                            <input
                                                type="number"
                                                onChange={onChangeMrp}
                                                className="form-control mrp"
                                                defaultValue={(priceGroup?.[1]?.mrp)}
                                                placeholder="200" />

                                        </div>
                                    </div>
                                    <div class="row price-row">
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">Minimum Qty</label>
                                            <input
                                                type="number"
                                                onChange={onChangeMrp}
                                                min="0"
                                                className="form-control min"
                                                defaultValue={(priceGroup?.[2]?.min)}
                                                placeholder="501" />
                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">Maximum Qty</label>
                                            <input
                                                type="number"
                                                className="form-control max"
                                                defaultValue={(priceGroup?.[2]?.max)}
                                                min="0"
                                                onChange={onChangeMrp}
                                                placeholder="1000" />

                                        </div>
                                        <div className="col-md-4 mb-3">
                                            <label className="form-label">MRP</label>
                                            <input
                                                type="number"
                                                min="0"
                                                onChange={onChangeMrp}
                                                className="form-control mrp"
                                                defaultValue={(priceGroup?.[2]?.mrp)}
                                                placeholder="200" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card mb-5">
                                <div className="card-body p-4">
                                    <h4 className="f-700">Inventory</h4>
                                    <div className="mb-3">
                                        <label className="form-label">SKU *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            required
                                            defaultValue={product?.sku}
                                            onChange={onChangeSku}
                                            placeholder="SKU Number" />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Stock Quantity  *</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            required
                                            defaultValue={product?.quantity}
                                            onChange={onChangeQuantity}
                                            placeholder="Stock Quantity" />

                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Unit *</label>
                                        <select required className="form-select" onChange={onChangeUnit} value={unit}>
                                            <option value="Piece/Pieces">Piece/Pieces</option>
                                            <option value="Bag/Bags">Bag/Bags</option>
                                            <option value="Barrel/Barrels">Barrel/Barrels</option>
                                            <option value="Box/Boxes">Box/Boxes</option>
                                            <option value="Case/Cases">Case/Cases</option>
                                            <option value="Centimeter/Centimeters">Centimeter/Centimeters</option>
                                            <option value="Foot/Feet">Foot/Feet</option>
                                            <option value="Gallon/Gallons">Gallon/Gallons</option>
                                            <option value="Gram/Grams">Gram/Grams</option>
                                            <option value="Inch/Inches">Inch/Inches</option>
                                            <option value="Kilogram/Kilograms">Kilogram/Kilograms</option>
                                            <option value="Liter/Liters">Liter/Liters</option>
                                            <option value="Meter/Meters">Meter/Meters</option>
                                            <option value="Ounce/Ounces">Ounce/Ounces</option>
                                            <option value="Pack/Packs">Pack/Packs</option>
                                            <option value="Pair/Pairs">Pair/Pairs</option>
                                            <option value="Pole/Poles">Pole/Poles</option>
                                            <option value="Pound/Pounds">Pound/Pounds</option>
                                            <option value="Quarter/Quarters">Quarter/Quarters</option>
                                            <option value="Roll/Rolls">Roll/Rolls</option>
                                            <option value="Set/Sets">Set/Sets</option>
                                            <option value="Sheet/Sheets">Sheet/Sheets</option>
                                            <option value="Square Foot/Square Feet">Square Foot/Square Feet</option>
                                            <option value="Ton/Tons">Ton/Tons</option>
                                            <option value="Unit/Units">Unit/Units</option>
                                        </select>
                                    </div>

                                </div>
                            </div>
                            <div className="card mb-5">
                                <div className="card-body p-4">
                                    <h4 className="f-700">Lead Time</h4>
                                    <div className="row">
                                        {(leadTimes && leadTimes.length > 0) &&
                                            leadTimes.map((data, index) => {
                                                return (<div className="row mb-2">
                                                    <div className="col-md-10">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <input
                                                                    type="text"
                                                                    required
                                                                    name="qty"
                                                                    defaultValue={data?.qty}
                                                                    onChange={(evnt) => handleLeadsChange(index, evnt)}
                                                                    className="form-control"
                                                                    placeholder="Quantity (sets)" />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input
                                                                    type="text"
                                                                    required
                                                                    name="days"
                                                                    defaultValue={data?.days}
                                                                    onChange={(evnt) => handleLeadsChange(index, evnt)}
                                                                    className="form-control"
                                                                    placeholder="Lead time (days)" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <button type="button" style={{ marginTop: '5px' }} onClick={(evnt) => removeLeadTimeFields(index, evnt)} className="btn-delete"><i><svg viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><path d="m3 7h2v20.48a3.53 3.53 0 0 0 3.52 3.52h15a3.53 3.53 0 0 0 3.48-3.52v-20.48h2a1 1 0 0 0 0-2h-7v-2a2 2 0 0 0 -1.95-2h-8.05a2 2 0 0 0 -2 2v2h-7a1 1 0 0 0 0 2zm9-4h8v2h-8zm-2 4h15v20.48a1.52 1.52 0 0 1 -1.52 1.52h-15a1.52 1.52 0 0 1 -1.48-1.52v-20.48z" /><path d="m12.68 25a1 1 0 0 0 1-1v-12a1 1 0 0 0 -2 0v12a1 1 0 0 0 1 1z" /><path d="m19.32 25a1 1 0 0 0 1-1v-12a1 1 0 0 0 -2 0v12a1 1 0 0 0 1 1z" /></g></svg></i></button>
                                                    </div>
                                                </div>
                                                )
                                            })
                                        }
                                        <div class="col-12 mt-2 mb-2"><button type="button" onClick={addLeadTimeField} className="btn-add"><i><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m12 3c.5523 0 1 .44772 1 1v7h7c.5523 0 1 .4477 1 1s-.4477 1-1 1h-7v7c0 .5523-.4477 1-1 1s-1-.4477-1-1v-7h-7c-.55228 0-1-.4477-1-1s.44772-1 1-1h7v-7c0-.55228.4477-1 1-1z" fill="#ffffff" fill-rule="evenodd" /></svg></i></button> Add New</div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-5">
                                <div className="card-body p-4">
                                    <h4 className="f-700">Certifications</h4>
                                    <div className="row">
                                        {HelperService.productCertification().map(cert => {
                                            return (
                                                <div className="mb-3 col-md-3">
                                                    <div class="">
                                                        <input
                                                            class="form-check-input product_certifications"
                                                            type="checkbox"
                                                            value={cert}
                                                            id={cert}
                                                            style={{ margin: 0, marginRight: '10px' }}
                                                            defaultChecked={(certifications.length > 0 && certifications.filter(cc => { return (cc == cert) }).length > 0)}
                                                        />
                                                        <label class="form-check-label m-0" for={cert}>
                                                            {cert}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end btn-min-width">
                                <button className="btn btn-primary" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
                : <span className="spinner-border spinner-border-sm"></span>

            }

        </div>
    );
};

export default EditProductSection;