import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import img1 from "../images/app-store.png";
import img2 from "../images/play-store.png";
import img3 from "../images/alibaba-icon.png";
import img4 from "../images/facebook.png";
import img5 from "../images/insta.jpg";
import img6 from "../images/twitter.jpg";
import img7 from "../images/Linkdin.jpg";
import img8 from "../images/youtube.jpg";
import Logo from "../images/logo.png";
import "../customCss/Footer.css";
import "../customCss/Footer.css";
import DataService from "../services/data.service";
import AuthService from "../services/auth.service";
function LoginFooter() {

  const [smlinks, setSmLinks] = useState([]);
  const [acc, setAcc] = useState(false)
  const auth = AuthService.getCurrentUser();

  const getData = async () => {
    await DataService.getSocialLinks().then((data) => {
      setSmLinks(data?.data?.data);
    });
  };

  useEffect(() => {
    getData();
    check();

  }, []);

  const check = () => {
    if (auth) {
      setAcc(true);
    } else {
      setAcc(false);
    }
  }
  return (
    <>
      <div className="container-fluid main">
        <div className="container common links py-5">
          <div className="row">
            <div className="col-md-6 col-lg-2 mb-2">
              <div className="navbar-logo">
                <Link to="/" className="navbar-brand">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
              <div className="top_1">
                <h5>Customer Services </h5>
              </div>
              <div className="list_1">
                <ul className="list_ul">
                  {/* <li>
                    <Link className="common_link" to="/faq">
                      Help Center
                    </Link>
                  </li> */}
                  <li>
                    <Link className="common_link" to="">
                      Policies and Rules
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/buy-and-sell-guide">
                      How to sell and buy
                    </Link>
                  </li>

                  <li>
                    <Link className="common_link" to="/abuse-policy">
                      Report abuse
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6  col-lg-2">
              <div className="top_2">
                <h5>About us</h5>
              </div>
              <div className="list_1">
                <ul>
                  <li>
                    <Link className="common_link" to="/about">
                      About Dealt it.com
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="">
                      About Dealt it Advertisement
                    </Link>
                  </li>


                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 ">
              <div className="top_4">
                <h5>Dealit Payment Method</h5>
              </div>
              <div className="list_1">
                <ul>
                  <li>
                    <Link className="common_link" to="/payment-transaction-guide">
                      Payment Method Help
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/payment-methods">
                      Payment Options
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="">
                      Payment Transactions
                    </Link>
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-md-6  col-lg-2">
              <div className="top_5">
                <h5>Let Us Help You</h5>
              </div>
              <div className="list_1">
                <ul>
                  <li>
                    <Link className="common_link" to="">
                      Track Packages View Orders
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="">
                      Post Products
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/user-profile">
                      My Account
                    </Link>
                  </li>
                  <li>
                    <Link className="common_link" to="/return-policy">
                      Return and Replacement
                    </Link>
                  </li>
                  {/* <li>
                  <Link className="common_link" to="">
                      Careers
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container common pt-5 pb-4">
          <div className="row">
            <div className="col-md-4">
              {/* <div className="store_content_1 d-flex ">
                <h6 className="pe-2 pt-1">Download:</h6>
                <div className="store_images d-flex ">
                  <div className="apple ">
                    <Link className="common_link" to="">
                      <img src={img1} alt="" height="30px" />
                    </Link>
                  </div>
                  <div className="google">
                    <Link className="common_link" to="">
                      <img src={img2} alt="" height="30px" />
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="col-md-4">

            </div>
            <div className="col-md-4">
              <div className="icons d-flex">
                <h6 className="pe-3">Follow us:</h6>
                <ul className="m-0 d-flex icon_main ">
                  <li className="icon-list me-2">
                    <Link to={smlinks?.facebook ? smlinks?.facebook : "#"} target="_blank">
                      <i class="fab fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.instagram ? smlinks?.instagram : "#"} target="_blank">
                      <i class="fab fa-instagram"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.twitter ? smlinks?.twitter : "#"} target="_blank">
                      <i class="fa-brands fa-x-twitter"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.linkedin ? smlinks?.linkedin : "#"} target="_blank">
                      <i class="fab fa-linkedin-in"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.youtube ? smlinks?.youtube : "#"} target="_blank">
                      <i class="fab fa-youtube"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.pinterest ? smlinks?.pinterest : "#"}>
                      <i class="fab fa-pinterest" target="_blank"></i>
                    </Link>
                  </li>

                  <li className="icon-list me-2">
                    <Link to={smlinks?.weibo ? smlinks?.weibo : "#"}>
                      <i class="fab fa-weibo" target="_blank"></i>
                    </Link>
                  </li>
                  <li className="icon-list me-2">
                    <Link to={smlinks?.tiktok ? smlinks?.tiktok : "#"}>
                      <i class="fab fa-tiktok" target="_blank"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default LoginFooter;
