import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom'

import "../../../src/view/customCss/Category.css";
import Header from '../common/Header';
import Footer from '../common/Footer';
import TrendSec from './component/Categorylayout/TrendSec';
import NewSec from './component/Categorylayout/NewSec';
import TabSection from './component/Categorylayout/TabSection';
import DataService from '../services/data.service'
import Slider from "react-slick";
import "../../view/customCss/slick.css"
function CategoryPage() {
  const params = useParams();
  const [masterData, setMasterData] = useState([]);
  const [filterData, setFilterData] = useState([]);



  const getData = async () => {
      await DataService.getHomePageData().then((data) => {
          const getSlug = data.data.masterCategories.filter((value) => {
              return value.slug.includes(params.master)
          });
          setMasterData(getSlug);
          // console.log(getSlug)
          const setSlug = getSlug[0].Categories.filter((value) => {
            return value.slug.includes(params.category)
        });
        setFilterData(setSlug)
        console.log(setSlug)
      });
  };
  useEffect(() => {
      getData();
  }, []);

  
    return (
      <>
      <Header/>
{/* <!--############################## BANNER-SECTION-START ##############################--> */}
<section className="category-banner-section" ></section>

{/* <!--############################## BANNER-SECTION-END ##############################--> */}

{/* <!--############################## TREND-SECTION-START ##############################--> */}
<TrendSec filterData={filterData}/>
{/* <!--############################## TREND-SECTION-END ##############################--> */}

{/* <!--############################## SELECTION-SECTION-START ##############################--> */}
{/* <NewSec/> */}
{/* <!--############################## SELECTION-SECTION-END ##############################--> */}
{/* <!--############################## TABS-SECTION-START ##############################--> */}
{/* <TabSection/> */}
{/* <!--############################## TABS-SECTION-END ##############################--> */} 
<Footer/>     
      </>
     
    )
}

export default CategoryPage
