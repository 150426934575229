import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import AddAddressBlock from "./addAddress";
import EditAddressBlock from "./editAddress";
import DataService from "../../services/data.service";
import { toast } from 'react-toastify';
import pencil from "../../images/pencil-fill.png";
import deletefill from "../../images/address-delete-icon.png";
//import title_bg from '../../../public/assets/img/slider/page-title.jpg';
const AddressBlock = (props) => {

  const [data, setData] = useState({});

  const editAddress = async (e, item)=>{
    e.preventDefault();
    setData(item);
  }

  const deleteAddress = (e, item) =>{
    e.preventDefault();
    DataService.deleteAddress(item?.id).then(
        () => {
            toast.success('Address deleted successfully!', {
                position: toast.POSITION.TOP_RIGHT
            });
            props.getAddress();
        },
        (error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.msg) ||
                error.message ||
                error.toString();
                toast.error(resMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
        }
    );
     
  }

  return (
    <div className="block  p-lg-5 p-3">
        <div className="block-title">
            <div className="d-flex justify-content-between align-items-start align-items-md-center flex-column flex-md-row">
                <div>
                    {/* <h2>My Addresses</h2> */}
                </div>
                <div>
                <NavLink to='#' className="add_address" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    Add Address
                </NavLink>
                </div>
            </div>
            {props.data && props.data.length > 0
                        ? props.data.map((item, i) => (
                    <div className="mt-3 p-3 address-main row align-items-center">
                        <div className="col-sm-8">
                            <div className="address-tag">
                                {item.type == 'office' ?  "Office" : "Home"}
                            </div>
                            <div className="d-flex">
                                <div className="place-name">
                                {item.first_name} {item.last_name}
                                </div>
                                <div className="phone-number">
                                {item.phone}
                                </div>
                            </div>
                            <div className="address-detail1">
                            {item.address} {item.city} {item.region}
                            <br></br>
                            {item.landmark}
                            </div>
                        </div>
                        <div className="col-sm-4 d-flex justify-content-end address_editSec">
                          <Link onClick={(e) => editAddress(e, item)}  data-bs-toggle="modal" data-bs-target="#exampleModalEdit"  >
                            <button className="address_editButton"><img src={pencil}/>Edit</button>
                          </Link>
                          <Link to={'#'} onClick={(e) => { if (window.confirm('Are you sure you want to delete this address?')) deleteAddress(e, item) }} >
                           <img src={deletefill}/>
                          </Link>
                        </div>
                    </div>
             )):<p className="nothing">No Addresses Here</p>}
        </div>
        <AddAddressBlock></AddAddressBlock>
        <EditAddressBlock data={data}></EditAddressBlock>

    </div>
     
  );
};

export default AddressBlock;