import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.png";

const Preloader = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return isLoading ? <div className="preloader" style={{ height: '100%' }}>
    <img src={Logo} alt='main_logo' style={{ opacity: '1' }} />
    <div class="preloader-speeding-wheel"></div>
    <h4>Loading ... </h4>
  </div> :
    <div className="preloader" style={{ height: '0%', pointerEvents: 'none' }}>
      <img src={Logo} alt='main_logo' style={{ opacity: '0' }} />
    </div>
    ;
};

export default Preloader;