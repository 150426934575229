import React, { useEffect, useState, useRef } from "react";
import DataService from "../services/data.service";
import { toast } from 'react-toastify';
import Footer from "../common/Footer";
import Header from "../common/Header";
import Sidebar from "./component/UserProfileLayout/UserProfilePage/sidebar";
import Addressblock from "../pages/address/address";
import { NavLink, Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import UserProfileMain from "./component/UserProfileLayout/UserProfilePage/UserProfileMain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
const AddressPage = () => {
  useEffect(() => {
    getAddress()
  }, []);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const getAddress = async () => {
    setLoading(true)
    await DataService.getAddress().then((data) => {
      setLoading(false);
      const response = data?.data?.data
      setData(data?.data?.data)

    }).catch((error) => {
      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.msg) ||
        error.message ||
        error.toString();
      setLoading(false);
    })
  }
  const navigate = useNavigate();
  const logout = (e) => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/login");
    window.location.reload();
  }
  return (
    <>
      <div>
        <Header />
      </div>
      <section className="profile-section">
        <div className="container">
          <div className="row justify-content-center py-4 m-0">
            <div className="col-md-10 bd_fo_white">
              <UserProfileMain />
              <div class="container h-100 py-2 bg-dark-white">
                 
                <div className="d-flex flex-column flex-md-row">
                  {/* <div className="col-12 col-md-3">
                    <Sidebar />
                  </div> */}
                  <div className="col-12 col-md-12">
                    <div className="back_icon">
                    <Link to="/user-profile" className="back_btnmain"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                    {loading ?
                      <div className="col-lg-6 m-auto">
                        {loading && (
                          <div className="main_spinner">
                            <div class="spinner-border text-warning" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                      </div>
                      : <Addressblock data={data} getAddress={getAddress} />
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>



    </>
  );
};


export default AddressPage;







