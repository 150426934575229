import React, { useState, useMemo, useEffect } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import Sidebar from '../common/sidebar'
import Select from 'react-select';
import CountryList from 'react-select-country-list';
import DataService from '../../services/data.service';

const CompanyAccount = () => {
const [states, setStates] = useState("")
const [city, setCity] = useState("")
const [countries, setCountries] = useState("")
const [loading, setLoading] = useState(false)

    const [accountNumber, setAccountNumber] = useState('');
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleAccountNumberChange = (e) => {
        setAccountNumber(e.target.value);
        if (e.target.value !== confirmAccountNumber) {
        } else {
            setErrorMessage('');
        }
    };

    const handleConfirmAccountNumberChange = (e) => {
        setConfirmAccountNumber(e.target.value);
        if (e.target.value !== accountNumber) {
            setErrorMessage("Account numbers don't match");
        } else {
            setErrorMessage('');
        }
    };



    // const countries = [
    //     { id: 1, name: "USA" },
    //     { id: 2, name: "Canada" },
    //     { id: 3, name: "India" },
    //     // Add more countries
    // ];

    // const states = [
    //     { id: 1, name: "New York", countryId: 1 },
    //     { id: 2, name: "California", countryId: 1 },
    //     { id: 3, name: "Ontario", countryId: 2 },
    //     { id: 4, name: "Punjab", countryId: 3 },
    //     // Add more states
    // ];

    // const cities = [
    //     { id: 1, name: "New York City", stateId: 1 },
    //     { id: 2, name: "Los Angeles", stateId: 2 },
    //     { id: 3, name: "Toronto", stateId: 3 },
    //     { id: 4, name: "Zirakpur", stateId: 4 },
    //     { id: 5, name: "Mohali", stateId: 4 },
    //     // Add more cities
    // ];

    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");
    const [selectedCity, setSelectedCity] = useState("");

    const handleCountryChange = (e) => {
        setSelectedCountry(e.target.value);
        setSelectedState(""); // Reset state when country changes
        setSelectedCity(""); // Reset city when country changes
    };

    const handleStateChange = (e) => {
        setSelectedState(e.target.value);
        getstates(e.target.value); // Reset city when state changes
    };

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };


    React.useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        setLoading(true);
        DataService.getCountries().then((data) => {
            setCountries(data.data.data);
            // console.log(data.data.data);

        });


    }


    const getstates = (asi) => {
        setLoading(true);
        DataService.getStates(asi).then((data) => {
            setStates(data.data.data);


        });


    }



    const getcity = (xyz) => {
        setLoading(true);
        DataService.getCity(xyz).then((data) => {
            setCity(data.data.data);

        });


    }

    return (
        <>
            <div className="bg-grey h-100">
                <Header />

                <section className="content-area mt-4">
                    <Sidebar />
                    <div className="Right-content">
                        <div className="main-content">
                            <div className='Company_account'>
                                <h1>Company Account Details</h1>
                                <div className="input_field">
                                    <label for="company_logo" class="form-label">
                                        Bank name
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        class="form-control"
                                        id="company_name"
                                        placeholder=""

                                    />
                                </div>

                                <div className="input_field_bank">
                                    <label for="company_logo" class="form-label">
                                        Country
                                    </label>
                                    <select className='bank_select' onChange={handleCountryChange} value={selectedCountry}>
                                        <option value="">Select a country</option>
                                        {
                                            countries && countries.length > 0 ? countries.map((option, index) => {
                                                return (
                                                    <>
                                                        <option key={index} value={option.id}>{option.name}</option>
                                                    </>
                                                )
                                            }) : ""
                                        }
                                    </select>
                                </div>

                                <div className='main_bank'>

                                    <label for="company_logo" className="newLabel_bank">
                                        Bank address
                                    </label>

                                    <div className='inner_bank'>
                                        <div className='bank_area'>
                                            <label>Street:</label>
                                            <input
                                                type="text"
                                                name="street"
                                                class="form-control"
                                                id="company_name"

                                            />
                                        </div>
                                        <div className='bank_area'>

                                            <label>City:</label>
                                            <select className='bank_select' onChange={handleCityChange} value={selectedCity}>
                                                <option value="">Select a city</option>
                                                {/* {cities
                                                    .filter((city) => city.stateId === parseInt(selectedState))
                                                    .map((city) => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.name}
                                                        </option>
                                                    ))} */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='inner_bank'>
                                        <div className='bank_area'>
                                            <label>State:</label>
                                            <select className='bank_select' onChange={handleStateChange} value={selectedState}>
                                                <option value="">Select a state</option>
                                                {/* {states
                                                    .filter((state) => state.countryId === parseInt(selectedCountry))
                                                    .map((state) => (
                                                        <option key={state.id} value={state.id}>
                                                            {state.name}
                                                        </option>
                                                    ))} */}
                                            </select>
                                        </div>
                                        <div className='bank_area'>
                                            <label>Zip Code:</label>
                                            <input
                                                type="text"
                                                name="zipCode"
                                                class="form-control"
                                                id="company_name"

                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input_field">
                                    <label for="company_logo" class="form-label">
                                        Company name or Person name
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        class="form-control"
                                        id="company_name"
                                        placeholder=""

                                    />
                                </div>
                                <div className='main_bank'>

                                    <label for="company_logo" className="newLabel_bank">
                                        Company address
                                    </label>

                                    <div className='inner_bank'>
                                        <div className='bank_area'>
                                            <label>Street:</label>
                                            <input
                                                type="text"
                                                name="street"
                                                class="form-control"
                                                id="company_name"

                                            />
                                        </div>
                                        <div className='bank_area'>
                                            <label>City:</label>
                                            <select className='bank_select' onChange={handleCityChange} value={selectedCity}>
                                                <option value="">Select a city</option>
                                                {/* {cities
                                                    .filter((city) => city.stateId === parseInt(selectedState))
                                                    .map((city) => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.name}
                                                        </option>
                                                    ))} */}
                                            </select>
                                        </div>
                                    </div>
                                    <div className='inner_bank'>
                                        <div className='bank_area'>
                                            <label>State:</label>
                                            <select className='bank_select' onChange={handleStateChange} value={selectedState}>
                                                <option value="">Select a state</option>
                                                {/* {states
                                                    .filter((state) => state.countryId === parseInt(selectedCountry))
                                                    .map((state) => (
                                                        <option key={state.id} value={state.id}>
                                                            {state.name}
                                                        </option>
                                                    ))} */}
                                            </select>
                                        </div>
                                        <div className='bank_area'>
                                            <label>Zip Code:</label>
                                            <input
                                                type="text"
                                                name="zipCode"
                                                class="form-control"
                                                id="company_name"

                                            />
                                        </div>
                                    </div>
                                </div>



                                <div className="input_field">
                                    <label for="company_logo" class="form-label">
                                        Recipients address
                                    </label>
                                    <input
                                        required
                                        type="text"
                                        class="form-control"
                                        id="company_name"
                                        placeholder=""

                                    />
                                </div>
                              
                                <div className="input_field">
                                    <label for="company_logo" class="form-label">
                                        Account number
                                    </label>
                                    <input
                                        type="text"
                                        id="accountNumber"
                                        class="form-control"

                                        value={accountNumber}
                                        onChange={handleAccountNumberChange}
                                    />
                                </div>
                                <div className="input_field">
                                    <label for="company_logo" class="form-label">
                                        Confirm Account number
                                    </label>
                                    <input
                                        type="text"
                                        class="form-control"

                                        id="confirmAccountNumber"
                                        value={confirmAccountNumber}
                                        onChange={handleConfirmAccountNumberChange}
                                    />
                                    <p style={{ color: 'red' }}>{errorMessage}</p>
                                </div>


                                {/* <div>
      <h2>Select Payment Option</h2>
      <div>
        <label>
          <input
            type="radio"
            name="paymentOption"
            value="Visa"
            checked={selectedOption === "Visa"}
            onChange={() => handleOptionChange("Visa")}
          />
          Visa
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            name="paymentOption"
            value="Debit"
            checked={selectedOption === "Debit"}
            onChange={() => handleOptionChange("Debit")}
          />
          Debit
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            name="paymentOption"
            value="PayPal"
            checked={selectedOption === "PayPal"}
            onChange={() => handleOptionChange("PayPal")}
          />
          PayPal
        </label>
      </div>
      <div>
        <label>
          <input
            type="radio"
            name="paymentOption"
            value="Mastercard"
            checked={selectedOption === "Mastercard"}
            onChange={() => handleOptionChange("Mastercard")}
          />
          Mastercard
        </label>
      </div>
      <p>You selected: {selectedOption}</p>
    </div> */}

                                <div className="d-flex border-bottom py-3 justify-content-center align-center">

                                    <button
                                        className="main_button"
                                        type="submit"
                                        style={{ margin: "0px 0px 0px 15px" }}

                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </section>


            </div>

        </>
    )
}

export default CompanyAccount