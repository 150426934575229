import React from 'react'
import { Link } from 'react-router-dom';


function HomeCardSecond(dataSecond) {
    return (
        // dataSecond.dataSecond.map((nitems) => {
          
                <>
                    <div className="col-md-6">
                        <Link className="all_links" to="/category-page">
                            <div className="part_2_inner_img img_cards">
                                <img src={dataSecond.dataSecond[0].img} alt="pimg" />
                            </div>
                            <div className="part_2_inner_btn">
                                <button className="btn btn-main product_viewmore">
                                View More
                                </button>
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <Link className="all_links" to="">
                            <div className="part_2_inner_img img_cards">
                                <img src={dataSecond.dataSecond[1].img} alt="" />
                            </div>
                            <div className="part_2_inner_content">
                                <div className="part_2_inner_content_price content_price">
                                    <p>US${dataSecond.dataSecond[1].price}</p>
                                </div>
                                <div className="part_2_inner_content_info">
                                    <p>{dataSecond.dataSecond[1].pice}pieces</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    </>
            
        // })
    )
}

export default HomeCardSecond
