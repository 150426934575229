import React, {useEffect, useState, useRef } from "react";
import DataService from "../services/data.service";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { NavLink, Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Notificationpage from "./notification/Notification";

const Notification = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Notifications";
        getData();
    }, []);

    const getData = async ()=>{
        setLoading(true)
          await DataService.getNotifications(true).then((data) => {
              setLoading(false);
              const response = data?.data?.data
              setData(data?.data?.data)
             
        }).catch((error)=>{
              const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.msg) ||
              error.message ||
              error.toString();
              setLoading(false);
        })
    }



     
    return (
        <>
        <Header />

        <section className="bg-dark-white">
        <div class="container h-100 py-5 bg-dark-white">
        <div className="d-flex flex-column flex-md-row">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-6 new_block">
            {/* <div className="back_icon">
            <Link to={'/profile'}><i class="fas fa-long-arrow-alt-left"></i></Link>
        </div> */}
                {loading ?
                    <div className="col-lg-6 m-auto">
                    {loading && (
                        <div className="main_spinner">
                        <div class="spinner-border text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    )}
                    </div>
                    :
                    <Notificationpage data={data} getData={getData}/>
                }  
             </div>
             <div className="col-12 col-md-3">
            </div>
        </div>
        </div>
        <Footer />
        </section>
    </>
  );
};

export default Notification;